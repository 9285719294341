import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styles from '../../../../Styles';

const DELTA = "challenges";

class ActivityListDelta extends Component {
  
  render() {
    const { session, profile, auth, group_id, classes, debrief } = this.props;
      return (
      <List>
          {session.active_game.results.hasOwnProperty(group_id)
            ? session.active_game.results[group_id].hasOwnProperty(DELTA)
              ? session.active_game.results[group_id][DELTA].map((item, index) => {
                  if ( debrief === false ){
                    return (
                      this.props.hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile) ?
                      <ListItem key={index} button onClick={() => this.props.itemAction(index, item, DELTA, 'Challenge')}>
                        <div dangerouslySetInnerHTML={ {__html: item } } className={classes.bodyListText}></div>
                      </ListItem>
                      :
                      <ListItem key={index}>                 
                         <div dangerouslySetInnerHTML={ {__html: item } } className={classes.bodyListText}></div>                                         
                      </ListItem>
                    );
                  } else {
                    return (                      
                      <ListItem key={index} button>
                        <div dangerouslySetInnerHTML={ {__html: item } } className={classes.bodyListText}></div>
                      </ListItem>                      
                    );
                  }
                })
              : null
            : null} 
        </List>
      );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ActivityListDelta);
