import {
  createStore,
  applyMiddleware,
  //compose
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './reducers/authReducer';
import sessionReducer from './reducers/sessionReducer';
import gameReducer from './reducers/gameReducer';
import thunk from 'redux-thunk';
import fbConfig from '../config/fbConfig';

import { reduxFirestore, getFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';

import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  gol_auth: authReducer,
  current_session: sessionReducer,
  game_state: gameReducer,
});

//const initialState = {};

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })), reduxFirestore(fbConfig)));

export default store;
