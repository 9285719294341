import p1 from './../images/avatar/p1.jpeg';
import p2 from './../images/avatar/p2.jpeg';
import p3 from './../images/avatar/p3.jpeg';
import p4 from './../images/avatar/p4.jpeg';
import p5 from './../images/avatar/p5.jpeg';


export const PROFILE_MALE = 'Microsoft Guy Online (Natural) - English (United States)';
export const PROFILE_FEMALE = 'Microsoft Clara Online (Natural) - English (Canada)';

export const PROFILE_MALE2 = 'Google UK English Male';
export const PROFILE_FEMALE2 = 'Google UK English Female';




export const StepList = {
    1 : { rotation: 20 },
    2 : { rotation: 20 },
    3 : { rotation: 20 },
    4 : { rotation: 20 },
    5 : { rotation: 20 }
};


export const ProfileCategories = {
    1 : 'Scenario 1',   
    2 : 'Scenario 2',    
    3 : 'Scenario 3',
    4 : 'Scenario 4',    
    5 : 'Scenario 5',    
}

export const ProfileList = [
    {
        id: 1,
        profile_step : 1,
        profile_text: `Samantha never picks up after herself – she leaves housekeeping and shadow boards untidy; she just refuses to do this.`,
        profile_image: p1,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 2,
        profile_step : 2,
        profile_text: `Matthew is storming around his workstation slamming boxes and throwing broken drywall.`,
        profile_image: p2,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 3,
        profile_step : 3,
        profile_text: `Luke, the lead hand, tends to avoid conflict and puts off talking about any issues that come up at work, hoping this will blow over. However instead the problems on the team are getting more intense and people are picking sides.`,
        profile_image: p3,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 4,
        profile_step : 4,
        profile_text: `Andy has a great sense of humor, though he is often sarcastic in his remarks, which seems to put others on edge.`,
        profile_image: p4,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    },
    {
        id: 5,
        profile_step : 5,
        profile_text: `Joe openly dislikes Bruce.  They have some disagreement that doesn’t involve work but has carried over to work.  This has created tension whenever they work together.`,
        profile_image: p5,
        profile_gender: PROFILE_MALE,
        profile_gender2: PROFILE_MALE2
    }
];


