import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { updateGroupState } from './../../../../../store/actions/gameActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import WalkStep from './../specific_components/walkStep';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextEntry from '../specific_components/textEntry';
import ioc from './../images/ioc.png';

class Board extends Component {

    constructor(props) {
        super(props)
        this.myRef = React.createRef()  
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const x = ( this.props.step * 350 );
        let t = 500 + (this.props.step * 75 );
        setTimeout(() => {
            this.scrollToMyRef(x);
        }, t);
    }

    scrollToMyRef = (x) => {
        if ( this !== undefined && this !== null ){
            if ( this.myRef !== undefined && this.myRef !== null ){
                if ( this.myRef.current !== undefined && this.myRef.current !== null ){
                    this.myRef.current.scrollLeft = x;
                }
            }
        }
    }

    render(){
        const { session, profile, auth, classes, step, group_id, params, stepsDone } = this.props;

        return (
            <div>
               <Typography variant='h4' >{session.active_game.name}</Typography> 
               <Typography variant="body1" className={classes.spaceBottom}>How does conflict with a team member affect you, your team, and your organization?<br/><br/>Tap on the steps to view different scenarios.</Typography>
               
               <div className={classes.board}>
                    <div className={classes.boardScroller} ref={this.myRef}>
                        <WalkStep
                                params={params}
                                cur_step={step}
                                step={1}
                                session={session}
                                group_id={group_id}
                                profile={profile}
                                auth={auth}
                                leader={session.groups[group_id].leadPlayer}
                                pos={{x:0,y:0}}
                                rotation='10deg'
                        />
                        <WalkStep
                                params={params}
                                cur_step={step}
                                step={2}
                                session={session}
                                group_id={group_id}
                                profile={profile}
                                auth={auth}
                                leader={session.groups[group_id].leadPlayer}
                                pos={{x:0,y:0}}
                                rotation='-10deg'
                        />
                        <WalkStep
                                params={params}
                                cur_step={step}
                                step={3}
                                session={session}
                                group_id={group_id}
                                profile={profile}
                                auth={auth}
                                leader={session.groups[group_id].leadPlayer}
                                pos={{x:0,y:0}}
                                rotation='10deg'
                        />
                        <WalkStep
                                params={params}
                                cur_step={step}
                                step={4}
                                session={session}
                                group_id={group_id}
                                profile={profile}
                                auth={auth}
                                leader={session.groups[group_id].leadPlayer}
                                pos={{x:0,y:0}}
                                rotation='-10deg'
                        />
                        <WalkStep
                                params={params}
                                cur_step={step}
                                step={5}
                                session={session}
                                group_id={group_id}
                                profile={profile}
                                auth={auth}
                                leader={session.groups[group_id].leadPlayer}
                                pos={{x:0,y:0}}
                                rotation='-10deg'
                        />
                    </div>
               </div>
               <Typography variant="h5">Scenarios Completed {stepsDone}/5 {stepsDone===5?<CheckCircleIcon fontSize="large" className={classes.successIcon}/>:null}</Typography>

               {stepsDone === 5 
               ?
               <div className={`${classes.spacingTop} ${classes.spacingBottom}`}>
               <Grid container>
                   <Grid item sm={6} xs={12}>
                   <Typography variant="body1">Great Job! Now that you've finished reviewing the scenarios, please answer the following questions:</Typography>
                   </Grid>
                   <Grid item sm={6} xs={12} align="center">
                    <img src={ioc} className={classes.imageSize} style={{maxWidth:"200px"}}/>
                   </Grid>
               </Grid>
               
               <Grid container className={classes.spacingTop} spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" style={{fontWeight: "bold", marginBottom:"15px", fontSize: "14px"}}>What are your key learnings about the impact of conflict from this activity?</Typography>
                        <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"notes"} savePath={`active_game.results.${group_id}.notes`}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body2" style={{fontWeight: "bold", marginBottom:"15px", fontSize: "14px"}}>What best practices can you use to reduce the impact of conflict?</Typography>
                        <TextEntry session={session} profile={profile} auth={auth} group_id={group_id} finalPath={"notesBP"} savePath={`active_game.results.${group_id}.notesBP`}/>
                    </Grid>
               </Grid>
               
                </div>
                : null}
            </div>
            
        );
    }
}



const mapStateToProps = (state) => {
    return { };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID))
    };
  };
  
  export default compose( connect(mapStateToProps, mapDispatchToProps), withStyles(styles) )(Board);