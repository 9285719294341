import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Typography from '@material-ui/core/Typography';
import { striphtml } from './../../Game/games/game_functions/functions';




export default function GameText(props) {

    const [plainText, setPlainText] = React.useState(props.o[props.oKey]);
    const [editorState, setEditorState] = React.useState(false);

    React.useEffect(() => {
        if ( props.oKey === 'game_title'){
            setEditorState(true);
            return;
        }
        const html = props.o[props.oKey];
        const contentBlock = htmlToDraft(html);
        if ( contentBlock ){
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
        }
    },[])


    const onChangeText = (event) => {
       setPlainText(event.target.value);
    }

    const savePlainText = (event) => {
        if ( props.setDoc === 'default' || props.setDoc === 'Default' ){
            return;
        }

        //const c = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        const c = plainText;
        //console.log(props.setDoc, props.hostID, props.game_id, 'game_texts', props.oKey, c);
        props.saveOption(props.setDoc, props.hostID, props.game_id, 'game_texts', props.oKey, c);
    }

    const saveText = (event, editorState) => {
        if ( props.setDoc === 'default' || props.setDoc === 'Default' ){
            return;
        }

        
        const c = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        console.log('OOKIE', props.setDoc, props.hostID, props.game_id, 'game_texts', props.oKey, c);
        props.saveOption(props.setDoc, props.hostID, props.game_id, 'game_texts', props.oKey, c);
    }

    const onEditorStateChange = (editorState) => {
        if ( props.disabled === true ){
            return;
        }
        setEditorState(editorState);
    }

    if ( editorState === false ){
        return null;
    }

    if ( props.oKey === 'game_title' ){
        return (
            <div ind={props.ind} style={{margin: "15px 0"}}>
                <TextField label={props.oKey} name={props.oKey} id={props.oKey} value={plainText} onChange={onChangeText} onBlur={(event) => savePlainText(event)}  fullWidth multiline />
            </div>
        )
    } else {
        return (
            <div key={props.ind} style={{margin: "15px 0", border: "1px solid #000", padding: "10px"}}>
                <Typography variant="h6">{props.oKey}</Typography>
                <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                handlePastedText={() => false}
                onBlur={(event, editorState) => saveText(event, editorState)}
                onEditorStateChange={onEditorStateChange}
                />            
          </div>
        )
    }
    

    

    
}