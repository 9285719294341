import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const ShowcaseFeedbackDiscussionLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download PDF" {...a11yProps(5)} />
                <Tab className={classes.tabsLNTab} label="Slide Deck" {...a11yProps(6)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>This activity helps participants consider behaviors that build trust or destroy trust at various levels within an organization</li>
                    <li className={classes.properListItem}>In small groups, participants identify trust behaviors (the things that people do or say) and record them on a chart</li>
                    <li className={classes.properListItem}>The groups will consider various levels of leadership within the organization as they go through this activity to highlight the differences between the roles</li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Behaviors that build or destroy trust</li>
                    <li className={classes.properListItem}>How trust behaviors can look different at various levels of an organization</li>
                    <li className={classes.properListItem}>The importance of leaders behaving (what you say and do) in a way that will build trust with their teams </li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>You will be working in small groups to discuss what success looks like for the team and what obstacles might get in the way of achieving our goals. </li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Have a discussion about what success looks like and record your points</li>
                    <li className={classes.properListItem}>Think about and record any obstacles that might get in the way of the successes</li>  
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What themes are showing up in the success column? </li>
                    <li className={classes.properListItem}>What themes do we see in the obstacles? </li>   
                    <li className={classes.properListItem}>What might we do, as a team, to overcome some of the obstacles? </li>   
                    <li className={classes.properListItem}>How can collectively work together to achieve success? </li>                             
                </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Leader Notes</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download PDF
                </Button>
                </a>
            </TabPanel>
           
            <TabPanel className={classes.LNTabPanel} value={value} index={6}>
                <Typography variant="h5">Slide Deck</Typography>  
                              
            </TabPanel>
           
        </div> 
    )
}

export default withStyles(styles)(ShowcaseFeedbackDiscussionLN);