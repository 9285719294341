import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const TrustSpectrumLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}>This activity highlights the fact that trust, for each of us, covers a spectrum.
                 A series of real-life situations are examined and discussed to identify if we would give or withhold our trust to this leader.
                  Individuals will have varying degrees of trust they would give in each situation and together teams internalize the impact on the work environment.</Typography>

            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Identifying key behaviours that improve trust on a team</li>
                    <li className={classes.properListItem}>Examining behaviours that negatively impact trust on a team</li>
                    <li className={classes.properListItem}>The implications for a leader when employees do not freely share their trust</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Like many topics that we have discussed, trust covers a spectrum</li>
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}>Would I trust this person ranges from:</Typography>
                <ul>
                    <li>1-Never: <b>I would NEVER trust this leader.</b></li>
                    <li>3-Sometimes: <b>I would SOMETIMES trust this leader.</b></li>
                    <li>5-Always: <b>I would ALWAYS try to trust this leader.</b> </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}>Let’s look at some examples and determine where on the spectrum our level of trust is. In small groups, read through the scenarios. Decide, as a group, where you would place this on the trust spectrum. </Typography>      
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Review each situation</li>
                    <li className={classes.properListItem}>Where would you place your trust with this leader?  </li>
                    <li className={classes.properListItem}>Place the card on the appropriate part of the spectrum. </li>
                </ul>
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What do you notice about the qualities that instill trust and those that don’t? </li>
                    <li className={classes.properListItem}>How do certain behaviors impact your trust level in a leader? </li>
                    <li className={classes.properListItem}>What happens if a leader scores highly on the lower end of the spectrum? </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
          
        </div> 
    )
}

export default withStyles(styles)(TrustSpectrumLN);