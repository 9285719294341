import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import walkawhileCropped from './../images/ioc.png';
import styles from '../../../../Styles';
import { HideUntilLoaded } from 'react-animation';
import { moveBackToActiveWithParams, updateGroupStateParams } from './../../../../../store/actions/gameActions';
import { determineHost } from './../../game_functions/functions';
import { hasGameControl } from '../../game_functions/functions'
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import p1_token from './../images/avatar/p1_token.png';
import p2_token from './../images/avatar/p2_token.png';
import p3_token from './../images/avatar/p3_token.png';
import p4_token from './../images/avatar/p4_token.png';
import p5_token from './../images/avatar/p5_token.png';


const INITIAL_STATE = {
    hidden: true
}

class WalkStep extends Component {
    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    componentDidMount(){
        if ( this.props.step !== this.props.cur_step ){
            if ( this.props.step <= this.props.cur_step ){
                this.setState({
                    ...this.state,
                    hidden: false
                });
            }
        } else {
            let t = 50; 
            setTimeout(() => {                
                this.setState({
                    ...this.state,
                    hidden: false
                });
            },t);
        }
    }

  goToActivityPhase = () => {
    if ( hasGameControl(this.props.session.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile) ){
    
        let p = { ...this.props.session.active_game.groupStates[this.props.group_id].params };
        p.phase = 'activity';
        p.step = this.props.step;
        this.props.updateGroupStateParams( this.props.group_id, p, determineHost(this.props.profile, this.props.auth));
    } else {
        /* TODO: ADD SNACKBAR TO ALERT USER THAT ONLY A LEADER CAN CONTROL */
    }
  }


  imageToUse = (step) => {
        if ( step === 1 ){
            return <img src={p1_token} style={{width:"200px"}}/>;
        }
        if ( step === 2 ){
            return <img src={p2_token} style={{width:"200px"}}/>;
        }
        if ( step === 3 ){
            return <img src={p3_token} style={{width:"200px"}}/>;
        }
        if ( step === 4 ){
            return <img src={p4_token} style={{width:"200px"}}/>;
        }
        if ( step === 5 ){
            return <img src={p5_token} style={{width:"200px"}}/>;
        }
  }
    
  render() {
      
    const { classes, step, rotation, cur_step } = this.props;
     
        if ( this.state.hidden && cur_step !== step ){
            return null;
        }
        if ( cur_step === step && this.state.hidden ){
            return(
                <div onClick={() => this.goToActivityPhase()} className={`${classes.walkStep} ${classes.doNotShow}`}>
                <HideUntilLoaded imageToLoad={walkawhileCropped}>
                    { cur_step === step ? <div className={classes.arrowPos}><div className="arrow bounce"></div></div> : null }
                    <div className={classes.padWalkStep}>
                        { this.imageToUse(step) }
                        <Grid item xs={12} align="center">
                            Scenario {step}
                        </Grid>
                    </div>
                </HideUntilLoaded>
            </div>
            );
        }
        return (
            <div onClick={() => this.goToActivityPhase()} className={classes.walkStep}>
                <HideUntilLoaded imageToLoad={walkawhileCropped}>
                    { cur_step === step ? <div className={classes.arrowPos}><div className="arrow bounce"></div></div> : null }
                    <div className={classes.padWalkStep}>
                        { this.imageToUse(step) }
                        <Grid item xs={12} align="center">
                            Scenario {step}
                        </Grid>
                    </div>
                </HideUntilLoaded>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {        
        moveBackToActiveWithParams: (session, host, parameters) => dispatch(moveBackToActiveWithParams(session, host, parameters)),
        updateGroupStateParams: ( group, groupStateParams, hostID ) => dispatch(updateGroupStateParams(group, groupStateParams, hostID) )
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WalkStep);
