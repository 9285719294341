import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  




export const ComparisonTable = (props) => {

    

  return (
    <div>
      <div>Puzzle Times</div>
      <TableContainer component={Paper} style={{maxWidth: "600px"}}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Group</StyledTableCell>
              <StyledTableCell align="right">Last Round</StyledTableCell>
              <StyledTableCell align="right">This Round</StyledTableCell>
              <StyledTableCell align="right">Difference</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(props.session.active_game.results).sort().map((groupKey, ind) => {
              let minutes = 0;
              let secondsElapsed = 0;
              let lastSecondsElapsed = 0;
              let remainder = 0;
              let timeelapsed = 0;
              let time = "";

              let timeMessage = "";
              let lastMessage = "";

              if ( props.session.active_game.results[groupKey].finishedIn !== undefined ){

                /* they finished the current round */

                  secondsElapsed = props.session.active_game.results[groupKey].finishedIn;
                  minutes = Math.floor(secondsElapsed / 60);
                  remainder = secondsElapsed % 60;

                  time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;
                  timeMessage = `${time}`;



              } else {
                  timeMessage = "Puzzle not completed.";
              }

              console.log('GroupKey', groupKey, props.session.active_game.resultSnapshot[groupKey]);
              if ( props.session.active_game.resultSnapshot[groupKey] === undefined ){
                lastMessage = "N/A";
                lastSecondsElapsed = 0;
              } else {
                if ( props.session.active_game.resultSnapshot[groupKey].finishedIn !== undefined ){
                  lastSecondsElapsed = props.session.active_game.resultSnapshot[groupKey].finishedIn;
                  minutes = Math.floor(lastSecondsElapsed / 60);
                  remainder = lastSecondsElapsed % 60;
    
                  time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${lastSecondsElapsed} seconds`;
                  lastMessage = `${time}`;
                } else {
                  lastMessage = "Puzzle not completed.";
                }
    
              }
              
              let diff = '';
              if ( lastMessage === "N/A" ){
                diff = 'N/A';
              } else {
                diff = (secondsElapsed - lastSecondsElapsed);
              }

              if ( timeMessage === 'Puzzle not completed.' || lastMessage === 'Puzzle not completed.'){
                diff = "N/A";
              }

              if ( diff !== 'N/A' ){
                  
                  minutes = Math.floor(Math.abs(diff) / 60);
                  remainder = lastSecondsElapsed % 60;
    
                  diff = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${lastSecondsElapsed} seconds`;
              }
              

                /* they either did not finish the current round, or this is an old result that didn't support FinishedIn */

                // First, check to see if the old finishedOn is in tact. If it is, then use that since this is an old result.
                  /*if ( props.session.active_game.groupStates[groupKey].params.finishedOn !== undefined ){
                      timeelapsed = props.session.active_game.groupStates[groupKey].params.finishedOn - props.session.active_game.groupStates[groupKey].params.startedOn;
                      secondsElapsed = Math.floor(timeelapsed / 1000);
                      minutes = Math.floor(secondsElapsed / 60);
                      remainder = secondsElapsed % 60;

                      time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;
                      timeMessage = `Puzzle completed in ${time}`;
                  } else {
                    // If this also doesn't exist, it simply means that they did not complete this round
                      time = "";
                      timeMessage = "Puzzle not completed";
                  }*/

                  // Then, we need to see if they completed the last round. Only supported in newer versions.




                  
                  
              

              
              return (
                <StyledTableRow key={ind}>
                  <StyledTableCell component="th" scope="row">
                    {props.session.groups[groupKey].title}
                  </StyledTableCell>
                  <StyledTableCell align="right">{lastMessage}</StyledTableCell>
                  <StyledTableCell align="right">{timeMessage}</StyledTableCell>
                  <StyledTableCell align="right">{diff}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
