import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Typography from '@material-ui/core/Typography';

import {
  //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
  //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
  GROUP_STATE_RESULTS, GROUP_STATE_ACTIVE
} from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
//import GameHelp from './gameHelp';
import { updateGroupState, updateGroupStateParams, updateResultsPathObject, editResults, removeResults, moveToPhaseWithParamsOneGroup } from './../../../../../store/actions/gameActions';
import WordPlayground from './../specific_components/wordPlayground.js';
import styles from '../../../../Styles';

import { DragDropContext } from 'react-beautiful-dnd';
import DropArea from './../specific_components/dropArea';
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import PieceList from './../specific_components/pieceList';
import DoneIcon from '@material-ui/icons/Done';

const INITIAL_STATE = {  
  open: false,  
  pieceListOpen: false,
  isInterfaceLoaded: false,
  containerWidth: null,
  gridRightWidth: null,
  gridLeftWidth: null,
};



class Activity extends Component {
  constructor(props) {
    super(props);

    
    this.state = {
      ...INITIAL_STATE,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    
  }

  updateWindowDimensions() {
    this.setState({ 
      ...this.state, 
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if ( !this.state.isInterfaceLoaded ){
      if ( this.mainDiv !== undefined ){
        //console.log('hERE!', this.mainDiv);
        this.setState({
          ...this.state,
          isInterfaceLoaded: true,
        });
      }
    }
    //window.addEventListener('resize', this.updateWindowDimensions);

  }

  
  finishActivity(){
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  }



  

  render() {
    const {
      //game_id, game_title,
      session,
      profile,
      auth,
      //group_state, game_state,
      classes,
      game_options
      //leader
    } = this.props;
    const { open, helpOpen, entry_text } = this.state;
    const isInvalid = entry_text === '';

    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const numResults = (session.active_game.results[group_id] === undefined) ? 0 : (session.active_game.results[group_id].card_choices === undefined ? 0 : session.active_game.results[group_id].card_choices.length);
    //console.log('GROUP_ID:', group_id);
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile) === true ? true : 
      this.props.session.active_game.groupStates[this.props.group_id].params.secondaryLeader === this.props.auth.uid ? true : false;

    console.log('hasControl?', hasControl, this.props.session.active_game.groupStates[this.props.group_id].params.secondaryLeader, this.props.auth.uid)

    const results = session.active_game.results[group_id] === undefined ?
                      {} :
                      session.active_game.results[group_id].choices === undefined ?
                      {} :
                      session.active_game.results[group_id].choices;
    const host = determineHost(profile, auth);
    
    let lockText = "";
    if ( game_options !== false ){
      lockText = game_options.game_texts.lock_text;
    }
    
    return (
      
      <div ref={this.mainDiv}>
       
 

            <div>
              <div className={classes.spacingBottom}>
                <PieceList session={session} hasControl={hasControl} profile={profile} host={host} auth={auth} group_id={group_id}/>
              </div>
              { hasControl ? 
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DoneIcon/>}
                  onClick={() => this.finishActivity()}
                  style={{marginRight: "20px"}}
                >
                  Finish Activity
                </Button>
                : null }
            </div>


              
       
         
        
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResultsPathObject: (path, value, hostID) => dispatch(updateResultsPathObject(path, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    moveToPhaseWithParamsOneGroup: (session, host, parameters, groupState, group) => dispatch(moveToPhaseWithParamsOneGroup(session, host, parameters, groupState, group)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);
