import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import styles from '../../../../Styles';

const DELTA = "delta";

class TextEntryList extends Component {
  
  render() {
    const { session, profile, classes, auth, group_id, resultsPath, finalPath, debrief } = this.props;
    console.log("RESULTS PATH >>>>>>>>>>>",resultsPath)
    let hasResults = false;
    if ( resultsPath.hasOwnProperty(group_id ) ){
        if ( resultsPath[group_id].hasOwnProperty(finalPath) ){
            hasResults = true;
        }
    }

      return (
      <List style={{border: "1px solid #ccc", borderRadius: "5px", padding: "20px"}}>
          { hasResults === true
              ? Object.keys(resultsPath[group_id][finalPath]).sort().map((item, index) => {
                  if ( debrief === false ){
                    return (
                      this.props.hasControl === true ?
                      <ListItem key={index} button onClick={() => this.props.itemAction(item, resultsPath[group_id][finalPath][item])}>
                        <div dangerouslySetInnerHTML={ {__html: resultsPath[group_id][finalPath][item] } } className={classes.bodyListText}></div>
                      </ListItem>
                      :
                      <ListItem key={index}>                 
                         <div dangerouslySetInnerHTML={ {__html: resultsPath[group_id][finalPath][item] } } className={classes.bodyListText}></div>                                         
                      </ListItem>
                    );
                  } else {
                    return (                      
                      <ListItem key={index} button>
                        <div dangerouslySetInnerHTML={ {__html: resultsPath[group_id][finalPath][item] } } className={classes.bodyListText}></div>
                      </ListItem>                      
                    );
                  }
                })
              : <ListItem>
                <div style={{fontSize:"10px", fontStyle:"italic", opacity:"0.7"}}>Click "Add Response" to add an answer to this list.</div>
              </ListItem>
          }
        </List>
      );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TextEntryList);
