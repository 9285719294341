import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from '../../Styles';

const getItemStyle = (selected) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '5px',
  margin: '0 8px 0 0',
  background: selected ? '#dde9f5' : 'transparent',
  border: selected ? '1px solid #000' : '1px solid #eee',
  borderRadius: "10px"
});


class Player extends Component {

  render() {
    const { classes } = this.props;

    const sel = this.props.selectedPlayers.includes(this.props.player.id) ? true : false;
    return (
      <div style={getItemStyle(sel)} onClick={(e) => this.props.clickHandler(e, this.props.player.id)} onContextMenu={(e) => this.props.contextHandler(e, this.props.player.id)}>
        <Grid container className={classes.avatarRoot} spacing={2}>
          <Grid item xs={12}>
            <Grid container justify='center' spacing={2}>
              <Grid item align='center'>
                <Avatar alt={this.props.player.displayName ? this.props.player.displayName : 'New Player'} className={` ${classes.medium} ${classes.glassPurpleAvatar}`}></Avatar>
                <Typography variant='body1' className={classes.nameSpacing}>
                  {this.props.player.displayName ? this.props.player.displayName : 'New Player'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Player.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Player);