import React, { Component } from "react";
import {
  editResultsPath,
  removeResultsPath,
  updateGroupStateParamsVal,
  updateGroupStateStateVal,
  updateResultsPath,
} from "../../../../../store/actions/gameActions";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import PieceDrawer from "./pieceDrawer";
import Popover from "@material-ui/core/Popover";
import TextField from "@material-ui/core/TextField";
import WordPlayground from "./wordPlayground";
import { compose } from "redux";
import { connect } from "react-redux";
import { pieces } from "./../data/data";
import styles from "../../../../Styles";
import { v4 as uuidv4 } from "uuid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography"; 

const INITIAL_STATE = {
  mouseX: null,
  mouseY: null,
  currentSlot: null,
  pieceListOpen: false,
  popoverOpen: false,
  anchorEl: null,
  currentPiece: null,
  drawingEnabled: false,
  eraserEnabled: false,
  draggingSuggestion: false,
  scrollPosition: 0,
  scrollBound: false,
  suggestText: "",
  suggestOpen: false,
};

class PieceList extends Component {
  constructor(props) {
    super(props);

    this.draggedEl = React.createRef();
    //this.scrollContainer = React.createRef();
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    //console.log('ok', this.scrollContainer);
    //if ( this.scrollContainer.current !== null ){
    //this.scrollContainer.current.addEventListener('scroll', this.scrollMonitor);
    //}
  }

  componentWillUnmount() {
    //this.scrollContainer.current.removeEventListener('scroll', this.scrollMonitor)
  }

  openMenu(event, index, word) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.props.hasControl) {
      return;
    }

    this.setState({
      ...this.state,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      currentSlot: index,
    });
  }

  handleContextClose = () => {
    this.setState({
      ...this.state,
      mouseX: null,
      mouseY: null,
      currentSlot: null,
    });
  };

  handlePopoverClose = () => {
    this.setState({
      ...this.state,
      pieceListOpen: false,
      popoverOpen: false,
      anchorEl: null,
      currentPiece: null,
    });
  };
  handlePopoverOpen = (event, piece) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      popoverOpen: true,
      currentPiece: piece,
    });
  };

  componentWillUnmount() {
    //paper.project.remove();
  }

  dragStart = (ev) => {
    let w = ev.target.attributes.pwidth.value;
    let h = ev.target.attributes.pheight.value;

    ev.dataTransfer.setData("text", ev.target.id);
    ev.dataTransfer.setDragImage(ev.target, 10, 10);

    let r = {
      src: ev.target.attributes.src.value,
      id: ev.target.attributes.piece.value,
      width: w,
      height: h,
    };

    this.draggedEl.current = r;

    setTimeout(() => this.closePieceList(), 150);
  };

  clearDraggedEl = () => {
    this.draggedEl.current = null;
    this.setState({
      ...this.state,
      draggingSuggestion: false,
    });
  };



  updateImages = (type, image) => {
    /* Types: add, update, remove */
    let path = `active_game.results.${this.props.group_id}.images`;
    if (type === "add") {
      this.props.editResultsPath(path, image, this.props.host);
    } else if (type === "remove") {
      this.props.editResultsPath(path, image, this.props.host);
    } else if (type === "update") {
      this.props.editResultsPath(path, image, this.props.host);
    }
    //console.log("update images!!!", type, image);
  };




  assignDraggedEl = (el) => {
    //console.log("assigning?", el);
    this.draggedEl.current = el;
    //console.log("how is it not done? ", this.draggedEl.current);
  };



  onChange = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  handleClose = (event) => {
    this.setState({
      ...this.state,
      suggestOpen: false,
      pieceListOpen: false,
      popoverOpen: false,
    });
  };

  scrollMonitor = (e) => {
    //e.target.scrollTop
    //console.log("huh?");
    let s = e.target.scrollTop - this.state.scrollPosition;
    if (s > 100 || s < 100) {
      //console.log("test");
      this.setState({
        ...this.state,
        scrollPosition: e.target.scrollTop,
      });
    }
  };

  render() {
    const { classes, session, hasControl, group_id, profile, host, auth } =
      this.props;
    const pieceList = [];

    return (
      <div>
        <Typography variant="h4">{this.props.title}</Typography>
        {/* DIALOG FOR SUGGESTIONS */}

    
          

        {/* BUTTON TO TOGGLE THE PIECE LIST DRAWER

        <Button
          variant="contained"
          color="primary"
          disabled={this.state.pieceListOpen === true ? true : false}
          startIcon={<ExtensionIcon />}
          onClick={() => this.openPieceList()}
          style={{ marginRight: "20px" }}
        >
          Open Piece List
        </Button> */}

        {/* THE POPOVER TO BE USED FOR NON-LEADERS */}


        {/* THE DRAWER */}


        {/* THE CANVAS */}
        {/* <Suggestions
          session={session}
          results={
            session.active_game.results[this.props.group_id] === undefined
              ? []
              : session.active_game.results[this.props.group_id].suggestions ===
                undefined
              ? []
              : session.active_game.results[this.props.group_id].suggestions
          }
          profile={this.props.profile}
          auth={this.props.auth}
          host={this.props.host}
          control={hasControl}
          group_id={this.props.group_id}
          assignDragEl={this.assignDraggedEl}
          updateDragState={this.toggleSuggestionDrag}
        /> */}
        <div className={classes.spacingTop}>
          <WordPlayground
            session={session}
            title={session.groups[this.props.group_id].title}
            results={
              session.active_game.results[this.props.group_id] === undefined
                ? {}
                : session.active_game.results[this.props.group_id]
            }
            draggedEl={this.draggedEl.current}
            clearDraggedEl={this.clearDraggedEl}
            updateLines={this.updateLines}
            updateImages={this.updateImages}
            control={true}
            profile={profile}
            host={host}
            auth={auth}
            group_id={group_id}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateParamsVal: (group, val, groupStateParams, hostID) =>
      dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
    updateGroupStateStateVal: (group, groupState, hostID) =>
      dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    editResultsPath: (path, value, hostID) =>
      dispatch(editResultsPath(path, value, hostID)),
    removeResultsPath: (path, value, hostID) =>
      dispatch(removeResultsPath(path, value, hostID)),
    updateResultsPath: (path, value, hostID) =>
      dispatch(updateResultsPath(path, value, hostID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(PieceList);
