import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import {
    updateGroupStateParamsVal,
    updateResultsParamsVal,
    editResultsPath,
    updateGroupStateStateVal,
    updateResultsPath
} from '../../../../../store/actions/gameActions'
import paper from 'paper/dist/paper-core'
import WordPlayground from './wordPlayground'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
    GROUP_STATE_RESULTS,
    GAME_STATE_RESULTS
} from '../../../../../store/actions/gameActions'
import { CARDS } from '../data/data'
import CardList from './cardList'
import styles from '../../../../Styles'

const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null
}

class TangramMS extends Component {
    constructor(props) {
        super(props)

        const leader =
            this.props.session.groups['group-1'].leadPlayer
        const currentPlayerId = this.props.auth.uid



        this.state = {
            ...INITIAL_STATE,
        }
    }

    componentDidMount() { }
    componentWillUnmount() {
        paper.project.remove()
    }

    finishActivity = () => {
        let groupID = this.props.group_id

        let now = Date.now()
        this.props.updateGroupStateParamsVal(
            groupID,
            'finishedOn',
            now,
            this.props.host
        )
        let timeelapsed =
            now -
            this.props.session.active_game.groupStates[groupID].params.startedOn
        let secondsElapsed = Math.floor(timeelapsed / 1000)

        this.props.updateResultsParamsVal(
            groupID,
            'finishedIn',
            secondsElapsed,
            this.props.host
        )
        /* TODO: Update group state, but ensure it doesn't touch the params */
        this.props.updateGroupStateStateVal(
            groupID,
            GROUP_STATE_RESULTS,
            this.props.host
        )
    }

    updateTangramCoords = (id, pieceId, x, y, rotation, originalRotation = null) => {
        let path = `active_game.results.${this.props.group_id}.tangramCoords.${pieceId}`
        let val = ``;
        //console.log('wtf', originalRotation, id, pieceId);

        if (originalRotation !== null && originalRotation !== 'null') {
            //console.log('what');
            val = {
                x: x,
                y: y,
                type: id,
                rotation: rotation,
                originalRotation: originalRotation
            }
        } else {
            //console.log('no', originalRotation);

            val = {
                x: x,
                y: y,
                type: id,
                rotation: 0,
                originalRotation: 0
            }
        }

        //console.log('asdfas', path, val, this.props.host);
        this.props.editResultsPath(path, val, this.props.host)
    }

    updateImages = (type, image) => {
        /* Types: add, update, remove */
        let path = `active_game.results.${this.props.group_id}.images`
        if (type === 'add') {
            this.props.updateResultsPath(path, image, this.props.host)
        } else if (type === 'remove') {
            this.props.editResultsPath(path, image, this.props.host)
        } else if (type === 'update') {
            this.props.editResultsPath(path, image, this.props.host)
        }
    }

    render() {
        const { classes } = this.props
        const { cards } = this.state

        //console.log('uhhh', this.props.control);

        return (
            <div>
                <div>


                    <WordPlayground
                        draggedEl={this.props.draggedEl}
                        clearDraggedEl={this.props.clearDraggedEl}
                        tangramCoords={
                            this.props.session.active_game.results[
                                this.props.group_id
                            ] !== undefined
                                ? this.props.session.active_game.results[
                                    this.props.group_id
                                ].tangramCoords
                                : undefined
                        }
                        results={
                            this.props.session.active_game.results[this.props.group_id] ===
                                undefined
                                ? {}
                                : this.props.session.active_game.results[
                                this.props.group_id
                                ]
                        }
                        updateCoords={this.updateTangramCoords}
                        updateImages={this.updateImages}
                        control={this.props.isDebrief ? false : true}
                    ></WordPlayground>



                    <div className={classes.spacingTop}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.finishActivity()}
                        >
                            I'm Finished
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupStateParamsVal: (group, val, groupStateParams, hostID) =>
            dispatch(
                updateGroupStateParamsVal(group, val, groupStateParams, hostID)
            ),
        updateResultsParamsVal: (group, val, groupStateParams, hostID) =>
            dispatch(
                updateResultsParamsVal(group, val, groupStateParams, hostID)
            ),
        updateGroupStateStateVal: (group, groupState, hostID) =>
            dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) =>
            dispatch(editResultsPath(path, value, hostID)),

        updateResultsPath: (path, value, hostID) =>
            dispatch(updateResultsPath(path, value, hostID))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(TangramMS)
