import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, editResultsPath, removeResultsPath, updateGroupStateStateVal } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DragDropContext } from 'react-beautiful-dnd';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Hidden from '@material-ui/core/Hidden';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import DropArea from './../specific_components/dropArea';
import TextLogger from './../specific_components/textLogger';
import ActivityTimer from './../specific_components/activityTimer';
import stopDrop from './../images/stopDrop.png';
import CaseStudyPopup from './../specific_components/caseStudy';
import HelpTL from './../specific_components/helpTL';
import HelpTR from './../specific_components/helpTR';
import HelpLL from './../specific_components/helpLL';
import HelpLR from './../specific_components/helpLR';
import { URGENT_AND_NOT_IMPORTANT, URGENT_AND_IMPORTANT, NOT_URGENT_AND_NOT_IMPORTANT, IMPORTANT_AND_NOT_URGENT } from './../data/data';

const UPPER_LEFT = "ul";
const UPPER_RIGHT = "ur";
const LOWER_LEFT = "ll";
const LOWER_RIGHT = "lr";
const UNASSIGNED = "ua";


const INITIAL_STATE = {
  noItems: true,
  plusItemsExist: false,
  deltaItemsExist: false,
  open: false,
  priorities: {},
  curColumn: null,
  dropDisabled: false,
  wasOpen: false,
  isEdit: false,
  showList: false,
  ulItems: [],
  urItems: [],
  lrItems: [],
  llItems: [],
  uaItems: ["a", "b"]
};

const getTimeEpoch = () => {
  return `p${new Date().getTime().toString()}`;                             
}


class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
  }

  onDragStart(result){
    this.setState({
      ...this.state,
      dropDisabled: result.source.droppableId
    })
  }

  onDragEnd(result) {
    const { source, destination, draggableId } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    // we don't care about re-ordering here, so just ignore drops that are in the same area where they started
    if (destination.droppableId === source.droppableId) {
      return;
    }

    const start = (this.state.priorities[source.droppableId] === undefined ? {} : {...this.state.priorities[source.droppableId]});
    const finish = (this.state.priorities[destination.droppableId] === undefined ? {} : {...this.state.priorities[destination.droppableId]});
    
    finish[draggableId] = start[draggableId];
    delete start[draggableId];

    let priorities = {...this.state.priorities};

    priorities[source.droppableId] = start;
    priorities[destination.droppableId] = finish;

    this.setState({
      ...this.state,
      priorities: priorities,
      dropDisabled: false
    });

    let p = `active_game.results.${this.props.group_id}`;
    this.props.editResultsPath(p, priorities, determineHost(this.props.profile, this.props.auth));
    
    return;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      ...this.state,
      priorities: (this.props.session.active_game.results[this.props.group_id] === undefined ? {} : this.props.session.active_game.results[this.props.group_id])           
    });
    
  }

  componentDidUpdate(prevProps, prevState) {
      /* When a leader opens the writing window, we want to show it for all users */
      if ( ( this.props.session.active_game.groupStates[this.props.group_id].params.writing !== undefined && this.props.session.active_game.groupStates[this.props.group_id].params.writing !== false ) && (this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === undefined || this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === false) && this.state.open === false ) {
        this.setState({
          ...this.state,
          open: true
        })
      }
      /* When a leader closes the writing window, we want to close it for all users */
      if ( this.props.session.active_game.groupStates[this.props.group_id].params.writing === false && this.state.open === true ) {
        this.setState({
          ...this.state,
          open: false
        })
      }
      /* Something has moved or been created. Update UI */
      if ( this.props.session.active_game.results[this.props.group_id] !== prevProps.session.active_game.results[this.props.group_id] ){
          if ( this.state.priorities !== this.props.session.active_game.results[this.props.group_id] ){
            this.setState({
              ...this.state,
              priorities: (this.props.session.active_game.results[this.props.group_id] === undefined ? {} : this.props.session.active_game.results[this.props.group_id])          
            });
          }
      }
      if ( (this.props.session.active_game.groupStates[this.props.group_id].params.interrupting !== undefined && this.props.session.active_game.groupStates[this.props.group_id].params.interrupting !== false) && this.state.open === true ){
        this.setState({
          ...this.state,
          open: false
        });
      }
  }

  addPriority = () => {
    let group_id = this.props.group_id;
    const hasControl = hasGameControl(this.props.session.groups[group_id].leadPlayer, this.props.auth.uid, this.props.profile);

    if ( !hasControl ){
      return;
    }
    this.setState({
        ...this.state,
        entry_text: '',
        isEdit: false,
        curColumn: UNASSIGNED
    });

    let s = {
      ...this.props.session.active_game.groupStates[group_id].params,
      writing: this.props.auth.uid,
    };
    this.props.updateGroupStateParams(group_id, s, determineHost(this.props.profile, this.props.auth));
  }

  handleClose = (event) => {
    if ( !hasGameControl(this.props.session.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile) ){
      return;
    }
    let groupID = this.props.group_id;
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: false,
      ttd: ""
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  handleSave = (event) => {
    let groupID = this.props.group_id;
    let p = `active_game.results.${groupID}.${this.state.curColumn}.${getTimeEpoch()}`;
    this.props.editResultsPath(p, this.state.entry_text.replaceAll('\n', '<br/>'), determineHost(this.props.profile, this.props.auth));

    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: false,
      ttd: ""
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
    });
  };

  

  itemAction = (index, value, col) => {
    this.setState({
      ...this.state,
      isEdit: index,
      entry_text: value.replaceAll('<br/>', '\n'),
      curColumn: col
    });

    let s = {
      ...this.props.session.active_game.groupStates[this.props.group_id].params,
      writing: this.props.auth.uid,
      ttd: value.replaceAll('<br/>', '\n')
    };
    this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
  };

  updateRow = (row) => {
    let groupID = this.props.group_id;
    let p = `active_game.results.${groupID}.${this.state.curColumn}.${row}`;
    this.props.editResultsPath(p, this.state.entry_text.replaceAll('\n', '<br/>'), determineHost(this.props.profile, this.props.auth));

    let s = {
      ...this.props.session.active_game.groupStates[this.props.group_id].params,
      writing: false,
      ttd: false
    };
    this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let groupID = this.props.group_id;
    let p = `active_game.results.${groupID}.${this.state.curColumn}.${row}`;
    this.props.removeResultsPath(p, null, determineHost(this.props.profile, this.props.auth));

    let s = {
      ...this.props.session.active_game.groupStates[this.props.group_id].params,
      writing: false,
    };
    this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      isEdit: false,
    });
  };

  finishActivity = () => {
    let groupID = this.props.group_id;
  
    /* TODO: Update group state, but ensure it doesn't touch the params */
    this.props.updateGroupStateStateVal(groupID, GROUP_STATE_RESULTS, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });    
  };

  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  render() {
    const {
      session,
      profile,
      auth,
      group_id,
      classes,
    } = this.props;
    const { entry_text } = this.state;
    const isInvalid = entry_text === '';
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);
    const editingID = this.state.isEdit !== false ? parseInt((this.state.isEdit).replace('p', '')) : 0;
    const numInterruptions = session.active_game.groupStates[group_id].params.alreadyPicked === undefined ? 0 : session.active_game.groupStates[group_id].params.alreadyPicked.length;

    return ( 
      <div>
          { /* Dialogue for Adding/Editing Notes */}
         <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth PaperProps={{style: {backgroundColor: '#fdfd86'}}}>
         { hasControl === true && this.state.open ?
                this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid ?
                  <TextLogger ttd={this.props.session.active_game.groupStates[this.props.group_id].params.ttd} group_id={group_id} val={entry_text} host={determineHost(this.props.profile, this.props.auth)}/>
                : null
              : null
         }
            <DialogContent>
              <DialogContentText className={classes.bodyText}>{ hasControl === true ? (editingID > 0 && editingID <= 12) ? `` : `` : 'Group leader is creating/editing/viewing an entry...'}</DialogContentText>
              { hasControl ?
                this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid ?
                 
                (editingID > 0 && editingID <= 12) ?
                <div className={classes.spacingBottom}>{entry_text}</div>
                : <TextField multiline autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label="Issue" type='email' fullWidth /> 
                
                :
                <div className={classes.spacingBottom}>{this.props.session.active_game.groupStates[this.props.group_id].params.ttd}</div>
              : 
                <div className={classes.spacingBottom}>{this.props.session.active_game.groupStates[this.props.group_id].params.ttd}</div>
              }
            </DialogContent>
                  <DialogActions>
                  { hasControl === true && this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid && (editingID === 0 || editingID > 12) ? 
                    this.state.isEdit !== false ? (
                      <div>
                        <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                          Delete
                        </Button>
                        <Button onClick={this.handleClose} color='primary'>
                          Cancel
                        </Button>
                        <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                          Save
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <Button onClick={this.handleClose} color='primary'>
                          Cancel
                        </Button>
                        <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                          Save
                        </Button>
                      </div>
                    )
                    : null }
                  </DialogActions>
          </Dialog>
        { numInterruptions >= 12 ? 
          null
          :
        <ActivityTimer session={session} auth={auth} profile={profile} group_id={group_id} host={determineHost(this.props.profile, this.props.auth)} control={hasControl}/>
        }
        <Typography variant='h3'>
            {session.active_game.name}
        </Typography>
        <Typography variant="body1" className={classes.spacingTop}>
          Based on the <CaseStudyPopup/>, what are the issues you need to deal with and in what order? <br/><br/>Use the Add Issue button to add specific issues and then drag them to the appropriate spot within the priority matrix. 
        </Typography>
        <Grid container>
          <Grid xs={6} item>
            <Button type='submit' color="primary" variant='contained' onClick={() => this.addPriority()} className={classes.spacingTop} startIcon={<AddIcon/>}>
              Add Issue
            </Button>
          </Grid>
          <Grid xs={6} item align="right">
            { hasControl && numInterruptions === 12 ? 
            <Button type='submit' color="primary" variant='contained' onClick={() => this.finishActivity()} className={classes.spacingTop} startIcon={<DoneIcon/>}>
              Finish Activity
            </Button>
            : null }
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
            { /* Quadrants */ }
            <Hidden mdDown>
              <Grid container spacing={2} className={classes.spacingTop}>
                <Grid item md={12} lg={10}>
                    <Grid container>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Not Important <HelpTL/></Grid>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Important <HelpTR/></Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ul} ${classes[URGENT_AND_NOT_IMPORTANT]}`}>
                          <div className={classes.quadrant_inside}>
                              <DropArea control={hasControl} postits={this.state.priorities[UPPER_LEFT] !== undefined ? this.state.priorities[UPPER_LEFT] : []} id={UPPER_LEFT} direction="horizontal"  itemAction={this.itemAction} disabled={this.state.dropDisabled === UPPER_LEFT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ur} ${classes[URGENT_AND_IMPORTANT]}`}>
                        <div className={classes.quadrant_inside}>
                              <DropArea control={hasControl} postits={this.state.priorities[UPPER_RIGHT] !== undefined ? this.state.priorities[UPPER_RIGHT] : []} id={UPPER_RIGHT} direction="horizontal" itemAction={this.itemAction} disabled={this.state.dropDisabled === UPPER_RIGHT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_bl} ${classes[NOT_URGENT_AND_NOT_IMPORTANT]}`}>
                      <div className={classes.quadrant_inside}>
                            <DropArea control={hasControl} postits={this.state.priorities[LOWER_LEFT] !== undefined ? this.state.priorities[LOWER_LEFT] : []} id={LOWER_LEFT} direction="horizontal" itemAction={this.itemAction} disabled={this.state.dropDisabled === LOWER_LEFT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_br} ${classes[IMPORTANT_AND_NOT_URGENT]}`}>
                      <div className={classes.quadrant_inside}>
                            <DropArea control={hasControl} postits={this.state.priorities[LOWER_RIGHT] !== undefined ? this.state.priorities[LOWER_RIGHT] : []} id={LOWER_RIGHT} direction="horizontal" itemAction={this.itemAction} disabled={this.state.dropDisabled === LOWER_RIGHT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Not Urgent &amp; Not Important <HelpLL/></Grid>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Important &amp; Not Urgent <HelpLR/></Grid>
                    </Grid>
                </Grid>
                <Grid item md={12} lg={2} align="center" >
                  <span className={classes.boldedText}>Unassigned</span>
                  <div className={`${classes.unassigned_quadrant} ${classes.unassigned_section}`}>
                        <DropArea direction="vertical" control={hasControl} postits={this.state.priorities[UNASSIGNED] !== undefined ? this.state.priorities[UNASSIGNED] : []} id={UNASSIGNED} direction="vertical" itemAction={this.itemAction} disabled={this.state.dropDisabled === UNASSIGNED ? true : false}/>
                  </div>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden lgUp>
              <Grid container spacing={2} className={classes.spacingTop}>
                <Grid item md={12} lg={12} xs={12} align="center" >
                  <span className={classes.boldedText}>Unassigned</span>
                  <div className={`${classes.unassigned_quadrant_two} ${classes.unassigned_section}`}>
                    <DropArea control={hasControl} postits={this.state.priorities[UNASSIGNED] !== undefined ? this.state.priorities[UNASSIGNED] : []} id={UNASSIGNED} direction="horizontal" itemAction={this.itemAction} disabled={this.state.dropDisabled === UNASSIGNED ? true : false}/>
                  </div>
                </Grid>
                <Grid item md={12} lg={12}>
                    <Grid container>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Not Important <HelpTL/></Grid>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Important <HelpTR/></Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ul} ${classes[URGENT_AND_NOT_IMPORTANT]}`}>
                          <div className={classes.quadrant_inside}>
                          <DropArea control={hasControl} postits={this.state.priorities[UPPER_LEFT] !== undefined ? this.state.priorities[UPPER_LEFT] : []} id={UPPER_LEFT} direction="horizontal"  itemAction={this.itemAction} disabled={this.state.dropDisabled === UPPER_LEFT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ur} ${classes[URGENT_AND_IMPORTANT]}`}>
                        <div className={classes.quadrant_inside}>
                        <DropArea control={hasControl} postits={this.state.priorities[UPPER_RIGHT] !== undefined ? this.state.priorities[UPPER_RIGHT] : []} id={UPPER_RIGHT} direction="horizontal" itemAction={this.itemAction} disabled={this.state.dropDisabled === UPPER_RIGHT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_bl} ${classes[NOT_URGENT_AND_NOT_IMPORTANT]}`}>
                      <div className={classes.quadrant_inside}>
                      <DropArea control={hasControl} postits={this.state.priorities[LOWER_LEFT] !== undefined ? this.state.priorities[LOWER_LEFT] : []} id={LOWER_LEFT} direction="horizontal" itemAction={this.itemAction} disabled={this.state.dropDisabled === LOWER_LEFT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_br} ${classes[IMPORTANT_AND_NOT_URGENT]}`}>
                      <div className={classes.quadrant_inside}>
                      <DropArea control={hasControl} postits={this.state.priorities[LOWER_RIGHT] !== undefined ? this.state.priorities[LOWER_RIGHT] : []} id={LOWER_RIGHT} direction="horizontal" itemAction={this.itemAction} disabled={this.state.dropDisabled === LOWER_RIGHT ? true : false}/>
                          </div>
                      </Grid>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Not Urgent &amp; Not Important <HelpLL/></Grid>
                      <Grid item xs={6} align="center" className={classes.boldedText}>Important &amp; Not Urgent <HelpLR/></Grid>
                    </Grid>
                </Grid>  
              </Grid>
            </Hidden>
          </DragDropContext>
          <Grid container>
            <Grid item xs={12} align="right"><img src={stopDrop} alt='stop drop or go graphic'className={classes.dropImage}/></Grid>
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    removeResultsPath: (group, value, hostID) => dispatch(removeResultsPath(group, value, hostID))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);