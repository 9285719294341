export const SESSION_LOADED = 'SESSION_LOADED';
export const SESSION_ERROR_1 = 'ERROR CREATING SESSION';
export const SESSION_ERROR_2 = 'ERROR LOADING SESSION';
export const SESSION_ERROR_3 = 'ERROR ENDING SESSION. NOT FOUND.';
export const SESSION_ARCHIVED = 'SESSION ARCHIVED SUCCESSFULLY';
export const SESSION_ERROR_4 = 'ERROR REMOVING OLD SESSION';
export const SESSION_ERROR_5 = 'ERROR ARCHIVING SESSION';
export const GAME_STATE_INIT = 'init';

export const maybeCreateSession = (user) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const url_code = Date.now() + Math.floor(Math.random() * 1000 + 1);
    const doc_id = (firebase.auth().currentUser.uid).toString();

    if (user.role === 'host') {
      firestore
        .collection('current_sessions')
        .doc(doc_id)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            const d = {
              host: firebase.auth().currentUser.uid,
              url: url_code.toString(),
              players: [],
              moderators: [],
              past_games: [],
              active_game: {},
              groups: {
                'group-0': {
                  title: 'Users not in groups',
                  id: 'group-0',
                  playerIds: [],
                  leadPlayer: '',
                },
                'group-1': {
                  title: 'Group 1',
                  id: 'group-1',
                  playerIds: [],
                  leadPlayer: '',
                }
              },
              sessionName: "Session_" + url_code
            };

            firestore
              .collection('current_sessions')
              .doc(doc_id)
              .set(d)
              .then(() => {
                dispatch({ type: SESSION_LOADED });
              })
              .catch((err) => {
                dispatch({ type: SESSION_ERROR_1, err });
              });
          }
        })
        .catch((err) => {
          dispatch({ type: SESSION_ERROR_2, err });
        });
    } else {
      firebase.auth().signOut();
    }
  };
};

export const createZoomSession = (hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('current_zoom')
      .doc(hostID)
      .set({
        topic: ''
      })
      .then(() => {
        dispatch({ type: 'ZOOM_SESSION_LOADED' });
      })
      .catch((err) => {
        dispatch({ type: 'ZOOM_SESSION_ERROR_1', err });
      });

  };
};

export const addModeratorToSession = (uid, host) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {

          doc.ref
            .update({
              moderators: firestore.FieldValue.arrayUnion(uid),
            })
            .then(() => {
              dispatch({ type: 'MODERATOR_JOINED' });
            })
            .catch((err) => {
              dispatch({ type: 'MODERATOR_JOIN_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'MODERATOR_JOIN_ERROR', err });
      });
  };
}

export const maybeSignOutUser = (user) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    if (user.role === 'player') {
      // TODO: remove player from DB
      firebase.auth().signOut();
    }
  };
};

export const renameSession = (host, name) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('host', '==', host)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .update({
              sessionName: name,
            })
            .then(() => {
              dispatch({ type: 'SESSION_RENAMED' });
            })
            .catch((err) => {
              dispatch({ type: 'SESSION_RENAME_ERROR', err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: 'SESSION_RENAME_ERROR', err });
      });
  };
};

export const logError = (error, info) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('mail')
      .doc()
      .set({
        to: 'ryan@metastudios.co',
        message: {
          subject: `An error has been detected on the GOL Virtual Platform.`,
          text: `Error: ${error}. Info: ${JSON.stringify(info)}`,
          html: `Error: ${error}. <br/><br/>Info: ${JSON.stringify(info)}`,
        },
      });
  }

}


export const endSession = () => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const ref = firestore.collection('current_sessions').where('host', '==', user.uid);

    ref
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          doc.ref
            .delete()
            .then(() => {
              dispatch({ type: SESSION_ARCHIVED });
            })
            .catch((err) => {
              dispatch({ type: SESSION_ERROR_4, err });
            });
        });
      })
      .catch((err) => {
        dispatch({ type: SESSION_ERROR_4, err });
      });
  };
};


export const copyGroupsFromPlannedSession = (session) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();


    const planned_session_id = session.planned_session_id;


    firestore
      .collection("planned_sessions")
      .doc(planned_session_id)
      .get()
      .then((doc) => {

        const planned_session = doc.data();
        const groupKeys = Object.keys(planned_session.groups);

        let playerIds = [];
        let group_states = {};
        let groups = { ...planned_session.groups };
        let playerProfiles = { ...session.playerProfiles };
        groupKeys.forEach((groupKey, index) => {

          const theGroup = planned_session.groups[groupKey];
          // these are the codes
          const thePlayerCodes = theGroup.playerIds;

          group_states[groupKey] = {
            'params': {},
            'state': GAME_STATE_INIT
          }

          //playerCodes.push(...thePlayers);
          let groupPlayerIds = [];
          thePlayerCodes.forEach((code, ind) => {
            const theCode = code.toString();
            Object.keys(playerProfiles).map((playerId, index) => {
              if (playerProfiles[playerId].plannedSessionCode === theCode) {
                // match found
                groupPlayerIds.push(playerId);
              }
            });

          });

          groups[groupKey].playerIds = [...groupPlayerIds];
          if (groupPlayerIds.length > 0) {
            groups[groupKey].leadPlayer = groupPlayerIds[0];
          }
          playerIds.push(...groupPlayerIds);
        });


        session.players.forEach((player, ind) => {
          if (!playerIds.includes(player)) {
            groups['group-0'].playerIds.push(player);
          }
        });


        firestore
          .collection('current_sessions')
          .doc(session.id)
          .update({
            [`groups`]: { ...groups },
            [`active_game.groupStates`]: { ...group_states }
          })
          .then(() => {
            dispatch({ type: 'GROUP_COPY_FROM_PLANNED_SESSION_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'GROUP_COPY_FROM_PLANNED_SESSION_ERROR', err })
          })


      })
      .catch((err) => {
        dispatch({ type: 'GROUP_COPY_FROM_PLANNED_SESSION_ERROR', err })
      });



  };
};

export const copyGroupsFromLastActivity = (session) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    const lastSession = [...session.past_games].pop();
    const groupKeys = Object.keys(lastSession.groups);
    let playerCodes = [];
    let playerIds = [];
    let group_states = {};
    groupKeys.forEach((groupKey, index) => {
      const theGroup = lastSession.groups[groupKey];
      const thePlayers = theGroup.playerIds;

      group_states[groupKey] = {
        'params': {},
        'state': GAME_STATE_INIT
      }

      playerIds.push(...thePlayers);
    });

    let groups = { ...lastSession.groups };

    session.players.forEach((player, ind) => {
      if (!playerIds.includes(player)) {
        groups['group-0'].playerIds.push(player);
      }
    });


    firestore
      .collection('current_sessions')
      .doc(session.id)
      .update({
        [`groups`]: { ...groups },
        [`active_game.groupStates`]: { ...group_states }
      })
      .then(() => {
        dispatch({ type: 'GROUP_COPY_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'GROUP_COPY_ERROR', err })
      })
  };
}