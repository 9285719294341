import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { URGENT_AND_NOT_IMPORTANT, URGENT_AND_IMPORTANT, NOT_URGENT_AND_NOT_IMPORTANT, IMPORTANT_AND_NOT_URGENT } from './../data/data';


const INITIAL_STATE = {
    open: false
};

const UPPER_LEFT = "ul";
const UPPER_RIGHT = "ur";
const LOWER_LEFT = "ll";
const LOWER_RIGHT = "lr";

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
    }

    moveToFloorExercise = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor' });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.session.active_game.resultSnapshot === undefined ? {} : this.props.session.active_game.resultSnapshot, this.props.session.active_game.results)
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    getResults( results, group_id, quadrant ){
        if ( results === undefined ){
            return {};
        }
        if ( results[group_id] === undefined ){
            return {};
        }
        if ( results[group_id][quadrant] === undefined ){
            return {};
        }
        return results[group_id][quadrant];        
    }

    render() {
    const {
      session,
      classes,
    } = this.props;

        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{session.active_game.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {Object.keys(session.active_game.results)
                    .sort()
                    .map((groupKey, ind) => {
                        return (
                        <div key={ind}>
                            <Typography variant="h4"  className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[groupKey].title}</Typography>
                            <Grid container spacing={2} className={classes.spacingTop}>
                                <Grid item md={12} lg={10}>
                                    <Grid container>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Not Important</Grid>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Urgent &amp; Important</Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ul} ${classes[URGENT_AND_NOT_IMPORTANT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(session.active_game.results, groupKey, UPPER_LEFT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{session.active_game.results[groupKey][UPPER_LEFT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_ur} ${classes[URGENT_AND_IMPORTANT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(session.active_game.results, groupKey, UPPER_RIGHT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{session.active_game.results[groupKey][UPPER_RIGHT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_bl} ${classes[NOT_URGENT_AND_NOT_IMPORTANT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(session.active_game.results, groupKey, LOWER_LEFT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{session.active_game.results[groupKey][LOWER_LEFT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className={`${classes.grid_quadrant} ${classes.quadrant_br} ${classes[IMPORTANT_AND_NOT_URGENT]}`}>
                                        <div className={classes.padMe}>
                                            {
                                                Object.keys(this.getResults(session.active_game.results, groupKey, LOWER_RIGHT)).map((k, ind) => {
                                                    return (
                                                        <div className={classes.spacingBottom} key={ind}>
                                                            <Typography variant="body1">{session.active_game.results[groupKey][LOWER_RIGHT][k]}</Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Not Urgent &amp; Not Important</Grid>
                                    <Grid item xs={6} align="center" className={classes.boldedText}>Important &amp; Not Urgent</Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        );
                    })}
                </Grid>
            </Grid>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);