import { Layer, Line, Stage, Transformer, Rect } from "react-konva";
import React, { useEffect, useState } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { URLImage } from "./URLImage";
import { BLOCKS_SIDEBAR } from './../data/data';
import paper from 'paper/dist/paper-core'

import { v4 as uuidv4 } from "uuid";

const INITIAL_STATE = {
  timerActive: true,
  currentInterruption: null,
  x: 50,
  y: 50,
  isDragging: false,
  images: [],
  isDrawing: false,
  lines: [],
};


const WordPlayground = (props) => {
  const stageRef = React.useRef();
  const [images, setImages] = useState([]);

  const [lines, setLines] = useState([]);
  const isDrawing = React.useRef(false);

  const [enableDrawing, setEnableDrawing] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [mouseY, setMouseY] = useState(null);
  const [mouseX, setMouseX] = useState(null);
  const [contextImageId, setContextImageId] = useState(null);

  const [deselectAll, setDeselectAll] = useState(false);

  const [selectedId, selectShape] = React.useState(null);
  const [nodesArray, setNodes] = React.useState([]);

  const trRef = React.useRef();
  const layerRef = React.useRef();
  const Konva = window.Konva;

  //console.log('props control', props.control);

  React.useEffect(() => {
    if (props.results.images !== images && props.results.images !== undefined) {
      let newImages = props.results.images.slice();
      setImages(newImages);
    }
  }, [props.results.images]);

  useEffect(() => {
    const project = new paper.Project();
    return () => {

      //paper.project.remove();
    }
  }, [])

  const handleTransformEnd = (event) => {
    let scaleX = event.target.attrs.scaleX;
    let scaleY = event.target.attrs.scaleY;
    let rotation = event.target.attrs.rotation;

    const id = event.target.attrs.id;

    const slicedImages = images.slice();

    const imageInQuestion = slicedImages.find((i) => i.id === id);
    const index = slicedImages.indexOf(imageInQuestion);

    let newImage = {
      ...imageInQuestion,
      scaleX: scaleX,
      scaleY: scaleY,
      rotation: rotation,
      x: event.target.x(),
      y: event.target.y(),
    };

    slicedImages[index] = newImage;

    if (props.control) {
      setImages(slicedImages);
    }
    props.updateImages("update", slicedImages);
  };

  const onDragStartImage = (e) => {
    if (isDrawing.current === true) {
      return false;
    }
  };

  const sendToBack = (e) => {
    const id = contextImageId;

    const items = images.slice();

    const item = items.find((i) => i.id === id);
    const index = items.indexOf(item);
    // remove from the list:
    items.splice(index, 1);

    let newImage = {
      ...item,
    };

    // add to the back
    items.unshift(newImage);
    if (props.control) {
      setImages(items);
    }
    props.updateImages("update", items);
    handleContextClose();
  };

  const handleImageDragEnd = (e) => {

    const id = e.target.attrs.id;

    //console.log('ha!', id);

    const items = images.slice();
    const item = items.find((i) => i.id === id);
    const index = items.indexOf(item);
    // remove from the list:
    //items.splice(index, 1);

    let newImage = {
      ...item,
      x: e.target.x(),
      y: e.target.y(),
    };

    items[index] = newImage;

    setDeselectAll(true);
    // add to the top
    //items.push(newImage);
    if (props.control) {
      setImages(items);
    }
    props.updateImages("update", items);
  };


  const selectionRectRef = React.useRef();
  const selection = React.useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0.3)"
    });
    node.getLayer().batchDraw();
  };

  const oldPos = React.useRef(null);
  const onMouseDown = (e) => {
    //console.log('hmmmmm, ', e.target);
    const isElement = e.target;
    const isTransformer = e.target.findAncestor("Transformer");
    if (isElement instanceof Konva.Image || isTransformer) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    selection.current.visible = true;
    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseMove = (e) => {
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    updateSelectionRect();
  };

  const onMouseUp = () => {
    oldPos.current = null;
    if (!selection.current.visible) {
      return;
    }
    const selBox = selectionRectRef.current.getClientRect();

    const elements = [];
    //console.log('uh', layerRef.current);
    layerRef.current.children.forEach((elementNode) => {

      if (elementNode instanceof Konva.Image) {
        //console.log('element, ', elementNode);

        const elBox = elementNode.getClientRect();
        if (Konva.Util.haveIntersection(selBox, elBox)) {
          //console.log('this one', elementNode)
          elements.push(elementNode);
        }
      }
    });
    trRef.current ?? trRef.current.nodes(elements);
    selection.current.visible = false;
    // disable click event
    Konva.listenClickTap = false;
    updateSelectionRect();
  };

  const onClickTap = (e) => {
    // if we are selecting with rect, do nothing
    //if (selectionRectangle.visible()) {
    //return;
    //}
    let stage = e.target.getStage();
    let layer = layerRef.current;
    let tr = trRef.current;
    // if click on empty area - remove all selections
    if (e.target === stage) {
      selectShape(null);
      setNodes([]);
      tr.nodes([]);
      layer.draw();
      return;
    }

    // do nothing if clicked NOT on our rectangles
    //console.log('asdfas', e.target);
    if (!e.target.hasName(".rect")) {
      return;
    }

    // do we pressed shift or ctrl?
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isSelected = tr.nodes().indexOf(e.target) >= 0;

    if (!metaPressed && !isSelected) {
      // if no key pressed and the node is not selected
      // select just one
      tr.nodes([e.target]);
    } else if (metaPressed && isSelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      const nodes = tr.nodes().slice(); // use slice to have new copy of array
      // remove node from array
      nodes.splice(nodes.indexOf(e.target), 1);
      tr.nodes(nodes);
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      const nodes = tr.nodes().concat([e.target]);
      tr.nodes(nodes);
    }
    layer.draw();
  };


  const addElementsToCanvas = (els, results) => {
    const newImages = [];

    if (results !== undefined) {
      results.forEach((el) => {
        let newImage = {
          ...el
        };
        newImages.push(newImage);
      });
    }




  };

  useEffect(() => {
    addElementsToCanvas(BLOCKS_SIDEBAR, props.tangramCoords);
  }, []);

  const openContextMenu = (event, contextImageId) => {
    setContextImageId(contextImageId);
    setMouseY(event.clientY - 4);
    setMouseX(event.clientX - 2);
  };

  const handleContextClose = () => {
    setMouseY(null);
    setMouseX(null);
    setContextImageId(null);
    setDeselectAll(true);
  };

  const resetDeselectAll = () => {
    setDeselectAll(false);
  };

  const doDeSel = (event) => {
    if (event.target.attrs.id === "theStage") {
      setDeselectAll(true);
    }
  };




  const addElementToCanvas = (el) => {
    //console.log('Adding to canvas', el);
    let newImage = {
      ...stageRef.current.getPointerPosition(),
      src: el.src,
      width: el.width,
      height: el.height,
      dataId: el.id,
      scaleX: 1,
      scaleY: 1,
      rotation: 0,
      id: uuidv4(),
      name: "object"
    };
    setImages(
      images.concat([
        newImage
      ])
    );
    props.updateImages('add', newImage);
  }



  const GUIDELINE_OFFSET = 5;

  // were can we snap our objects?
  const getLineGuideStops = (skipShape) => {
    // we can snap to stage borders and the center of the stage
    var vertical = [0, stageRef.current.width() / 2, stageRef.current.width()];
    var horizontal = [0, stageRef.current.height() / 2, stageRef.current.height()];

    //console.log('lineguidestops: ', vertical, horizontal);
    // and we snap over edges and center of each object on the canvas
    stageRef.current.find('.object').forEach((guideItem) => {
      //console.log('guideitem', guideItem);
      if (guideItem === skipShape) {
        return;
      }
      var box = guideItem.getClientRect();
      // and we can snap to all edges of shapes
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
      horizontal.push([box.y, box.y + box.height, box.y + (box.height) / 2]);
    });
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }

  // what points of the object will trigger to snapping?
  // it can be just center of the object
  // but we will enable all edges and center
  const getObjectSnappingEdges = (node) => {
    var box = node.getClientRect();
    var absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y) - 5,
          offset: Math.round(absPos.y - box.y) - 5,
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        }
      ],
    };
  }

  // find all snapping possibilities
  const getGuides = (lineGuideStops, itemBounds) => {
    var resultV = [];
    var resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        var diff = Math.abs(lineGuide - itemBound.guide);
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        var diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    var guides = [];

    // find closest snap
    var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      });
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      });
    }
    return guides;
  }

  const drawGuides = (guides) => {
    guides.forEach((lg) => {
      if (lg.orientation === 'H') {
        var line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 1,
          name: 'guid-line',
          dash: [4, 6],
        });
        layerRef.current.add(line);
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide,
        });
      } else if (lg.orientation === 'V') {
        var line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: 'rgb(0, 161, 255)',
          strokeWidth: 1,
          name: 'guid-line',
          dash: [4, 6],
        });
        layerRef.current.add(line);
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0,
        });
      }
    });
  }

  const onDragMoveLayer = (e) => {
    // clear all previous lines on the screen
    //console.log('here');
    layerRef.current.find('.guid-line').forEach((l) => l.destroy());

    // find possible snapping lines
    var lineGuideStops = getLineGuideStops(e.target);
    // find snapping points of current object
    var itemBounds = getObjectSnappingEdges(e.target);

    // now find where can we snap current object
    var guides = getGuides(lineGuideStops, itemBounds);

    // do nothing of no snapping
    if (!guides.length) {
      //console.log('ending');
      return;
    }

    drawGuides(guides);

    var absPos = e.target.absolutePosition();
    // now force object position
    guides.forEach((lg) => {
      switch (lg.snap) {
        case 'start': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'center': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
        case 'end': {
          switch (lg.orientation) {
            case 'V': {
              absPos.x = lg.lineGuide + lg.offset;
              break;
            }
            case 'H': {
              absPos.y = lg.lineGuide + lg.offset;
              break;
            }
          }
          break;
        }
      }
    });
    e.target.absolutePosition(absPos);
  }

  const onDragEndLayer = (e) => {
    // clear all previous lines on the screen
    layerRef.current.find('.guid-line').forEach((l) => l.destroy());
  }




  return (
    <div>
      <Menu
        keepMounted
        open={mouseY !== null && mouseX !== null}
        onClose={handleContextClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseY !== null && mouseX !== null
            ? { top: mouseY, left: mouseX }
            : undefined
        }
      >
        <MenuItem onClick={sendToBack}>Send to Back</MenuItem>
        {/* <MenuItem onClick={deletePiece}>Remove this Piece</MenuItem> */}
        <MenuItem>--------------</MenuItem>
        <MenuItem onClick={handleContextClose}>Cancel</MenuItem>
      </Menu>

      <div
        style={{ height: "760px", width: "1200px", marginTop: "20px" }}
        id="white-board"
        onDrop={(e) => {
          e.preventDefault();
          stageRef.current.setPointersPositions(e);
          //console.log("DROPPING!", props.draggedEl);
          //console.log('Position?: ', stageRef.current.getPointerPosition() );
          addElementToCanvas(props.draggedEl);


        }}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className="whiteboard-base-text">

        </div>
        <Stage
          width={1200}
          height={760}
          onMouseDown={onMouseDown}
          ref={stageRef}
          id="theStage"
          onMousemove={onMouseMove}
          onMouseup={onMouseUp}
          onClick={doDeSel}
        >
          <Layer
            ref={layerRef}
            onDragMove={onDragMoveLayer}
            onDragEnd={onDragEndLayer}
          >
            {images.map((image, ind) => {
              return (
                <URLImage
                  key={ind}
                  scaleX={1}
                  scaleY={1}
                  isDrawing={isDrawing.current}
                  image={image}
                  handleDragEnd={handleImageDragEnd}
                  dragStart={onDragStartImage}
                  transformEnd={handleTransformEnd}
                  control={props.control}
                  openContext={openContextMenu}
                  deselectAll={false}
                  resetDeselectAll={resetDeselectAll}
                />
              );
            })}
            <Transformer
              // ref={trRef.current[getKey]}
              ref={trRef}
              resizeEnabled={false}
              rotateEnabled={false}
              flipEnabled={true}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }
                return newBox;
              }}
            />
            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef} />
          </Layer>

        </Stage>
      </div>
    </div>
  );
};

export default WordPlayground;
