import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal, updateGroupStateParamsValDistortion } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import styles from '../../../../Styles';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import { gameOptions } from './../../../gameServer';
import ImgPuzzleClue from './../images/puzzleClue1.png';

const INITIAL_STATE = {
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

class TapIn extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
        
    }

    approveRequest = (uid) => {
        this.props.updateGroupStateParamsValDistortion(this.props.group_id, 'engineer', uid, this.props.host);
        this.props.updateGroupStateParamsVal(this.props.group_id, 'tapInRequest', null, this.props.host);
    }
   
    tapIn = () => {
        this.props.updateGroupStateParamsVal(this.props.group_id, 'tapInRequest', this.props.auth.uid, this.props.host);
    }

    denyRequest = () => {
        this.props.updateGroupStateParamsVal(this.props.group_id, 'tapInRequest', null, this.props.host);
    }

    render() {
        const { session, auth, classes, group_id, role
        //profile, contractor, engineer
             } = this.props;
 
        let oImg = null;
        let p = this.props.puzzle;
        let g = gameOptions(this.props.session.active_game.id);
        if ( p !== undefined ){
            g['Puzzle'].options.map((option, ind) => {
                if ( option.id === parseInt(p) ){
                    oImg = option.img;
                }
                return null;
            })
        }
        const theImg = oImg

        return (
            <div>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body1" style={{}}>Current Role: <span style={{fontSize: "1.5rem", fontWeight: "bold"}}>{role}</span></Typography>
                    </Grid>
                    { role === 'Contractor' 
                    ?
                    <Grid item xs={6}></Grid>
                    :
                    <Grid item xs={6} align="right">       
                            { role === 'Engineer' ?
                            <Grid container>
                                <Grid item xs={8} align="right"> 
                                    { session.active_game.groupStates[group_id].params.tapInRequest !== undefined && session.active_game.groupStates[group_id].params.tapInRequest !== null ?
                                        session.active_game.groupStates[group_id].params.tapInRequest === auth.uid ?
                                        <Typography variant="body1" style={{fontStyle: "italic"}}>You have requested to Tap-In.</Typography>
                                        :
                                        <Typography variant="body1" style={{fontStyle: "italic"}}>{session.playerProfiles[session.active_game.groupStates[group_id].params.tapInRequest].displayName} is currently requesting to Tap-In.</Typography>
                                    :
                                    <Button variant="contained" color="primary" onClick={() => this.tapIn()} startIcon={<PlayForWorkIcon/>}>Tap In!</Button>
                                    }
                                </Grid>
                                <Grid item xs={4} align="right">
                                    { role !== 'Lead Engineer' ? <img src={theImg} alt='puzzle clue' className={classes.imageSize} style={{maxWidth: "150px"}}/> : null }
                                </Grid>
                            </Grid>
                            : 
                            role === 'Lead Engineer' ? 
                                <Grid container>
                                    <Grid item xs={12}>
                                    { 
                                        /* Put incoming tap-in requests here */
                                        session.active_game.groupStates[group_id].params.tapInRequest !== undefined && session.active_game.groupStates[group_id].params.tapInRequest !== null ?

                                            <Alert severity="info" onClose={() => {}} style={{position: 'fixed', top: '15%', right: '15%' }} action={
                                                <div><Button style={{color: "#fff"}} onClick={() => this.approveRequest(session.active_game.groupStates[group_id].params.tapInRequest)}>Allow</Button><Button  style={{color: "#fff"}} onClick={() => this.denyRequest()}>Deny</Button></div>
                                            }>{session.playerProfiles[session.active_game.groupStates[group_id].params.tapInRequest].displayName} has requested to Tap In.</Alert>
                                        :
                                        null
                                    }
                                    </Grid>
                                </Grid>
                            : null
                            }   
                    </Grid>
                    }
                </Grid>   
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
        updateGroupStateParamsValDistortion : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsValDistortion(group, val, groupStateParams, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TapIn);
