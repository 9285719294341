import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import { getUserGroup_hc } from '../../game_functions/functions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { gameOptions } from './../../../gameServer';


class Results extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

  render() {
    const {
      session,
      classes,
    } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const timeelapsed = session.active_game.groupStates[group_id].params.finishedOn - session.active_game.groupStates[group_id].params.startedOn;
    const secondsElapsed = Math.floor(timeelapsed / 1000);
    const minutes = Math.floor(secondsElapsed / 60);
    const remainder = secondsElapsed % 60;

    let oImg = null;
    let p = this.props.session.active_game.Puzzle;
    let g = gameOptions(this.props.session.active_game.id);
    if ( p !== undefined ){
        g['Puzzle'].map((option, ind) => {
            if ( option.id === p ){
                oImg = option.img;
            }
            return null;
        })
    }

    //const theImg = this.props.session.active_game.Puzzle === undefined ? ImgPuzzleClue : oImg

    return (
      <div>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" >You're Done!</Typography>
              <Typography variant="body1" className={classes.spacingBottom}>You completed the puzzle in <span style={{fontWeight: "bold"}}>{ minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds` }</span>.</Typography>
              
             
              <div>
                  <Typography variant="body1">Waiting for other groups to finish their activity. When all groups are done, you will be taken to the debrief.</Typography>
                  <br />
                  <br />
                  <div className={classes.cupContainerLeft}>
                  <LiquidLoadingBlock/>
                </div>
              </div>  
            </Grid>
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);