import { GAME_STATE_INIT } from "./gameActions";
import { GameGroupDefaultStates } from './../../components/Game/gameServer';

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signInAnon = (code, myCode) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('url', '==', code)
      .get()
      .then((snapshot) => {
        
        let host = null;
        snapshot.forEach((doc) => {
          host = doc.data().host;
        });
        if (!snapshot.empty) {
         
            // not yet signed in, go for it.
            firebase
              .auth()
              .signInAnonymously()
              .then((userResp) => {
                
                let a = {...getState().gol_auth};
                a.authCode = code;
                a.sessionHost = host;
                a.authCodeValid = true;
                a.plannedSessionCode = myCode;
                
                dispatch({ type: 'LOGIN_ANON_SUCCESS', d: {...a, authMessage: 'LOGIN_SUCCESS'} });
              })
              .catch((err) => {
                dispatch({ type: 'LOGIN_ANON_ERROR', err });
              });
        } else {
          dispatch({ type: 'LOGIN_CODE_ERROR' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_CODE_ERROR', err });
      });
  };
};

export const getModeratorHosts = (hosts) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log("the hosts:", hosts)

    firestore
      .collection('players')
      .where(firestore.FieldPath.documentId(), 'in', hosts)
      .get()
      .then((snapshot) => {
        let d = [];
        //console.log('SNAP', snapshot.docs);
        snapshot.forEach((doc) => {
          //console.log('azz');
          d.push({...doc.data(), id: doc.id});   
          //console.log(d);      
        });
        //console.log("SUCCESS");
        dispatch({ type: 'GOT_HOSTS_OK', data: d });
      })
      .catch((err) => {
        //console.log('ERROR');
        dispatch({ type: 'HOST_GET_ERROR', err });
      });
  };
};

export const updateModeratorHost = (hostID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log("the hosts:", hosts)

    firestore
      .collection('players')
      .doc(firebase.auth().currentUser.uid)
      .update({
        'moderates' : hostID
      })
      .then((snapshot) => {
        //console.log("SUCCESS");
        dispatch({ type: 'UPDATED_HOSTS_OK' });
      })
      .catch((err) => {
        //console.log('ERROR');
        dispatch({ type: 'HOST_GET_ERROR', err });
      });
  };
};

export const validateCode = (code) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('current_sessions')
      .where('url', '==', code)
      .get()
      .then((snapshot) => {
        
        //let host = null;
        let a = {...getState().gol_auth};
        if ( snapshot.empty ) {

          // pre-planned session is not live, let's check to see if it exists in the pre-planned section
          firestore
          .collection('planned_sessions')
          .where('url_code', '==', parseInt(code))
          .get()
          .then((snapshot) => {
            
            //console.log(snapshot);
            if ( snapshot.empty ){
              //console.log('not found', code);
              dispatch({ type: 'SESSION_NOT_FOUND', ret : { ...a, authCodeValid : false }});
            } else {
              let sesh = {};
              snapshot.forEach((doc) => {
                sesh = doc.data();
              });
              //console.log('pre');
              dispatch({ type: 'PRE_PLANNED_SESSION_NOT_YET_STARTED', ret : { ...a, authCodeValid : true, planned_session: sesh  }});
            }

          })
          .catch((err) => {
            //console.log('catch');
            dispatch({ type: 'SESSION_NOT_FOUND', ret : { ...a, authCodeValid : false, err: err }});
          })
        } else {
          //snapshot.forEach((doc) => {
          //host = doc.data().host;
         // });
         //console.log('valid');
          dispatch({ type: 'CODE_VALIDATED', ret : { ...a, authCodeValid : true } });
        }
      });
  };
}

export const validateCode_v2 = (code, you) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    //console.log('CODES AND SUCH ', code, you);

    let codeString = code.toString();
    let youString = you.toString();

    firestore
      .collection('current_sessions')
      .where('url', '==', codeString)
      .get()
      .then((snapshot) => {
        
        //let host = null;
        let a = {...getState().gol_auth};
        if ( snapshot.empty) {
          //console.log('HERE');
          // pre-planned session is not live, let's check to see if it exists in the pre-planned section
          firestore
          .collection('planned_sessions')
          .where('url_code', '==', codeString)
          .get()
          .then((snapshot) => {
            if ( snapshot.empty ){
              
              dispatch({ type: 'SESSION_NOT_FOUND', ret : { ...a, authCodeValid : false, myCode: youString }});
            } else {
              let sesh = {};
              snapshot.forEach((doc) => {
                sesh = doc.data();
              });
              dispatch({ type: 'PRE_PLANNED_SESSION_NOT_YET_STARTED', ret : { ...a, authCodeValid : false, myCode: youString, planned_session: sesh  }});
            }

          })
          .catch((err) => {
            dispatch({ type: 'SESSION_NOT_FOUND', ret : { ...a, authCodeValid : false, myCode: youString, err: err }});
          })
          
        } else {
          //console.log('HERE2');
           // pre-planned session is currently live, send "you" code back to store in profile

          // we have to make sure the code hasn't already been used by someone in the session
          let sesh = {};
          snapshot.forEach((doc, ind) => {
            sesh = doc.data();
          })

          //console.log('sesh', sesh);
          let codeUsed = false;
          if ( sesh.playerProfiles !== undefined ){
          let seshKeys = Object.keys(sesh.playerProfiles);
            seshKeys.forEach((key, ind) => {
              if ( sesh.playerProfiles[key].plannedSessionCode === youString ){
                codeUsed = true;
              }
            });
          }

          if ( codeUsed === true ){
            dispatch({ type: 'CODE_ALREADY_USED', ret: { ...a, authCodeValid: false, authMessage: 'Code Already Used' }});
          } else {
            firestore
            .collection('planned_sessions')
            .where('url_code', '==', codeString)
            .get()
            .then((snapshot) => {
              if ( snapshot.empty ){
                //console.log('empty');
                dispatch({ type: 'CODE_VALIDATED_NO_PREPLAN', ret : { ...a, authCodeValid : false, myCode: youString, authMessage: 'No valid preplanned session' }});
              } else {
                //console.log('not emptoy');
                let sesh = {};
                snapshot.forEach((doc) => {
                  sesh = doc.data();
                  sesh['id'] = doc.id;
                });
                dispatch({ type: 'CODE_VALIDATED', ret : { ...a, authCodeValid : true, myCode: youString, planned_session: sesh  }});
              }

            })
            .catch((err) => {
              //console.log('what');
              dispatch({ type: 'CODE_VALIDATED_NO_PREPLAN', ret : { ...a, authCodeValid : false, myCode: youString, err: err }});
            })
          }

       

          

         
          
        }
      });
  };
}

export const anonProfileCreate = (a) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log('Creating Profile: ', a);
    if ( a.myCode !== undefined && a.planned_session !== undefined && a.myCode !== false && a.planned_session !== false ){
      firestore
      .collection('planned_session_players')
      .doc(a.planned_session.id)
      .collection('players')
      .doc(a.myCode)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var p = doc.data();
          firestore
          .collection('players')
          .doc(firebase.auth().currentUser.uid)
          .set({
            role: 'player',
            sessionHost: a.sessionHost,
            sessionCode: a.authCode,
            plannedSessionCode: a.myCode === undefined ? '' : a.myCode,
            displayName: p.name,
            userAdded: new Date(),
          })
          .then(() => {
            let st = 'playerProfiles.' + firebase.auth().currentUser.uid;

            let groups = {...a.planned_session.groups};
            let groupPath = `groups.group-0.playerIds`;
            let usedGroupKey = '';
            Object.keys(groups).forEach((groupKey, ind) => {
              if ( groups[groupKey].playerIds.includes(parseInt(a.myCode)) ){
                usedGroupKey = groupKey;
                groupPath = `groups.${groupKey}.playerIds`;
              }
            })

            firestore
            .collection('current_sessions')
            .where('url', '==', a.authCode)
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {

                let updateLeadPlayer = false;
                let leadPlayerGroupPath = `groups.${usedGroupKey}.leadPlayer`;
                if ( usedGroupKey !== 'group-0' && usedGroupKey !== '' ){
                  let s = {...doc.data()};
                  let groups = {...s.groups};
                  if ( groups[usedGroupKey].playerIds.length === 0 ){
                    updateLeadPlayer = true;
                  }
                }

                if ( updateLeadPlayer ){
                  doc.ref
                  .update({
                    [st] : {
                        role: 'player',
                        sessionHost: a.sessionHost,
                        sessionCode: a.authCode,
                        plannedSessionCode: a.myCode === undefined ? '' : a.myCode,
                        displayName: p.name,
                        userAdded: new Date(), 
                      },
                      [groupPath] : firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid),
                      [leadPlayerGroupPath] : firebase.auth().currentUser.uid
                    }).then(() => {
                      dispatch({ type: 'LOGIN_PROFILE_CREATED', ret : { ...a, authMessage: 'LOGIN_PROFILE_CREATED' } });
                    });
                } else {
                  doc.ref
                  .update({
                    [st] : {
                        role: 'player',
                        sessionHost: a.sessionHost,
                        sessionCode: a.authCode,
                        plannedSessionCode: a.myCode === undefined ? '' : a.myCode,
                        displayName: p.name,
                        userAdded: new Date(), 
                      },
                      [groupPath] : firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid)
                    }).then(() => {
                      dispatch({ type: 'LOGIN_PROFILE_CREATED', ret : { ...a, authMessage: 'LOGIN_PROFILE_CREATED' } });
                    });
                }

                      
              });
            }) 
          })
          .catch((err) => {
            dispatch({ type: 'LOGIN_PROFILE_ERROR', ...a, ...err });
          });
        } else {
          dispatch({ type: 'LOGIN_PROFILE_ERROR', ...a });
        }
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_PROFILE_ERROR', ...a, ...err });
      });
    } else {
      firestore
        .collection('players')
        .doc(firebase.auth().currentUser.uid)
        .set({
          role: 'player',
          sessionHost: a.sessionHost,
          sessionCode: a.authCode,
          plannedSessionCode: a.plannedSessionCode === undefined ? '' : a.plannedSessionCode,
          userAdded: new Date(),
        })
        .then(() => {
          let st = 'playerProfiles.' + firebase.auth().currentUser.uid;

          firestore
          .collection('current_sessions')
          .where('url', '==', a.authCode)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
              .update({
                [st] : {
                    role: 'player',
                    sessionHost: a.sessionHost,
                    sessionCode: a.authCode,
                    plannedSessionCode: a.plannedSessionCode === undefined ? '' : a.plannedSessionCode,
                    userAdded: new Date(),
                  }, 
                //'groups.group-0.playerIds' : firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid)
                }).then(() => {
                  dispatch({ type: 'LOGIN_PROFILE_CREATED', ret : { ...a, authMessage: 'LOGIN_PROFILE_CREATED' } });
                });       
            });
          }) 
        })
        .catch((err) => {
          dispatch({ type: 'LOGIN_PROFILE_ERROR', err });
        });
    }
  };
};

export const endLoading = () => {
  return (dispatch) => {
    dispatch({ type: 'LOADING_DONE' });
  };
}


export const updateProfileNameSimple = (name, profile, session) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('in the func');
    const firebase = getFirebase();
    const firestore = getFirestore();
    let a = {...getState().gol_auth};


    dispatch({ type: 'LOADING' });

    if ( profile.role === 'host' || profile.role === 'moderator' ){
      console.log('host or mod');
      firestore
      .collection('players')
      .doc(firebase.auth().currentUser.uid)
      .update({
        displayName: name,
      }).then(() => {
        dispatch({ type: 'PROFILE_UPDATED', ret: {...a, authMessage: 'PROFILE_UPDATED'} });
      }).catch((err) => {
        dispatch({ type: 'LOGIN_PROFILE_UPDATE_ERROR', err });
      });
    } else {
      console.log('player');
      firestore
      .collection('players')
      .doc(firebase.auth().currentUser.uid)
      .update({
        displayName: name,
      }).then(() => {
        
        let sessionCode = profile.sessionCode;
        console.log('in the then', sessionCode);
        let st = 'playerProfiles.' + firebase.auth().currentUser.uid + '.displayName';
        firestore
        .collection('current_sessions')
        .where('url', '==', sessionCode)
        .get()
        .then((snapshot) => {
          console.log('here?', snapshot.empty)
          snapshot.forEach((doc) => {
            console.log('in the doc thing');
            doc.ref
            .update({
              [st] : name                     
            }).then(() => {
              console.log('updated?');
              dispatch({ type: 'PROFILE_UPDATED', ret: {...a, authMessage: 'PROFILE_UPDATED'} });
            }).catch((err) => {
              console.log('error?');
              dispatch({ type: 'LOGIN_PROFILE_UPDATE_ERROR', err });
            })
          })
        });
      }).catch((err) => {
        dispatch({ type: 'LOGIN_PROFILE_UPDATE_ERROR', err });
      });
    }
    
    
  };
}

export const updateProfileName = (name, profile, groupNo, groupName, session) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    let sessionCode = profile.sessionCode;
    if ( profile.role === 'host' || profile.role === 'moderator' ){
      sessionCode = firebase.auth().currentUser.uid;

      dispatch({ type: 'LOADING' });

      let a = {...getState().gol_auth};
      firestore
        .collection('players')
        .doc(firebase.auth().currentUser.uid)
        .update({
          displayName: name,
        })
        .then(() => {
          /*let st = 'playerProfiles.' + firebase.auth().currentUser.uid + '.displayName';
          firestore
          .collection('current_sessions')
          .where('host', '==', sessionCode)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref
              .update({
                [st] : name                     
              });
            })
          });*/
          dispatch({ type: 'PROFILE_UPDATED', ret: {...a, authMessage: 'PROFILE_UPDATED'} });
        })
        .catch((err) => {
          dispatch({ type: 'LOGIN_PROFILE_UPDATE_ERROR', err });
        });
    } else {
      dispatch({ type: 'LOADING' });

      //console.logconsole.log('aaaa', groupNo);
      //console.log('bbb', groupName);
      let groups = {...session.groups};
      let g = '';
      let gName = '';
      if ( groupNo === 'metastudiosinc-_-custom' ){
        //console.log('1');
        g = 'group-' + groupName;
        gName = 'Group ' + groupName;
      } else if ( groupNo === 'unknown' ){
        //console.log('2');
        g = 'group-0';
      } else {
        //console.log('3');
        g = groupNo;
      }

      //console.log('about to update', g);

      let a = {...getState().gol_auth};
      firestore
        .collection('players')
        .doc(firebase.auth().currentUser.uid)
        .update({
          displayName: name,
        })
        .then(() => {
          //console.log('in the then');
          let st = 'playerProfiles.' + firebase.auth().currentUser.uid + '.displayName';
          let gt = 'groups.' + g + '.playerIds';
          let glp = 'groups.' + g + '.leadPlayer';
          let gtName = 'groups.' + g + '.title';
          let gO = 'groups.' + g;

          //console.log('before the leadPlayer');
          let updateLeadPlayer = false;          
          if ( g !== 'group-0' ){           
            console.log('just inside');
            let groups = {...session.groups};
            console.log('after this');
            if ( groups[g] !== undefined ){
              if ( groups[g].playerIds.length === 0 ){
                console.log('in the thing');
                updateLeadPlayer = true;
              }
            }
          }
          
          //console.log('in here', groupNo, groupName, g, gName);
          if ( groupNo === 'metastudiosinc-_-custom' ){
            console.log('just inside');
            firestore
            .collection('current_sessions')
            .where('url', '==', sessionCode)
            .get()
            .then((snapshot) => {
              //console.log('in the inside of the thinger');
              snapshot.forEach((doc) => {
                if (Object.keys(groups).includes(g) ){
                  //existing group
                  if ( updateLeadPlayer === true ){
                    //console.log('updating 1');
                    doc.ref
                    .update({
                      [st] : name,
                      [gt] : firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid),
                      [gtName] : gName,
                      [glp]: firebase.auth().currentUser.uid
                    });
                  } else {
                    //console.log('updating 2');
                    doc.ref
                    .update({
                      [st] : name,
                      [gt] : firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid),
                      [gtName] : gName
                    });
                  }
                } else {
                  // new group
                  //console.log('updating 3');

                  if ( Object.keys(session.active_game).length > 0 ){
                    // game in progress
                    doc.ref
                    .update({
                      [st] : name,
                      [gO] : {
                        'playerIds' : [firebase.auth().currentUser.uid],
                        'id' : g,
                        'leadPlayer' : firebase.auth().currentUser.uid,
                        'title' : gName
                      },
                      [`active_game.groupStates.${g}`] : {
                        state: GameGroupDefaultStates(session.active_game.id),
                        params: {}
                      }                      
                    });
                  } else {
                    doc.ref
                    .update({
                      [st] : name,
                      [gO] : {
                        'playerIds' : [firebase.auth().currentUser.uid],
                        'id' : g,
                        'leadPlayer' : firebase.auth().currentUser.uid,
                        'title' : gName
                      }                      
                    });
                  }

                  
                }



                
                
              })
            });
          } else {
            firestore
            .collection('current_sessions')
            .where('url', '==', sessionCode)
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                if ( updateLeadPlayer === true ){
                  doc.ref
                  .update({
                    [st] : name,
                    [gt] : firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid),
                    [glp]: firebase.auth().currentUser.uid
                  });
                } else {
                  doc.ref
                  .update({
                    [st] : name,
                    [gt] : firestore.FieldValue.arrayUnion(firebase.auth().currentUser.uid)
                  });
                }
                
              })
            });
          }
          
          
          dispatch({ type: 'PROFILE_UPDATED', ret: {...a, authMessage: 'PROFILE_UPDATED'} });
        })
        .catch((err) => {
          dispatch({ type: 'LOGIN_PROFILE_UPDATE_ERROR', err });
        });
    }
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let uid = getState().firebase.auth.uid;
    let a = {...getState().gol_auth};

    if (getState().firebase.profile.role === 'player') { 
      firebase
        .auth()
        .signOut()
        .then(() => {
          firestore
            .collection('players')
            .doc(uid)
            .delete()
            .then(() => {  
              dispatch({ type: 'SIGNOUT_SUCCESS', ret: { ...a, authMessage: 'SIGNOUT_SUCCESS' } });
            })
            .catch((err) => {
              dispatch({ type: 'SIGNOUT_ERROR' });
            });
        });
    } else {
      firebase
        .auth()
        .signOut()
        .then(() => {
          dispatch({ type: 'SIGNOUT_SUCCESS', ret: { ...a, authMessage: 'SIGNOUT_SUCCESS' } });
        });
    }
  };
};

export const closeAuthPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_AUTH_POPUPS' });
  };
};

export const sendForgottenPasswordEmail = (email) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    dispatch({ type: 'LOADING' });

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        dispatch({ type: 'PASSWORD_RESET_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_PASSWORD_RESET', err });
      });
  };
};

export const createEmailUser = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.emailAddress, newUser.password)
      .then((userResp) => {
        firestore
          .collection('players')
          .doc(userResp.user.uid)
          .set({
            role: 'host',
            userAdded: new Date(),
          })
          .then((r) => {
            dispatch({ type: 'NEW_USER_SUCCESS' });
          })
          .catch((err) => {
            dispatch({ type: 'NEW_USER_ERROR', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};

export const updateUserInfoDB = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('consumer')
      .doc(user.id)
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.firstName + ' ' + user.lastName,
        initials: user.firstName[0] + user.lastName[0],
        company: '',
        role: 'consumer',
      })
      .then(() => {
        dispatch({ type: 'USER_DB_INFO_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_DB_INFO_ERROR', err });
      });
  };
};

export const updateUserPassword = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(user.newPassword)
          .then(() => {
            dispatch({ type: 'USER_PASSWORD_WAS_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'USER_PASSWORD_WAS_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const signUpWithGoogle = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((err) => {
        dispatch({ type: 'GOOGLE_LOGIN_ERROR', err });
      });
  };
};

export const signInGoogle = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);
    dispatch({ type: 'LOGIN_SUCCESS' });
  };
};


export const sendContactEmail = (name, company, email, phone, message) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    dispatch({type:'LOADING'});
    firestore
    .collection('mail')
    .doc()
    .set({
      bcc: 'ryan@metastudios.co',
      to: 'swartz.ryan@gmail.com',
      message: {
        subject: `Virtual Glass Admin - Contact Us Request`,
        text: `A contact us request was submit through the glass of learning admin portal. Name: ${name}. Email: ${email}. Phone: ${phone}. Company: ${company}. Message: ${message}.`,
        html: `A contact us request was submit through the glass of learning admin portal. <br/><br/>Name: ${name}. <br/>Email: ${email}. <br/>Phone: ${phone}. <br/>Company: ${company}. <br/><br/>Message: ${message}.`,
      },
    }).then(() => {  
      dispatch({ type: 'SEND_CONTACT_EMAIL' });
    })
    .catch((err) => {
      dispatch({ type: 'SEND_CONTACT_EMAIL_ERROR', err });
    });
  };
}

export const closeAdminPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_ADMIN_POPUPS' });
  };
};
