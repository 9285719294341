import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import TextField from '@material-ui/core/TextField'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import { endSession } from '../../store/actions/sessionActions'
import {
    updateHostState,
    endGame,
    updateModState
} from '../../store/actions/gameActions'
import { renameSession } from '../../store/actions/sessionActions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import EditIcon from '@material-ui/icons/Edit'
import {
    GAME_STATE_ACTIVE,
    GAME_STATE_RESULTS
} from '../../store/actions/gameActions'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import TutorialVideoPlayer from './tutorialVideos'
import HostControls from './hostControls'
import ModControls from './modControls'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import styles from '../Styles'
import TimeToLeave from '@material-ui/icons/TimeToLeave'

const INITIAL_STATE = {
    open: false,
    dialogueOpen: false,
    sessionDialogueOpen: false,
    dialogueNameOpen: false,
    dialogueTutorialOpen: false,
    renameText: ''
}

class sessionControls extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...INITIAL_STATE,
            renameText: props.session.sessionName
        }
    }

    openTutorial = () => {
        this.setState({
            ...this.state,
            open: false,
            dialogueTutorialOpen: true
        })
    }
    closeTutorial = () => {
        this.setState({
            ...this.state,
            dialogueTutorialOpen: false
        })
    }

    handleDrawerOpen = () => {
        this.setState({
            ...this.state,
            open: true
        })
    }

    handleDrawerClose = () => {
        this.setState({
            ...this.state,
            open: false
        })
    }

    handleDialogueOpen = () => {
        this.setState({
            ...this.state,
            dialogueOpen: true
        })
    }

    handleDialogueClose = () => {
        this.setState({
            ...this.state,
            dialogueOpen: false
        })
    }

    handleSessionDialogueOpen = () => {
        this.setState({
            ...this.state,
            sessionDialogueOpen: true
        })
    }

    handleSessionDialogueClose = () => {
        this.setState({
            ...this.state,
            sessionDialogueOpen: false
        })
    }

    handleBothClose = () => {
        this.setState({
            ...this.state,
            dialogueOpen: false,
            open: false
        })
    }

    endSession = (event) => {
        event.preventDefault()
        this.props.endSession()
        this.handleDrawerClose()
    }

    editGroups = () => {
        const hostState = { state: 'group_select', params: {} }
        if (this.props.profile.role === 'host') {
            this.props.updateHostState(hostState)
        }
        if (this.props.profile.role === 'moderator') {
            this.props.updateModState(
                this.props.auth.uid,
                hostState,
                this.props.profile.moderates
            )
        }
        this.handleDrawerClose()
    }

    goToDashboard = () => {
        const hostState = { state: 'main', params: {} }
        if (this.props.profile.role === 'host') {
            this.props.updateHostState(hostState)
        }
        if (this.props.profile.role === 'moderator') {
            this.props.updateModState(
                this.props.auth.uid,
                hostState,
                this.props.profile.moderates
            )
        }
        this.handleDrawerClose()
    }
    goToResults = () => {
        const hostState = { state: 'results', params: {} }
        if (this.props.profile.role === 'host') {
            this.props.updateHostState(hostState)
        }
        if (this.props.profile.role === 'moderator') {
            this.props.updateModState(
                this.props.auth.uid,
                hostState,
                this.props.profile.moderates
            )
        }
        this.handleDrawerClose()
    }

    gotoGroup = (groupKey) => {
        const hostState = { state: 'group', params: { group: groupKey } }
        if (this.props.profile.role === 'host') {
            this.props.updateHostState(hostState)
        }
        if (this.props.profile.role === 'moderator') {
            this.props.updateModState(
                this.props.auth.uid,
                hostState,
                this.props.profile.moderates
            )
        }
        this.handleDrawerClose()
    }
    backToList = () => {
        const hostState = { state: '' }
        if (this.props.profile.role === 'host') {
            this.props.updateHostState(hostState)
        }
        if (this.props.profile.role === 'moderator') {
            this.props.updateModState(
                this.props.auth.uid,
                hostState,
                this.props.profile.moderates
            )
        }
        this.handleDrawerClose()
    }

    renameSession = () => {
        this.setState({
            ...this.state,
            dialogueNameOpen: true,
            open: false
        })
    }

    saveRename = () => {
        const host =
            this.props.profile.role === 'host'
                ? this.props.auth.uid
                : this.props.profile.moderates
        console.log(host, this.state.renameText)
        this.props.renameSession(host, this.state.renameText)
        this.setState({
            ...this.state,
            dialogueNameOpen: false
        })
    }

    goToAdmin = () => {
        window.open('https://admin.glassoflearning.com', '_blank').focus()
    }

    cancelRenameSession = () => {
        this.setState({
            ...this.state,
            dialogueNameOpen: false,
            open: false
        })
    }

    endGame = () => {
        this.props.endGame()
        this.handleBothClose()
    }

    onChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        })
    }

    render() {
        const { open, renameText } = this.state
        const { classes, profile, session, auth } = this.props

        if (profile.role !== 'host' && profile.role !== 'moderator') {
            return null
        }

        return (
            <div>
                <TutorialVideoPlayer
                    open={this.state.dialogueTutorialOpen}
                    closeTutorial={this.closeTutorial}
                    classes={classes}
                />
                <Dialog
                    open={this.state.dialogueOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This action will{' '}
                            <span className={classes.boldedText}>
                                end the current activity
                            </span>{' '}
                            and{' '}
                            <span className={classes.boldedText}>
                                take you back to the activity selection list.
                            </span>{' '}
                            Are you sure you want to do this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleDialogueClose}
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.endGame}
                            variant="contained"
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.sessionDialogueOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This action will{' '}
                            <span className={classes.boldedText}>
                                end the current session.
                            </span>{' '}
                            This will not just end the activity, but also reset
                            all teams and the current session link will no
                            longer work. <br />
                            <br />
                            Are you sure you want to do this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleSessionDialogueClose}
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.endSession}
                            variant="contained"
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.dialogueNameOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Enter a new name for the session:
                        </DialogContentText>
                        <TextField
                            multiline
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="renameText"
                            name="renameText"
                            value={renameText}
                            onChange={this.onChange}
                            label="Session Name"
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.cancelRenameSession}
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.saveRename}
                            variant="contained"
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={this.handleDrawerOpen}
                    className={`${clsx(open && classes.hide)} ${classes.menuZ}`}
                >
                    <MenuIcon className={classes.menuZ} />
                </IconButton>

                <Drawer
                    className={`${classes.drawer} ${classes.menuZ}`}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>{' '}
                        <span className={classes.playersTitle}>Controls</span>
                    </div>
                    <Divider />

                    {profile.role === 'host' ? (
                        <HostControls
                            session={session}
                            profile={profile}
                            auth={auth}
                            classes={classes}
                            endGame={this.endGame}
                            onChange={this.onChange}
                            cancelRenameSession={this.cancelRenameSession}
                            goToAdmin={this.goToAdmin}
                            saveRename={this.saveRename}
                            renameSession={this.renameSession}
                            goToGroup={this.gotoGroup}
                            backToList={this.backToList}
                            editGroups={this.editGroups}
                            goToResults={this.goToResults}
                            endSession={this.endSession}
                            closeTutorial={this.closeTutorial}
                            openTutorial={this.openTutorial}
                            handleDrawerOpen={this.handleDrawerOpen}
                            handleDrawerClose={this.handleDrawerClose}
                            handleDialogOpen={this.handleDialogueOpen}
                            handleDialogClose={this.handleDialogueClose}
                            handleBothClose={this.handleBothClose}
                            handleSessionDialogClose={
                                this.handleSessionDialogueClose
                            }
                            handleSessionDialogOpen={
                                this.handleSessionDialogueOpen
                            }
                            goToDashboard={this.goToDashboard}
                            open={open}
                            user={auth}
                        />
                    ) : (
                        <ModControls
                            session={session}
                            profile={profile}
                            auth={auth}
                            classes={classes}
                            endGame={this.endGame}
                            onChange={this.onChange}
                            cancelRenameSession={this.cancelRenameSession}
                            goToAdmin={this.goToAdmin}
                            saveRename={this.saveRename}
                            renameSession={this.renameSession}
                            goToGroup={this.gotoGroup}
                            editGroups={this.editGroups}
                            goToResults={this.goToResults}
                            endSession={this.endSession}
                            closeTutorial={this.closeTutorial}
                            openTutorial={this.openTutorial}
                            handleDrawerOpen={this.handleDrawerOpen}
                            handleDrawerClose={this.handleDrawerClose}
                            handleDialogOpen={this.handleDialogueOpen}
                            handleDialogClose={this.handleDialogueClose}
                            handleBothClose={this.handleBothClose}
                            goToDashboard={this.goToDashboard}
                            backToList={this.backToList}
                            user={auth}
                        />
                    )}
                </Drawer>
            </div>
        )

        if (
            session.active_game === undefined ||
            Object.keys(session.active_game).length === 0
        ) {
            return profile.role === 'host' ? (
                <div>
                    <TutorialVideoPlayer
                        open={this.state.dialogueTutorialOpen}
                        closeTutorial={this.closeTutorial}
                        classes={classes}
                    />
                    <Dialog
                        open={this.state.dialogueNameOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Enter a new name for the session:
                            </DialogContentText>
                            <TextField
                                multiline
                                autoFocus
                                autoComplete="off"
                                margin="dense"
                                id="renameText"
                                name="renameText"
                                value={renameText}
                                onChange={this.onChange}
                                label="Session Name"
                                type="text"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.cancelRenameSession}
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.saveRename}
                                variant="contained"
                                color="primary"
                                autoFocus
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={this.handleDrawerOpen}
                        className={`${clsx(open && classes.hide)} ${
                            classes.menuZ
                        }`}
                    >
                        <MenuIcon className={classes.menuZ} />
                    </IconButton>
                    <Drawer
                        className={`${classes.drawer} ${classes.menuZ}`}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>{' '}
                            <span className={classes.playersTitle}>
                                Controls
                            </span>
                        </div>
                        <Divider />
                        <List>
                            {profile.role === 'moderator' ||
                            profile.role === 'host' ? (
                                <div>
                                    <Divider />

                                    <ListItem
                                        button
                                        onClick={() => {
                                            this.editGroups()
                                        }}
                                    >
                                        <ListItemIcon>
                                            <GroupIcon
                                                className={classes.lightPurple}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Edit Groups" />
                                    </ListItem>

                                    <Divider />
                                    <ListItem
                                        button
                                        onClick={this.openTutorial}
                                    >
                                        <ListItemIcon>
                                            <VideoLibraryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Tutorial Videos" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem button onClick={this.goToAdmin}>
                                        <ListItemIcon>
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Go To Admin" />
                                    </ListItem>
                                </div>
                            ) : null}
                            {session !== undefined ? (
                                <div>
                                    <Divider />
                                    <ListItem
                                        button
                                        onClick={() => this.renameSession()}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Rename Session" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem button onClick={this.endSession}>
                                        <ListItemIcon>
                                            <HighlightOffIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="End Session" />
                                    </ListItem>
                                    <Divider />
                                </div>
                            ) : null}
                        </List>
                    </Drawer>
                </div>
            ) : null
        } else {
            console.log('session', session)
            return profile.role === 'host' || profile.role === 'moderator' ? (
                <div>
                    <TutorialVideoPlayer
                        open={this.state.dialogueTutorialOpen}
                        closeTutorial={this.closeTutorial}
                        classes={classes}
                    />
                    <Dialog
                        open={this.state.dialogueOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                This action will{' '}
                                <span className={classes.boldedText}>
                                    end the current activity
                                </span>{' '}
                                and{' '}
                                <span className={classes.boldedText}>
                                    take you back to the activity selection
                                    list.
                                </span>{' '}
                                Are you sure you want to do this?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.handleDialogueClose}
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.endGame}
                                variant="contained"
                                color="primary"
                                autoFocus
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.dialogueNameOpen}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="md"
                        fullWidth
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Enter a new name for the session:
                            </DialogContentText>
                            <TextField
                                multiline
                                autoFocus
                                autoComplete="off"
                                margin="dense"
                                id="renameText"
                                name="renameText"
                                value={renameText}
                                onChange={this.onChange}
                                label="Session Name"
                                type="text"
                                fullWidth
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.cancelRenameSession}
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.saveRename}
                                variant="contained"
                                color="primary"
                                autoFocus
                            >
                                Confirm
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={this.handleDrawerOpen}
                        className={`${clsx(open && classes.hide)} ${
                            classes.menuZ
                        }`}
                    >
                        <MenuIcon className={classes.menuZ} />
                    </IconButton>
                    <Drawer
                        className={`${classes.drawer} ${classes.menuZ}`}
                        variant="persistent"
                        anchor="left"
                        open={open}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={this.handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>{' '}
                            <span className={classes.playersTitle}>
                                Controls
                            </span>
                        </div>
                        <Divider />
                        <List>
                            {session !== undefined &&
                            session.players.length > 0 &&
                            session.hasGroups !== false ? (
                                <ListItem
                                    button
                                    onClick={() => {
                                        this.editGroups()
                                    }}
                                >
                                    <ListItemIcon>
                                        <GroupIcon
                                            className={classes.lightPurple}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit Groups" />
                                </ListItem>
                            ) : null}
                            {session !== undefined &&
                            session.active_game.gameState.state ===
                                GAME_STATE_ACTIVE ? (
                                <ListItem
                                    button
                                    onClick={() => {
                                        this.goToDashboard()
                                    }}
                                >
                                    <ListItemIcon>
                                        <DashboardIcon
                                            className={classes.medPurple}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Activity Dashboard"
                                        className={classes.boldedText}
                                    />
                                </ListItem>
                            ) : null}
                            {session !== undefined &&
                            session.active_game.gameState.state ===
                                GAME_STATE_RESULTS ? (
                                <ListItem
                                    button
                                    onClick={() => {
                                        this.goToResults()
                                    }}
                                >
                                    <ListItemIcon>
                                        <EqualizerIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Activity Results" />
                                </ListItem>
                            ) : null}
                            {session !== undefined &&
                            session.active_game.gameState.state ===
                                GAME_STATE_ACTIVE &&
                            Object.keys(session.active_game.groups).length >
                                0 ? (
                                <div>
                                    <ListItem>
                                        <ListItemText primary="View Groups" />
                                    </ListItem>
                                    <List>
                                        {Object.keys(session.active_game.groups)
                                            .sort()
                                            .map((groupKey, index) => {
                                                if (
                                                    session.active_game.groups[
                                                        groupKey
                                                    ].id !== 'group-0'
                                                ) {
                                                    return (
                                                        <ListItem
                                                            button
                                                            key={index}
                                                            onClick={() => {
                                                                this.gotoGroup(
                                                                    groupKey
                                                                )
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <PeopleOutlineIcon />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={
                                                                    session
                                                                        .active_game
                                                                        .groups[
                                                                        groupKey
                                                                    ].title
                                                                }
                                                            />
                                                        </ListItem>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                    </List>
                                </div>
                            ) : null}
                        </List>
                        <List className={classes.spacingTop}>
                            {profile.role === 'moderator' ||
                            profile.role === 'host' ? (
                                <div>
                                    <Divider />
                                    <ListItem
                                        button
                                        onClick={this.openTutorial}
                                    >
                                        <ListItemIcon>
                                            <VideoLibraryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Tutorial Videos" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem button onClick={this.goToAdmin}>
                                        <ListItemIcon>
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Go To Admin" />
                                    </ListItem>
                                </div>
                            ) : null}
                            {session.active_game !== undefined &&
                            profile.role === 'host' ? (
                                <div>
                                    <Divider />
                                    <ListItem
                                        button
                                        onClick={this.handleDialogueOpen}
                                    >
                                        <ListItemIcon>
                                            <HighlightOffIcon
                                                className={classes.red}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="End Activity" />
                                    </ListItem>
                                </div>
                            ) : null}
                            {session !== undefined &&
                            profile.role === 'host' ? (
                                <div>
                                    <Divider />
                                    <ListItem
                                        button
                                        onClick={() => this.renameSession()}
                                    >
                                        <ListItemIcon>
                                            <EditIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Rename Session" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem button onClick={this.endSession}>
                                        <ListItemIcon>
                                            <HighlightOffIcon
                                                className={classes.red}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="End Session" />
                                    </ListItem>
                                    <Divider />
                                </div>
                            ) : null}
                        </List>
                    </Drawer>
                </div>
            ) : null
        }
    }
}

const mapStateToProps = (state) => {
    return {
        players_list: state.firestore.ordered.players,
        players_list_O: state.firestore.data.players
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        endSession: () => dispatch(endSession()),
        endGame: () => dispatch(endGame()),
        updateHostState: (hostState) => dispatch(updateHostState(hostState)),
        updateModState: (mod, hostState, host) =>
            dispatch(updateModState(mod, hostState, host)),
        renameSession: (host, name) => dispatch(renameSession(host, name))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(sessionControls)
