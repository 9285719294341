import React, { Component } from 'react';
import {
  GAME_STATE_INIT,
  GAME_STATE_ACTIVE,
  GAME_STATE_RESULTS,
} from '../../store/actions/gameActions';
import AwaitingGame from '../AwaitingGame';
import AwaitingGroup from '../AwaitingGroup';
import GameServer from './gameServer';
import { getUserGroup } from './games/game_functions/functions';


export default class GroupView extends Component {
  render() {
    const { game_id, game_title, session, profile, auth, game_state, group_state, game_options } = this.props;
    switch (game_state) {
      case GAME_STATE_INIT:
        return <AwaitingGame game_options={game_options} session={this.props.session}  />;

      //break;
      case GAME_STATE_ACTIVE:
        let g = getUserGroup(session, auth.uid);
        if ((g === null || g === 'group-0') && profile.role === 'player') {
          return <AwaitingGroup session={this.props.session} />;
        } else {
          return <GameServer game_options={game_options} game_id={game_id} game_title={game_title} session={session} profile={profile} auth={auth} game_state={game_state} group_state={group_state} view='group' />;
        }

      //break;
      case GAME_STATE_RESULTS:
        let gg = getUserGroup(session, auth.uid);
        if ((gg === null || gg === 'group-0') && profile.role === 'player') {
          return <AwaitingGroup game_options={game_options} session={this.props.session}  />;
        } else {
          return <GameServer game_options={game_options} game_id={game_id} game_title={game_title} session={session} profile={profile} auth={auth} game_state={game_state} group_state={group_state} view='group' />;
        }
      default:
        return null;
    }
  }
}
