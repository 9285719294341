import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ImageGallery from 'react-image-gallery';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';

const BestWorstBossLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>
                Participants use a variety of online crafts to create a metaphorical representation of the best or worst leader they have worked with. The debrief examines how it feels to work for each of these types of leaders and that how we show up as leaders is our choice. This sets participants up for leadership training.

                </Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Recognize the impact leaders have</li>
                    <li className={classes.properListItem}>Think about how we show up as leaders with our teams</li>
                    <li className={classes.properListItem}>Appreciate that we can choose to be whichever leader we want…the world’s best, or the world’s worst</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <Typography variant="body1"  className={classes.LNProperStart}>Leaders show up in many different ways. We can all think of leaders that we have worked with who are amazing and also ones who weren’t so great. This activity examines how we show up as leaders in a fun, creative way. </Typography>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
                <Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>
                    Metaphorically recreate:
                </Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>To give us context for what we mean by leadership, I want you to recreate leaders you have worked for </li>
                            <li className={classes.properListItem}>In your group, using the craft supplies and the flip chart paper, recreate what this person looks like, metaphorically speaking</li>
                            <li className={classes.properListItem}>What we mean by metaphorically is that if you were creating the worst leader, maybe they have no ears because they didn't listen anyway, maybe they have no legs because they didn't leave their office</li>
                            <li className={classes.properListItem}>Or if it's the best leader, maybe they have multiple legs and arms because it seemed like they were always available, and ready to help</li>
                            <li className={classes.properListItem}>So use your creativity. We will ask you to present your creation and introduce your team members</li>
                            <li className={classes.properListItem}>You will have 10 minutes to create your section, then you will assemble your "creations", introduce your team, and explain to the group</li>
                        </ul> 
                        <Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>Groups Present:</Typography>
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>Have the groups explain their creations.</li>
                            <li className={classes.properListItem}>When they have completed their explanation, ask how it "felt" to work for this leader?</li>
                            <li className={classes.properListItem}>Write those words right on the "leader" creation</li>
                        </ul>                 
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>TELL the group that we have choices</li>
                            <li className={classes.properListItem}>We can choose to be whichever leader we want…the world’s best, or the world’s worst (Unforgiveable, unremarkable, unforgettable)</li>   
                            <li className={classes.properListItem}>Make some notes in your journal</li>                            
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
           
        </div>    
    )
}

export default withStyles(styles)(BestWorstBossLN);