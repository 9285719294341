import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { saveGroups, beginGame, endLoading } from '../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ActivityOptions from './activityOptions';
import styles from '../Styles';


const INITIAL_STATE = { };

class OptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) { }


  render() {
    const {
      classes,
    } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4' className={`${classes.greyText}`}>
            Activity Options
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.spacingBottom} />
        </Grid>
        <Grid item xs={12} className={classes.spacingTop}>
          <ActivityOptions session={this.props.session}/>
        </Grid>
      </Grid>
    );
  }
}

OptionPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endLoading: () => dispatch(endLoading()),
    saveGroups: (groups) => dispatch(saveGroups(groups)),
    beginGame: (groups) => dispatch(beginGame(groups)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(OptionPage);