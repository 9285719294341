
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import plus_delta from '../images/plus_delta.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import { getFirebase } from 'react-redux-firebase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions'
import { updateGroupState } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import LoadingBlock from '../../../../Loading';
import Slide from '@material-ui/core/Slide';
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import activity_context from './../images/activity_context.png';
import activity_filled from './../images/activity_filled.png';
import group_sandbox from './../images/group_sandbox.png';
import personal_sandbox from './../images/personal_sandbox.png';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import styles from '../../../../Styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const INITIAL_STATE = {    
    logoURLs: {},
    imagesLoaded: false,
    helpOpen: false,
    secondaryLeaderOpen: false,
    secondaryLeader: '' 
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  


class Instructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }

    processImages = async () => {
        let logos = {};
        const sto = getFirebase().storage();
        await Promise.all( Object.keys(this.props.game_options.game_images).map( async (key, ind) => {
            let ref = sto.ref(this.props.game_options.game_images[key]);
            let url = await ref.getDownloadURL();
            logos[key] = url;
        }));
        return Promise.resolve(logos);
    }

    async componentDidMount() {
        try {
            await this.processImages().then((logos) => {
                window.scrollTo(0, 0)
                this.setState({
                    ...this.state,
                    logoURLs : logos,
                    imagesLoaded: true,
                    secondaryLeader: this.props.session.active_game.groupStates[this.props.group_id].secondaryLeader === undefined ? '' : this.props.session.active_game.groupStates[this.props.group_id].secondaryLeader
                }); 
                return Promise.resolve();
            });
        } catch (error) {
            console.log('error');
        }
        
    }

    sendToNextStep = (event) => {
        const groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
        let s = { 
            'state' : GROUP_STATE_ACTIVE,
            'params' : {
                'startedOn' : (this.props.session.active_game.groupStates[groupID].params.startedOn === undefined || this.props.session.active_game.groupStates[groupID].params.startedOn === null) ? Date.now() : this.props.session.active_game.groupStates[groupID].params.startedOn,
                'secondaryLeader' : this.state.secondaryLeader
            }
        };
        this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
    }

    helpOpen = () => {
        this.setState({
            ...this.state,
            helpOpen: true
        })
    }

    handleChange = (event) => {
        console.log('what', event.target.name, event.target.value);
        this.setState({ ...this.state, 
            [event.target.name]: event.target.value });
    };

    handleLegendClose = () => {
        this.setState({
            ...this.state,
            helpOpen: false
        })
    }

    render(){
        const { session, profile, auth, classes, leader, game_options } = this.props;
        const { secondaryLeader } = this.state;

        if ( !this.state.imagesLoaded ){
            return <LoadingBlock/>;
        }

        let title = session.active_game.name;
        //console.log("HOW?", game_options);
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }

        return (
            <div>

                <Dialog  open={this.state.helpOpen} onClose={this.handleLegendClose} TransitionComponent={Transition} maxWidth='md' fullWidth >
                    <AppBar position="static">  
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.handleLegendClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                            <Typography variant="h6" className={classes.boldedText}>
                            How to use the activity
                            </Typography>                    
                    </Toolbar>
                    </AppBar>
                    <Grid container spacing={2} className={classes.dialogueGrid}>
                        <Grid item xs={12}  className={classes.spacingTop}>
                            <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>The activity features two sections as seen below:</Typography>
                            
                            <br/><br/><br/>
                        </Grid>
                    </Grid>
                    
                                    
                </Dialog>


               <Typography variant='h3' className={classes.spaceBottom}>
                    {title}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={this.state.logoURLs.main} className={classes.imageStyle} alt="Game"/>
                        
                        <div className={classes.spacingTop}>
                            <br/><br/>
                            
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                       
                        
                       
<Typography variant='body1' className={classes.spaceBottom} dangerouslySetInnerHTML={{ __html : game_options.game_texts.intro_text}}></Typography>
                        

                        <Typography variant="body1" className={classes.spacingBottom}>Choose the person to be secondary leader:</Typography>
                        <FormControl className={classes.formControl} style={{width: '100%', marginBottom: '20px'}}>
                            <InputLabel id="demo-controlled-open-select-label">Secondary Leader</InputLabel>
                            <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            name="secondaryLeader"
                            value={secondaryLeader}
                            onChange={this.handleChange}
                            disabled={hasGameControl(leader, auth.uid, profile) === false}
                            >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            { this.props.session.groups[this.props.group_id].playerIds.map((playerid, ind) => {
                                if ( this.props.session.groups[this.props.group_id].leadPlayer === playerid ){
                                    return null;
                                }
                                return (
                                    <MenuItem key={ind} value={playerid}>{this.props.session.playerProfiles[playerid].displayName}</MenuItem>
                                );
                            }) }
                            </Select> 
                        </FormControl>

                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            (secondaryLeader !== '' ) ?
                            <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Select a Secondary Leader to Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid>
               
            </div>
        );
    }

}



const mapStateToProps = (state) => {
    //console.log('this is my state', state);
    return {

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID))
    };
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(Instructions);
