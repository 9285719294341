import React, { Component } from 'react';
import GameText from './game_text';
import GameMisc from './game_misc';
import GameImage from './game_image';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { DropzoneDialog } from 'material-ui-dropzone';





export default function GameOptionsSet(props) {

    const [optionSetName, setOptionSetName] = React.useState(props.set === null || props.set === undefined ? 'New Set' : props.set === 'default' ? 'Default' : props.myCollection[props.set].name);
    //console.log('hmm', props.myCollection);
   
    const disabled = optionSetName === 'Default' ? true : false;
    const o = optionSetName === 'Default' ? props.optionsDefault : props.myCollection[props.set];


    const onNameChange = (event, val) => {
        setOptionSetName(val);
    }

    const sendUpdateName = (setDoc, hostID, game_id, key, val) => {
        props.saveOptionSetName(setDoc, hostID, game_id, key, val);
    }

    return (
        <div style={{marginTop: "50px"}}>
            <Typography variant="h5">{ optionSetName === 'New Set' ? `${optionSetName}` : `Editing a Set`}</Typography>

            <div style={{padding:"30px 0"}}>
                <Typography variant="h6">Set Name:</Typography>
                <TextField disabled={disabled} name={`set_name`} id={`set_name`} value={optionSetName} onChange={(event, value) => onNameChange(event, value)} onBlur={(event) => sendUpdateName(props.set, props.hostID, props.game.id, 'name', event.target.value)}   fullWidth multiline />
            </div>


            <form autoComplete="off" noValidate style={{marginBottom: "30px"}}>
            {  Object.keys(o.game_images).length > 0 ?
            <div className={props.classes.spacingTop}>
                <Typography variant="h5">Images</Typography>
                <div style={{ border: "1px solid #000", borderRadius: "5px", padding: "30px"}}>
                {
                    Object.keys(o.game_images).map((key, ind) => {
                       return <GameImage saveActivityOptionSetFile={props.saveActivityOptionSetFile} game_id={props.game.id} hostID={props.hostID} setDoc={props.set} saveOption={props.saveOption} disabled={disabled} key={ind} oKey={key} ind={ind} o={o.game_images}/>
                    })
                }
                </div>
            </div>
            : null
            }
            <div className={props.classes.spacingTop}>
                <Typography variant="h5">Text</Typography>
                <div style={{ border: "1px solid #000", borderRadius: "5px", padding: "30px"}}>
                {
                    Object.keys(o.game_texts).sort().map((key, ind) => {
                        return <GameText game_id={props.game.id} hostID={props.hostID} setDoc={props.set} saveOption={props.saveOption} disabled={disabled} key={ind} oKey={key} ind={ind} o={o.game_texts}/>
                    })
                }
                </div>
            </div>
            {  Object.keys(o.game_misc).length > 0 ?
                <div className={props.classes.spacingTop}>
                    <Typography variant="h5">Miscellaneous</Typography>
                    {
                    Object.keys(o.game_misc).sort().map((key, ind) => {
                        return <GameMisc g={props.g} game_id={props.game.id} hostID={props.hostID} setDoc={props.set} saveOption={props.saveOption} disabled={disabled} key={ind} oKey={key} ind={ind} o={o.game_misc} classes={props.classes}/>
                    })
                }
                </div>
             : null }
            </form>

            <Button size='small' onClick={() => props.returnToSetList()} variant="contained" className={props.classes.glassPurple} startIcon={<AssignmentReturnIcon />} style={{marginRight: "10px"}}>
                Return to Sets List
            </Button>



            
        </div>
    )
}