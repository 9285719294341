import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import ImageGallery from 'react-image-gallery';
import Notes from './../images/leader_notes.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const BBSSLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download PDF" {...a11yProps(5)} />
                <Tab className={classes.tabsLNTab} label="Slide Deck" {...a11yProps(6)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>This activity provides the opportunity for participants to share tools they have
implemented during the training program, and the successes they have seen.
Using online sticky notes, participants can type in their success stories for
others to see and use. A facilitated conversation will help highlight the ways
these ideas can be incorporated by others into their own work.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Collective wisdom of the group</li>
                    <li className={classes.properListItem}>Successes that can that be used and implemented</li>
                    <li className={classes.properListItem}>Sharing best practices</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <Typography variant="body1"  className={classes.LNProperStart}>This is an opportunity to share your success stories with each other, learn from
each other and gain insights on how to apply the learning of others to your
own work.</Typography>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>Participants will be in small groups to work on this activity. They will record
their successes and wins on sticky notes and talk about how they applied the
learning in their work.</li>
                            <li className={classes.properListItem}>In the large group, the ideas will shared and links can be made to ideas and
successes that overlap or can be applied in a broader context.</li>
                            
                        </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>How can you take some of these ideas and apply it in your own work?</li>
                            <li className={classes.properListItem}>What stands out for you from these ideas and success stories?</li>   
                            <li className={classes.properListItem}>Where are there opportunities to collaborate?</li>   
                            <li className={classes.properListItem}>If there is something you hear that you would like to implement, call out
“Steal” and steal that idea for your location</li>   
                            <li className={classes.properListItem}>Write down the idea for your reference</li>                           
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Leader Notes</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download PDF
                </Button>
                </a>
            </TabPanel>
           
            <TabPanel className={classes.LNTabPanel} value={value} index={6}>
                <Typography variant="h5">Slide Deck</Typography>  
                              
            </TabPanel>
           
        </div> 
    )
}

export default withStyles(styles)(BBSSLN);