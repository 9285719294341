import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import styles from '../../../../Styles';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import { gameOptions } from './../../../gameServer';

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { GAME_STATE_RESULTS } from './../../../../../store/actions/gameActions';

const INITIAL_STATE = {
    open: false,
    groupsDone: []
}

class PopupAlert extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps, prevState){
        if ( prevProps !== undefined ){
            let g = [];
            let o = false;
            if ( prevProps.session.active_game.groupStates !== this.props.session.active_game.groupStates ){

                Object.keys(this.props.session.active_game.groupStates).map((groupKey, ind) => {
                    if ( this.props.session.active_game.groupStates[groupKey].state === GAME_STATE_RESULTS ){
                        g.push(groupKey);
                    }

                    if ( this.props.session.active_game.groupStates[groupKey].state === GAME_STATE_RESULTS && prevProps.session.active_game.groupStates[groupKey].state !== GAME_STATE_RESULTS ){
                        o = true;                        
                    }
                });

                if ( o === true ){
                    this.setState({
                        ...this.state,
                        open: true,
                        groupsDone: g
                    })
                }
            }
            
        }
    }

    setOpen = (b) => {
        this.setState({
            ...this.state,
            open: b
        })
    }


    render() {
        const { session } = this.props;
        const { open } = this.state;

        return (
          <div>
            <Collapse in={open} style={{position:"fixed", top:"20px", zIndex: 9999}}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                A group has finished the activity!
                <div style={{marginTop: "15px"}}>
                    Groups Done: 
                    <ul>
                        {
                            this.state.groupsDone.map((g, ind) => {
                                return (<li key={ind}>
                                    { session.groups[g].title }
                                </li>)
                            })
                        }
                    </ul>
                </div>
              </Alert>
            </Collapse>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(PopupAlert);
