import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import GamePiece from './gamePiece';
import { BLOCKS } from '../../aggregate/data/data';
import { PointCalculation } from './comparisonTable';





const useStyles = makeStyles({
    tCell: {
        '&:hover': {
        backgroundColor: 'rgba(103, 58, 183, 0.3)'
        },
        fontSize: "20px",    
        border: "1px solid #000",
        height: "150px",
        width: "200px",
        backgroundColor: "#fff"    
    },
    normalTCell: {
        fontStyle: "italic",
        fontSize: "14px"
    },
    highlightedTCell: {
        backgroundColor: "rgba(103, 58, 183, 0.3)"
    },
    popoverItem: {
        display: "inline-block",
        padding: "20px",
        cursor: "pointer"
    },
    popoverNoneLeft: {
        opacity: "0.25",
    },
    lineSelected: {
        backgroundColor: "rgba(0,128,0,0.3) !important"
    },
    lineNotSelected: {

    },
    lineGroupOne: {
        backgroundColor: "rgba(252,123,214, 0.3)"
    },
    lineGroupTwo: {
        backgroundColor: "rgba(45,150,191, 0.3)"
    },
    lineBoth: {
        background: "rgba(0,128,0, 0.3)",
        background: 'linear-gradient(90deg, rgba(252,123,214,0.3) 0%, rgba(45,150,191,0.4) 100%)'
    }

    });

const pieceParser = (res, teams) => {
    //console.log(': ', res, teams);
    if ( res === undefined || res === false ){
        return null;
    } else {
        if ( res === teams[0] ){
            return 'team1';
        }
        else if ( res === teams[1] ){
            return 'team2';
        }
        else if ( res === `wild-${teams[0]}` ){
            return 'wild';
        }
        else if ( res === `wild-${teams[1]}` ){
            return 'wild';
        }
        else if ( res === 'wild' ){
            return 'wild';
        }
    }
    
}


const PersonifyActivitySheet = (props) => {
    
    const classes = useStyles();
    const rows = [1,2,3,4];
    const cols = [1,2,3,4];

    

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [lines, setLines] = React.useState({});
    const [curVal, setCurVal] = React.useState(null);
    const [curId, setCurId] = React.useState(null);
    const [results, setResults] = React.useState(props.results);

    React.useEffect( () => {
        setResults(props.results);
        if ( Object.keys(props.results).length === 16 ){
            props.finishActivity();
        }
        const split = props.vsKey.split(":");
        let score = props.session.active_game.results[props.vsKey] === undefined ? { [split[0]] : 0, [split[1]]: 0} : props.session.active_game.results[props.vsKey].grid === undefined ? { [split[0]] : 0, [split[1]]: 0} : PointCalculation(props.session.active_game.results[props.vsKey].grid, props.vsKey );
        //console.log('score?', score);
        setLines(score.linesDetailed);
        //console.log(props.results);
    }, [props.results]);

    React.useEffect( () => {
        //console.log(lines);
    }, [lines]);


    const handlePopoverOpen = (event, thisVal, thisId) => {
        //console.log('here?', thisVal, thisId, props.control);
        if ( !props.control ){
            return;
        }

        if ( !props.myTurn && !props.profile.role === 'host' ){
            return;
        }

        let noVal = false;
        if ( thisVal === undefined || thisVal === false ){
            noVal = true;
        }

        if ( !noVal ){
            return;
        }
        

        //console.log('passed');

        //setCurVal(thisVal);
        setCurId(thisId);
        props.updateSpot(thisId);
        setAnchorEl(event.target);  

    };
    const handlePopoverClose = () => {
        setCurId(null);
        props.updateSpot('');
        setAnchorEl(null);
    }

    const handlePopoverClick = (val, id, piecesRemaining) => {
        if ( piecesRemaining === 0 ){
            return;
        }
        
        setCurId(null);
        props.updateSpot('');
        setAnchorEl(null);

        
        //console.log('???', val, id);
        props.updateResult(val, id);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const normalPiecesRemaining = (6-props.regPiecesUsed);
    const wildPiecesRemaining = (2-props.wildPiecesUsed);

    //console.log('debfiref', props.debreif)
    
    let hash = [];
    const teams = props.vsKey.split(':');
    //console.log(teams);
    //console.log('lines2', lines);

    return (
    <div>
        <Popover 
            open={open}
            id={id}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            ><div style={{padding:"10px"}}>
                <div style={{textAlign: "center", fontWeight: "bold", textTransform: "uppercase"}}>Choose a piece to place</div>
                <div className={`${classes.popoverItem} ${normalPiecesRemaining===0 ? classes.popoverNoneLeft : ''}`} onClick={(event) => handlePopoverClick(props.turn, curId, normalPiecesRemaining)}>
                    <GamePiece type={pieceParser(props.turn, props.teams)}/>
                    x{normalPiecesRemaining}
                </div>
                <div className={`${classes.popoverItem} ${wildPiecesRemaining===0 ? classes.popoverNoneLeft : ''}`} onClick={(event) => handlePopoverClick(`wild-${props.turn}`, curId, wildPiecesRemaining)}>
                    <GamePiece type={pieceParser('wild', props.teams)}/>
                    x{wildPiecesRemaining}
                </div>
            </div>
        </Popover>
        <TableContainer style={{marginTop:"30px", width: "auto", display: "inline-block", backgroundColor: "#ccc"}} className={`${(props.myTurn === true || props.isHostOrMod) && !props.debrief ? `yourturn` : `notyourturn`}`}>
                <Table aria-label="simple table" style={{width: "auto", margin: "5px"}} >                
                    <TableBody>
                        { rows.map((row, ind) => {
                            return (
                            <TableRow key={ind}>
                                { cols.map( (col, index) => {
                                    let id = `${row}x${col}`;
                                    let tableClass = "lineNotSelected";
                                   // console.log('what????');
                                    if ( lines !== undefined ){
                                        if ( Object.keys(lines).length > 0 ){
                                            if ( lines[teams[0]] !== undefined ){ 
                                                lines[teams[0]].forEach((line) => {
                                                    //console.log('eeeeeee1', line, id);
                                                    
                                                    if ( line.indexOf(id) >= 0 ){
                                                        //console.log('FOUND', id)
                                                        tableClass = "lineGroupOne";
                                                    }
                                                });
                                            }
                                            if ( lines[teams[1]] !== undefined ) {
                                                lines[teams[1]].forEach((line) => {
                                                    //console.log('eeeeeee2', line, id);
                                                    if ( line.indexOf(id) >= 0 ){
                                                        //console.log('found', id, tableClass)
                                                        if ( tableClass === "lineGroupOne" || tableClass === "lineBoth" ){
                                                            tableClass = "lineBoth";
                                                        } else {
                                                            tableClass = "lineGroupTwo";
                                                        }
                                                    }
                                                });
                                            }
                                            if ( lines['wild'] !== undefined ) {
                                                lines['wild'].forEach((line) => {
                                                    //console.log('eeeeeee2', line, id);
                                                    if ( line.indexOf(id) >= 0 ){                                                
                                                        tableClass = "lineBoth";                                               
                                                    }
                                                });
                                            }
                                        }
                                    }
                                    return (
                                        <TableCell key={index} onClick={(event) => handlePopoverOpen(event, props.results[id], id)} id={id} className={`${classes.tCell} ${classes[tableClass]}  ${curId === id || props.currentSpot === id ? props.debrief === true ? classes.normalTCell : classes.highlightedTCell : classes.normalTCell}`}  align="center"><GamePiece type={pieceParser(props.results[id], props.teams)}/></TableCell>
                                    )
                                })}
                            </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer><br/>
            { props.debrief === false ? 
            <div>
                <Typography variant="body1" style={{ fontWeight: "bold", textTransform: "uppercase", marginTop: "15px"}}>Pieces Remaining</Typography>
                <div className={classes.popoverItem}>
                    <GamePiece type={pieceParser(props.isHostOrMod ? props.turn : props.group_id, props.teams)}/>
                    x{normalPiecesRemaining}
                </div>
                <div className={classes.popoverItem}>
                    <GamePiece type={pieceParser('wild', props.teams)}/>
                    x{wildPiecesRemaining}
                </div>
            </div>
            : null }
    </div>
    );
}


export default PersonifyActivitySheet
