import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import PieTable from './../specific_components/pieTable';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        // session.active_game.results
        this.state = {
          ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }   

    render() {
    const {
      session,
      classes,
      auth,
      profile,
      game_options
    } = this.props;

    let title = session.active_game.name;
    if ( game_options !== false ){
        title = game_options.game_texts.game_title;
    }   
        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{title}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(session.active_game.results)
                .sort()
                .map((groupKey, ind) => {
                    const results = this.props.session.active_game.results[groupKey] === undefined ? {} : this.props.session.active_game.results[groupKey].table === undefined ? {} : this.props.session.active_game.results[groupKey].table;
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[groupKey].title}</Typography>                        
                        <PieTable results={results} control={false}/>
                    </div>                    
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);