import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Droppable } from 'react-beautiful-dnd'
import GridBlock from './gridBlock'
import { withStyles } from '@material-ui/core/styles'
import styles from '../../../../Styles'

const getListStyle = (isDraggingOver, dir, bw) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    margin: '0',
    minHeight: `${bw - 2}px`,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
})

class DropArea extends Component {
    render() {
        if (this.props.control === true) {
            return (
                <Droppable
                    droppableId={this.props.id}
                    direction={this.props.direction}
                    isDropDisabled={
                        this.props.type === 'drawer'
                            ? false
                            : this.props.postits.length > 0
                            ? true
                            : false
                    }
                    key={this.props.id}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={getListStyle(
                                snapshot.isDraggingOver,
                                this.props.direction === 'vertical'
                                    ? true
                                    : false,
                                this.props.boxWidth
                            )}
                        >
                            {this.props.postits.map((blockid, index) => (
                                <GridBlock
                                    boxWidth={
                                        this.props.type === 'drawer'
                                            ? this.props.boxWidth
                                            : this.props.boxWidth - 2
                                    }
                                    bgColorTable={this.props.bgColorTable}
                                    blockid={blockid}
                                    key={blockid}
                                    index={index}
                                    control={this.props.control}
                                    id={blockid}
                                />
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            )
        } else {
            return (
                <div
                    style={getListStyle(
                        false,
                        this.props.direction === 'vertical' ? true : false,
                        this.props.boxWidth
                    )}
                >
                    {this.props.postits.map((blockid, index) => (
                        <GridBlock
                            boxWidth={
                                this.props.type === 'drawer'
                                    ? this.props.boxWidth
                                    : this.props.boxWidth - 2
                            }
                            bgColorTable={this.props.bgColorTable}
                            blockid={blockid}
                            key={blockid}
                            index={blockid}
                            control={this.props.control}
                            id={blockid}
                        />
                    ))}
                </div>
            )
        }
    }
}

DropArea.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DropArea)
