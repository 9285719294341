import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import styles from './../Styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MessageIcon from '@material-ui/icons/Message';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Box from '@material-ui/core/Box';

import { getLeaderNotes } from './../Game/gameServer';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
export const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


const LeaderNotes = (props) => {

    const { classes, open, activityID, activityName } = props;

    const goToContact = () => {
        window.open('/contact', '_blank');
    }
    
    return (
        <Dialog open={open} onClose={props.closeNotes} fullWidth maxWidth='md' TransitionComponent={Transition}>
            <AppBar position="static" className={classes.LNNoShadow}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.closeNotes} aria-label="close">
                <CloseIcon />
                </IconButton>
                    <Typography variant="h6">
                    Leader Notes - {activityName}                    
                    </Typography>
            </Toolbar>
            </AppBar>
         
            { getLeaderNotes(activityID) }  
          
            <AppBar position="static" style={{textAlign: 'right', alignItems: 'flex-end'}}>
            <Toolbar>                
                    <Typography variant="h6">
                        Questions? 
                    </Typography> 
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={`${classes.LNFooterButton} ${classes.LNButtonLeft}`}
                            startIcon={<MessageIcon />}
                        >
                            Text 226-337-4997
                        </Button> 
                        <Typography variant="h6"> OR </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {goToContact()}}
                            size="large"
                            className={`${classes.LNFooterButton} ${classes.LNButtonRight}`}
                            startIcon={<ContactMailIcon />}
                        >
                            Contact Us
                        </Button>
                                       
            </Toolbar>
            </AppBar>    
        </Dialog>
    )
}


export default LeaderNotes;