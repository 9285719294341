import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { deepPurple } from '@material-ui/core/colors';
import LogoAlt from '../../images/VG_logo_long.png';

const styles = {
  
  logo: {    
    maxHeight: "75px",
    maxWidth: "100%",
    margin: '20px 0',
    display: 'block',
  },
  purpleLine: {
    background: deepPurple[500],
    display: 'block',
    height: 10,
  },  
  purpleGradient: {
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  }
};

class DefaultPageHeader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className='outer-header-container'>
       
          <div>
             <div style={{maxWidth: "1800px", width: "100%", margin: "0 auto", padding: "0 30px"}}>           
                
                <Grid container style={{justifyContent: "center"}}>
                  <Grid item xl={6} lg={6} md={6} xs={6}>
                        <img src={LogoAlt} alt='Virtual Glass' className={classes.logo} />
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} xs={6} align="right" style={{alignSelf: "center"}}>                     
                        <Button size="large" variant="contained" color="primary" style={{marginRight: "15px", display: "inline-block"}} onClick={() => { window.location = '/login'}}>Sign In</Button>
                        <Button size="large" variant="contained" color="primary" style={{display: "inline-block"}} onClick={() => { window.location = '/contact'}}>Contact Us</Button>                      
                  </Grid>
                </Grid>

           
              </div>
              <Grid container>
                <Grid item lg={12} xs={12}>
                    <Paper square elevation={0} className={classes.purpleLine}></Paper>
                </Grid>
                <Grid item lg={12} xs={12}>
                    <Paper square elevation={0} className={classes.purpleGradient}></Paper>
                </Grid>
              </Grid>
          </div>
        
      </div>
    );
  }
}

DefaultPageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { };
};

const mapDispatchToProps = (dispatch) => {
  return { };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(DefaultPageHeader);