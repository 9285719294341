import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import styles from '../../../../Styles';
import Button from '@material-ui/core/Button';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import DiscCard from './card';
import { CardList } from '../cards/cardList';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import AddIcon from '@material-ui/icons/Add';
import { updateResults, editResults } from './../../../../../store/actions/gameActions';
import Popover from '@material-ui/core/Popover';

const INITIAL_STATE = {
  open: false,
  anchorEl: null,
  curCard: null
};


class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  handleDrawerOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  handlePopoverOpen = (event, card) => {
    this.setState({
      ...this.state,
      anchorEl: event.currentTarget,
      curCard: card
    });
  }

  handlePopoverClose = () => {
    this.setState({
      ...this.state,
      anchorEl: null,
      curCard: null
    })
  }

  wheel = (e) => {
    this.scrollLeft -= (e.wheelDelta);
  }

  addToResults(){
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    this.props.updateResults(group_id, 'card_choices', this.state.curCard.id, (determineHost(this.props.profile, this.props.auth)));
    this.handlePopoverClose();
  }

  render() {
    const { classes, session, profile, auth, numResults } = this.props;
    const { open, anchorEl } = this.state;
    const group_id = getUserGroup_hc(session, auth.uid, profile.role);
    const popopen = Boolean(anchorEl);
    const id = popopen ? 'simple-popover' : undefined;
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);

    return (
      <div>
          <Button 
              variant="contained"
              color="primary"
              startIcon={<ViewAgendaIcon />}
              onClick={this.handleDrawerOpen} className={clsx(open && classes.hide)}>
          Browse Cards
          </Button>
          <Button 
              variant="contained"
              color="primary"
              disabled={true}
              startIcon={<ViewAgendaIcon />}
              onClick={this.handleDrawerOpen} className={clsx(!open && classes.hide)}>
          Browsing Cards
          </Button>
          <Popover 
            id={id}
            open={popopen}
            anchorEl={anchorEl}
            onClose={this.handlePopoverClose}
            PaperProps={{
              style: {
              padding: "20px"
              }
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }} >
              <div>
                { numResults < 5 ?
                <Button 
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon/>}
                  onClick={() => this.addToResults()} >
                  Add To Choices
                  </Button>
                    :
                  <Button 
                    variant="contained"
                    color="primary"
                    disabled={true}
                    startIcon={<AddIcon/>}
                    onClick={() => this.addToResults()}>
                    Add To Choices
                  </Button>
                    }
              </div>       
            </Popover>
          <Drawer anchor='right' open={open} onClose={this.handleDrawerClose}>
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronRightIcon />
                </IconButton>{' '}
                <span className={classes.playersTitle}>Cards</span>
              </div>
              <List className={`${classes.relativeList} ${classes.doNotPadTop}`}>
                <ListItem className={classes.doNotPad}>
                  <List className={`${classes.horizontalList} ${classes.borderTop}`}>
                      { CardList.map((card, ind) => {
                        if (session.groups[group_id].gameKey === card.card_category || session.groups[group_id].gameKey === 'N/A' || session.groups[group_id].gameKey === undefined ){
                          let hidden = false;
                          if ( numResults > 0 ){
                            if ( session.active_game.results[group_id].card_choices.includes(card.id)){
                              hidden = true;
                            }
                          }
                          if ( hidden ){
                            return null;
                          } else {
                          if ( !hasControl ){
                            return (<ListItem key={ind} className={classes.stretchToFit}>
                                <DiscCard hidden={hidden} session={session} profile={profile} auth={auth} card_id={card.id} card_words={card.card_words} card_category={card.card_category} card_subwords={card.card_subwords} card_fun={card.card_fun}/>
                            </ListItem>)
                          } else {
                            return (<ListItem  onClick={(e) => this.handlePopoverOpen(e, card)} key={ind} className={classes.stretchToFit}>
                                <DiscCard hidden={hidden} session={session} profile={profile} auth={auth} card_id={card.id} card_words={card.card_words} card_category={card.card_category} card_subwords={card.card_subwords} card_fun={card.card_fun}/>
                            </ListItem>)
                          }
                          }
                        } else {
                          return null;
                        }
                    })}
                  </List>
                </ListItem>
              </List>
          </Drawer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Cards);