
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import plus_delta from '../images/plus_delta.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import { getFirebase } from 'react-redux-firebase';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions'
import { updateGroupState } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import LoadingBlock from '../../../../Loading';
import Slide from '@material-ui/core/Slide';
import HelpIcon from '@material-ui/icons/Help';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';

import activity_context from './../images/activity_context.png';
import activity_filled from './../images/activity_filled.png';
import group_sandbox from './../images/group_sandbox.png';
import personal_sandbox from './../images/personal_sandbox.png';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import styles from '../../../../Styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

const INITIAL_STATE = {    
    logoURLs: {},
    imagesLoaded: false,
    helpOpen: false    
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  


class Instructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
    }

    processImages = async () => {
        let logos = {};
        const sto = getFirebase().storage();
        await Promise.all( Object.keys(this.props.game_options.game_images).map( async (key, ind) => {
            let ref = sto.ref(this.props.game_options.game_images[key]);
            let url = await ref.getDownloadURL();
            logos[key] = url;
        }));
        return Promise.resolve(logos);
    }

    async componentDidMount() {
        try {
            await this.processImages().then((logos) => {
                window.scrollTo(0, 0)
                this.setState({
                    ...this.state,
                    logoURLs : logos,
                    imagesLoaded: true
                }); 
                return Promise.resolve();
            });
        } catch (error) {
            console.log('error');
        }
        
    }

    sendToNextStep = (event) => {
        const groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
        let s = { 
            'state' : GROUP_STATE_ACTIVE,
            'params' : {
                'startedOn' : (this.props.session.active_game.groupStates[groupID].params.startedOn === undefined || this.props.session.active_game.groupStates[groupID].params.startedOn === null) ? Date.now() : this.props.session.active_game.groupStates[groupID].params.startedOn
            }
        };
        this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
    }

    helpOpen = () => {
        this.setState({
            ...this.state,
            helpOpen: true
        })
    }

    handleLegendClose = () => {
        this.setState({
            ...this.state,
            helpOpen: false
        })
    }

    render(){
        const { session, profile, auth, classes, leader, game_options } = this.props;

        if ( !this.state.imagesLoaded ){
            return <LoadingBlock/>;
        }

        let title = session.active_game.name;
        //console.log("HOW?", game_options);
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }

        return (
            <div>

                <Dialog  open={this.state.helpOpen} onClose={this.handleLegendClose} TransitionComponent={Transition} maxWidth='md' fullWidth >
                    <AppBar position="static">  
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.handleLegendClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                            <Typography variant="h6" className={classes.boldedText}>
                            How to use the activity
                            </Typography>                    
                    </Toolbar>
                    </AppBar>
                    <Grid container spacing={2} className={classes.dialogueGrid}>
                        <Grid item xs={12}  className={classes.spacingTop}>
                            <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>The activity features two sections as seen below:</Typography>
                            <div>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <img src={group_sandbox} className={classes.imageSize}/>
                                    </Grid>                                   
                                </Grid>
                                <Typography variant="body1">On the left, the group leader can move around words to visually find matching elements. As the moves happen, other memebers of the group will also see it.</Typography><br/>
                                <Typography variant="body1">At any time, members of the group that are not the leader can swap over to a personal sandbox to move words around, by clicking the <span style={{fontWeight: "bold"}}>"Personal Sandbox"</span> button. Anything done in there is only seen by that individual user.</Typography>

                            </div>
                            <Typography variant="body1" className={`${classes.boldedText} ${classes.spacingTop}`}>On the right hand side is where answers are stored.</Typography>
                            <div>
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <img src={activity_context} className={classes.imageSize}/>
                                    </Grid>                                   
                                </Grid>
                                <Typography variant="body1">Clicking on any of the spaces that say "word here" as the group leader will bring up a context menu, and allow you to select a word to enter that slot.</Typography><br/>
                                <Typography variant="body1">As answers are entered, the blank spaces will start to fill in, illustrating the combinations of words you have chosen. When all answers are entered correctly, the group will be taken to the results screen.</Typography>

                            </div>

                            <br/><br/><br/>
                        </Grid>
                    </Grid>
                    
                                    
                </Dialog>


               <Typography variant='h3' className={classes.spaceBottom}>
                    {title}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={this.state.logoURLs.main} className={classes.imageStyle} alt="Game"/>
                        
                        <div className={classes.spacingTop}>
                            <div style={{fontSize: "10px", fontStyle: "italic"}}>Adapted from Circle Words, Chris Cavert &amp; Friends FUNDoing</div>
                            <br/><br/>
                            <Button variant="contained" color="primary" startIcon={<HelpIcon/>} onClick={this.helpOpen}>How to use the activity</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                       
                        
                        { game_options.game_texts.quote === undefined || game_options.game_texts.quote === '' ?
                        null
                        :
                        <blockquote><span className="blocktext" dangerouslySetInnerHTML={{ __html: game_options.game_texts.quote }}></span> <br/><br/><span className={classes.boldedText}>– <span dangerouslySetInnerHTML={{ __html: game_options.game_texts.quote_by }}></span></span>
</blockquote>
     }
<Typography variant='body1' className={classes.spaceBottom} dangerouslySetInnerHTML={{ __html : game_options.game_texts.intro_text}}></Typography>


                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid>
               
            </div>
        );
    }

}



const mapStateToProps = (state) => {
    //console.log('this is my state', state);
    return {

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID))
    };
  };
  
  export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
  )(Instructions);
