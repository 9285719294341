import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Footer from '../Footer';
import LogoHeader from '../Header';
import AwaitingGame from '../AwaitingGame';
import GamesList from '../GamesList';
import Game from '../Game';
import { endSession, maybeCreateSession, maybeSignOutUser, addModeratorToSession, renameSession } from '../../store/actions/sessionActions';
import { HOST_STATE_GROUP_SELECT } from '../../store/actions/gameActions';
import styles from '../Styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { determineHost } from './../Game/games/game_functions/functions';
import GroupSelector from './../Game/groupSelection';


const INITIAL_STATE = {
  copied: false,
  valid: true
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function getHost(profile, auth) {
  return profile.role === 'host' ? auth.uid : profile.sessionHost;
}


class SessionBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.current_session !== undefined && this.props.current_session === undefined) {
      this.props.maybeSignOutUser(this.props.profile);
    }
    if (this.props.current_session !== undefined && prevProps.current_session !== undefined) {

      if (prevProps.current_session.players.includes(this.props.auth.uid) && !this.props.current_session.players.includes(this.props.auth.uid)) {
        this.props.maybeSignOutUser(this.props.profile);
      }
    }

    /* If a player somehow manages to stay signed in but the current session doesn't match their code, then that's the end. */
    if (this.props.current_session !== undefined && this.props.profile.role === 'player') {
      if (this.props.current_session.url !== this.props.profile.sessionCode) {
        if (this.state.valid === true) {
          this.setState({
            ...this.state,
            valid: false
          })
        }
      } else {
        if (this.state.valid === false) {
          this.setState({
            ...this.state,
            valid: true
          })
        }
      }
    }
  }

  createSession = (event) => {
    event.preventDefault();
    this.props.maybeCreateSession(this.props.profile);
  };

  endSession = (event) => {
    event.preventDefault();
    this.props.endSession();
  };

  handleClose = (event) => {
    this.setState({
      ...this.state,
      copied: false
    });
  }

  render() {
    const { classes, t } = this.props;

    if (!isLoaded(this.props.theme) || !isLoaded(this.props.defaultTheme)) {
      return null;
    }

    let theTheme = this.props.defaultTheme;
    if (this.props.theme !== null && this.props.theme !== undefined) {
      theTheme = this.props.theme;
    }

    if (theTheme.color === undefined) {
      theTheme = {
        ...theTheme,
        color: this.props.defaultTheme.color
      };
    }
    if (theTheme.logo === undefined) {
      theTheme = {
        ...theTheme,
        logo: this.props.defaultTheme.logo
      }
    }


    const modState = this.props.current_session === undefined ? false : this.props.current_session.moderatorStates === undefined ? false : this.props.current_session.moderatorStates[this.props.auth.uid] === undefined ? false : this.props.current_session.moderatorStates[this.props.auth.uid].state;

    // TODO: Figure out when stuff is still loading, and show loading properly
    if (!isLoaded(this.props.current_session_manip)) {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />
          <div className='outer-container'>
            <div className='container'>
            </div>
          </div>
          <Footer session={this.props.session} theme={theTheme} />
        </div>
      );
    }

    /* IF we've detected an invalid session, stop here */
    if (this.state.valid === false) {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />

          <div className='outer-container'>
            <div className='container'>
              <Typography variant="h4">The session you were signed in for is no longer valid.</Typography>
              <Typography variant="body1">Please sign out and try another session code.</Typography>
            </div>
          </div>
          <Footer profile={this.props.profile} auth={this.props.auth} session={this.props.session} hostInfo={this.props.hostInfo} theme={theTheme} />
        </div>
      );
    }

    if (this.props.profile.role === 'moderator') {
      if (isEmpty(this.props.current_session_manip)) {
        return (
          <div className='meta-react'>
            <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />
            <div className='outer-container'>
              <div className='container'>
                <Typography variant="h4">Welcome, {this.props.profile.displayName}</Typography>
                <Typography variant="body1">There is currently no session available to join.</Typography>
              </div>
            </div>
            <Footer profile={this.props.profile} auth={this.props.auth} session={this.props.session} hostInfo={this.props.hostInfo} theme={theTheme} />
          </div>
        );
      }

      /* If not joined to the current session, join it */
      if (!this.props.current_session.moderators.includes(this.props.auth.uid)) {
        this.props.addModeratorToSession(this.props.auth.uid, this.props.profile.moderates);
        return (
          <div className='meta-react'>
            <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />

            <div className='outer-container'>
              <div className='container'>
                <Typography variant="h4">Welcome, {this.props.profile.displayName}</Typography>
                <Typography variant="body1">One moment, joining up with the session...</Typography>

              </div>
            </div>
            <Footer profile={this.props.profile} auth={this.props.auth} session={this.props.session} hostInfo={this.props.hostInfo} theme={theTheme} />
          </div>
        );
      }

      /* If joined and no game selected, then continue showing moderator soon to start screen */

      if (Object.keys(this.props.current_session.active_game).length === 0 && modState === "") {

        return (
          <div className='meta-react'>
            <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />

            <div className='outer-container'>
              <div className='container'>
                <Typography variant="h4">Welcome, {this.props.profile.displayName}</Typography>
                <Typography variant="body1">You're all joined up. Hold on until the host launches an activity.</Typography>

              </div>
            </div>
            { /*         <ZoomWrapper  profile={this.props.profile} auth={this.props.auth}  session={this.props.current_session}/> */}
            <Footer profile={this.props.profile} auth={this.props.auth} session={this.props.session} hostInfo={this.props.hostInfo} theme={theTheme} />
          </div>
        );
      }
      //  return (
      //    <div className='meta-react'>
      //      <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth}  theme={theTheme}/>

      //      <div className='outer-container'>
      //        <div className='container'>
      //        <Typography variant="h4">Welcome, {this.props.profile.displayName}</Typography>
      //        <Typography variant="body1">You're all joined up. Hold on until the host launches an activity.</Typography>

      //        </div>
      //      </div>
      /*         <ZoomWrapper  profile={this.props.profile} auth={this.props.auth}  session={this.props.current_session}/> */
      //      <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}  hostInfo={this.props.hostInfo} theme={theTheme}/>
      //    </div>
      //    );
      //}

    }


    if (this.props.current_session !== undefined) {
      if ((this.props.profile.role === 'host' || this.props.profile.role === 'moderator')) {

        if (this.props.profile.role === 'host') {
          if (this.props.current_session.hostState !== undefined) {
            if (this.props.current_session.hostState.state === HOST_STATE_GROUP_SELECT) {
              return (
                <div className='meta-react'>
                  <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />

                  <div className='outer-container'>
                    <div className='container main'>
                      <div className="session-info"><span className={classes.greyText}>Session Name: </span><span className={classes.boldedText} style={{ fontSize: '18px' }}>{this.props.current_session.sessionName}</span></div>
                      <span className={`${classes.greyText} session-info`}>Session URL: </span>{' '}
                      <span className={`${classes.session} ${classes.glassPurple} session-info`}>
                        {window.location.href}p/{this.props.current_session.url}
                      </span>
                      <span className="session-info">
                        <CopyToClipboard text={`${window.location.href}p/${this.props.current_session.url}`}
                          onCopy={() => this.setState({ ...this.state, copied: true })}>
                          <IconButton className="session-info" color="primary" aria-label="Copy to Clipboard" style={{ padding: "0 8px" }}>
                            <FileCopyIcon />
                          </IconButton>
                        </CopyToClipboard>
                      </span>
                      <Snackbar open={this.state.copied} autoHideDuration={3000} onClose={this.handleClose}>
                        <Alert onClose={this.handleClose} severity="success">
                          Session URL Copied to Clipboard!
                        </Alert>
                      </Snackbar>
                      <div style={{ marginTop: 30 }}>

                        <GroupSelector session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} players={this.props.current_session.players} />
                      </div>
                    </div>
                  </div>

                  <Footer session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} hostInfo={this.props.hostInfo} theme={theTheme} />
                </div>
              );
            }
          }
        }

        if (this.props.profile.role === 'moderator') {

          if (modState === HOST_STATE_GROUP_SELECT) {
            return (
              <div className='meta-react'>
                <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />

                <div className='outer-container'>
                  <div className='container main'>
                    <div className="session-info"><span className={classes.greyText}>Session Name: </span><span className={classes.boldedText} style={{ fontSize: '18px' }}>{this.props.current_session.sessionName}</span></div>
                    <span className={`${classes.greyText} session-info`}>Session URL: </span>{' '}
                    <span className={`${classes.session} ${classes.glassPurple} session-info`}>
                      {window.location.href}p/{this.props.current_session.url}
                    </span>
                    <span className="session-info">
                      <CopyToClipboard text={`${window.location.href}p/${this.props.current_session.url}`}
                        onCopy={() => this.setState({ ...this.state, copied: true })}>
                        <IconButton className="session-info" color="primary" aria-label="Copy to Clipboard" style={{ padding: "0 8px" }}>
                          <FileCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </span>
                    <Snackbar open={this.state.copied} autoHideDuration={3000} onClose={this.handleClose}>
                      <Alert onClose={this.handleClose} severity="success">
                        Session URL Copied to Clipboard!
                      </Alert>
                    </Snackbar>
                    <div style={{ marginTop: 30 }}>
                      <GroupSelector session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} players={this.props.current_session.players} />
                    </div>
                  </div>
                </div>

                <Footer session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} hostInfo={this.props.hostInfo} theme={theTheme} />
              </div>
            );
          }

        }
      }

    }

    return (
      <div className='meta-react'>
        <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} theme={theTheme} />

        <div className='outer-container'>
          <div className='container main'>

            {this.props.profile.role === 'host' ? (
              /* 1 - IF USER IS A HOST */
              this.props.current_session !== undefined ? (
                /* 2 - IF THERE IS A SESSION */
                <div className={classes.sessionContain}>
                  <div className="container secondary">
                    <div className="session-info"><span className={classes.greyText}>Session Name: </span><span className={classes.boldedText} style={{ fontSize: '18px' }}>{this.props.current_session.sessionName}</span></div>
                    <span className={`${classes.greyText} session-info`}>Session URL: </span>{' '}
                    <span className={`${classes.session} ${classes.glassPurple} session-info`}>
                      {window.location.href}p/{this.props.current_session.url}
                    </span>
                    <span className="session-info">
                      <CopyToClipboard text={`${window.location.href}p/${this.props.current_session.url}`}
                        onCopy={() => this.setState({ ...this.state, copied: true })}>
                        <IconButton className="session-info" color="primary" aria-label="Copy to Clipboard" style={{ padding: "0 8px" }}>
                          <FileCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </span>
                    <Snackbar open={this.state.copied} autoHideDuration={3000} onClose={this.handleClose}>
                      <Alert onClose={this.handleClose} severity="success">
                        Session URL Copied to Clipboard!
                      </Alert>
                    </Snackbar>
                    {Object.keys(this.props.current_session.active_game).length === 0 ? (
                      /* 3 - IF THERE IS AN ACTIVE GAME */

                      <GamesList session={this.props.current_session} profile={this.props.profile} auth={this.props.auth}></GamesList>
                    ) : /* 3 - NO ACTIVE GAME */ null}
                  </div>
                </div>
              ) : (
                /* 2 - NO SESSION */

                isEmpty(this.props.current_session_manip) ?
                  <div className={classes.sessionContain}>
                    {/* NEED SOME STYLE HELP */}
                    <Typography variant='h5'>
                      Welcome!
                    </Typography>
                    <p>To begin, let's start up a session.</p>
                    <div>

                      <Button className={classes.glassPurple} startIcon={<PlayCircleFilledWhiteIcon />} type='submit' onClick={this.createSession} variant='contained'>
                        Start Session
                      </Button>
                    </div>
                  </div>
                  : null
              )
            ) : /* 1 - NOT A HOST */ null}

            {this.props.current_session !== undefined /* 1A - A SESSION EXISTS */ ? (
              this.props.profile.role === 'player' && !this.props.current_session.players.includes(this.props.auth.uid) ? (
                <AwaitingGame session={this.props.current_session} hostInfo={this.props.hostInfo} />
              ) : Object.keys(this.props.current_session.active_game).length !== 0 /* 2A - IF THERE IS AN ACTIVE GAME */ ? (
                <Game id={this.props.current_session.active_game.id} title={this.props.current_session.active_game.name} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} />
              ) : /* 2A - NO ACTIVE GAME */
                this.props.profile.role === 'player' ? (
                  <AwaitingGame session={this.props.current_session} hostInfo={this.props.hostInfo} />
                ) : null
            ) : /* 1A - NO ACTIVE SESSION */
              this.props.profile.role === 'admin' ?
                <Typography variant="body1">You are currently signed in as an ADMIN. Please sign out and sign in as another user.</Typography>
                :
                null}

          </div>
        </div>
        { /*         <ZoomWrapper  profile={this.props.profile} auth={this.props.auth}  session={this.props.current_session}/> */}
        <Footer session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} hostInfo={this.props.hostInfo} theme={theTheme} />
      </div>
    );
  }
}

SessionBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    current_session: state.firestore.ordered.current_sessions === undefined || state.firestore.ordered.current_sessions === null ? undefined : state.firestore.ordered.current_sessions[0],
    current_session_manip: state.firestore.ordered.current_sessions,
    hostInfo: state.firestore.data.hostInfo,
    theme: state.firestore.data.theme,
    defaultTheme: state.firestore.data.defaultTheme
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endSession: () => dispatch(endSession()),
    maybeCreateSession: (user) => dispatch(maybeCreateSession(user)),
    maybeSignOutUser: (user) => dispatch(maybeSignOutUser(user)),
    addModeratorToSession: (uid, host) => dispatch(addModeratorToSession(uid, host))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [
    {
      collection: 'current_sessions',
      where: ['host', '==', determineHost(props.profile, props.auth)],
    },
    {
      collection: 'players',
      doc: determineHost(props.profile, props.auth),
      storeAs: 'hostInfo'
    },
    {
      collection: 'theme',
      doc: determineHost(props.profile, props.auth),
      storeAs: 'theme'
    },
    {
      collection: 'theme',
      doc: 'default',
      storeAs: 'defaultTheme'
    }
  ])
)(SessionBase);
