import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { EXPECTATIONS, ACCOUNTABILITIES } from './activity';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import CircleOfInfluenceDefbrief from './images/CircleOfInfluenceDebrief.png';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { phase : ACCOUNTABILITIES } );
    }
    returnToResultsE = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { phase : EXPECTATIONS } );
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.session.active_game.resultSnapshot === undefined ? {} : this.props.session.active_game.resultSnapshot, this.props.session.active_game.results)
        })
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      session,
      classes,
      profile
    } = this.props;
    const { diff } = this.state;

    if ( diff === undefined ){
        return null;
    } else {
        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{session.active_game.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                <Dialog fullScreen open={this.state.open} onClose={this.handleClose} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
                        <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                        {session.active_game.name} - Debrief
                        </Typography>                    
                    </Toolbar>
                    </AppBar>
                    <Grid container spacing={2} className={classes.dialogueGrid}>
                        <Grid item xs={12} sm={6} className={classes.doubleSpacingTop}>
                            <img src={CircleOfInfluenceDefbrief} className={classes.imageSize} alt="Circle of Influence"/>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.doubleSpacingTop}>
                            <Typography variant="body1" className={`${classes.spaceBottom} ${classes.medBodyText} ${classes.spacingTop}`}>When we focus on what we <span className={classes.boldedText}>can’t influence</span>, our <span className={classes.boldedText}>circle of influence shrinks.</span></Typography>
                            <Typography variant="body1" className={classes.medBodyText}>When we focus on what we <span className={classes.boldedText}>can influence</span>, our <span className={classes.boldedText}>circle of concern shrinks</span> – limit what you can’t influence; focus on what you can influence.</Typography>
                            <Typography variant="body1" className={classes.medBodyText}>Limit what you can't influence; <span className={classes.boldedText}>focus on what you can influence.</span></Typography>
                        </Grid>
                    </Grid>                    
                </Dialog>
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(session.active_game.results)
                .sort()
                .map((groupKey, ind) => {
                    let hasAcc = session.active_game.results.hasOwnProperty(groupKey) ? session.active_game.results[groupKey].hasOwnProperty(ACCOUNTABILITIES) ? true : false : false;

                    return (
                    <div key={ind}>
                        <Typography variant="h4">{session.groups[groupKey].title}</Typography>
                        <Grid container className={classes.paperExtra}>
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                            <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Expectations:</Typography>
                                {/* Results Here */}
                                <List className={classes.listClassExtra}>
                                {diff.hasOwnProperty(groupKey)
                                    ? diff[groupKey].hasOwnProperty(EXPECTATIONS)
                                    ? Object.keys(diff[groupKey][EXPECTATIONS]).map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                            <ListItemText primary={diff[groupKey][EXPECTATIONS][item].data} className={classes[diff[groupKey][EXPECTATIONS][item].type]} />
                                            </ListItem>
                                        );
                                        })
                                    : 
                                    diff[groupKey].data.hasOwnProperty(EXPECTATIONS) ?
                                    Object.keys(diff[groupKey].data[EXPECTATIONS]).map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                            <ListItemText primary={diff[groupKey].data[EXPECTATIONS][item]}/>
                                            </ListItem>
                                            );
                                        })
                                        : null
                                    : null}
                                </List>
                            </Grid>
                            { hasAcc === true ?
                            <Grid item xs={12} sm={12}>
                            <Typography variant='h6' className={`${classes.spaceBottom} ${classes.boldedText}`}>Accountabilities:</Typography>
                                {/* Results Here */}  
                                <List className={classes.listClassExtra}>
                                {session.active_game.results.hasOwnProperty(groupKey)
                                    ? session.active_game.results[groupKey].hasOwnProperty(ACCOUNTABILITIES)                                   
                                        ?
                                        session.active_game.results[groupKey][ACCOUNTABILITIES].map((item, index) => {
                                            return (
                                                <ListItem key={index}>
                                                <ListItemText primary={item}/>
                                                </ListItem>
                                                );
                                            })
                                    : null                                                
                                : null }
                                </List>
                            </Grid>
                            : null }
                            </Grid>
                        </Grid>
                        </Grid>
                    </div>
                    );
                })}
            </Grid>
            { profile.role === 'host' ?
            <Grid item xs={12} className={classes.spacingTop}>
                    <Button 
                        variant="contained"
                        color="primary"
                        startIcon={<AssignmentReturnIcon />}
                        onClick={this.returnToResults}
                        className={classes.marginStep}>
                    Return to Activity For Accountabilities
                    </Button>
                    <Button 
                        variant="contained"
                        color="primary"
                        startIcon={<AssignmentReturnIcon />}
                        onClick={this.returnToResultsE}>
                    Return to Activity For Expectations
                    </Button>
            </Grid>
            : null }
            </Grid>
        </div>
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
