import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const TrustBehaviorsLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download PDF" {...a11yProps(5)} />
                <Tab className={classes.tabsLNTab} label="Slide Deck" {...a11yProps(6)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>This activity helps participants consider behaviors that build trust or destroy trust at various levels within an organization</li>
                    <li className={classes.properListItem}>In small groups, participants identify trust behaviors (the things that people do or say) and record them on a chart</li>
                    <li className={classes.properListItem}>The groups will consider various levels of leadership within the organization as they go through this activity to highlight the differences between the roles</li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Behaviors that build or destroy trust</li>
                    <li className={classes.properListItem}>How trust behaviors can look different at various levels of an organization</li>
                    <li className={classes.properListItem}>The importance of leaders behaving (what you say and do) in a way that will build trust with their teams </li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Trust behaviors can look different across various levels of an organization. </li>
                    <li className={classes.properListItem}>There are many behaviors that will always build trust (e.g. doing what you say you’ll do, being honest, providing clear expectations) or destroy trust (e.g. being dishonest, gossiping, breaking promises)</li>
                    <li className={classes.properListItem}>However, trust behaviors can show up differently with upper management versus someone at the employee level</li>
                    <li className={classes.properListItem}>For example, trust behaviors of a senior leader, might look like being visible to employees, or taking the time to connect with employees on a fairly regular basis. </li>
                    <li className={classes.properListItem}>At the employee level, trust behaviors might look like being on time for work, being respectful to work colleagues and pitching in when help is needed. </li>
                    <li className={classes.properListItem}>We’re going to have a conversation about what trust behaviors look like at different levels of leadership to provide more clarity about some of the  distinctions that can be made  </li>
                </ul>                
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Guidelines</Typography>      
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Consider the various levels of leadership within your organization (as per the chart)</li>
                    <li className={classes.properListItem}>Discuss the behaviors you that you would see (the things people do or say) at each level that would build or destroy trust</li>
                    <li className={classes.properListItem}>Record your points in the table</li>
                </ul>
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debriefing Topics – options for questions</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What similarities do we see across the different levels of the organization in terms of how trust is built?</li>
                    <li className={classes.properListItem}>What similarities do we see across the different levels of the organization in terms of how trust is destroyed?</li>
                    <li className={classes.properListItem}>Where might you need to put some attention on your own behaviors?</li>
                    <li className={classes.properListItem}>What are some of the impacts of these behaviors on the levels below or above? </li>                              
                </ul>
                <Typography variant="h5">Additional info to share</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Employees who trust their immediate boss :</b></Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>Higher job satisfaction</b></li>
                    <li className={classes.properListItem}><b>More commitment to the company</b></li>
                    <li className={classes.properListItem}><b>Feel they are treated more fairly in processes and decision making</b></li>
                    <li className={classes.properListItem}><b>Feel the organization supports them more</b></li>
                    <li className={classes.properListItem}><b>Feel that leaders fairly allocate resources</b></li>
                    <li className={classes.properListItem}><b>Treat others well</b></li>
                    <li className={classes.properListItem}><b>Follow procedures in a way that is evident with results</b></li>
                </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Leader Notes</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download PDF
                </Button>
                </a>
            </TabPanel>
           
            <TabPanel className={classes.LNTabPanel} value={value} index={6}>
                <Typography variant="h5">Slide Deck</Typography>  
                              
            </TabPanel>
           
        </div> 
    )
}

export default withStyles(styles)(TrustBehaviorsLN);