import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import { deepDiffMapper } from '../../game_functions/functions'
import {
    moveBackToResultsPhase,
    moveBackToActiveWithParams
} from '../../../../../store/actions/gameActions'
import Typography from '@material-ui/core/Typography'
import styles from '../../../../Styles'
import ActivityListPlus from '../specific_components/activityListPlus'
import ActivityListDelta from '../specific_components/activityListDelta'
import AddIcon from '@material-ui/icons/Add'
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory'
import { QUESTIONS } from './../data/data'

import { parseMultiPart } from '../../../../GamesList/game_options_components/game_misc'

const INITIAL_STATE = {
    open: false
}

class GameResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...INITIAL_STATE
        }
    }

    returnToResults = () => {
        this.props.moveBackToResultsPhase(
            this.props.session,
            this.props.auth.uid
        )
    }

    moveToFloorExercise = () => {
        this.props.moveBackToActiveWithParams(
            this.props.session,
            this.props.auth.uid,
            { phase: 'floor' }
        )
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(
                this.props.session.active_game.resultSnapshot === undefined
                    ? {}
                    : this.props.session.active_game.resultSnapshot,
                this.props.session.active_game.results
            )
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        })
    }

    render() {
        const { session, classes, auth, profile, game_options } = this.props

        //console.log('aa', game_options);

        let groupKeys = Object.keys(session.groups).sort()
        let groupInd = null

        let questions_arr = parseMultiPart(
            game_options.game_misc.multipart__number_of_questions
        )
        if (questions_arr === undefined) {
            questions_arr = QUESTIONS
        }
        let numQuestions = questions_arr.length

        let title = session.active_game.name
        if (game_options !== false) {
            title = game_options.game_texts.game_title
        }

        let isSameQuestions = false
        if (game_options !== false) {
            isSameQuestions =
                game_options.game_misc.select__Same_questions_for_each_team
        }

        return (
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h3">{title}</Typography>
                        <Typography
                            variant="h5"
                            className={classes.spaceBottom}
                        >
                            Debrief
                        </Typography>
                        {/*: null */}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        {Object.keys(session.active_game.results)
                            .sort()
                            .map((groupKey, ind) => {
                                //console.log("GROUP KEYS", groupKeys);
                                groupKeys.forEach((groupId, ind) => {
                                    if (groupKey === groupId) {
                                        groupInd = ind - 1
                                    }
                                })

                                if (!isSameQuestions) {
                                    return (
                                        <div key={ind}>
                                            <Typography
                                                variant="h4"
                                                className={`${classes.spacingTop} ${classes.spacingBottom}`}
                                            >
                                                {session.groups[groupKey].title}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                QUESTION:
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{ fontSize: '1.5em' }}
                                                className={
                                                    classes.spacingBottom
                                                }
                                            >
                                                {questions_arr[groupInd] !==
                                                undefined
                                                    ? questions_arr[groupInd]
                                                    : questions_arr[0]}
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        align="center"
                                                        className={
                                                            classes.tableHead
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                classes.spanWithHTML
                                                            }
                                                            dangerouslySetInnerHTML={{
                                                                __html: game_options
                                                                    .game_texts
                                                                    .answers_field_name
                                                            }}
                                                        ></span>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        align="center"
                                                        className={
                                                            classes.tableBody
                                                        }
                                                    >
                                                        <ActivityListPlus
                                                            session={session}
                                                            profile={profile}
                                                            auth={auth}
                                                            group_id={groupKey}
                                                            debrief={true}
                                                            hasGameControl={
                                                                false
                                                            }
                                                            showList={true}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={ind}>
                                            <Typography
                                                variant="h4"
                                                className={`${classes.spacingTop} ${classes.spacingBottom}`}
                                            >
                                                {session.groups[groupKey].title}
                                            </Typography>
                                            {questions_arr.map(
                                                (question, ind) => {
                                                    return (
                                                        <div
                                                            key={ind}
                                                            style={{
                                                                marginBottom: 30
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    fontWeight:
                                                                        'bold'
                                                                }}
                                                            >
                                                                QUESTION{' '}
                                                                {ind + 1}:
                                                            </Typography>
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    fontSize:
                                                                        '1.5em'
                                                                }}
                                                                className={
                                                                    classes.spacingBottom
                                                                }
                                                            >
                                                                {question}
                                                            </Typography>
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={12}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        align="center"
                                                                        className={
                                                                            classes.tableHead
                                                                        }
                                                                    >
                                                                        <span
                                                                            className={
                                                                                classes.spanWithHTML
                                                                            }
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: game_options
                                                                                    .game_texts
                                                                                    .answers_field_name
                                                                            }}
                                                                        ></span>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        align="center"
                                                                        className={
                                                                            classes.tableBody
                                                                        }
                                                                    >
                                                                        <ActivityListPlus
                                                                            session={
                                                                                session
                                                                            }
                                                                            profile={
                                                                                profile
                                                                            }
                                                                            auth={
                                                                                auth
                                                                            }
                                                                            group_id={
                                                                                groupKey
                                                                            }
                                                                            debrief={
                                                                                true
                                                                            }
                                                                            hasGameControl={
                                                                                false
                                                                            }
                                                                            showList={
                                                                                true
                                                                            }
                                                                            ind={
                                                                                ind
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    )
                                }
                            })}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        moveBackToResultsPhase: (session, host) =>
            dispatch(moveBackToResultsPhase(session, host)),
        moveBackToActiveWithParams: (session, host, params) =>
            dispatch(moveBackToActiveWithParams(session, host, params))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(GameResults)
