import { Image, Transformer } from "react-konva";
import React, { useEffect } from "react";

import useImage from "use-image";

export const URLImage = ({
  image,
  isDrawing,
  handleDragEnd,
  dragStart,
  transformEnd,
  control,
  openContext,
  deselectAll,
  resetDeselectAll,
  selectThis,
  resetSelectThis
}) => {
  const [img] = useImage(image.src);
  const trRef = React.useRef();
  const imgRef = React.useRef();
  const [isSelected, setIsSelected] = React.useState([false]);

  useEffect(() => {
    if (deselectAll === true) {
      setIsSelected(false);
      resetDeselectAll();
    }
  }, [deselectAll]);

  useEffect(() => {
    if (selectThis === true) {
      setIsSelected(true);
      resetSelectThis();
    }
  }, [selectThis]);

  useEffect(() => {
    if (isSelected === true && control === true) {
      // we need to attach transformer manually
      trRef.current.nodes([imgRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  let offsetX = img ? parseInt(image.width) / 2 : 0;
  let offsetY = img ? parseInt(image.height) / 2 : 0;

  const canvasWidth = 850;
  const canvasHeight = 650;

  const contextHandler = (e) => {
    e.evt.preventDefault();
    setIsSelected(true);
    openContext(e.evt, image.id);
  };

  //console.log('image: ', img);

  return (
    <>
      <Image
        ref={imgRef}
        image={img}
        x={image.x}
        y={image.y}
        id={image.id}
        dataId={image.dataId}
        rotation={image.rotation}
        scaleX={image.scaleX !== undefined ? image.scaleX : 1}
        scaleY={image.scaleY !== undefined ? image.scaleY : 1}
        onClick={(e) => {
          e.evt.preventDefault();
          if (e.evt.button === 0) {
            setIsSelected(isSelected === true ? false : true);
          }
        }}
        dragBoundFunc={(pos) => {
          var newY = pos.y < 10 ? 10 : pos.y;
          newY = newY > canvasHeight ? canvasHeight : newY;

          var newX = pos.x < 10 ? 10 : pos.x;
          newX = newX > canvasWidth ? canvasWidth : newX;

          return {
            x: newX,
            y: newY,
          };
        }}
        width={parseInt(image.width)}
        height={parseInt(image.height)}
        // I will use offset to set origin to the center of the image
        offsetX={offsetX}
        offsetY={offsetY}
        draggable={isDrawing === true ? false : control === true ? true : false}
        onDragEnd={handleDragEnd}
        onDragStart={dragStart}
        onTransformEnd={transformEnd}
      />

      {isSelected === true && control === true ? (
        <Transformer
          ref={trRef}
          resizeEnabled={false}
          flipEnabled={true}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize

            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      ) : null}
    </>
  );
};
