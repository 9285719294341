import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import ActivityListPlus from '../specific_components/activityListPlus';
import ActivityListDelta from '../specific_components/activityListDelta';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
    }

    moveToFloorExercise = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor' });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.session.active_game.resultSnapshot === undefined ? {} : this.props.session.active_game.resultSnapshot, this.props.session.active_game.results)
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      session,
      classes,
      auth,
      profile
    } = this.props;

        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{session.active_game.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {Object.keys(session.active_game.results)
                    .sort()
                    .map((groupKey, ind) => {
                        return (
                        <div key={ind}>
                            <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[groupKey].title}</Typography>
                            <Grid container spacing={2}>        
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12}  align="center" className={classes.tableHead}>
                                    
                                    Values <ThumbUpIcon/>
                                        
                                    </Grid>
                                    
                                    
                                    <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    
                                        
                                        <ActivityListPlus session={session} profile={profile} auth={auth} group_id={groupKey} debrief={true} hasGameControl={false} showList={true}/>
                                    
                                    </Grid>
                                    
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12}  align="center" className={classes.tableHead}>    
                                        Stressors <ThumbDownIcon/>     
                                    </Grid>
                                    <Grid item xs={12}  align="center" className={classes.tableBody}>
                                        <ActivityListDelta session={session} profile={profile} auth={auth} group_id={groupKey} debrief={true} hasGameControl={false} showList={true} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        );
                    })}
                </Grid>   
            </Grid>
        </div>
    );
    
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);