import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import styles from './../../../../Styles'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import ViewListIcon from '@material-ui/icons/ViewList'
import HiddenAgendaLN from './leaderNotes';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const INITIAL_STATE = {
    open: false,
    legendOpen: false
}

class HostInstructions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...INITIAL_STATE
        }
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        })
    }

    handleLegendClickOpen = () => {
        this.setState({
            ...this.state,
            legendOpen: true
        })
    }

    handleLegendClose = () => {
        this.setState({
            ...this.state,
            legendOpen: false
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Typography variant="h5" className={classes.greyText}>
                    Host Tools
                </Typography>
                <div className={classes.spacingTop}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SpeakerNotesIcon />}
                            onClick={this.handleClickOpen}
                        >
                            Leader Notes
                        </Button>
                        <div className={classes.spacingTop}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<ViewListIcon />}
                                onClick={this.handleLegendClickOpen}
                            >
                                State Legend
                            </Button>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                className={classes.boldedText}
                            >
                                Leader Notes
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <HiddenAgendaLN/>                    
                </Dialog>
                <Dialog
                    open={this.state.legendOpen}
                    onClose={this.handleLegendClose}
                    TransitionComponent={Transition}
                >
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleLegendClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                className={classes.boldedText}
                            >
                                State Legend
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Grid
                        container
                        spacing={2}
                        className={classes.dialogueGrid}
                    >
                        <Grid item xs={12} className={classes.spacingTop}>
                            <Typography
                                variant="body1"
                                className={`${classes.boldedText} ${classes.spacingTop}`}
                            >
                                Init
                            </Typography>
                            <div>
                                {/* <img src={distortion_sl_init} alt='intro graphic and assign roles' className={`${classes.imageSize} ${classes.borderImage}`}/> */}
                            </div>
                            <Typography
                                variant="body1"
                                className={`${classes.boldedText} ${classes.spacingTop}`}
                            >
                                Active
                            </Typography>
                            <div>
                                {/* <img src={distortion_sl_active} alt='distortion game screen' className={`${classes.imageSize} ${classes.borderImage}`}/> */}
                            </div>
                            <Typography
                                variant="body1"
                                className={`${classes.boldedText} ${classes.spacingTop}`}
                            >
                                Results
                            </Typography>
                            <div>
                                {/* <img src={distortion_sl_results} alt='wrapping up and waiting for others' className={`${classes.imageSize} ${classes.borderImage}`}/> */}
                            </div>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(HostInstructions)
