import React, { Component } from 'react';

import * as ROUTES from '../../constants/routes';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

//New sign in method
import { connect } from 'react-redux';
import { signIn, signInGoogle, closeAuthPopUps } from '../../store/actions/authActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ImageHeader from '../Header/imageHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Footer from '../Footer';
//import GoogleLogo from '../../images/icons/GoogleLogo';
//import FacebookIcon from '@material-ui/icons/Facebook';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
//import { FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
  },
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
    textAlign: 'left',
  },
  forgot: {
    opacity: '0.5',
  },
};

const INITIAL_STATE = {
  email: '',
  password: '',
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();

    this.props.signIn(this.state);
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  loginGoogle = () => {
    this.props.signInGoogle();
  };
  closePopUps = () => {
    this.props.closeAuthPopUps();
  };

  render() {
    const { email, password } = this.state;

    const isInvalid = password === '' || email === '';

    const { auth, t, classes, authError, authMessage, authErrorPopUp, authPopUp } = this.props;

    if (this.props.match.params.id) {
    } else {
      if (auth.uid) return <Redirect to={ROUTES.LANDING} />;
    }

    return (
      <div className='meta-react'>
        <ImageHeader />
        <div className='outer-container'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} justify='center'>
                    <Grid item xs={12}></Grid>
                    <Grid item md={4} xs={12} align='center'>
                      <Typography variant='h4'>{t('signIn.pageTitle')}</Typography>
                    </Grid>
                    <Grid item xs={12} container justify='center'>
                      <Grid item sm={1} xs={2}>
                        <Divider />
                      </Grid>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <form onSubmit={this.onSubmit}>
                        <Grid item container spacing={3} justify='center'>
                          <Grid item xs={12}>
                            <TextField name='email' className={classes.textField} value={email} onChange={this.onChange} type='text' label={t('signIn.emailAddress')} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField name='password' className={classes.textField} value={password} onChange={this.onChange} type='password' label={t('signIn.password')} />
                          </Grid>
                          <Grid item xs={12} align='center'>
                            <Button disabled={isInvalid} type='submit' variant='contained' onClick={this.onSubmit}>
                              {t('signIn.signInButton')}
                            </Button>
                          </Grid>
                          <Grid item xs={12} align='center'>
                            <Button component={Link} to={ROUTES.FORGOTTEN_PASSWORD} className={classes.forgot}>
                              {t('signIn.forgotPassword')}
                            </Button>
                          </Grid>
                         { /* <Grid item container spacing={2} xs={12} alignContent='center' alignItems='center'>
                            <Grid item xs={4}>
                              <Divider />
                            </Grid>
                            <Grid item xs={4} align='center'>
                              <Typography variant='h6'>{t('signIn.or')}</Typography>
                            </Grid>

                            <Grid item xs={4}>
                              <Divider />
                            </Grid>
                          </Grid>
                         
                          
                          <Grid item container spacing={2} xs={12} alignContent='center' alignItems='center'>
                            <Grid item xs={2}>
                              <Divider />
                            </Grid>
                            <Grid item xs={8} align='center'>
                              <Typography variant='body1'>{t('signIn.noAccount')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Divider />
                            </Grid>
                          </Grid>
                         <Grid item xs={12}>
                            <Button variant='contained' size='large' className={classes.button} component={Link} to={ROUTES.SIGN_UP}>
                              {t('signIn.register')}
                            </Button>
                            </Grid>*/ }
                          </Grid> 
                        
                      </form>
                    </Grid>
                  </Grid>
                  <Snackbar open={authPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                    <Alert onClose={this.closePopUps} severity='success'>
                      {authMessage}
                    </Alert>
                  </Snackbar>
                  <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                    <Alert onClose={this.closePopUps} severity='error'>
                      {authError}
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

SignInFormBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.gol_auth.authError,
    authMessage: state.gol_auth.authMessage,
    authPopUp: state.gol_auth.authPopUp,
    authErrorPopUp: state.gol_auth.authErrorPopUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    signInGoogle: () => dispatch(signInGoogle()),
    closeAuthPopUps: () => dispatch(closeAuthPopUps()),
  };
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(SignInFormBase);
