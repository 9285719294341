import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import HomePage from '../Home';
import LoginPage from '../SignIn';
import Default from '../Default';
import SignUp from '../SignUp';
import AnonSignIn from '../SignIn/anonSignIn';
import AnonSignInMyCode from '../SignIn/anonSignIn_myCode';
import ChangeName from '../SignIn/changeName';
import ForgotPasswordForm from '../SignIn/forgotPassword';
import Contact from '../Contact'
import {connect} from 'react-redux';

import * as ROUTES from '../../constants/routes';

const App = (props) => (
  <Router>
    <div>
      <Route exact path={ROUTES.LANDING} component={HomePage} />
      <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
      <Route exact path={ROUTES.SIGN_UP_REDIRECT} component={SignUp} />
      <Route exact path={ROUTES.LOGIN} component={LoginPage} />
      <Route exact path={ROUTES.FORGOTTEN_PASSWORD} component={ForgotPasswordForm} />
      <Route exact path={ROUTES.ANON_SIGN_IN} component={AnonSignIn} />
      <Route exact path={ROUTES.ANON_SIGN_IN_PRE} component={AnonSignInMyCode} />
      <Route exact path={ROUTES.ANON_SIGN_IN_NID} component={AnonSignIn} />
      <Route exact path={ROUTES.CHANGE_NAME} component={ChangeName} />
      <Route exact path={ROUTES.DEFAULT} component={Default} />
      <Route exact path={ROUTES.CONTACT} component={Contact} />
    </div>
  </Router>
);

const MapStateToProps = (state) => {
    return {
       
    }
};

const MapDispatchToProps = (dispatch) => {
    return {
        
    }
};

export default connect(MapStateToProps, MapDispatchToProps)(App);
