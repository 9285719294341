import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TextField from '@material-ui/core/TextField';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button'; 
import { endSession } from '../../store/actions/sessionActions';
import { updateHostState, endGame, updateModState } from '../../store/actions/gameActions';
import { renameSession } from '../../store/actions/sessionActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import EditIcon from '@material-ui/icons/Edit';
import { GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_RESULTS, HOST_STATE_GROUP, GAME_STATE_INIT } from '../../store/actions/gameActions';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import TutorialVideoPlayer from './tutorialVideos';
import ListIcon from '@material-ui/icons/List';
import StopIcon from '@material-ui/icons/Stop';
import { sortGroupStrings } from './../Game/games/game_functions/functions';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styles from '../Styles';
import TimeToLeave from '@material-ui/icons/TimeToLeave';


export default function ModControls(props) {

    const { session, profile, classes, open } = props;

    const gameActive = session.active_game.name === undefined ? false : true;
    const modState = session.moderatorStates === undefined ? false : session.moderatorStates[props.auth.uid] === undefined ? false : session.moderatorStates[props.auth.uid].state;
    const modParams = session.moderatorStates === undefined ? {} : session.moderatorStates[props.auth.uid] === undefined ? {} : session.moderatorStates[props.auth.uid].params;
    const gameState = gameActive ? session.active_game.gameState.state : false;

    return (
     

            <List style={{paddingTop: 0, paddingBottom: 0}}>

         
                <ListItem className={classes.navHeader}>
                    <ListItemText primary='Options' />
                  </ListItem>

                 

               
                 
                  <Divider/>
                  <ListItem button onClick={props.openTutorial}>
                    <ListItemIcon>
                      <VideoLibraryIcon  />
                    </ListItemIcon>
                    <ListItemText primary='Tutorial Videos' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={props.goToAdmin}>
                    <ListItemIcon>
                      <ExitToAppIcon  />
                    </ListItemIcon>
                    <ListItemText primary='Go To Admin' />
                  </ListItem>
               
                  <ListItem className={classes.navHeader}>
                    <ListItemText primary='Navigation' />
                  </ListItem>
                
                <ListItem
                  button
                  onClick={() => {
                    props.editGroups();
                  }}
                  style={{background: (modState === HOST_STATE_GROUP_SELECT) ? "#d1c4e9": "#fff"}}
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary='Edit Groups' />
                </ListItem>
           
             

              
              {session !== undefined && gameState === GAME_STATE_RESULTS ? (
                <ListItem
                  button
                  onClick={() => {
                    props.goToResults();
                  }}
                  style={{background: (gameState === GAME_STATE_RESULTS && modState === HOST_STATE_RESULTS) ? "#d1c4e9": "#fff"}}
                >
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText primary='Activity Results' />
                </ListItem>
              ) : null}

                {session !== undefined && gameState === GAME_STATE_ACTIVE && Object.keys(session.groups).length > 0 ? (
                <div>
                  <ListItem className={classes.navHeader}>
                    <ListItemText primary='View Groups' />
                  </ListItem>
                  <List style={{paddingTop: 0, paddingBottom: 0}}>
                    {Object.keys(session.groups)
                      .sort(sortGroupStrings)
                      .map((groupKey, index) => {
                        if (session.groups[groupKey].id !== 'group-0') {
                          return (
                            <ListItem
                              button
                              key={index}
                              onClick={() => {
                                props.goToGroup(groupKey);
                              }}
                              style={{background: (modState === HOST_STATE_GROUP && modParams.group === groupKey) ? "#d1c4e9": "#fff"}}
                            >
                              <ListItemIcon>
                                <PeopleOutlineIcon />
                              </ListItemIcon>
                              <ListItemText primary={session.groups[groupKey].title} />
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </List>
                </div>
              ) : null}

            </List>
         
    );
}