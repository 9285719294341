export const LANDING = '/';
export const LOGIN = '/login';
export const HOME = '/';
export const SIGN_UP = '/signup';
export const SIGN_UP_REDIRECT = '/signup/:id';
export const PASSWORD_FORGET = '/pw-forget';
export const FORGOTTEN_PASSWORD = '/password-reset';
export const ANON_SIGN_IN = '/p/:id';
export const ANON_SIGN_IN_PRE = '/p/:id/:you';
export const ANON_SIGN_IN_NID = '/p';
export const CHANGE_NAME = '/name';
export const DEFAULT = '/about'
export const CONTACT = '/contact';

