import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "1.1rem"
      },
      helpText: {
          fontWeight: "bold",
          padding: "30px 20px 0 20px",
          fontSize: "1.2rem"

      }
}));

export default function ActivityDescDelta() {
    const classes = useStyles();  

  
    return (
        <div>
             <div className={classes.root}>
                Current challenges/struggles/barriers with virtual learning?
            </div>
            <div className={classes.helpText}>
                To begin adding an entry, tap on the pencil icon in the Obstacles column.
            </div>
        </div>
       
    )
}
