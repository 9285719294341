import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from '../../../../Styles';
import scenario from './../images/scenario.png';
import { HideUntilLoaded } from 'react-animation';
import { moveBackToActiveWithParams, updateGroupStateParams } from './../../../../../store/actions/gameActions';
import { determineHost } from './../../game_functions/functions';
import { hasGameControl } from '../../game_functions/functions'


const INITIAL_STATE = {
    hidden: true
}

class WalkStep extends Component {
    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    componentDidMount(){
        if ( this.props.step !== this.props.cur_step ){
            if ( this.props.step <= this.props.cur_step ){
                this.setState({
                    ...this.state,
                    hidden: false
                });
            }
        } else {
            let t = 50; 
            setTimeout(() => {                
                this.setState({
                    ...this.state,
                    hidden: false
                });
            },t);
        }
    }

  goToActivityPhase = () => {
    if ( hasGameControl(this.props.session.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile) ){
        let p = { ...this.props.session.active_game.groupStates[this.props.group_id].params };
        p.phase = 'activity';
        p.step = this.props.step;
        this.props.updateGroupStateParams( this.props.group_id, p, determineHost(this.props.profile, this.props.auth));
    } else {
        /* TODO: ADD SNACKBAR TO ALERT USER THAT ONLY A LEADER CAN CONTROL */
    }
  }
    
  render() {
      
    const { classes, step, cur_step } = this.props;
     
        if ( this.state.hidden && cur_step !== step ){
            return null;
        }
        if ( cur_step === step && this.state.hidden ){
            return <div onClick={() => this.goToActivityPhase()} className={`${classes.walkStep} ${classes.doNotShow} ${classes.marginStep}`}>
                <HideUntilLoaded imageToLoad={scenario}>
                    { cur_step === step ? <div className={classes.arrowPos}><div className="arrow bounce"></div></div> : null }
                    <div className={classes.padWalkStep}>
                        <img src={scenario} alt="step" className={`${classes.imageSize}`}/>
                        <Grid item xs={12} align="center">
                            Scenario {step}
                        </Grid>
                    </div>
                </HideUntilLoaded>
            </div>;
        }
        return (
            <div onClick={() => this.goToActivityPhase()} className={`${classes.walkStep} ${classes.marginStep}`}>
                <HideUntilLoaded imageToLoad={scenario}>
                    { cur_step === step ? <div className={classes.arrowPos}><div className="arrow bounce"></div></div> : null }
                    <div className={classes.padWalkStep}>
                        <img src={scenario} alt="step" className={`${classes.imageSize}`}/>
                        <Grid item xs={12} align="center">
                            Scenario {step}
                        </Grid>
                    </div>
                </HideUntilLoaded>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {        
        moveBackToActiveWithParams: (session, host, parameters) => dispatch(moveBackToActiveWithParams(session, host, parameters)),
        updateGroupStateParams: ( group, groupStateParams, hostID ) => dispatch(updateGroupStateParams(group, groupStateParams, hostID) )
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WalkStep);
