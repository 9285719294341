export const DEV = process.env.NODE_ENV === 'development'
export const BASE_PIECE_LENGTH = 36 // Don't touch!!
export const BASE_PIECE_HEIGHT = 36 // Samesies!!
export const NUB_RADIUS = 2
export const NUB_SIZE = [25, 25]
export const OVERLAPPING_OPACITY = 0.25
export const COLLISION_MARGIN = 1
export const INSET_BORDER = 4
export const STRICT_ERROR_MARGIN = 2
export const SOFT_ERROR_MARGIN = 5
export const SNAP_DISTANCE = 10
export const CLICK_TIMEOUT = 300
export const VICTORY_PARTICLES_DURATION = 1500
export const SCRAMBLE_PADDING = 100
export const COLOR_TRANSITION_DURATION = 250
export const FADE_TRANSITION_DURATION = 1000
export const FADE_STAGGER_DURATION = 500
export const DIALOG_CLOSED_REASON = 'DIALOG_CLOSED_REASON'

export const PARTICLES_COUNT = 60
export const MAX_PARTICLE_SIZE = 5
export const MIN_PARTICLE_SIZE = 2
export const MIN_PARTICLE_OPACITY = 0.25
export const MAX_PARTICLE_OPACITY = 0.75

export const DIGITS = [
    '0️⃣',
    '1️⃣',
    '2️⃣',
    '3️⃣',
    '4️⃣',
    '5️⃣',
    '6️⃣',
    '7️⃣',
    '8️⃣',
    '9️⃣'
]
export const LETTERS = [
    '🄰',
    '🄱',
    '🄲',
    '🄳',
    '🄴',
    '🄵',
    '🄶',
    '🄷',
    '🄸',
    '🄹',
    '🄺',
    '🄻',
    '🄼',
    '🄽',
    '🄾',
    '🄿',
    '🅀',
    '🅁',
    '🅂',
    '🅃',
    '🅄',
    '🅅',
    '🅆',
    '🅇',
    '🅈',
    '🅉'
]
