import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS, } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import { CSSTransition } from "react-transition-group";
import CreateIcon from '@material-ui/icons/Create';
import TurnsManager from './../specific_components/turns.js';


const INITIAL_STATE = {

};




class Activity extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
        ...INITIAL_STATE,
    };
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    
  }

  componentDidUpdate(prevProps, prevState) {
      
  }

  

  render() {
    const {
      session,
      profile,
      auth,
      classes,
      game_options
    } = this.props;
    const { entry_text } = this.state;
    const isInvalid = entry_text === '';
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);
    console.log("session in personify activity render >>>", session);
    let title = session.active_game.name;
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }
    return (
      <div>
         <Typography variant='h3' className={classes.spaceBottom}>
              {title}
          </Typography>
          <TurnsManager 
            debrief={false}
            session={session} 
            profile={profile} 
            auth={auth} 
            group_id={group_id} 
            control={hasControl} 
            results={session.active_game.results}
            vsKey={session.groups[group_id].vsKey}
          />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);