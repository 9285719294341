import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { images } from './../data/data';
import ImageGallery from 'react-image-gallery';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';




const AggregateLN = ( props ) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };


    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>This activity highlights the assumptions we sometimes make on teams when
trying to complete a task.
The story for this activity is that
an architect has
come up with plans for a new condo complex, however, before he could
complete the drawings, he quit the job. Each team will complete the
Architects drawings for the ground floor of a condo complex. The team will
only have the Architect’s notes to complete the construction.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>When sorting information to solve a problem, critical information can be overlooked, or information of no value can cloud the thought process</li>
                    <li className={classes.properListItem}>Making use of the team – which can result in team collaboration or team members sitting idle</li>
                    <li className={classes.properListItem}>How assumptions are made in team projects and how group think can set in when we don’t challenge our thinking</li>
                    <li className={classes.properListItem}>The impact of assumptions on successful progress to complete a task</li>
                    <li className={classes.properListItem}>Managing our time more effectively by effectively deciphering information in a strategic manner.</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <Typography variant="body1"  className={classes.LNProperStart}>You will be working in a small team to complete this task. You will see a set of
clues and a set of blocks in different colours on the screen. Using the clues,
you will have to move the blocks into the right configuration to match what
the architect had planned</Typography>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
                <Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>
                    Story:
                </Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>An architect has come up with plans for a new condo complex, however, before he could complete the drawings, he quit the job</li>
                            <li className={classes.properListItem}>Your team will complete the Architects drawings for the ground floor of a condo complex.</li>
                            <li className={classes.properListItem}>You will only have the Architect’s notes to complete the construction.</li>
                        </ul> 
                        <Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>Clues to Focus on:</Typography>
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>Two types of walls – adjacent and outside – what does this mean?</li>
                            <li className={classes.properListItem}>The Shopping Complex consists of 12 equally sized cubes. Each store in the Shopping Complex consists of 3 cubes– you have 28 cubes; what should you do?</li>
                            <li className={classes.properListItem}>“Each store in the Shopping Complex is identical in shape”. – What are the choices for laying out the 3 blocks per section? What shape must it be?</li>
                            <li className={classes.properListItem}>You can access the fitness center from anywhere on the ground floor of the condo complex – where must the fitness center be?</li>
                            <li className={classes.properListItem}>The bottom left area of the complex is a mirror reflection of bottom right side – if blocks can only be laid out in a few formations, what does this mean for the shape of the bottom left and bottom right areas?</li>
                        </ul>                 
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>What did we do well as a team?</li>
                            <li className={classes.properListItem}>What could we have done differently?</li>   
                            <li className={classes.properListItem}>What Assumptions did the team make? How did this impact your progress?</li>                            
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
           
           
        </div> 
    )
}


export default withStyles( styles )(AggregateLN);