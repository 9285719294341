import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import ListItem from '@material-ui/core/ListItem';
import { CardList} from '../cards/cardList';
import { Draggable } from 'react-beautiful-dnd';
import styles from '../../../../Styles';
import DiSC_Icon from '../images/DiSC_Icon.png'
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import RemoveIcon from '@material-ui/icons/Remove';
import {
    getUserGroup_hc,
    determineHost
  } from '../../game_functions/functions';
import { editResults } from './../../../../../store/actions/gameActions';

const INITIAL_STATE = {
    anchorEl: null,
    curCard: null
  };

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0',
    margin: '0 8px 0 0',
    ...draggableStyle,
});

class DiscCardDraggable extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    clickCard(e, card_id){
        this.setState({
            ...this.state,
            anchorEl: e.currentTarget,
            curCard: card_id
          });
    }

    handlePopoverClose = () => {
        this.setState({
          ...this.state,
          anchorEl: null,
          curCard: null
        })
    }

    removeFromResults(){
        const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

        let r = [...this.props.session.active_game.results[group_id].card_choices];
        let i = r.indexOf(this.state.curCard);

        r.splice(i, 1);
        this.props.editResults(group_id, 'card_choices', r, (determineHost(this.props.profile, this.props.auth)));
        this.handlePopoverClose();
    }
    
    render() {
        const { classes, card_id, control } = this.props;
        const { anchorEl } = this.state;
        const popopen = Boolean(anchorEl);
        const id = popopen ? 'simple-popover' : undefined;

        let card = CardList.find(obj => {
            return obj.id === card_id
        });
        if ( card === undefined ) {
            return null;
        }
        if ( !control ){
            return (
                <ListItem className={classes.doNotPad}> 
                    <Card className={`${classes.discCardDraggable} ${classes[card.card_category]}`} variant="outlined">
                        <div className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`}>
                            <Grid container className={classes.alignVerticalCenter}>
                                <Grid item xs={3} align="center">
                                    <img src={DiSC_Icon} className={classes.iconStyle} alt="Game"/>
                                    { card.card_fun ? <Typography variant="body2" align="center">
                                        FUN
                                    </Typography>
                                    : null }
                                </Grid>
                                <Grid item xs={9} className={classes.cardPadding}>
                                    <Typography variant="body1">
                                        {card.card_words}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2" gutterBottom>
                                        {card.card_subwords}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </ListItem>
            );
        }

        return (
            <div>
                <Draggable draggableId={`${card_id}`} index={this.props.index}>
                {(provided, snapshot) => (
                <ListItem onClick={(e) => this.clickCard(e, card_id)} className={classes.doNotPad} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}> 
                    <Card className={`${classes.discCardDraggable} ${classes[card.card_category]}`} variant="outlined">
                        <div className={`${classes.alignVerticalCenter} ${classes.cardActionPadding}`}>
                            <Grid container className={classes.alignVerticalCenter}>
                                <Grid item xs={3} align="center">
                                    <img src={DiSC_Icon} className={classes.iconStyle} alt="Game"/>
                                    { card.card_fun ? <Typography variant="body2" align="center">
                                        FUN
                                    </Typography>
                                    : null }
                                </Grid>
                                <Grid item xs={9} className={classes.cardPadding}>
                                    <Typography variant="body1">
                                        {card.card_words}
                                    </Typography>
                                    <Typography color="textSecondary" variant="body2" gutterBottom>
                                        {card.card_subwords}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Card> 
                </ListItem>            
                )}            
                </Draggable>
                <Popover 
                        id={id}
                        open={popopen}
                        anchorEl={anchorEl}
                        onClose={this.handlePopoverClose}
                        PaperProps={{
                        style: {
                        padding: "20px"
                        }
                        }}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        >
                        <div>                     
                        <Button 
                        variant="contained"
                        color="primary"
                        startIcon={<RemoveIcon/>}
                        onClick={() => this.removeFromResults()}
                        >
                        Remove
                        </Button>
                        </div> 
                </Popover>               
            </div>
        );
    }
}

DiscCardDraggable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {};
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    };
  };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(DiscCardDraggable);



