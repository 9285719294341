import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'

export const parseMultiPart = (val) => {
    const FIELDSPLIT = ':::'
    const QSPLIT = ';;'
    //const num = val.split(FIELDSPLIT)[0];
    const t = val.split(FIELDSPLIT)[1]
    const qs = t.split(QSPLIT)

    return qs
}

export default function GameMisc(props) {
    const [plainText, setPlainText] = React.useState(props.o[props.oKey])
    const isCustomField =
        props.oKey.indexOf('__') >= 0 ? props.oKey.indexOf('__') : false
    const fieldType =
        isCustomField !== false ? props.oKey.substring(0, isCustomField) : false
    const regex = /_/g
    const fieldName =
        isCustomField !== false
            ? props.oKey.substring(isCustomField + 2).replace(regex, ' ')
            : props.oKey.replace(regex, ' ')

    const FIELDSPLIT = ':::'
    const QSPLIT = ';;'
    const num =
        fieldType === 'multipart' ? plainText.split(FIELDSPLIT)[0] : false
    const t = fieldType === 'multipart' ? plainText.split(FIELDSPLIT)[1] : false
    const qs = fieldType === 'multipart' ? t.split(QSPLIT) : false

    const [multiText, setMultiText] = React.useState(
        fieldType === 'multipart' ? num : props.o[props.oKey]
    )
    const [multiTextArr, setMultiTextArr] = React.useState(
        fieldType === 'multipart' ? qs : []
    )

    console.log('G', plainText)

    const onChangeMisc = (event) => {
        setPlainText(event.target.value)
    }
    const onChangeMiscMultiPartNum = (event) => {
        if (isNaN(event.target.value)) {
            return
        } else {
            if (event.target.value > 100) {
                return
            } else {
                setMultiText(event.target.value)
            }
        }
    }
    const onChangeMiscMultiPart = (event, ind) => {
        let m = multiTextArr
        //console.log(m);
        m[ind] = event.target.value
        //console.log(m);
        setMultiTextArr([...m])
        //console.log(multiTextArr);
    }

    const onChangeMiscSelect = (event) => {
        //console.log(event.target.value);
        if (props.setDoc === 'default' || props.setDoc === 'Default') {
            return
        }
        setPlainText(event.target.value)
        console.log('here?', event.target.value)
        props.saveOption(
            props.setDoc,
            props.hostID,
            props.game_id,
            'game_misc',
            props.oKey,
            event.target.value
        )
    }

    const saveMisc = (event, type) => {
        if (props.setDoc === 'default' || props.setDoc === 'Default') {
            return
        }

        let val = plainText
        if (type === 'multipart') {
            // setup the data to save properly for the multipart
            val = multiText + FIELDSPLIT + multiTextArr.join(QSPLIT)
        }

        //const c = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        //const c = plainText;
        //console.log(props.setDoc, props.hostID, props.game_id, 'game_texts', props.oKey, c);
        props.saveOption(
            props.setDoc,
            props.hostID,
            props.game_id,
            'game_misc',
            props.oKey,
            val
        )
    }
    const saveMiscNum = (event, type) => {
        if (multiText === '') {
            setMultiText(multiTextArr.length)
        }
        if (props.setDoc === 'default' || props.setDoc === 'Default') {
            return
        }

        // now save
        let val = multiTextArr.length + FIELDSPLIT + multiTextArr.join(QSPLIT)
        props.saveOption(
            props.setDoc,
            props.hostID,
            props.game_id,
            'game_misc',
            props.oKey,
            val
        )
    }

    React.useEffect(() => {
        if (multiText === '') {
            return
        }
        let m = []
        for (let i = 0; i < multiText; i++) {
            m.push(multiTextArr[i])
        }
        setMultiTextArr([...m])
    }, [multiText])

    //console.log('Test fields: ', props.oKey, isCustomField, fieldType, fieldName);

    if (fieldType === false) {
        return (
            <div key={props.ind} style={{ margin: '15px 0' }}>
                <TextField
                    label={fieldName}
                    name={props.oKey}
                    id={props.oKey}
                    value={plainText}
                    onChange={onChangeMisc}
                    onBlur={(event) => saveMisc(event, fieldType)}
                    fullWidth
                    multiline
                />
                {props.g[props.oKey] !== undefined ? (
                    <div>
                        <div>{props.g[props.oKey].description}</div>
                        <div>
                            <br />
                            Reference:
                            <Grid container>
                                {props.g[props.oKey].options.map(
                                    (oItem, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={3}
                                                id={oItem.id}
                                                style={{ cursor: 'pointer' }}
                                                align="center"
                                                key={index}
                                            >
                                                <div style={{ margin: '20px' }}>
                                                    <Typography
                                                        variant="body1"
                                                        style={{
                                                            fontWeight: 'bold'
                                                        }}
                                                    >
                                                        {oItem.id}
                                                    </Typography>

                                                    <img
                                                        src={oItem.img}
                                                        alt=""
                                                        className={
                                                            props.classes
                                                                .imageSize
                                                        }
                                                    />
                                                </div>
                                            </Grid>
                                        )
                                    }
                                )}
                            </Grid>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }

    if (fieldType === 'select') {
        console.log('here', props.g[props.oKey], props.g, props.oKey)
        return (
            <div key={props.ind} style={{ margin: '15px 0' }}>
                {props.g[props.oKey] !== undefined ? (
                    <div>
                        <div>
                            <InputLabel shrink id={props.oKey}>
                                {fieldName}
                            </InputLabel>
                            <Select
                                label={fieldName}
                                name={props.oKey}
                                id={props.oKey}
                                value={plainText}
                                onChange={onChangeMiscSelect}
                            >
                                {props.g[props.oKey].options.map(
                                    (oItem, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                value={oItem.id}
                                            >
                                                {oItem.title}
                                            </MenuItem>
                                        )
                                    }
                                )}
                            </Select>
                            <div>{props.g[props.oKey].description}</div>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }

    if (fieldType === 'multipart') {
        if (plainText.indexOf(FIELDSPLIT) < 0) {
            return null
        }

        console.log('what')
        return (
            <div key={props.ind} style={{ margin: '15px 0' }}>
                <TextField
                    label={fieldName}
                    name={props.oKey}
                    id={props.oKey}
                    value={multiText}
                    onChange={onChangeMiscMultiPartNum}
                    onBlur={(event) => saveMiscNum(event, fieldType)}
                    fullWidth
                    multiline
                />
                <div key={props.ind} style={{ margin: '15px 15px' }}>
                    {multiTextArr.map((q, ind) => {
                        //console.log('wtf', q, multiTextArr);
                        return (
                            <TextField
                                style={{ margin: '10px 0' }}
                                key={ind}
                                label={`#${ind + 1}`}
                                name={`${ind}`}
                                id={`${ind}`}
                                value={multiTextArr[ind]}
                                onChange={(event) =>
                                    onChangeMiscMultiPart(event, ind)
                                }
                                onBlur={(event) => saveMisc(event, fieldType)}
                                fullWidth
                                multiline
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    return null
}

/*
    Option Types:

    - multipart: number of things | things
    - select: special select

    game_misc -> multipart__field_name
    game_misc -> select__field_name
*/
