import { ThemeContext } from 'css-system'
import paper from 'paper/dist/paper-core'
import React, {
    useContext,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react'
import { View } from './view'
import {
    CLICK_TIMEOUT,
    FADE_TRANSITION_DURATION,
    SNAP_DISTANCE,
    PARTICLES_COUNT,
    MIN_PARTICLE_OPACITY,
    MIN_PARTICLE_SIZE,
    MAX_PARTICLE_SIZE,
    MAX_PARTICLE_OPACITY,
    COLORS
} from '../utils/constants'
import { useShowBackgroundPattern } from '../contexts/showBackgroundPattern'
import { SoundContext } from '../contexts/sound'
import { createPiecesGroup } from '../utils/createPiecesGroup'
import { getSnapVector } from '../utils/getSnapVector'
import { restrictGroupWithinCanvas } from '../utils/restrictGroupWithinCanvas'
import { scrambleGroup } from '../utils/scrambleGroup'
import { updateColisionState } from '../utils/updateColisionState'
import { Card } from './card'

export const Tangram = (props) => {
    const { playTangram, playVictory } = useContext(SoundContext)
    const playRef = useRef()
    playRef.current = {
        tangram: playTangram,
        victory: playVictory
    }

    const theme = useContext(ThemeContext)
    const [showBackgroundPattern] = useShowBackgroundPattern()
    const [victoryPhase] = useState(false)
    const canvasRef = useRef()
    const scaleFactorRef = useRef()
    const piecesGroupRef = useRef()
    const particlesRef = useRef()
    const coumpoundPathRef = useRef()
    const showBackgroundPatternRef = useRef()

    // Handle window resize
    useEffect(() => {
        const handleResize = () => {
            if (canvasRef.current && piecesGroupRef.current) {
                for (const pieceGroup of piecesGroupRef.current.children) {
                    // It seems that some android devices resize browser when switching activities
                    restrictGroupWithinCanvas(pieceGroup, canvasRef.current)
                }
            }
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    /* When results update, move tangram pieces */
    useEffect(() => {
        if (props.tangramCoords !== undefined) {
            for (const pieceGroup of piecesGroupRef.current.children) {
                let id = pieceGroup.data.id
                if (props.tangramCoords[id] !== undefined) {
                    if (
                        Math.round(props.tangramCoords[id].x) !==
                            Math.round(pieceGroup.position.x) ||
                        Math.round(props.tangramCoords[id].y) !==
                            Math.round(pieceGroup.position.y) ||
                        Math.round(props.tangramCoords[id].rotation) !==
                            Math.round(pieceGroup.rotationReadable)
                    ) {
                        pieceGroup.setPosition(
                            props.tangramCoords[id].x,
                            props.tangramCoords[id].y
                        )
                        pieceGroup.position.x = props.tangramCoords[id].x
                        pieceGroup.position.y = props.tangramCoords[id].y
                        if (
                            pieceGroup.rotationReadable !==
                            props.tangramCoords[id].rotation
                        ) {
                            pieceGroup.rotation =
                                pieceGroup.rotation +
                                (props.tangramCoords[id].rotation -
                                    pieceGroup.rotationReadable)
                            pieceGroup.rotationReadable =
                                props.tangramCoords[id].rotation
                        }
                        restrictGroupWithinCanvas(pieceGroup, canvasRef.current)
                        updateColisionState(pieceGroup, piecesGroupRef.current)
                    }
                }
            }
        }
    }, [props.tangramCoords])

    // Init a game
    useLayoutEffect(() => {
        const attachPieceGroupEvents = (pieceGroup) => {
            let anchorPoint = null
            let ghostGroup = null
            let mouseDownTimestamp = null
            let mouseDownPoint = null

            const handleMouseEnter = (mdEvent) => {
                if (document.activeElement === canvasRef.current) {
                    document.body.style.cursor = 'pointer'
                }
            }

            const handleMouseLeave = (mdEvent) => {
                document.body.style.cursor = 'default'
            }

            const handleMouseDown = (mdEvent) => {
                if (props.role !== 'Contractor') {
                    return
                }
                mouseDownTimestamp = Date.now()
                mouseDownPoint = mdEvent.point
                anchorPoint = mdEvent.point.subtract(pieceGroup.position)
                ghostGroup = pieceGroup.clone({ insert: false, deep: true })
                pieceGroup.bringToFront()
            }

            const handleMouseDrag = (mdEvent) => {
                if (props.role !== 'Contractor') {
                    return
                }
                document.body.style.cursor = 'move'

                const newAnchorPoint = mdEvent.point.subtract(
                    pieceGroup.position
                )
                const vector = newAnchorPoint.subtract(anchorPoint)

                ghostGroup.position = pieceGroup.position.add(vector)

                const ghostShape = ghostGroup.children['display 1']
                const otherShapes = piecesGroupRef.current.children
                    .filter((otherGroup) => otherGroup !== pieceGroup)
                    .map(({ children }) => children['display'])

                const coumpoundShapes =
                    showBackgroundPatternRef.current && coumpoundPathRef.current
                        ? coumpoundPathRef.current.children
                            ? coumpoundPathRef.current.children
                            : [coumpoundPathRef.current]
                        : null

                const snapVector = getSnapVector(
                    SNAP_DISTANCE,
                    ghostShape,
                    otherShapes,
                    coumpoundShapes
                )

                if (snapVector) {
                    ghostGroup.position.x += snapVector.x
                    ghostGroup.position.y += snapVector.y
                }

                restrictGroupWithinCanvas(ghostGroup, canvasRef.current)
                pieceGroup.position = ghostGroup.position
                updateColisionState(pieceGroup, piecesGroupRef.current)
            }

            const handleMouseUp = (muEvent) => {
                if (props.role !== 'Contractor') {
                    return
                }
                document.body.style.cursor = 'pointer'
                if (
                    muEvent.point.subtract(mouseDownPoint).length <
                        SNAP_DISTANCE &&
                    Date.now() - mouseDownTimestamp < CLICK_TIMEOUT
                ) {
                    pieceGroup.rotation += 45
                    pieceGroup.rotationReadable =
                        parseInt(pieceGroup.rotationReadable) + 45
                    if (pieceGroup.data.id === 'rh') {
                        pieceGroup.data.rotation += 45
                        if (pieceGroup.data.rotation === 180) {
                            pieceGroup.data.rotation = 0
                            pieceGroup.rotationReadable = 0
                            pieceGroup.scale(-1, 1) // Horizontal flip
                        }
                    }

                    restrictGroupWithinCanvas(pieceGroup, canvasRef.current)
                    updateColisionState(pieceGroup, piecesGroupRef.current)
                    mouseDownTimestamp = null
                    mouseDownPoint = null
                }

                props.updateCoords(
                    pieceGroup.data.id,
                    pieceGroup.position.x,
                    pieceGroup.position.y,
                    pieceGroup.rotationReadable
                )

                anchorPoint = null
                ghostGroup && ghostGroup.remove()
                ghostGroup = null

                if (!coumpoundPathRef.current) {
                    return
                }

                if (showBackgroundPatternRef.current === false) {
                    coumpoundPathRef.current.position =
                        piecesGroupRef.current.position
                }
            }

            pieceGroup.on({
                mouseenter: handleMouseEnter,
                mouseleave: handleMouseLeave,
                mousedown: handleMouseDown,
                mousedrag: handleMouseDrag,
                mouseup: handleMouseUp
            })

            pieceGroup.data.removeListeners = () =>
                pieceGroup.off({
                    mouseenter: handleMouseEnter,
                    mouseleave: handleMouseLeave,
                    mousedown: handleMouseDown,
                    mousedrag: handleMouseDrag,
                    mouseup: handleMouseUp
                })
        }

        const init = () => {
            if (paper.projects.length > 0) {
                return
            }

            paper.setup(canvasRef.current)

            piecesGroupRef.current = createPiecesGroup()

            const outerBounds = paper.project.view.bounds
            const innerBounds = coumpoundPathRef.current
                ? coumpoundPathRef.current.bounds
                : piecesGroupRef.current.children[3].bounds.scale(2)

            if (outerBounds.width > outerBounds.height) {
                scaleFactorRef.current = Math.min(
                    2,
                    Math.min(outerBounds.width * 0.6, 700) / innerBounds.width,
                    Math.min(outerBounds.height * 0.8, 600) / innerBounds.height
                )
            } else {
                scaleFactorRef.current = Math.min(
                    2,
                    Math.min(outerBounds.width * 0.8, 600) / innerBounds.width,
                    Math.min(outerBounds.height * 0.6, 700) / innerBounds.height
                )
            }

            if (coumpoundPathRef.current) {
                coumpoundPathRef.current.scale(scaleFactorRef.current)
                coumpoundPathRef.current.position = paper.view.center
            }

            for (const pieceGroup of piecesGroupRef.current.children) {
                pieceGroup.scale(scaleFactorRef.current)
                if (
                    props.tangramCoords === undefined &&
                    props.role === 'Contractor'
                ) {
                    scrambleGroup(
                        pieceGroup,
                        canvasRef.current,
                        props.updateCoords
                    )
                }

                attachPieceGroupEvents(pieceGroup)
                restrictGroupWithinCanvas(pieceGroup, canvasRef.current)
                updateColisionState(pieceGroup, piecesGroupRef.current)
                pieceGroup.children['display'].fillColor =
                    COLORS[pieceGroup.data.id]
                pieceGroup.children['insetBorder'].strokeColor = '#000'
            }
        }

        init()

        return () => {
            /* Change to ONLY destroy if the results are done */
            if (paper.projects.length <= 0) {
                particlesRef.current = null
                piecesGroupRef.current = null
                coumpoundPathRef.current = null
            }
        }
    })

    useLayoutEffect(() => {
        showBackgroundPatternRef.current = showBackgroundPattern
        if (!coumpoundPathRef.current) {
            return
        }
        if (showBackgroundPattern) {
            coumpoundPathRef.current.fillColor = theme.colors.shape
            coumpoundPathRef.current.position = paper.view.center
        } else {
            coumpoundPathRef.current.fillColor = 'transparent'
        }
    }, [0, showBackgroundPattern, theme])

    useLayoutEffect(() => {
        const particleGroup = new paper.Group()

        particleGroup.sendToBack()

        const maxPoint = new paper.Point(
            canvasRef.current.width,
            canvasRef.current.height
        ).divide(window.devicePixelRatio)

        const getRandomRadius = () =>
            Math.random() * (MAX_PARTICLE_SIZE - MIN_PARTICLE_SIZE) +
            MIN_PARTICLE_SIZE

        const getRandomOpacity = () =>
            Math.random() * (MAX_PARTICLE_OPACITY - MIN_PARTICLE_OPACITY) +
            MIN_PARTICLE_OPACITY

        particlesRef.current = new Array(PARTICLES_COUNT)

        for (let i = 0; i < PARTICLES_COUNT; i++) {
            const particle = new paper.Path.Circle({
                center: paper.Point.random().multiply(maxPoint),
                radius: getRandomRadius(),
                opacity: getRandomOpacity(),
                parent: particleGroup,
                data: { index: i }
            })

            const randomize = () => {
                const values = {
                    radius: getRandomRadius(),
                    opacity: getRandomOpacity(),
                    'position.x': Math.min(
                        canvasRef.current.width / window.devicePixelRatio,
                        Math.max(
                            0,
                            particle.position.x + Math.random() * 200 - 100
                        )
                    ),
                    'position.y': Math.min(
                        canvasRef.current.height / window.devicePixelRatio,
                        Math.max(
                            0,
                            particle.position.y + Math.random() * 200 - 100
                        )
                    )
                }

                particle.data.animation = particle
                    .tween(values, {
                        duration: Math.random() * 10000 + 5000,
                        easing: 'easeInOutQuad'
                    })
                    .then(randomize)
            }
            randomize()

            particlesRef.current[i] = particle
        }
    }, [0])

    useLayoutEffect(() => {
        if (piecesGroupRef.current !== undefined) {
            for (const pieceGroup of piecesGroupRef.current.children) {
                pieceGroup.children['display'].fillColor =
                    COLORS[pieceGroup.data.id]
                pieceGroup.children['insetBorder'].strokeColor =
                    COLORS[pieceGroup.data.id]
            }
            for (const particle of particlesRef.current) {
                particle.fillColor = '#000'
            }
        }
    }, [theme.colors])

    return (
        <View
            css={{
                flex: '1',
                position: 'relative',
                color: 'dialogText',
                animation: `${FADE_TRANSITION_DURATION}ms fadeIn ease`
            }}
        >
            {showBackgroundPattern === false && (
                <View
                    css={{
                        zIndex: -1,
                        position: 'absolute',
                        top: 3,
                        right: 3,
                        cursor: 'pointer'
                    }}
                >
                    <Card tangram={0} selected hideBadge></Card>
                </View>
            )}
            <View
                as="canvas"
                ref={canvasRef}
                css={{
                    minHeight: 'auto',
                    flex: 1
                }}
            />
            {victoryPhase && null}
        </View>
    )
}
