import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS, } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { editResultsAndGroupStatePath, editResultsPath, updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, removeResultsPath, updateGroupStateStateVal } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import DialogBox from './dialogBox';
import TextLogger from './../specific_components/textLogger';

import { CSSTransition } from "react-transition-group";
import CreateIcon from '@material-ui/icons/Create';
import LiquidLoadingBlock from '../../../../Loading/loadingCup';

import { steps } from './../data/data';
import ChooseYourOwnHistory from './chooseYourOwnHistory';




export const withAnimation = WrappedComponent => {
  class _AnimatedComponent extends React.Component {
    state = {
      mount: this.props.mount,
      inProgress: this.props.mount && this.props.animateOnLoad,
      blockAnimation: !this.props.animateOnLoad
    };

    static getDerivedStateFromProps(props, state) {
      if (!state.blockAnimation && props.mount !== state.mount) {
        return {
          inProgress: true,
          mount: props.mount
        };
      }

      return null;
    }

    constructor(props) {
      super(props);
      this.wrapperRef = React.createRef();
    }

    componentDidMount() {
      this.setState({
        blockAnimation: false
      });
    }

    shouldComponentUpdate(nextProps, nextState) {
      return this.state.blockAnimation === nextState.blockAnimation;
    }

    onAnimationEnd = event => {
      const { target } = event;
      const { current } = this.wrapperRef;

      if (target === current) {
        this.setState({
          inProgress: false
        });
      }
    };

    render() {
      const { mount, inProgress, blockAnimation } = this.state;
      const { onMount, onUnmount, defaultClass } = this.props;
      const animationClass = mount ? onMount : onUnmount;

      return  (inProgress || mount) ? (
        <div
          ref={this.wrapperRef}
          className={`${defaultClass} ${!blockAnimation ? animationClass : ''}`}
          onAnimationEnd={this.onAnimationEnd}
        >
          <WrappedComponent {...this.props} />
        </div>
      ) : null;
    }
  };

  _AnimatedComponent.defaultProps = {
    animateOnLoad: true,
    defaultClass: ''
  };
  return _AnimatedComponent;
};


const AnimatedDialogBox = withAnimation(DialogBox);
/*

  State: 
    messageState: message | choice | subMessage

  

*/

const LEFT_COL = "lc";
const RIGHT_COL = "rc";

const INITIAL_STATE = {
    open: false,
    isEdit: false,
    curColumn: null,
    entry_text: ""
};

const getTimeEpoch = () => {
    return `p${new Date().getTime().toString()}`;                             
  }

class DebriefChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        };
  
    }

    addMessage = (col) => {
        let group_id = this.props.group_id;
        
        if ( !this.props.hasControl ){
          return;
        }
        this.setState({
            ...this.state,
            entry_text: '',
            isEdit: false,
            curColumn: col
        });
    
        let s = {
          ...this.props.session.active_game.groupStates[group_id].params,
          writing: this.props.auth.uid,
        };
        this.props.updateGroupStateParams(group_id, s, determineHost(this.props.profile, this.props.auth));
    }
    handleClose = (event) => {
        if ( !this.props.hasControl ){
          return;
        }
        let groupID = this.props.group_id;
        let s = {
          ...this.props.session.active_game.groupStates[groupID].params,
          writing: false,
          ttd: ""
        };
        this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
    };
    handleSave = (event) => {
        let groupID = this.props.group_id;
        let theID = this.props.session.active_game.results[groupID][this.state.curColumn] !== undefined ?
            Object.keys(this.props.session.active_game.results[groupID][this.state.curColumn]).length
            : 0;
        if ( theID > 9 ){
            theID = '0' + theID;
        } else {
            theID = '00' + theID;
        }

        let p = `active_game.results.${groupID}.${this.state.curColumn}.id_${theID}`;
        this.props.editResultsPath(p, this.state.entry_text.replaceAll('\n', '<br/>'), determineHost(this.props.profile, this.props.auth));
    
        let s = {
          ...this.props.session.active_game.groupStates[groupID].params,
          writing: false,
          ttd: ""
        };
        this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
    
        this.setState({
          ...this.state,
          open: false,
        });
    };
    itemAction = (index, value, col) => {
        this.setState({
          ...this.state,
          isEdit: index,
          entry_text: value.replaceAll('<br/>', '\n'),
          curColumn: col
        });
    
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: this.props.auth.uid,
          ttd: value.replaceAll('<br/>', '\n')
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
    };
    updateRow = (row) => {
        let groupID = this.props.group_id;
        let p = `active_game.results.${groupID}.${this.state.curColumn}.${row}`;
        this.props.editResultsPath(p, this.state.entry_text.replaceAll('\n', '<br/>'), determineHost(this.props.profile, this.props.auth));
    
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: false,
          ttd: false
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
    
        this.setState({
          ...this.state,
          isEdit: false,
        });
    };
    deleteRow = (row) => {
        let groupID = this.props.group_id;
        let p = `active_game.results.${groupID}.${this.state.curColumn}.${row}`;
        this.props.removeResultsPath(p, null, determineHost(this.props.profile, this.props.auth));
    
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: false,
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
    
        this.setState({
          ...this.state,
          isEdit: false,
        });
    };
    onChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });    
    };

    componentDidUpdate(prevProps, prevState) {
        /* When a leader opens the writing window, we want to show it for all users */
        if ( ( this.props.session.active_game.groupStates[this.props.group_id].params.writing !== undefined && this.props.session.active_game.groupStates[this.props.group_id].params.writing !== false ) && (this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === undefined || this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === false) && this.state.open === false ) {
            this.setState({
                ...this.state,
                open: true
            })
        }
        /* When a leader closes the writing window, we want to close it for all users */
        if ( this.props.session.active_game.groupStates[this.props.group_id].params.writing === false && this.state.open === true ) {
            this.setState({
                ...this.state,
                open: false
            })
        }
    }

    render() {
        const {
        session,
        profile,
        auth,
        classes,
        group_id,
        hasControl,
        results,
        step
        } = this.props;

        const { entry_text } = this.state;

        

        /* results has:
            - step
            - choiceHistory
        */

    

        return (
            <div >
                <Grid container spacing={3} className={classes.spacingTop}>
                    <Grid item xs={8}>
                        { this.props.debrief === false ? <Typography variant="h5" style={{fontWeight:"bold"}}>Discuss In Your Teams</Typography> : <Typography variant="h5" style={{fontWeight:"bold"}}>Your Discussion</Typography>}
                    </Grid>
                    <Grid item xs={4} align="right">
                        <ChooseYourOwnHistory history={this.props.history} onlyCurrentRun={true} classes={this.props.classes} />
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.spacingTop}>
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}  align="center" className={classes.tableHead}>
                            { this.props.debrief === false ? <div className={`${classes.createButton}`} style={{left: 'auto', right: '25px'}}><CreateIcon onClick={() => this.addMessage(LEFT_COL)}/></div> : null }
                            Situations You Have Overcome 
                            
                        </Grid>
                        <Grid item xs={12}  align="center" className={classes.tableBody}>
                            <List>
                                {session.active_game.results.hasOwnProperty(group_id)
                                ? session.active_game.results[group_id].hasOwnProperty(LEFT_COL)
                                    ? Object.keys(session.active_game.results[group_id][LEFT_COL]).sort().map((item, index) => {
                                        if ( this.props.debrief === false ){
                                        return (
                                            this.props.hasControl ?
                                            <ListItem key={index} button onClick={() => this.itemAction(item, session.active_game.results[group_id][LEFT_COL][item], LEFT_COL)}>                     
                                            <div dangerouslySetInnerHTML={ {__html: session.active_game.results[group_id][LEFT_COL][item] } } className={classes.bodyListText}></div>
                                            </ListItem>
                                            :
                                            <ListItem key={index}>                 
                                                <div dangerouslySetInnerHTML={ {__html: session.active_game.results[group_id][LEFT_COL][item] } } className={classes.bodyListText}></div>                                      
                                            </ListItem>
                                        );
                                        } else {
                                        return (
                                            
                                            <ListItem key={index}>                 
                                            <div dangerouslySetInnerHTML={ {__html: session.active_game.results[group_id][LEFT_COL][item] } } className={classes.bodyListText}></div>                                         
                                            </ListItem>
                                        );
                                        }
                                    })
                                    : null
                                : null}
                            </List>
                            
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid item xs={12}  align="center" className={classes.tableHead}>
                            { this.props.debrief === false ? <div className={`${classes.createButton}`} style={{left: 'auto', right: '25px'}}><CreateIcon onClick={() => this.addMessage(RIGHT_COL)}/></div> : null }
                            Situations Still Challenging    
                        </Grid>
                        <Grid item xs={12}  align="center" className={classes.tableBody}>
                            <List>
                                {session.active_game.results.hasOwnProperty(group_id)
                                ? session.active_game.results[group_id].hasOwnProperty(RIGHT_COL)
                                    ? Object.keys(session.active_game.results[group_id][RIGHT_COL]).sort().map((item, index) => {
                                        if ( this.props.debrief === false ){
                                        return (
                                            this.props.hasControl ?
                                            <ListItem key={index} button onClick={() => this.itemAction(item, session.active_game.results[group_id][RIGHT_COL][item], RIGHT_COL)}>                     
                                            <div dangerouslySetInnerHTML={ {__html: session.active_game.results[group_id][RIGHT_COL][item] } } className={classes.bodyListText}></div>
                                            </ListItem>
                                            :
                                            <ListItem key={index}>                 
                                                <div dangerouslySetInnerHTML={ {__html: session.active_game.results[group_id][RIGHT_COL][item] } } className={classes.bodyListText}></div>                                      
                                            </ListItem>
                                        );
                                        } else {
                                        return (
                                            
                                            <ListItem key={index}>                 
                                            <div dangerouslySetInnerHTML={ {__html: session.active_game.results[group_id][RIGHT_COL][item] } } className={classes.bodyListText}></div>                                         
                                            </ListItem>
                                        );
                                        }
                                    })
                                    : null
                                : null}
                            </List>
                            
                        </Grid>
                    </Grid>
                </Grid>



                <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth PaperProps={{style: {backgroundColor: '#fdfd86'}}}>
                { hasControl === true && this.state.open ?
                        this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid ?
                        <TextLogger ttd={this.props.session.active_game.groupStates[this.props.group_id].params.ttd} group_id={group_id} val={entry_text} host={determineHost(this.props.profile, this.props.auth)}/>
                        : null
                    : null
                }
                    <DialogContent>
                        <DialogContentText className={classes.bodyText}>{ hasControl === true ? `` : 'Group leader is creating/editing/viewing an entry...'}</DialogContentText>
                        { hasControl ?
                            this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid ?
                            <TextField multiline autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label="Your Message" type='email' fullWidth /> 
                            :
                            <div className={classes.spacingBottom}>{this.props.session.active_game.groupStates[this.props.group_id].params.ttd}</div>
                            : 
                            <div className={classes.spacingBottom}>{this.props.session.active_game.groupStates[this.props.group_id].params.ttd}</div>
                        }
                    </DialogContent>
                    <DialogActions>
                    { hasControl === true && this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid ? 
                        this.state.isEdit !== false ? (
                        <div>
                            <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                            Delete
                            </Button>
                            <Button onClick={this.handleClose} color='primary'>
                            Cancel
                            </Button>
                            <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                            Save
                            </Button>
                        </div>
                        ) : (
                        <div>
                            <Button onClick={this.handleClose} color='primary'>
                            Cancel
                            </Button>
                            <Button disabled={hasControl ? false : true} onClick={this.handleSave} color='primary'>
                            Save
                            </Button>
                        </div>
                        )
                        : null }
                    </DialogActions>
                </Dialog>
                
            </div>      
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    editResultsPath: (group, top_level, value, hostID) => dispatch(editResultsPath(group, top_level, value, hostID)),
    editResultsAndGroupStatePath: (resultsPath, resultsValue, statePath, stateValue, historyPath, historyValue, hostID) => dispatch(editResultsAndGroupStatePath(resultsPath, resultsValue, statePath, stateValue, historyPath, historyValue, hostID)),
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    removeResultsPath: (group, value, hostID) => dispatch(removeResultsPath(group, value, hostID))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(DebriefChart);