import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';


const CircleWordsLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>This activity could be used for team bonding, team building or team development and there a lot of ways it can be debriefed.
                 Participants have a series of words that need to be placed in a specific order depending on how each word connects to another word.
                 Essentially, the words form a circle since they all connect. 
                 Topics that can be used to debrief include mind-set, community, communication, decision-making, flexibility, energy levels, social-emotional learning, success and failure, progression, alternative solutions/approaches, time constraint.
                  There are multiple options of complexity so that this activity can be used multiple times. Circle Words – Source: Adapted from Circle Words, Chris Cavert & Friends FUNDoing</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Effective communication</li>
                    <li className={classes.properListItem}>Working together as a team</li>
                    <li className={classes.properListItem}>Problem solving</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <Typography variant="body1"  className={classes.LNProperStart}>You will be working in small groups to see how quickly you can solve these word puzzles.
                 You’ll have to use the strengths of the team and work together. </Typography>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>Circle words are linking word combinations</li>
                            <li className={classes.properListItem}>Connect the words by matching each word with another to form phrases, words, or names </li>
                            <li className={classes.properListItem}>•	Reading the circle clockwise – left to right <br/>
o	Eg.    Door       Way       Side      Salad      Plate
</li>
                            <li className={classes.properListItem}>Try to solve as many of the puzzles as you within the time given</li>
                            
                        </ul>
                         
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>What did you do well as a team?</li>
                            <li className={classes.properListItem}>I chose…because… </li>   
                            <li className={classes.properListItem}>How/where were you challenged as a team?</li>   
                            <li className={classes.properListItem}>Were you more effective as a team completing the circle words?</li>                              
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
          
           
        </div> 
    )
}

export default withStyles(styles)(CircleWordsLN);