import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import image1 from '../images/Picture1.png';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const DiscCardsLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download PDF" {...a11yProps(5)} />
                <Tab className={classes.tabsLNTab} label="Slide Deck" {...a11yProps(6)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>In this 3-part activity, participants will have previously completed the DiSC assessment and will have been sent their individual reports.
                 Part 1: Teams will be set up according to their DiSC style.
                  Style teams will review a set of cards that have words corresponding to their style and, as a group, will select the top 5 cards that collectively, best describes the group.
                   Teams can also add their own descriptive words that best represents them. 
                   Part 2: teams will then answer two questions about their style at work, which will highlight for each style the key differences in their preferred work environment.
                    Part 3: Teams will plot their DiSC style on a visual map. This activity will illustrate the impact of missing or dominant styles in the workplace.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Understanding your own DiSC Style of Leadership</li>
                    <li className={classes.properListItem}>Understanding all of the DiSC styles and triggers that might arise</li>
                    <li className={classes.properListItem}>How to adapt your style to improve leadership results</li>
                </ul>             
            </TabPanel>
            <TabPanel className={classes.LNOVERFLOWTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>  
                <Typography variant="h5">What about your style of leadership?</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Understanding why relationships do or do not work is a complex subject, however, better understanding our own style and the style of others, puts you in a position to adjust your delivery for better reception </li>
                    <li className={classes.properListItem}>Sometimes it can be difficult to put your finger on what exactly makes a relationship work or not work.</li>
                    <li className={classes.properListItem}>This is true whether we’re talking about personal, co-worker, or reporting relationships.</li> 
                    <li className={classes.properListItem}>To interact more effectively with others, you sometimes have to bridge differences in style preferences and priorities. </li> 
                    <li className={classes.properListItem}>The first step is to identify those differences―as well as similarities―in a non-judgmental setting.   </li> 
                    <li className={classes.properListItem}>As you look at the possible differences in the styles, it can become clearer why we may not align on our ideas</li>  
                </ul> 
                <Typography variant="h5">Have you experienced working situations where…</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Someone is not necessarily poor performers, but somehow it was very challenging to work with them – this is a style issue</li>
                    <li className={classes.properListItem}>I know I can certainly think of colleagues that I’ve had, who were good at what they do, yet I can hardly bear working with them – that’s a style issue</li>
                    <li className={classes.properListItem}>Can you think of colleagues who may say “let’s just go over this again” and you’re thinking, oh my goodness we have talked that topic to death yet they are thinking, we have barely scratched the surface on this and need to dig deeper – that’s a style issue</li> 
                    <li className={classes.properListItem}>Can you think of colleagues that are all about the team, getting consensus, getting everyone to talk together, and work things through as a group, and you’re thinking just give me my list and I’ll get it done – that’s a style issue</li> 
                    <li className={classes.properListItem}>Today you are going to learn about your style and how this fits with the others in your plant</li> 
                    <li className={classes.properListItem}>From this learning, you will also be able to quickly identify the likely style of others that you work with and need to get things done through </li>
                    <li className={classes.properListItem}>By better understanding their style this will help you to adjust your style and expedite what needs to happen</li>  
                </ul>
                <Typography variant="h5">Introduction and Why Learn DiSC Styles:</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Developed by Psychologist Dr. William Marston 1928</li>
                    <li className={classes.properListItem}>Not a test: simply a self-evaluation</li>
                    <li className={classes.properListItem}>The DISC report holds no value judgments</li> 
                    <li className={classes.properListItem}>Provides Insights to:
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>Tailor your behavior and become self-aware</li>
                            <li className={classes.properListItem}>Be more effective when communicating with others</li> 
                        </ul>
                    </li> 
                    <li className={classes.properListItem}>Behaviours are relative</li> 
                    <li className={classes.properListItem}>The same behaviour, seen from a different perspective, will be perceived very differently</li>
                    <li className={classes.properListItem}>Four primary styles D, i, S, C</li>
                    <li className={classes.properListItem}>Each of us has a combination of the 4 styles</li>
                    <li className={classes.properListItem}><b>No one style is preferred over another</b></li> 
                    <li className={classes.properListItem}>View each style in its 100% form - help us understand the styles</li> 
                    <li className={classes.properListItem}>Behavior profiling tool</li> 
                    <li className={classes.properListItem}>Understanding styles can improve performance and increase productivity </li>
                    <li className={classes.properListItem}>DISC is the most widely used behaviour profiling tool </li> 
                    <li className={classes.properListItem}>The ability to create rapport is a fundamental leadership skill </li>
                    <li className={classes.properListItem}>We tend to like others like ourselves</li>    
                </ul>
                <img src={image1} alt='Disc Circle'/>
                <Typography variant="h5">D – Tendencies</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Getting immediate results</li>
                    <li className={classes.properListItem}>Taking action</li>
                    <li className={classes.properListItem}>Accepting challenges</li>
                </ul>
                <Typography variant="h5">I – Tendencies</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Involvement with people</li>
                    <li className={classes.properListItem}>Making a favorable impression</li>
                    <li className={classes.properListItem}>Collaboration and enthusiasm</li>
                </ul>
                <Typography variant="h5">S – Tendencies</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Calm, patient</li>
                    <li className={classes.properListItem}>Loyal and inclusive</li>
                    <li className={classes.properListItem}>Good listener</li>
                </ul>
                <Typography variant="h5">C – Tendencies</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Attention to standards and details</li>
                    <li className={classes.properListItem}>Analytical thinking</li>
                    <li className={classes.properListItem}>Accuracy and completeness</li>
                </ul>    
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise: Part 1</Typography>     
 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>In teams review the set of cards you have been given</li>
                    <li className={classes.properListItem}>As a group, select the top 5 cards that you believe best describe your group, and note them on your group flipchart</li>
                    <li className={classes.properListItem}>The team needs to have consensus on the words chosen</li>
                    <li className={classes.properListItem}>It is possible that not all words on a card will best describe your team</li>
                    <li className={classes.properListItem}>If necessary, add something else that you feel does represent you</li>
                    <li className={classes.properListItem}>When sorting your cards, also decide:</li>
                </ul>
                <Typography variant="h5">Exercise: Part 2</Typography>     
                <Typography variant="body1" className={classes.LNProperStart}>In teams complete the following 3 statements on a flip chart</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>The kind of work I like to do…</li>
                    <li className={classes.properListItem}>People drive me crazy when…</li>
                    <li className={classes.properListItem}>Identify a spokesperson to report to the group. </li>
                </ul>
                <Typography variant="h5">Exercise: Part 3</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Each person will plot their DiSC style on a visual map. </li>
                    <li className={classes.properListItem}>This activity will illustrate the impact of missing or dominant styles in the workplace.</li>
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>Consider your DISC style</li>
                            <li className={classes.properListItem}>If your DISC style doesn't match up with the other persons DISC style, what adjustments can you make?</li>   
                            <li className={classes.properListItem}>Flex your style – remember we all have all 4 styles available to us</li>   
                            <li className={classes.properListItem}>Take a few minutes and think about key relationships in your life…business or personal</li>   
                            <li className={classes.properListItem}>Think about common communication challenges you have in each of these relationships – what things tend to get in the way of great communication? </li>
                            <li className={classes.properListItem}>Think about how you need to adapt your own style to get the most out of each relationship</li> 
                            <li className={classes.properListItem}>You can use the chart in your handout to help record your thoughts</li>                           
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Leader Notes</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download PDF
                </Button>
                </a>
            </TabPanel>
           
            <TabPanel className={classes.LNTabPanel} value={value} index={6}>
                <Typography variant="h5">Slide Deck</Typography>  
                              
            </TabPanel>
           
        </div> 
    )
}

export default withStyles(styles)(DiscCardsLN);