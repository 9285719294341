import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { saveGroups, beginGame, endLoading, renameGroup, GAME_STATE_INIT } from '../../store/actions/gameActions';
import { copyGroupsFromLastActivity, copyGroupsFromPlannedSession } from '../../store/actions/sessionActions';
import Group from './groups/group';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import LiquidLoadingBlock from '../Loading/loadingCup';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { gameHasOptions, gameOptions } from './gameServer';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import styles from '../Styles';
import { determineHost } from './games/game_functions/functions';
import GroupIcon from '@material-ui/icons/Group';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';



// fake data generator
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `${k}`,
    content: `item ${k}`,
  }));

const INITIAL_STATE = {
  players: getItems(4),
  groupNameOpen: false,
  group_name_id: false,
  group_name: '',
  groups: {
    'group-1': {
      id: 'group-1',
      title: 'Group 1',
      playerIds: [],
    },
    'group-2': {
      id: 'group-2',
      title: 'Group 2',
      playerIds: [],
    },
  },
  groupOrder: ['group-1', 'group-2'],
  groupsLoaded: false,
  playersLoaded: false,
  isLoading: false,
  mounted: false,
  ready: false,
  mouseY: null,
  mouseX: null,
  selectedPlayers: []
};

class GroupSelector extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.props.endLoading();
    this.setState({
      ...this.state,
      mounted: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let updatePlayers = false;
    let updateGroups = false;
    let newState = {
      ...this.state,
    };



    if (!this.state.groupsLoaded) {

      let g2 = {
        ...this.props.session.groups,
      };
      //console.log("G2", g2);

      let g_order = [...Object.keys(g2).sort()];

      this.setState({
        ...this.state,
        groups: g2,
        groupOrder: g_order,
        groupsLoaded: true,
      });
    }

    if ((prevProps.players !== this.props.players || prevState.groupsLoaded !== this.state.groupsLoaded) && this.state.groupsLoaded && this.props.players !== undefined) {
      /* Players have changed */
      updatePlayers = true;
      newState.players = this.props.players;

      let g2 = {
        ...this.props.session.groups,
      };
      newState.groups = g2;
      newState.playersLoaded = true;
    } else {

      if (this.props.players.length <= 0 && this.state.playersLoaded) {
        this.setState({
          ...this.state,
          playersLoaded: false,
        });
      }
    }

    if (Object.keys(prevProps.session.groups).length !== Object.keys(this.props.session.groups).length && this.state.groupsLoaded && this.props.players !== undefined) {

      let g_order = [...Object.keys(newState.groups).sort()];

      newState = {
        ...newState,
        groupOrder: g_order
      };
      updateGroups = true;
    }

    //console.log('state?: ', this.state, newState);

    if (updatePlayers || updateGroups) {
      this.setState({
        ...newState,
      });
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addGroup = () => {
    let teams = false;
    let hasOptions = this.props.session.active_game?.id ? true : false;
    let inlineGameOptions = false;
    if (hasOptions) {
      if (gameOptions(this.props.session.active_game?.id)) {
        inlineGameOptions = gameOptions(this.props.session.active_game?.id);
      }
    }

    //console.log('things: ', inlineGameOptions);
    //console.log('inline game options', this.props.inlineGameOptions);
    if (inlineGameOptions !== false && inlineGameOptions !== undefined) {
      //console.log('inline game options', inlineGameOptions);
      if (inlineGameOptions.Teams === true) {
        // we need there to be an even number of groups
        teams = true;
      }
    }

    if (teams === true) {
      let c = Object.keys(this.state.groups).length;
      let newG = {
        ['group-' + c]: {
          id: 'group-' + c,
          title: 'Group ' + c,
          vsKey: 'group-' + c + ':group-' + (c + 1),
          leadPlayer: '',
          playerIds: [],
        },
        ['group-' + (c + 1)]: {
          id: 'group-' + (c + 1),
          title: 'Group ' + (c + 1),
          vsKey: 'group-' + c + ':group-' + (c + 1),
          leadPlayer: '',
          playerIds: [],
        }
      };
      let g = { ...this.state.groups, ...newG };
      let g_order = [...Object.keys(g)];

      this.setState({
        ...this.state,
        groups: g,
        groupOrder: g_order.sort(),
      });
      this.props.saveGroups(g);

    } else {
      let c = Object.keys(this.state.groups).length;
      let newG = {
        ['group-' + c]: {
          id: 'group-' + c,
          title: 'Group ' + c,
          leadPlayer: '',
          playerIds: [],
        },
      };
      let g = { ...this.state.groups, ...newG };
      let g_order = [...Object.keys(g)];

      this.setState({
        ...this.state,
        groups: g,
        groupOrder: g_order.sort(),
      });
      this.props.saveGroups(g);
    }



  };

  renameGroup = (groupId, groupName) => {
    this.setState({
      ...this.state,
      group_name_id: groupId,
      group_name: groupName,
      groupNameOpen: true
    })
  }

  removeGroup = (groupId) => { };

  validateVsKeys = () => {
    let keys = Object.keys(this.state.groups).sort();
    let g = { ...this.state.groups };
    let vsKey;
    for (let i = 0; i < keys.length; i++) {
      if (i > 0) {
        if (i % 2 === 0 || i === 0) {
          vsKey = keys[i - 1] + ':' + keys[i];
        } else {
          vsKey = keys[i] + ':' + keys[i + 1]
        }

        g[keys[i]] = {
          ...g[keys[i]],
          vsKey: vsKey
        }
      }
    };

    //console.log('tst', g);
    this.props.saveGroups(g);
  }

  groupChange = (type, index) => {
    if (type === 'add') {
    } else if (type === 'remove') {
    }
  };

  beginGame = () => {

    let valid = true;

    let hasOptions = this.props.session.active_game?.id ? true : false;
    let inlineGameOptions = false;
    if (hasOptions) {
      if (gameOptions(this.props.session.active_game?.id)) {
        inlineGameOptions = gameOptions(this.props.session.active_game?.id);
      }
    }

    let teams = false;
    if (inlineGameOptions.Teams === true) {
      teams = true;
    }

    //console.log('things: ', inlineGameOptions);

    if (inlineGameOptions !== false && inlineGameOptions !== undefined) {
      //console.log('this', inlineGameOptions);
      if (teams === true) {
        // we need there to be an even number of groups
        if (Object.keys(this.state.groups).length % 2 === 0) {
          valid = false;
        }
      }
    }

    if (valid === true) {
      if (teams === true) {
        // need to ensure the vsKeys are correct before beginning
        this.validateVsKeys();
      }

      this.props.beginGame(this.state.groups);
    } else {
      alert('This activity requires an even number of groups');
    }
  };

  onSave = () => {
    this.props.saveGroups(this.state.groups);
  };

  clickHandler = (event, playerId) => {
    let selPlayers = [...this.state.selectedPlayers];
    if (selPlayers.includes(playerId)) {
      let index = selPlayers.indexOf(playerId);
      if (index > -1) {
        selPlayers.splice(index, 1);
      }
    } else {
      selPlayers.push(playerId);
    }
    this.setState({
      ...this.state,
      selectedPlayers: selPlayers
    });
  }

  contextHandler = (event, playerId) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.selectedPlayers.length <= 0) {
      return;
    }

    this.setState({
      ...this.state,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  }

  handleContextClose = () => {
    this.setState({
      ...this.state,
      mouseX: null,
      mouseY: null
    });
  }

  deleteGroup = (groupId) => {

    if (!window.confirm('Are you sure you want to remove this group?')) {
      return;
    }



    let players = [...this.state.groups[groupId].playerIds];
    let groups = { ...this.state.groups };

    delete groups[groupId];

    if (players.length > 0) {
      //console.log('players', players);
      let groupZeroPlayers = [...this.state.groups[`group-0`].playerIds];
      players.forEach((playerId) => {
        groupZeroPlayers.push(playerId);
      });
      let newGroups = {
        ...groups,
        'group-0': {
          ...groups['group-0'],
          playerIds: groupZeroPlayers
        }
      };
      //console.log('new groups: ', newGroups);
      this.props.saveGroups(newGroups);
    } else {
      //console.log('new groups o: ', groups);
      this.props.saveGroups(groups);
    }
  }

  doMoveAction = (groupId) => {
    let selPlayers = [...this.state.selectedPlayers];
    let groups = { ...this.state.groups };
    let ngroups = { ...this.state.groups };
    let groupPlayers = [...this.state.groups[groupId].playerIds];


    selPlayers.forEach((playerId) => {

      if (!groups[groupId].playerIds.includes(playerId)) {
        groupPlayers.push(playerId);

        ngroups = {
          ...ngroups,
          [groupId]: {
            ...ngroups[groupId],
            playerIds: groupPlayers
          }
        }

        Object.keys(groups).forEach((group_id) => {
          if (groups[group_id].playerIds.includes(playerId) && group_id !== groupId) {

            let index = ngroups[group_id].playerIds.indexOf(playerId);

            if (index > -1) {
              let p = [...ngroups[group_id].playerIds];
              p.splice(index, 1)
              ngroups = {
                ...ngroups,
                [group_id]: {
                  ...ngroups[group_id],
                  playerIds: p
                }
              }
            }
          }
        });
      }
    });

    this.setState({
      ...this.state,
      groups: ngroups,
      selectedPlayers: [],
      mouseX: null,
      mouseY: null
    });
    this.props.saveGroups(ngroups);
  }

  clearSelected = () => {
    this.setState({
      ...this.state,
      selectedPlayers: [],
      mouseX: null,
      mouseY: null
    });
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      group_name: "",
      group_name_id: false,
      groupNameOpen: false
    })
  }

  handleSave = () => {
    this.props.renameGroup(this.state.group_name_id, this.state.group_name, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      groupNameOpen: false
    })
  }

  groupSort = (a, b) => {
    let aInd = a.indexOf('group-');
    let bInd = b.indexOf('group-');

    //console.log('Sorting: ', a, b, aInd, bInd);

    if (aInd < 0 || bInd < 0) {
      return -1;
    }

    let aNum = a.substring(6);
    let bNum = b.substring(6);

    //console.log('Nums: ', aNum, bNum);

    if (parseInt(aNum) > parseInt(bNum)) {
      //console.log('Sorting Less');
      return 1;
    } else if (parseInt(bNum) > parseInt(aNum)) {
      //console.log('Sorting More');
      return -1;
    }

    return 0;
  }

  useGroupsFromLastActivity = () => {
    this.props.copyGroupsFromLastActivity(this.props.session);
  }

  useGroupsFromPlannedSession = () => {
    this.props.copyGroupsFromPlannedSession(this.props.session);
  }

  render() {
    const {
      game_state,
      classes,
    } = this.props;

    //console.log(this.props);

    let hasOptions = this.props.session.active_game?.id ? true : false;
    let inlineGameOptions = false;
    if (hasOptions) {
      if (gameOptions(this.props.session.active_game?.id)) {
        inlineGameOptions = gameOptions(this.props.session.active_game?.id);
      }
    }

    //console.log('things: ', inlineGameOptions);

    let teams = false;
    if (inlineGameOptions !== false && inlineGameOptions !== undefined) {
      //console.log(this.props.inlineGameOptions);
      if (inlineGameOptions.Teams === true) {
        // we need there to be an even number of groups
        teams = true;
      }
    }

    //console.log('teams', teams);


    if (this.state.groupsLoaded && this.state.playersLoaded && !this.props.group_state.isLoading && this.props.full_player_list !== undefined && this.props.full_player_list !== null) {
      return (
        <div className={classes.root}>
          <Dialog open={this.state.groupNameOpen} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
            <DialogContent>
              <DialogContentText className={classes.bodyText}>Group Name:</DialogContentText>
              <TextField multiline autoFocus autoComplete="off" margin='dense' id='group_name' name='group_name' value={this.state.group_name} onChange={this.onChange} label="Group Name" type='email' fullWidth />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color='primary'>
                Cancel
              </Button>
              <Button onClick={this.handleSave} color='primary'>
                Save
              </Button>
            </DialogActions>
          </Dialog>
          {this.props.profile.role === 'moderator' && Object.keys(this.props.session.active_game).length > 0 ?
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={true}
              className={classes.persistentSnackbar}
            >
              <SnackbarContent
                message='Activity is in progress...'
              />
            </Snackbar>
            : null}
          <Menu
            keepMounted
            open={this.state.mouseY !== null}
            onClose={this.handleContextClose}
            anchorReference="anchorPosition"
            anchorPosition={
              this.state.mouseY !== null && this.state.mouseX !== null
                ? { top: this.state.mouseY, left: this.state.mouseX }
                : undefined
            }
          >
            {this.state.groupOrder.sort(this.groupSort).map((groupId, ind) => {
              return (
                <MenuItem key={ind} onClick={() => this.doMoveAction(groupId)}>Move to {this.state.groups[groupId].title}</MenuItem>
              );
            })}
            <MenuItem>--------------</MenuItem>
            <MenuItem onClick={() => this.clearSelected()}>Clear Selected</MenuItem>
          </Menu>
          {Object.keys(this.props.session.active_game).length > 0 ?
            <Typography variant='h2' className={` ${classes.greyText} ${classes.spacingBottom}`}>
              {this.props.session.active_game.name}
            </Typography>
            : null}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h4' className={`${classes.greyText}`}>
                Group Selection
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider className={classes.spacingBottom} />
            </Grid>
          </Grid>
          <div onContextMenu={(event) => this.contextHandler(event, null)}>
            {this.state.groupOrder.sort(this.groupSort).map((groupId, ind) => {
              const group = this.state.groups[groupId];
              const players = group.playerIds.map((playerId) => {
                let newPlayers = { ...this.props.full_player_list[playerId], id: playerId };
                return newPlayers;
              });
              return <Group teams={teams} ind={ind} deleteGroup={this.deleteGroup} selectedPlayers={this.state.selectedPlayers} key={groupId} group={group} group_id={groupId} players={players} player_list={this.props.full_player_list} clickHandler={this.clickHandler} contextHandler={this.contextHandler} renameGroup={this.renameGroup} />;
            })}
          </div>
          <Grid container spacing={3} className={classes.buttonGroup}>
            {game_state === 'init' ? (
              gameHasOptions(this.props.session.active_game.id) === true ?
                this.props.session.active_game.optionsSet === true ?
                  <Grid item>
                    <Button type='submit' onClick={this.beginGame} className={classes.glassButton} variant='contained' startIcon={<PlayCircleFilledWhiteIcon />}>
                      Begin Game
                    </Button>
                  </Grid>
                  :
                  <Grid item>
                    <Button type='submit' onClick={this.beginGame} className={classes.glassButton} variant='contained' disabled={true} startIcon={<PlayCircleFilledWhiteIcon />}>
                      Host Must Set Options Before Game Can Begin
                    </Button>
                  </Grid>
                :
                <Grid item>
                  <Button type='submit' onClick={this.beginGame} className={classes.glassButton} variant='contained' startIcon={<PlayCircleFilledWhiteIcon />}>
                    Begin Game
                  </Button>
                </Grid>
            ) :
              Object.keys(this.props.session.active_game).length > 0 && this.props.profile.role === 'host' ?
                this.props.session.active_game.gameState.state === GAME_STATE_INIT ?
                  <Grid item>
                    <Button type='submit' onClick={this.beginGame} className={classes.glassButton} variant='contained' startIcon={<PlayCircleFilledWhiteIcon />}>
                      Begin Game
                    </Button>
                  </Grid>
                  : null
                : null
            }
            <Grid item>
              <Button type='submit' onClick={this.addGroup} variant='contained' startIcon={<GroupAddIcon />}>
                Add a New Group
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6'>Waiting for Players to Join the Session</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center' className={classes.spacingTop}>
              <LiquidLoadingBlock />
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

GroupSelector.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    full_player_list: state.firestore.ordered.current_sessions[0].playerProfiles,
    group_state: state.game_state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endLoading: () => dispatch(endLoading()),
    saveGroups: (groups) => dispatch(saveGroups(groups)),
    beginGame: (groups) => dispatch(beginGame(groups)),
    renameGroup: (groupKey, groupName, host) => dispatch(renameGroup(groupKey, groupName, host)),
    copyGroupsFromPlannedSession: (session) => dispatch(copyGroupsFromPlannedSession(session)),
    copyGroupsFromLastActivity: (session) => dispatch(copyGroupsFromLastActivity(session)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(GroupSelector);