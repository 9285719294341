import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextEntryList from './../specific_components/textEntryList';


const INITIAL_STATE = {
    open: false
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#673ab7",
    color: theme.palette.common.white,
    borderRadius: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class GameResults extends Component {

    constructor(props) {
        super(props);
        // session.active_game.results
        this.state = {
          ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }   

    render() {
    const {
      session,
      classes,
      auth,
      profile,
      game_options
    } = this.props;

    let title = session.active_game.name;
    if ( game_options !== false ){
        title = game_options.game_texts.game_title;
    }   
        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{title}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(session.active_game.results)
                .sort()
                .map((groupKey, ind) => {
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[groupKey].title}</Typography>   

                        <TableContainer component={Paper} style={{width: "100%"}}>
                          <Table aria-label="customized table">
                          <TableHead>
                            <TableRow style={{fontSize: "1.3em", textTransform: "uppercase"}}>
                              <StyledTableCell align="center">Role</StyledTableCell>
                              <StyledTableCell align="center">Do/Say to <span style={{fontWeight:"bold", fontSize: "1.3em", color: "green"}}>Build</span> Trust</StyledTableCell>
                              <StyledTableCell align="center">Do/Say to <span style={{fontWeight:"bold", fontSize: "1.3em", color: "red"}}>Destroy</span> Trust</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                  
                                <StyledTableRow>
                                  <StyledTableCell component="th" scope="row">
                                    Plant Manager
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`PM_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`PM_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                  <StyledTableCell component="th" scope="row">
                                    Department Manager
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`DM_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`DM_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                  <StyledTableCell component="th" scope="row">
                                    Supervisor (YOU)
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`SUPERVISOR_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`SUPERVISOR_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>

                                <StyledTableRow >
                                  <StyledTableCell component="th" scope="row">
                                    Employee
                                  </StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`EMPLOYEE_buildtrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                  <StyledTableCell align="right"><TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`EMPLOYEE_destroytrust`} debrief={true} hasControl={false}/></StyledTableCell>
                                </StyledTableRow>
                                  
                            </TableBody>
                          </Table>
                        </TableContainer>                     
                    </div>
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);