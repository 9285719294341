import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';




const DiscConflictLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download PDF" {...a11yProps(5)} />
                <Tab className={classes.tabsLNTab} label="Slide Deck" {...a11yProps(6)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>Participants will have previously completed their DiSC Conflict assessment and sent their individual reports.
                  Style teams will examine what they value and what causes stress during conflict.
                    Using their assessment report, teams will choose key points to explain their conflict values and stressors to the other teams.
                     Each DiSC® conflict style group will present their selected values and stressors.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Understanding the values and stressors of each DiSC style when in conflict </li>
                    <li className={classes.properListItem}>How to move through conflict more effectively by recognizing the different styles</li>
                    <li className={classes.properListItem}>How to adapt your conflict style to improve leadership results</li>
                    <li className={classes.properListItem}>How to recognize conflict in others</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <Typography variant="h5">Value</Typography> 
                <ul className={classes.properList}>   
                    <li className={classes.properListItem}>Review the <b>value bullets on page 4 of your DiSC Conflict report</b></li>
                    <li className={classes.properListItem}>Pick the 3 that you would want others to know</li>
                    <li className={classes.properListItem}>Record</li>
                </ul>
                <Typography variant="h5"> </Typography> 
                <Typography variant="h5">Stressors:</Typography> 
                <ul className={classes.properList}>   
                    <li className={classes.properListItem}>Review the <b>stressful bullets on page 4 of your DiSC Conflict report</b></li>
                    <li className={classes.properListItem}>Pick the 3 that you would want others to know</li>
                    <li className={classes.properListItem}>Record</li>
                </ul>
                </TabPanel>

            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>In DiSC style groups, share your values and stressors from your DiSC Conflict assessments. </li>
                    <li className={classes.properListItem}>Record these in the flipcharts on Virtual Glass™ </li>         
                </ul>                            
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What do you notice about the values and stressors from the different styles?</li>
                    <li className={classes.properListItem}>How can this information help you when in conflict?</li>                                
                </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Leader Notes</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download PDF
                </Button>
                </a>
            </TabPanel>
           
            <TabPanel className={classes.LNTabPanel} value={value} index={6}>
                <Typography variant="h5">Slide Deck</Typography>  
                              
            </TabPanel>
           
        </div> 
    )
}

export default withStyles(styles)(DiscConflictLN);