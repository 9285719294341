import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const HiddenAgendaLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>The hidden agenda activity helps participants see how our own agendas (and not sharing them) can impact the outcome of a project.</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Participants will work in small groups to collectively build a wall with blocks. Each participant will have 1 to 4 hidden agendas that will need to be incorporated into the final product design. </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Set aside time to plan and strategize before beginning the work</li>
                    <li className={classes.properListItem}>Involving all team members in problem solving</li>
                    <li className={classes.properListItem}>Making best use of the teams' talents </li>
                    <li className={classes.properListItem}>Undercovering and understanding the hidden agendas of the team</li>
                    <li className={classes.properListItem}>Impact of hidden agendas on the outcomes of a project</li>
                    <li className={classes.properListItem}>Strategies to negotiate and share agendas to create win-win situations</li>
                    <li className={classes.properListItem}>How hidden agendas can create confusion and disagreement in a team</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Often, we have hidden agendas when we’re approaching projects or tasks on teams. This is also defined as an “ulterior motive for something”</li>
                    <li className={classes.properListItem}>It’s important to be aware of our own hidden agendas and that others will have their own agendas as well </li>
                    <li className={classes.properListItem}>How we navigate hidden agendas with our team can have a huge impact on the success or failure of the end result</li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Hidden Agendas - Build a Wall</Typography>
                <Typography variant="body1" className={classes.LNProperStart}><b>Team Exercise</b></Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Make a wall 12 rows high (a block is an individual piece) with 2 windows (minimum). </li>
                    <li className={classes.properListItem}>All blocks must be used.</li> 
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b>Rules</b></Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Each team member has 1 to 4 <b>hidden agenda cards.</b> </li>
                    <li className={classes.properListItem}>Do you show your hidden agenda cards</li>
                    <li className={classes.properListItem}>Instead, share your hidden agenda through conversations and be certain these are incorporated into the design of the wall.</li> 
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}>Note – this can take participants 30 - 45min to complete live, less time virtually. Stop the activity after this time – we do want some success though ok to not have all teams complete the task</Typography>
                <Typography variant="body1" className={classes.LNProperStart}> </Typography>
                <Typography variant="body1" className={classes.LNProperStart}>Virtually each person has 1-2 hidden agenda cards and this activity should take 20minutes</Typography>      
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief Questions - options</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>How easy was it to understand or incorporate the hidden agendas?</li>
                            <li className={classes.properListItem}>Did the hidden agendas throw off the team planning and execution?</li>   
                            <li className={classes.properListItem}>How did you incorporate the hidden agendas?</li>   
                            <li className={classes.properListItem}>How much planning was done before the work of “building” began?</li>   
                            <li className={classes.properListItem}>Were you able to achieve your objective?</li>
                            <li className={classes.properListItem}>What impact did your hidden agendas have on the design and discussion?</li>
                            <li className={classes.properListItem}>How did you incorporate the hidden agendas?</li>
                            <li className={classes.properListItem}>What were the greatest challenges? How did your resolve these?</li>
                            <li className={classes.properListItem}>Do you work with anyone with hidden agendas?</li>
                            <li className={classes.properListItem}>How do you uncover these at work?</li>
                            <li className={classes.properListItem}>What does this have to do with your business?</li>                           
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                
            </TabPanel>
           
            
        </div> 
    )
}

export default withStyles(styles)(HiddenAgendaLN);