import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AggregateBackground from './../images/AggregateBackground.png'
import { hasGameControl, determineHost } from '../../game_functions/functions'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import {
    updateGroupState,
    updateGroupStateParams,
    updateResults,
    editResults,
    removeResults,
    editResultsPath,
    removeResultsPath,
    updateGroupStateStateVal
} from './../../../../../store/actions/gameActions'
import AppBar from '@material-ui/core/AppBar'
import { DragDropContext } from 'react-beautiful-dnd'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Typography from '@material-ui/core/Typography'
import styles from '../../../../Styles'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import DropArea from './../specific_components/dropArea'
import GridOnIcon from '@material-ui/icons/GridOn'
import ClueCard from './../specific_components/clue'
import Box from '@material-ui/core/Box'
import { ClueCards, BLOCK_COLORS } from './../data/data'
import Toolbar from '@material-ui/core/Toolbar'

import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

/* New DND */
//import { DndProvider, useDrag } from 'react-dnd'
//import { HTML5Backend } from 'react-dnd-html5-backend'

const INITIAL_STATE = {
    noItems: true,
    plusItemsExist: false,
    deltaItemsExist: false,
    open: false,
    priorities: {},
    curColumn: null,
    dropDisabled: false,
    drawerOpen: false,
    helpOpen: false,
    width: window.innerWidth,
    height: window.innerHeight,
    wasOpen: false,
    tab: 0,
    filter: 'all',
    isEdit: false,
    showList: false,
    won: false,
    drawer: [],
    grid: {},
    winningCombo: {
        condo: {
            '1x5': [19, 20, 21, 22, 23, 24],
            '1x6': [19, 20, 21, 22, 23, 24],
            '2x5': [1, 2, 3, 4, 5, 6],
            '2x6': [19, 20, 21, 22, 23, 24],
            '3x3': [7, 8, 9, 10, 11, 12],
            '3x4': [1, 2, 3, 4, 5, 6],
            '3x5': [1, 2, 3, 4, 5, 6],
            '3x6': [13, 14, 15, 16, 17, 18],
            '4x3': [7, 8, 9, 10, 11, 12],
            '4x4': [7, 8, 9, 10, 11, 12],
            '4x5': [13, 14, 15, 16, 17, 18],
            '4x6': [13, 14, 15, 16, 17, 18]
        }
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
}

class Activity extends Component {
    constructor(props) {
        super(props)
        this.state = { ...INITIAL_STATE }
        this.onDragEnd = this.onDragEnd.bind(this)
        this.onDragStart = this.onDragStart.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }

    updateWindowDimensions() {
        this.setState({
            ...this.state,
            width: window.innerWidth,
            height: window.innerHeight
        })
    }

    calculateWin() {
        if (
            this.props.session.active_game.results[this.props.group_id] ===
            undefined
        ) {
            return false
        }

        if (
            this.props.session.active_game.results[this.props.group_id].grid ===
            undefined
        ) {
            return false
        }
        if (
            this.props.session.active_game.results[this.props.group_id].grid
                .condo === undefined
        ) {
            return false
        }
        if (
            JSON.stringify(
                Object.keys(
                    this.props.session.active_game.results[this.props.group_id]
                        .grid.condo
                ).sort()
            ) ===
            JSON.stringify(Object.keys(this.state.winningCombo.condo).sort())
        ) {
            // keys match, determine if right colors
            let res = true
            Object.keys(
                this.props.session.active_game.results[this.props.group_id].grid
                    .condo
            ).forEach((el) => {
                let v =
                    this.props.session.active_game.results[this.props.group_id]
                        .grid.condo[el]
                if (!this.state.winningCombo.condo[el].includes(parseInt(v))) {
                    res = false
                }
            })
            return res
        } else {
            return false
        }
    }

    onDragStart(result) {
        this.setState({
            ...this.state,
            dropDisabled: result.source.droppableId
        })
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            ...this.state,
            tab: newValue
        })
    }

    adjustImportance = (id, importance) => {
        let path = `active_game.results.${this.props.group_id}.importance.${id}`
        let val = importance
        this.props.editResultsPath(
            path,
            val,
            determineHost(this.props.profile, this.props.auth)
        )
    }

    handleFilter = (event, value) => {
        if (value !== null) {
            this.setState({
                ...this.state,
                filter: value
            })
        }
    }

    reorder = (drawerList, startIndex, endIndex) => {
        const result = Array.from(drawerList)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        return result
    }

    onDragEnd(result) {
        const { source, destination, draggableId } = result
        // dropped outside the list
        if (!destination) {
            return
        }
        // we don't care about re-ordering here, so just ignore drops that are in the same area where they started
        if (destination.droppableId === source.droppableId) {
            return
        }

        let startSpot = source.droppableId === 'drawer' ? 'drawer' : 'grid'
        let finishSpot =
            destination.droppableId === 'drawer' ? 'drawer' : 'grid'
        let start = null
        let finish = null

        console.log(
            'Start: ',
            startSpot,
            ' - End: ',
            finishSpot,
            ' ------- ',
            this.state[startSpot],
            this.state[finishSpot]
        )

        if (finishSpot === 'drawer') {
            // Dragging from Grid to Drawer
            start =
                this.state[startSpot] === undefined
                    ? {}
                    : { ...this.state[startSpot] }
            finish =
                this.state[finishSpot] === undefined
                    ? []
                    : [...this.state[finishSpot]]

            finish = [...finish, start[source.droppableId]]
            delete start[source.droppableId]
            //const [removed] = start.splice(result.source.index)
            //start.splice(result.destination.index, 0, removed)
        } else {
            if (finishSpot === 'grid' && startSpot === 'grid') {
                // Dragging from One Grid Element to Another
                start =
                    this.state[startSpot] === undefined
                        ? {}
                        : { ...this.state[startSpot] }
                finish =
                    this.state[finishSpot] === undefined
                        ? {}
                        : { ...this.state[finishSpot] }
                finish = {
                    ...finish,
                    [destination.droppableId]: start[source.droppableId]
                }
                delete finish[source.droppableId]
                //const [removed] = finish.splice(result.source.index)
                //finish.splice(result.destination.index, 0, removed)
            } else {
                // Dragging from Drawer to Grid
                start =
                    this.state[startSpot] === undefined
                        ? []
                        : [...this.state[startSpot]]
                finish =
                    this.state[finishSpot] === undefined
                        ? {}
                        : { ...this.state[finishSpot] }

                finish = {
                    ...finish,
                    [destination.droppableId]: draggableId
                }
                //let i = start.indexOf(parseInt(draggableId))
                //start.splice(i, 1)

                const res = Array.from(start)
                console.log('the source', source, destination)
                start.splice(source.index, 1)

                //start.splice(destination.index, 0, removed)
            }
        }

        if (finishSpot === 'drawer') {
            // Dragging from Grid to Drawer
            this.setState({
                ...this.state,
                drawer: finish,
                grid: start,
                dropDisabled: false
            })

            let gridPath = `active_game.results.${this.props.group_id}.grid`
            let gridVals = {
                drawer: finish,
                condo: start
            }

            this.props.editResultsPath(
                gridPath,
                gridVals,
                determineHost(this.props.profile, this.props.auth)
            )
        } else {
            if (finishSpot === 'grid' && startSpot === 'grid') {
                // Dragging from One Grid Element to Another
                this.setState({
                    ...this.state,
                    grid: finish,
                    dropDisabled: false
                })

                let gridPath = `active_game.results.${this.props.group_id}.grid`
                let gridVals = {
                    drawer: this.props.session.active_game.results[
                        this.props.group_id
                    ].grid.drawer,
                    condo: finish
                }

                this.props.editResultsPath(
                    gridPath,
                    gridVals,
                    determineHost(this.props.profile, this.props.auth)
                )
            } else {
                // Dragging from Drawer to Grid
                this.setState({
                    ...this.state,
                    drawer: start,
                    grid: finish,
                    dropDisabled: false
                })

                let gridPath = `active_game.results.${this.props.group_id}.grid`
                let gridVals = {
                    drawer: start,
                    condo: finish
                }

                this.props.editResultsPath(
                    gridPath,
                    gridVals,
                    determineHost(this.props.profile, this.props.auth)
                )
            }
        }
        return
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        let won = this.calculateWin()
        this.setState({
            ...this.state,
            drawer:
                this.props.session.active_game.results[this.props.group_id] ===
                undefined
                    ? {}
                    : this.props.session.active_game.results[
                          this.props.group_id
                      ].grid === undefined
                    ? {}
                    : this.props.session.active_game.results[
                          this.props.group_id
                      ].grid.drawer,
            grid:
                this.props.session.active_game.results[this.props.group_id] ===
                undefined
                    ? {}
                    : this.props.session.active_game.results[
                          this.props.group_id
                      ].grid === undefined
                    ? {}
                    : this.props.session.active_game.results[
                          this.props.group_id
                      ].grid.condo === undefined
                    ? {}
                    : this.props.session.active_game.results[
                          this.props.group_id
                      ].grid.condo,
            width: window.innerWidth,
            won: won,
            height: window.innerHeight
        })
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    componentDidUpdate(prevProps, prevState) {
        /* Something has moved or been created. Update UI */
        if (
            this.props.session.active_game.results[this.props.group_id] !==
            prevProps.session.active_game.results[this.props.group_id]
        ) {
            if (
                this.state.priorities !==
                this.props.session.active_game.results[this.props.group_id]
            ) {
                let won = this.calculateWin()

                this.setState({
                    ...this.state,
                    drawer:
                        this.props.session.active_game.results[
                            this.props.group_id
                        ] === undefined
                            ? {}
                            : this.props.session.active_game.results[
                                  this.props.group_id
                              ].grid.drawer,
                    grid:
                        this.props.session.active_game.results[
                            this.props.group_id
                        ] === undefined
                            ? {}
                            : this.props.session.active_game.results[
                                  this.props.group_id
                              ].grid.condo === undefined
                            ? {}
                            : this.props.session.active_game.results[
                                  this.props.group_id
                              ].grid.condo,
                    won: won
                })
            }
        }

        if (prevState.won === false && this.state.won === true) {
            // FINISH UP
            if (
                this.props.auth.uid ===
                this.props.session.active_game.groups[this.props.group_id]
                    .leadPlayer
            ) {
                this.props.updateGroupStateStateVal(
                    this.props.group_id,
                    GROUP_STATE_RESULTS,
                    determineHost(this.props.profile, this.props.auth)
                )
            }
        }
    }

    finishActivity = () => {
        let groupID = this.props.group_id

        /* TODO: Update group state, but ensure it doesn't touch the params */
        this.props.updateGroupStateStateVal(
            groupID,
            GROUP_STATE_RESULTS,
            determineHost(this.props.profile, this.props.auth)
        )
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleHelpClose = () => {
        this.setState({
            ...this.state,
            helpOpen: false
        })
    }

    openHelp = () => {
        this.setState({
            ...this.state,
            helpOpen: true
        })
    }

    render() {
        const {
            session,
            profile,
            auth,
            group_id,
            classes
            //leader
        } = this.props
        const hasControl = hasGameControl(
            session.groups[group_id].leadPlayer,
            auth.uid,
            profile
        )
        const calcWidth = this.state.width > 1280 ? 1280 : this.state.width
        const containerPWidth =
            calcWidth >= 993 ? 0.7 : calcWidth >= 601 ? 0.85 : 0.9
        const containerMod = calcWidth >= 993 ? 50 : calcWidth >= 601 ? 54 : 58
        const containerWidth = Math.round(calcWidth * containerPWidth) - 12
        const boxWidth = (containerWidth - containerMod) * 0.16666667

        return (
            <div>
                <DragDropContext
                    onDragEnd={this.onDragEnd}
                    onDragStart={this.onDragStart}
                >
                    <Typography
                        variant="h3"
                        className={classes.spacingBottom}
                        style={{ display: 'inline-block' }}
                    >
                        {session.active_game.name}
                    </Typography>
                    <div
                        className="my-legend"
                        style={{
                            display: 'inline-block',
                            float: 'right',
                            padding: '20px',
                            border: '1px solid #000'
                        }}
                    >
                        <div className="legend-title">Complex Block Legend</div>
                        <div className="legend-scale">
                            <ul className="legend-labels">
                                <li>
                                    <span
                                        style={{ backgroundColor: '#cd3527' }}
                                    ></span>
                                    Fitness
                                </li>
                                <li>
                                    <span
                                        style={{ backgroundColor: '#4ba9c3' }}
                                    ></span>
                                    Shopping
                                </li>
                                <li>
                                    <span
                                        style={{ backgroundColor: '#e6b153' }}
                                    ></span>
                                    Parking
                                </li>
                                <li>
                                    <span
                                        style={{ backgroundColor: '#a9b23b' }}
                                    ></span>
                                    Dining
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div
                        style={{ clear: 'both' }}
                        className={`${classes.spacingBottom}`}
                    ></div>
                    <Paper square className={classes.root}>
                        <AppBar position="static">
                            <Tabs
                                value={this.state.tab}
                                onChange={this.handleTabChange}
                                variant="fullWidth"
                                indicatorColor="secondary"
                                aria-label="icon label tabs example"
                            >
                                <Tab
                                    icon={<GridOnIcon />}
                                    label="GRID"
                                    style={{ fontSize: '1.4rem' }}
                                />
                                <Tab
                                    icon={<HelpOutlineIcon />}
                                    label="CLUES"
                                    style={{ fontSize: '1.4rem' }}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.tab} index={0}>
                            {/* Quadrants */}
                            <Grid container className={classes.spacingTop}>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.spacingTop}
                                >
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography variant="h5">
                                                Condo Complex
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} align="right"></Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.spacingTop}
                                    align="center"
                                    style={{
                                        backgroundImage: `url(${AggregateBackground})`,
                                        backgroundSize: 'contain',
                                        backgroundPosition: '50%',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                >
                                    <Grid
                                        container
                                        align="center"
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`1x1`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `1x1`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="1x1"
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`1x2`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `1x2`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="1x2"
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`1x3`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `1x3`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="1x3"
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`1x4`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `1x4`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="1x4"
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`1x5`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `1x5`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="1x5"
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`1x6`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `1x6`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="1x6"
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                type="box"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`2x1`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `2x1`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="2x1"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`2x2`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `2x2`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="2x2"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`2x3`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `2x3`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="2x3"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`2x4`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `2x4`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="2x4"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`2x5`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `2x5`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="2x5"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`2x6`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `2x6`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="2x6"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`3x1`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `3x1`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="3x1"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`3x2`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `3x2`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="3x2"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`3x3`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `3x3`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="3x3"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`3x4`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `3x4`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="3x4"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`3x5`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `3x5`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="3x5"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`3x6`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `3x6`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="3x6"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`4x1`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `4x1`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="4x1"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`4x2`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `4x2`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="4x2"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`4x3`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `4x3`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="4x3"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`4x4`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `4x4`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="4x4"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`4x5`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `4x5`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="4x5"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                        <div
                                            className={`box ${classes.gridB}`}
                                            style={{
                                                width: boxWidth,
                                                height: boxWidth
                                            }}
                                        >
                                            <DropArea
                                                control={hasControl}
                                                postits={
                                                    this.state.grid[`4x6`] !==
                                                    undefined
                                                        ? [
                                                              this.state.grid[
                                                                  `4x6`
                                                              ]
                                                          ]
                                                        : []
                                                }
                                                id="4x6"
                                                direction="horizontal"
                                                disabled={false}
                                                multiple={false}
                                                boxWidth={boxWidth}
                                                bgColorTable={BLOCK_COLORS}
                                                type="box"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.spacingTop}
                                >
                                    <Typography variant="h5">Blocks</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.spacingTop}
                                >
                                    <Grid
                                        container
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <DropArea
                                            control={hasControl}
                                            postits={this.state.drawer}
                                            boxWidth={boxWidth}
                                            bgColorTable={BLOCK_COLORS}
                                            id="drawer"
                                            direction="horizontal"
                                            disabled={false}
                                            multiple={false}
                                            type="drawer"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={this.state.tab} index={1}>
                            <Grid container className={classes.spacingBottom}>
                                <Grid item xs={12}>
                                    Filter By:{' '}
                                    <ToggleButtonGroup
                                        value={this.state.filter}
                                        exclusive
                                        onChange={this.handleFilter}
                                        aria-label="text alignment"
                                    >
                                        <ToggleButton
                                            value="2"
                                            aria-label="left aligned"
                                        >
                                            Very IMportant
                                        </ToggleButton>
                                        <ToggleButton
                                            value="1"
                                            aria-label="centered"
                                        >
                                            Important
                                        </ToggleButton>
                                        <ToggleButton
                                            value="0"
                                            aria-label="right aligned"
                                        >
                                            Not Important
                                        </ToggleButton>
                                        <ToggleButton
                                            value="all"
                                            aria-label="justified"
                                        >
                                            All
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.spacingBottom}
                                    align="right"
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<LiveHelpIcon />}
                                        onClick={() => this.openHelp()}
                                    >
                                        What Should I Do?
                                    </Button>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    {ClueCards.map((card, ind) => {
                                        return (
                                            <ClueCard
                                                filter={this.state.filter}
                                                key={ind}
                                                clue={card}
                                                adjustImportance={
                                                    this.adjustImportance
                                                }
                                                importance={
                                                    session.active_game.results[
                                                        group_id
                                                    ] !== undefined
                                                        ? session.active_game
                                                              .results[group_id]
                                                              .importance !==
                                                          undefined
                                                            ? session
                                                                  .active_game
                                                                  .results[
                                                                  group_id
                                                              ].importance[
                                                                  card.id
                                                              ] !== undefined
                                                                ? session
                                                                      .active_game
                                                                      .results[
                                                                      group_id
                                                                  ].importance[
                                                                      card.id
                                                                  ]
                                                                : -1
                                                            : -1
                                                        : -1
                                                }
                                            />
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </Paper>
                </DragDropContext>

                <Dialog
                    open={this.state.helpOpen}
                    onClose={this.handleHelpClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleHelpClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                className={classes.boldedText}
                            >
                                What Should I Do?
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        <Grid
                            container
                            spacing={2}
                            className={`${classes.spacingBottom} ${classes.spacingTop}`}
                        >
                            <Grid item xs={12}>
                                <div className={classes.boldedText}>
                                    Sort the information cards provided by their
                                    level of importance to solve the puzzle.
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupStateStateVal: (group, groupState, hostID) =>
            dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        updateGroupState: (group, groupState, hostID) =>
            dispatch(updateGroupState(group, groupState, hostID)),
        updateGroupStateParams: (group, groupStateParams, hostID) =>
            dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
        updateResults: (group, top_level, value, hostID) =>
            dispatch(updateResults(group, top_level, value, hostID)),
        editResults: (group, top_level, value, hostID) =>
            dispatch(editResults(group, top_level, value, hostID)),
        editResultsPath: (path, value, hostID) =>
            dispatch(editResultsPath(path, value, hostID)),
        removeResults: (group, top_level, value, hostID) =>
            dispatch(removeResults(group, top_level, value, hostID)),
        removeResultsPath: (group, value, hostID) =>
            dispatch(removeResultsPath(group, value, hostID))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Activity)
