import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import set_expectations_square from './images/set_expectations_square.png'
import { GROUP_STATE_ACTIVE } from '../../../../store/actions/gameActions'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { hasGameControl, getUserGroup } from '../game_functions/functions'
import { updateGroupState } from './../../../../store/actions/gameActions'
import Typography from '@material-ui/core/Typography'

import styles from '../../../Styles'

class Instructions extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    sendToNextStep = (event) => {
        if (this.props.profile.role === 'host') {
            let groupID = this.props.session.hostState.params.group
                ? this.props.session.hostState.params.group
                : null
            let s = {
                state: GROUP_STATE_ACTIVE,
                params: {}
            }
            console.log('um', groupID, s)
            this.props.updateGroupState(groupID, s, this.props.auth.uid)
        } else {
            let groupID = getUserGroup(this.props.session, this.props.auth.uid)
            let s = {
                state: GROUP_STATE_ACTIVE,
                params: {}
            }
            this.props.updateGroupState(
                groupID,
                s,
                this.props.profile.sessionHost
            )
        }
    }

    render() {
        const { session, profile, auth, classes, leader } = this.props

        return (
            <div>
                <Typography variant="h3" className={classes.spaceBottom}>
                    {session.active_game.name}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img
                            src={set_expectations_square}
                            className={classes.imageStyle}
                            alt="Game"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant="body1"
                            className={classes.spaceBottom}
                        >
                            Setting clear expectations and holding people
                            accountable has a major impact on safety and
                            productivity. Here you will practice these tools.
                        </Typography>

                        <Typography
                            variant="body1"
                            className={classes.spaceBottom}
                        >
                            Tap on continue to start.
                        </Typography>

                        <br />
                        <br />
                        {hasGameControl(leader, auth.uid, profile) ? (
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={this.sendToNextStep}
                            >
                                Continue
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                disabled={true}
                                variant="contained"
                            >
                                Leader can hit Continue
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState: (group, groupState, hostID) =>
            dispatch(updateGroupState(group, groupState, hostID))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Instructions)
