import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';


const CommunicationStylesLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>This activity supports the Communication Styles self-assessment based on the work of Paul Mok.
                 This examines four main communication styles: Inuitor, Feeler, Thinker and Sensor.
                  Each of us have the ability to flex into each of the four styles, though we generally have a preferred style.
                   These style differences can create challenges in the workplace as we each feel we are “right” in our approach.
                     For us to be effective in our actions, we need to be aware of how we send and receive information through our communication.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Understand your own communication style </li>
                    <li className={classes.properListItem}>Understand the communication styles of others</li>
                    <li className={classes.properListItem}>How to adapt your style to improve leadership and relationship results</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography> 
                <ul className={classes.properList}>   
                <li className={classes.properListItem}>Prior to the session, have participants compete part 1 and 2 of the Communication Styles Assessment</li>
                </ul>
                <Typography variant="h5"> </Typography> 
                <Typography variant="h5">Communication Styles</Typography> 
                <ul className={classes.properList}>   
                <li className={classes.properListItem}>For us to be effective in our actions, we need to be aware of how we send and receive information through our communication.</li>
                <li className={classes.properListItem}>To help us better understand this, we are going to complete a communicating style survey that we will complete and score right here.</li>
                <li className={classes.properListItem}>This survey is based on the work of Carl Jung. </li>
                <li className={classes.properListItem}>It should take you about 15 minutes to complete.  </li>
                <li className={classes.properListItem}>Try not to belabor any one question.</li>
                <li className={classes.properListItem}>Use your first response.</li>
                </ul>
                <Typography variant="h5"> </Typography> 
                <ul className={classes.properList}> 
                <li className={classes.properListItem}>What were your observations in completing this?</li>
                <li className={classes.properListItem}>Why do you suppose we communicate differently with different people?</li>
                <li className={classes.properListItem}>Why would some think we communicate better than others?</li>
                <li className={classes.properListItem}>Are there any observations from this activity that make you consider actions you could take to improve the score with some people?</li>
                </ul>
                <Typography variant="h5"> </Typography> 
                <Typography variant="h5">Understanding the Styles</Typography>    
                <Typography variant="body1"  className={classes.LNProperStart}><b>Intuitor</b> - Intuitors are typically creative people. They think big-picture and long-term, and don’t like to get bogged down in specific details</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Thinker</b> - Thinkers are typically detail-oriented and logical people. They think about all aspects of an issue or project, and like to have clear instructions to follow. They like to observe and see proof.</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Feeler</b> - Feelers are typically team-oriented, sensitive people who are always considering the interests and comfort of others.  They work to motivate and understand others. </Typography>          
                <Typography variant="body1"  className={classes.LNProperStart}><b>Sensor</b> - Sensors are typically results-oriented people who have a drive for productivity. They jump to action quickly, and work efficiently in order to complete tasks. </Typography>          
            </TabPanel>

            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Place people into like styles (I, T, F, S)</li>
                    <li className={classes.properListItem}>Identify a spokesperson to report to the group. </li>
                    <li className={classes.properListItem}>In your communication style group, review the set of cards you have been given—there are 20 cards in each set.</li>
                    <li className={classes.properListItem}>As a group, select the top 5 cards that you believe best describe your group.</li>         
                </ul> 
                <Typography variant="h5"> </Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Once you’ve chosen your 5 cards, answer the following questions and record your answers</li>        
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}>1.	Work I love to do…</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}>2.	People make me crazy when…</Typography>
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>What is your dominant communication style?</li>
                            <li className={classes.properListItem}>Who do you work well with and why?  </li>   
                            <li className={classes.properListItem}>Who do you struggle with and why?</li>   
                            <li className={classes.properListItem}>How do you need to adapt your style to fit others - flexibility?</li>                             
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
            <TabPanel className={classes.LNTabPanel} value={value} index={6}>
                <Typography variant="h5">Slide Deck</Typography>  
                              
            </TabPanel>
           
        </div> 
    )
}

export default withStyles(styles)(CommunicationStylesLN);