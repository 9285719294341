import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
    GAME_STATE_RESULTS,
    HOST_STATE_MAIN,
    HOST_STATE_RESULTS,
    GROUP_STATE_INIT,
    GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS
} from '../../../store/actions/gameActions'
import { getUserGroup } from './game_functions/functions'
import AwaitingGroup from '../../AwaitingGroup'
import { withStyles } from '@material-ui/core/styles'
import Instructions from './hiddenAgenda/phases/instructions'
import HostInstructions from './hiddenAgenda/specific_components/hostInstructions'
import GameResults from './hiddenAgenda/phases/gameResults'
import Activity from './hiddenAgenda/phases/activity'
import Results from './hiddenAgenda/phases/results'
import Grid from '@material-ui/core/Grid'
import GroupStatus from './game_components/groupStatus'
import LeaderControls from './game_components/leaderControls'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { updateGameState } from '../../../store/actions/gameActions'
import Typography from '@material-ui/core/Typography'

import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import styles from '../../Styles'

const INITIAL_STATE = {
    dialogueOpen: false
}

class HiddenAgenda extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...INITIAL_STATE
        }
    }

    maybeMoveToResults = () => {
        this.setState({
            ...this.state,
            dialogueOpen: true
        })
    }
    handleClose = () => {
        this.setState({
            ...this.state,
            dialogueOpen: false
        })
    }
    moveToResults = () => {
        let s = {
            state: GAME_STATE_RESULTS,
            params: {}
        }
        this.props.updateGameState(s, this.props.auth.uid)

        this.setState({
            ...this.state,
            dialogueOpen: false
        })
    }

    calculateProgress = (results, group_state) => {
        let p = 0

        if (group_state === undefined) {
            return p
        }
        if (group_state.state === undefined) {
            return p
        }

        if (group_state.state === GROUP_STATE_ACTIVE) {
            p += 10
        }
        if (results !== undefined) {
            p += 40
        }
        if (group_state.state === GROUP_STATE_RESULTS) {
            p = 100
        }

        return p
    }

    render() {
        const { game_title, session, profile, auth, view, classes, game_options } = this.props

        let title = game_title;
        if ( game_options !== false ){
            title = game_options.game_texts.game_title;
        }

        if (session.active_game.gameState.state === GAME_STATE_RESULTS) {
            /* ------ RESULTS VIEWS. HOST & GROUPS SHARE SAME VIEW HERE ------ */
            return (
                <GameResults session={session} profile={profile} auth={auth} />
            )
        } else {
            if (
                (profile.role === 'host' || profile.role === 'moderator') &&
                view !== 'group'
            ) {
                /* ------ HOST'S VIEWS ------ */
                const determinedState =
                    profile.role === 'host'
                        ? session.hostState.state
                        : session.moderatorStates[
                              this.props.auth.uid
                          ].state

                switch (determinedState) {
                    case HOST_STATE_MAIN:
                        /* DISPLAY GAME DASHBOARD */
                        return (
                            <div>
                                <Typography variant="h3">
                                    {title}
                                </Typography>
                                <Typography
                                    variant="h5"
                                    className={classes.spaceBottom}
                                >
                                    Dashboard
                                </Typography>

                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <HostInstructions />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography
                                            variant="h5"
                                            className={classes.greyText}
                                        >
                                            Groups
                                        </Typography>
                                        {Object.keys(session.groups)
                                            .sort()
                                            .map((group, index) => {
                                                if (
                                                    session.groups[
                                                        group
                                                    ].playerIds.length > 0
                                                ) {
                                                    return (
                                                        <GroupStatus
                                                            key={index}
                                                            group_id={
                                                                group
                                                            }
                                                            session={session}
                                                            results={
                                                                session
                                                                    .active_game
                                                                    .results[
                                                                    group
                                                                ]
                                                            }
                                                            group_state={
                                                                session
                                                                    .active_game
                                                                    .groupStates[
                                                                    group
                                                                ]
                                                            }
                                                            profile={profile}
                                                            auth={auth}
                                                            progressCalc={
                                                                this
                                                                    .calculateProgress
                                                            }
                                                        />
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {profile.role === 'host' ? (
                                            <Button
                                                variant="contained"
                                                onClick={
                                                    this.maybeMoveToResults
                                                }
                                                color="primary"
                                            >
                                                End Activity &amp; View Results
                                            </Button>
                                        ) : null}

                                        <Dialog
                                            open={this.state.dialogueOpen}
                                            aria-labelledby="alert-dialog-title"
                                            aria-describedby="alert-dialog-description"
                                        >
                                            <DialogContent>
                                                <DialogContentText id="alert-dialog-description">
                                                    Ending the Activity halts
                                                    all current progress and
                                                    will transition to the
                                                    results screen. Are you sure
                                                    you want to do this?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    onClick={this.handleClose}
                                                    color="primary"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    onClick={this.moveToResults}
                                                    color="primary"
                                                    autoFocus
                                                >
                                                    Proceed
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    case HOST_STATE_RESULTS:
                        /* ------ NOT USED IN THIS GAME ------ */
                        return (
                            <div>
                                <h2>Results</h2>
                            </div>
                        )

                    default:
                        break
                }
            } else if (
                profile.role === 'player' ||
                (view === 'group' &&
                    (profile.role === 'host' || profile.role === 'moderator'))
            ) {
                /* ------ PLAYER VIEWs || HOST CAN ALSO VIEW THESE THROUGH HOST GROUP VIEWS ------ */

                /* figure out which group the player is in, then filter on that */
                let group_id = null
                if (view === 'group' && profile.role === 'host') {
                    // group id is in hostState params
                    group_id = session.hostState.params.group
                } else if (view === 'group' && profile.role === 'moderator') {
                    group_id =
                        session.moderatorStates[this.props.auth.uid]
                            .params.group
                } else {
                    group_id = getUserGroup(session, auth.uid)
                }

                /* IF player is in group-0, show special case */
                if (group_id === 'group-0') {
                    return <AwaitingGroup session={this.props.session} />
                } else {
                    switch (session.active_game.groupStates[group_id].state) {
                        case GROUP_STATE_INIT:
                            return (
                                <div>
                                    {profile.role === 'host' ||
                                    profile.role === 'moderator' ? (
                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center'
                                            }}
                                            open={true}
                                            className={
                                                classes.persistentSnackbar
                                            }
                                        >
                                            <SnackbarContent
                                                message={`Currently viewing the group: ${session.groups[group_id].title}`}
                                            />
                                        </Snackbar>
                                    ) : null}
                                    <Instructions
                                        params={
                                            session.active_game.groupStates[
                                                group_id
                                            ].params
                                        }
                                        session={session}
                                        group_id={group_id}
                                        profile={profile}
                                        auth={auth}
                                        leader={
                                            session.groups[group_id]
                                                .leadPlayer
                                        }
                                        players={
                                            session.groups[group_id]
                                                .playerIds
                                        }
                                        game_options={game_options} 
                                    />
                                    <LeaderControls
                                        params={
                                            session.active_game.groupStates[
                                                group_id
                                            ].params
                                        }
                                        session={session}
                                        group_id={group_id}
                                        profile={profile}
                                        auth={auth}
                                        leader={
                                            session.groups[group_id]
                                                .leadPlayer
                                        }
                                        game_options={game_options} 
                                    />
                                </div>
                            )
                        case GROUP_STATE_ACTIVE:
                            return (
                                <div>
                                    {profile.role === 'host' ||
                                    profile.role === 'moderator' ? (
                                        <Snackbar
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center'
                                            }}
                                            open={true}
                                            className={
                                                classes.persistentSnackbar
                                            }
                                        >
                                            <SnackbarContent
                                                message={`Currently viewing the group: ${session.groups[group_id].title}`}
                                            />
                                        </Snackbar>
                                    ) : null}
                                    <Activity
                                        params={
                                            session.active_game.groupStates[
                                                group_id
                                            ].params
                                        }
                                        session={session}
                                        group_id={group_id}
                                        profile={profile}
                                        auth={auth}
                                        leader={
                                            session.groups[group_id]
                                                .leadPlayer
                                        }
                                        game_options={game_options} 
                                    />
                                    <LeaderControls
                                        params={
                                            session.active_game.groupStates[
                                                group_id
                                            ].params
                                        }
                                        session={session}
                                        group_id={group_id}
                                        profile={profile}
                                        auth={auth}
                                        leader={
                                            session.groups[group_id]
                                                .leadPlayer
                                        }
                                        game_options={game_options} 
                                    />
                                </div>
                            )
                        case GROUP_STATE_RESULTS:
                            return (
                                <Results
                                    params={
                                        session.active_game.groupStates[
                                            group_id
                                        ].params
                                    }
                                    session={session}
                                    group_id={group_id}
                                    profile={profile}
                                    auth={auth}
                                    leader={
                                        session.groups[group_id]
                                            .leadPlayer
                                    }
                                    game_options={game_options} 
                                />
                            )
                        default:
                            return (
                                <AwaitingGroup session={this.props.session} />
                            )
                    }
                }
            }
        }

        return null
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGameState: (state, host) => dispatch(updateGameState(state, host))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(HiddenAgenda)
