import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, updateGroupStateParamsVal, editResultsPath, updateResultsPath, removeResultsPath } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { ProfileList } from '../profiles/profileList';

import DoneIcon from '@material-ui/icons/Done';
import Board from './board';
import WalkProfile from './../specific_components/profile';
import GameHelp from './../specific_components/gameHelp';
import ioc from './../images/ioc.png';

const INITIAL_STATE = {
  noteOpen: false,
  entry_text: '',
  isEdit: false, 
  answer: []
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
    
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  



  nextStep = (step) => {
    let host = determineHost(this.props.profile, this.props.auth);
    this.props.updateResults(this.props.group_id, 'done', step, host)

    this.props.updateGroupStateParams(this.props.group_id, {
      phase : 'board'
    }, host);
  }
 

  getCorrespondingProfiles = (step) => {
    let e = ProfileList.find(obj => {
      return (obj.profile_step === step)
    });
    return {
      profile : e,
    };
  }

  
  
  render() {
    const {
      session,
      profile,
      auth,
      params, 
      step,
      phase,
      classes,
      group_id,
    } = this.props;
    console.log("Sesson in conflictSenarios activity  >>>", session);
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);
    const results = session.active_game.results;
    let group_result = results[group_id] ? results[group_id] : false;
    let e_result = false;
    let boardStep = 1;
    let stepsCompleted = 0;
    if ( group_result !== false ){       
        boardStep = group_result.done.length + 1;
        stepsCompleted = group_result.done.length;
        if ( boardStep > 5 ){
          boardStep = 5;
        }
    }

    if ( phase === 'board' ){
      return <Board
        params={params}
        step={boardStep}
        session={session}
        group_id={group_id}
        profile={profile}
        auth={auth}
        stepsDone={stepsCompleted}
        leader={session.groups[group_id].leadPlayer}
      />;
    }

    /* IF WE NEED TO CHANGE THE STEP DEPENDING ON THE GROUP, WE DO THAT HERE */
    const calculatedStep = step;
    const walkAwhileProfiles = this.getCorrespondingProfiles(calculatedStep);  

    return (
      <div>
        <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center" style={{margin: 0}}>Scenario {step}<br/><span className={classes.profileScenario}></span></Typography>  
        <Grid container spacing={3} className={classes.spacingTop}>
            <Grid item xs={12}>
                <WalkProfile 
                 walkProfile={walkAwhileProfiles.profile}
                 step={step}
                 session={session}
                 group_id={group_id}
                 profile={profile}
                 auth={auth}
                 hasControl={hasControl}
                />
            </Grid>
           
            <Grid container>
              <Grid item xs={6} style={{paddingTop: "60px", paddingBottom: "20px"}}>
                After reading the scenario, take some notes regarding: 
                <ul className="browser-default">
                  <li>What is the impact?</li><li>How would you resolve this conflict?</li>
                </ul>
                When done, click the finish scenario button to the right.
              </Grid>
              <Grid item xs={6} align="right" style={{paddingTop: "60px"}}>
                <Button type='submit' disabled={!hasControl} variant="contained" color="primary"  onClick={() => this.nextStep(step)} startIcon={<DoneIcon/>}>
                  Finish Scenario
                </Button>
                <br/><br/>
                <img src={ioc} className={classes.imageSize} style={{maxWidth:"200px"}}/>
              </Grid>
            </Grid>
                 
        </Grid>  
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    updateGroupStateParamsVal: ( group, value, groupStateParams, hostID ) => dispatch(updateGroupStateParamsVal(group, value, groupStateParams, hostID) ),
    editResultsPath: (path, value, host) => dispatch(editResultsPath(path, value, host)),
    updateResultsPath: (path, value, host) => dispatch(updateResultsPath(path, value, host)),
    removeResultsPath: (path, value, host) => dispatch(removeResultsPath(path, value, host)),

  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);