export const URGENT_AND_NOT_IMPORTANT = 'urgent_not_important';
export const URGENT_AND_IMPORTANT = 'urgent_and_important';
export const NOT_URGENT_AND_NOT_IMPORTANT = 'not_urgent_and_not_important';
export const IMPORTANT_AND_NOT_URGENT = 'important_and_not_urgent';

export const QUADRANT_COLORS = {
    [URGENT_AND_NOT_IMPORTANT] : {
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(207,165,97,1) 0%, rgba(207,165,97,0.6979166666666667) 100%, rgba(0,212,255,1) 100%);",
        title: 'Urgent & Not Important'
    },
    [URGENT_AND_IMPORTANT] : {
        //color: "rgba(80,156,147, 0.7)",
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(80,156,147,1) 0%, rgba(80,156,147,0.7035189075630253) 100%, rgba(0,212,255,1) 100%);",
        title: 'Urgent & Important'
    },
    [NOT_URGENT_AND_NOT_IMPORTANT] : {
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(6,114,185,1) 0%, rgba(6,114,185,0.6979166666666667) 100%, rgba(0,212,255,1) 100%);",
        title: 'Not Urgent & Not Important'
    },
    [IMPORTANT_AND_NOT_URGENT] : {
        color: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(142,115,179,1) 0%, rgba(142,115,179,0.6979166666666667) 100%, rgba(0,212,255,1) 100%);",
        title: 'Important & Not Urgent'
    }
}


export const PROFILE_MALE = 'Microsoft Guy Online (Natural) - English (United States)';
export const PROFILE_FEMALE = 'Microsoft Clara Online (Natural) - English (Canada)';

export const PROFILE_MALE2 = 'Google UK English Male';
export const PROFILE_FEMALE2 = 'Google UK English Female';

export const CaseStudy = {
    html: `First day back from holidays and you know what that means.  Already you can feel the stress as you sip your first cup of coffee.  You decide to check your emails to see what happened while away.  As you attempt to reconstruct the events that occurred while away, it looks like there were definitely some challenges that need addressing. It looks like you have some mandatory training that needs to be completed by the end of the week.  And it looks like you have several alerts to reconcile your expense reports.  “Oh well, the day is early!”, you say to yourself.  I’ll have time to get back to this later; these things can wait.”<br/><br/>
    Today you have a new person starting and you wonder who will train her. Robert is in, but he can be a real complainer and you do not want the new person learning that right away.  Joseph is also in; he is always happy and pleasant but sometimes just talks too much and does not seem focused on what is happening on the line.  He is also one of those old-time operators that doesn’t like to use the new equipment. You need to review the payroll system today and make sure the payroll activity is correct – this can be a real challenge if you do not keep up. <br/><br/>
    As you look at the cut schedule for today, it seems like the line will likely be short staffed in the middle of the day. Martha just called in and she is also not coming in today because she is taking a Medical Leave. You try calling a few other operators and they do not answer - you figure it is because they just don't want to work today.   No worries you can fill in today on the line, after all you worked on the board line at one time.  You do have a few things to complete today, including the daily Production Report, and review any safety issues that occurred while away.  <br/><br/>
    Diane, your best friend, has just stopped by to catch up.  She wants to know all about your vacation, what you did, what you saw, what the resort was like.  Together you sit down for a coffee in the break room and spend some time chatting. Diane tells you that George, one of your operators, was caught sleeping on the job – again.  You catch a glimpse at the clock and panic as you realize your shift change meeting is about to start and you are not prepared. You excuse yourself from Diane and go into the office. <br/><br/>
    Just as you get there, your manager calls and reminds you that today he will be expecting you to attend a WCM meeting on reoccurring issues happening in another area of the plant – an area you have never worked in and you wonder what you could possibly contribute.   The phone rings again, you had recommended someone for discipline before you went on vacation and now that same operator is calling you and asking for your documentation on the discipline.  That was a week ago and you are not sure where to start looking.  Before the shift change meeting you better check What’s App – oh my - issues happening in wet end and there are 44 notes to read – this is not even your end of the plant or your specialty. As you start reading these notes Paulo stops by and says you are going to need to calm your team down, as the process engineers have made equipment adjustments and not told anyone, and operators are not happy. Which reminds you, the shift change meeting is about to start – ok, you can always start with a safe start story of the sunburn you got on vacation. `,
};


export const Interruptions = [
    {
        id: 1,
        profile_title: "Process Engineer - OPL",
        profile_text: `A process engineer has just put a One Point Lesson (OPL) on your desk for you to train out. `,
    },
    {
        id: 2,
        profile_title: "New Employee - Quitting",
        profile_text: `A new employee just stopped by your office to tell you they are quitting because they are not getting the training from the person they were supposed to train with.`,
    },
    {
        id: 3,
        profile_title: "New Knives Needed",
        profile_text: `On your tour of the shop floor today you notice that several knives are without ceramic blades and you need to call purchasing about the status of the new knives. `,
    },
    {
        id: 4,
        profile_title: "Call the School",
        profile_text: `Please call your son’s school principal. It’s important you call back as soon as possible.`,
    },
    {
        id: 5,
        profile_title: "EHS Engineer - Training Request",
        profile_text: `The Environmental Health and Safety (EHS) engineer just called to tell you that he posted the training and no one has shown up yet. `,
    },
    {
        id: 6,
        profile_title: "Mario - Paystub Issue",
        profile_text: `Mario tells you his paystub doesn’t match what he worked last week. He’s headed on vacation next week and needs the cash. `,
    },
    {
        id: 7,
        profile_title: "Plant Manager - Learning Roadmap",
        profile_text: `The plant manager stops by to see how you’re doing and if you’ve had a chance to finish this weeks Learning Roadmap as he’d love to discuss it with you.`,
    },
    {
        id: 8,
        profile_title: "Call your Spouse",
        profile_text: `Your partner/spouse wants you to call back as soon as possible.`,
    },
    {
        id: 9,
        profile_title: "Board Line Jam - Alarm",
        profile_text: `There is a jam on the board line and you’re hearing alarms going off.`,
    },
    {
        id: 10,
        profile_title: "Production Manager - Request",
        profile_text: `You just got a message from the Production Manager that the Managing Director is coming tomorrow for a tour, and your area better be clean!`,
    },
    {
        id: 11,
        profile_title: "Warehouse Manager - Take Off Driver Issue",
        profile_text: `The Warehouse Manager calls you and says you need to talk to the take off drivers because they are damaging board and putting board in improper locations.`,
    },
];

