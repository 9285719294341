
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AggregateLogo from '../images/AggregateLogo.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions'
import { updateGroupState, editResultsPath } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import { BLOCKS } from './../data/data';

class Instructions extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    sendToNextStep = (event) => {
    let groupID =  getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);  
    console.log('groupid', groupID); 
    let res = this.props.session.active_game.results[groupID];

    if ( res === undefined ){
        res = {};
    }
    if ( res.grid === undefined ){ 
        let path = `active_game.results.${groupID}.grid.drawer`;
        let val = BLOCKS;
        this.props.editResultsPath(path, val, determineHost(this.props.profile, this.props.auth));  
    }
    let s = { 
            'state' : GROUP_STATE_ACTIVE,
            'params' : {}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth)); 
    }

    render(){
        const { session, profile, auth, classes, leader } = this.props;

        return (
            <div>
               <Typography variant='h3' className={classes.spaceBottom}>
                    {session.active_game.name}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={AggregateLogo} className={classes.imageStyle} alt="Game"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>  
                        <Typography variant="body1" className={classes.spacingBottom}>An architect has come up with plans for a new condo complex, however, before he could complete the drawings, he quit the job. </Typography>
                        <Typography variant="body1" className={classes.spacingBottom}>Your team will complete the Architect's drawings for the ground floor of a condo complex. You will only have the Architect's notes to complete the construction. </Typography>
                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid>  
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
    };
  };
  
  export default compose(connect(mapStateToProps, mapDispatchToProps),withStyles(styles))(Instructions);