import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal, editResultsPath, updateGroupStateStateVal, removeResultsPath } from '../../../../../store/actions/gameActions';
//import { createPiecesGroup } from './../utils/createPiecesGroup';
import paper from "paper/dist/paper-core"
import { Tangram } from './tangramImport';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
    //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
    //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS, GROUP_STATE_ACTIVE, GAME_STATE_RESULTS
  } from '../../../../../store/actions/gameActions';
  
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import styles from '../../../../Styles';

import { wordGroups } from './../data/data';

export const defaultDifficulty = "Easy";
export const defaultId = 0;

export function getPuzzle(session, wordGroups, group_id) {
    /*if ( session.active_game.Puzzle === undefined ){
        return wordGroups['Easy'][0];
    }*/

    if ( session.active_game.groupStates[group_id].params.puzzle === undefined ){
        return wordGroups[defaultDifficulty][defaultId];
    }
    //console.log('Getting puzzle?', session, wordGroups, group_id);
    return wordGroups[session.active_game.groupStates[group_id].params.puzzle.puzzleDifficulty][session.active_game.groupStates[group_id].params.puzzle.puzzleId];
}

export function getNextPuzzle(session, wordGroups, group_id) {
    /*if ( session.active_game.Puzzle === undefined ){
        return wordGroups['Easy'][0];
    }*/

    //let curPuzzle = getPuzzle(session, wordGroups, group_id);
    let curPuzzleId = defaultId;
    let curPuzzleDifficulty = defaultDifficulty;

    if ( session.active_game.groupStates[group_id].params.puzzle !== undefined ){
        curPuzzleId = session.active_game.groupStates[group_id].params.puzzle.puzzleId;
        curPuzzleDifficulty = session.active_game.groupStates[group_id].params.puzzle.puzzleDifficulty;
    }

    let nextId = curPuzzleId + 1;
    let nextDifficulty = curPuzzleDifficulty === 'Easy' ? 'Medium' : curPuzzleDifficulty === 'Medium' ? 'Hard' : curPuzzleDifficulty === 'Hard' ? 'N/A' : 'N/A';

    console.log('asdfasdfasd', nextDifficulty, wordGroups[curPuzzleDifficulty][nextId]);
    if ( nextDifficulty === 'N/A' && wordGroups[curPuzzleDifficulty][nextId] === undefined ){
        // we're out of puzzles, serve up a false to trigger that flag
        return false;
    }

    if ( wordGroups[curPuzzleDifficulty][nextId] === undefined ){
        // we go to next difficulty
        return {
            puzzleDifficulty: nextDifficulty,
            puzzleId : 0
        }
    } 
    // not next difficulty, just next puzzle in same difficulty
    return {
        puzzleDifficulty : curPuzzleDifficulty,
        puzzleId : nextId
    }
}



const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null,
    x: 50,
    y: 50,
    isDragging: false,
    mouseY: null,
    mouseX: null,
    currentSlot: null
}

class WordActionCenter extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
   
      
    }
        
    setWord(word){
        let spot = this.state.currentSlot;
        let path = `active_game.results.${this.props.group_id}.choices.${spot}`;
        if ( word === null ){
            this.props.removeResultsPath( path, word, this.props.host );
        } else {
            this.props.editResultsPath( path, word, this.props.host );
        }
        this.handleContextClose();        
    }

    isChoicesCorrect = () => {
        let data = getPuzzle(this.props.session, wordGroups, this.props.group_id);
        let correct = false;
        if ( this.props.session.active_game.results[this.props.group_id] !== undefined ){
    
          if (this.props.session.active_game.results[this.props.group_id].choices !== undefined ){
            let numWords = data.words.length;
            //console.log('NUMBER OF WORDS', numWords,  Object.keys(this.props.session.active_game.results[this.props.group_id].choices).length);
              // TODO: UPDATE to use dynamic word list
              if ( Object.keys(this.props.session.active_game.results[this.props.group_id].choices).length === numWords ){
                  //console.log("CHECKING");
                  correct = true;
                  let firstWord = this.props.session.active_game.results[this.props.group_id].choices[1];
    
                  
    
                  Object.keys(this.props.session.active_game.results[this.props.group_id].choices).map((resultInd, ind) => {
                    let word = this.props.session.active_game.results[this.props.group_id].choices[resultInd];
                    let afterWord = parseInt(resultInd) + 1;
                    if ( resultInd == numWords ){
                      afterWord = 1;
                    }
                    
                    let beforeWord = parseInt(resultInd) - 1;
                    if ( resultInd == 1 ){
                      console.log('what?');
                      beforeWord = numWords;
                    }
    
                    console.log('Checking', resultInd, numWords, 'Word: ', word, 'After Word: ', afterWord, this.props.session.active_game.results[this.props.group_id].choices[afterWord], 'Before Word: ', beforeWord, this.props.session.active_game.results[this.props.group_id].choices[beforeWord]);
                    if ( data.associations[word].after !== this.props.session.active_game.results[this.props.group_id].choices[afterWord] ){
                      correct = false;
                    }
                    if ( data.associations[word].before !== this.props.session.active_game.results[this.props.group_id].choices[beforeWord] ){
                      correct = false;
                    }
    
                  });
                 
              }
          }
        }
        console.log('IS CORRECT?', correct);
        return correct;
    }
   
    componentDidUpdate(){
        if ( this.props.session.active_game.results[this.props.group_id] !== undefined && this.props.hasControl ){

            if (this.props.session.active_game.results[this.props.group_id].choices !== undefined ){
                
                // TODO: UPDATE to use dynamic word list
                let data = getPuzzle(this.props.session, wordGroups, this.props.group_id);
                let numWords = data.words.length;
                if ( Object.keys(this.props.session.active_game.results[this.props.group_id].choices).length === numWords ){
        
                    if ( this.isChoicesCorrect() ){
                        this.finishActivity();
                    }
        
                }
            }
        }
    }

    openMenu(event, index, word){
        event.preventDefault();
        event.stopPropagation();
        if ( !this.props.hasControl){
            return;
        }

        //console.log('trhe index?', index);

        this.setState({
            ...this.state,
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            currentSlot: index
        });
    }

    handleContextClose = () => {
        this.setState({
          ...this.state,
          mouseX: null,
          mouseY: null,
          currentSlot: null
        });
    }

    clearSelected = () => {
        this.setWord(null);
    }

    clearAll = () => {       
        let path = `active_game.results.${this.props.group_id}.choices`;       
        this.props.editResultsPath( path, {}, this.props.host );
        this.handleContextClose();    
    }

    componentWillUnmount(){
        //paper.project.remove();      
    }

    finishActivity = () => {
        let groupID = this.props.group_id;
      
        this.props.updateGroupStateParamsVal(groupID, 'finishedOn', Date.now(), this.props.host); 
        /* TODO: Update group state, but ensure it doesn't touch the params */
        this.props.updateGroupStateStateVal(groupID, GROUP_STATE_RESULTS, this.props.host);
    };

    render() {
        const { classes, wordsList, correct_order, associations, results } = this.props;
        const numWords = wordsList.length;
        let usedWords = [];

        
            Object.keys(results).map((resKey, index) => {
                usedWords.push(results[resKey]);
            });
        
        return (
            <div style={{padding: "20px", backgroundColor: "#fff", minWidth: "226px" }} id="white-board">

                <Menu
                    keepMounted
                    open={this.state.mouseY !== null}
                    onClose={this.handleContextClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        this.state.mouseY !== null && this.state.mouseX !== null
                        ? { top: this.state.mouseY, left: this.state.mouseX }
                        : undefined
                    }
                    >
                    {wordsList.map((word, ind) => {
                        if ( usedWords.includes(word) ){
                            return null;
                        } 

                        return (
                            <MenuItem key={ind} onClick={() => this.setWord(word)}><span style={{fontWeight: "bold"}}>{word}</span></MenuItem>
                        );
                    })}
                    <MenuItem>--------------</MenuItem>
                    <MenuItem onClick={() => this.clearSelected()}>Clear Row</MenuItem>
                    <MenuItem>--------------</MenuItem>
                    <MenuItem onClick={() => this.clearAll()}>Clear ALL Rows</MenuItem>
                </Menu>

                <ul>
                    <li>
                        <span className={`${classes.centerVertically} ${classes.interimRow}`}><ArrowUpwardIcon className={classes.diffIcon}/> 
                        -&nbsp; 
                        { results[numWords] === undefined ? `_____` : results[numWords] }
                        &nbsp;
                        { results[1] === undefined ? `_____` : results[1] }
                    </span>
                    </li>
                    { wordsList.map((word, ind) => {
                        
                        let resIndex = ind+1;
                        let chosen = (results === undefined) ? false : (results[resIndex] === undefined ) ? false : results[resIndex];

                        let prevIndex = resIndex - 1;
                        if ( prevIndex <= 0 ){
                            prevIndex = numWords;
                        }
                        let nextIndex = resIndex + 1;
                        if ( nextIndex > numWords ){
                            nextIndex = 1;
                        }
                        
                        return (
                            <div key={ind} >                            
                                <li>
                                    <span className={classes.centerVertically}>
                                        <span className={classes.cwNumber}>{resIndex}.</span>
                                        { chosen === false ?
                                            <span className={`${classes.wordSpot} ${classes.wordSpotEmpty}`} onClick={(event) => this.openMenu(event, resIndex, word)}>word here</span>
                                        :
                                            <span className={classes.wordSpot} onClick={(event) => this.openMenu(event, resIndex, word)}>{chosen}</span>
                                        }
                                        
                                    </span>
                                </li>
                                {
                                    (resIndex) !== numWords ?    
                                        <li>
                                            <span className={`${classes.interimRow}`}>
                                                <span className={classes.centerVertically}>
                                                    <VerticalAlignCenterIcon className={classes.diffIcon}/>
                                                        -&nbsp;
                                                        { chosen === false ? `_____` : results[resIndex] }
                                                        &nbsp;
                                                        { results[nextIndex] === undefined ? `_____` : results[nextIndex] }
                                                     </span>
                                            </span>
                                        </li>
                                    : null 
                                }
                            </div>
                        )
                    })
                    }
                    <li><span className={`${classes.centerVertically} ${classes.interimRow}`}><ArrowDownwardIcon className={classes.diffIcon}/>
                     -&nbsp; 
                     { results[numWords] === undefined ? `_____` : results[numWords] }
                     &nbsp;
                     { results[1] === undefined ? `_____` : results[1] }
                     </span></li>
                </ul>
            </div>
        ); 
    
    }
}



const mapStateToProps = (state) => {
    //console.log('this is my state', state);
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
        updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
        removeResultsPath: (path, value, hostID) => dispatch(removeResultsPath(path, value, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WordActionCenter);
