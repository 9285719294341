import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import DefaultFooter from '../Footer/defaultFooter';
import { compose } from 'redux';
import Divider from '@material-ui/core/Divider';
import GOLLogo from '../../images/Glass-of-Learning-Logo-Main.jpg';
import Typography from '@material-ui/core/Typography';
import ErrorBoundary from '../Home/errorBoundary';
import DefaultPageHeader from '../Header/defaultPageHeader';
import styles from '../Styles';
import { Grid } from '@material-ui/core';
import LogoAlt from '../../images/LogoAlt.png';
import ComputerImage from './images/computer-image.jpg';
import Button from '@material-ui/core/Button';


class Default extends Component {

  componentDidMount() {  
  }

  componentDidUpdate(prevProps, prevState) {   
  }

  render() {
      const { classes } = this.props;

      return (
      <ErrorBoundary>
        
            <DefaultPageHeader />
            <div className='outer-container'>
              <div style={{maxWidth: "1800px", margin: "0 auto", padding: "0 30px"}}>
                  <Grid container style={{alignItems: "center"}} spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h3">Interactive Sessions with Activities, Information Sharing and Group or Pair Disucssions!</Typography>
                      <Typography variant="body1" style={{fontSize: '1.2rem'}}>Does your team work remotely? We have virtual options for learning and skill development.  
                      Take part in interactive sessions with activities, information sharing and group or pair discussions, using a variety of on-line learning tools, followed with application leading to behavior change. 
                      Keep your team engaged with mind games that will have them working together as a team to solve a puzzle or collaborate on problem solving situations. Then use the activity to debrief how this can relate back to their role within your company.
                      
                      </Typography>
                      
                      <a style={{textDecoration: "none", marginTop: "30px", display: "inline-block"}} target="_blank"  rel="noopener noreferrer" href="https://glassoflearning.com/design-learning-solutions/virtual-learning/"><Button size="large" variant="contained" color="primary">Learn more</Button></a>
                  
                      
                  
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <img src={ComputerImage} style={{maxWidth: "100%"}}/>
                    </Grid>
                  </Grid>
                  </div>
            </div>
            <DefaultFooter/>
      
      </ErrorBoundary>
      );
  }
}


const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(Default);
