import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import {
    updateGroupStateParamsVal,
    editResultsPath,
    updateGroupStateStateVal,
    removeResultsPath,
    updateResultsPath
} from '../../../../../store/actions/gameActions'
//import { createPiecesGroup } from './../utils/createPiecesGroup';

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
    //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
    //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS,
    GROUP_STATE_ACTIVE,
    GAME_STATE_RESULTS
} from '../../../../../store/actions/gameActions'

import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExtensionIcon from '@material-ui/icons/Extension'
import { pieces } from './../data/data'
import AddIcon from '@material-ui/icons/Add'
import bbss_logo from './../images/bbss_logo.png'
import Popover from '@material-ui/core/Popover'
import Suggestions from './suggestions'

import styles from '../../../../Styles'

//import { wordGroups } from '../data/data.js';

const PieceDrawer = (props) => {
    const drawerRef = React.useRef()
    const [scrollPos, setScrollPos] = React.useState(0)

    //console.log(props.dragStart);

    const saveEl = (e) => {
        //console.log(e.clientY, window.innerHeight, e.target.offsetParent.offsetTop);
        //if ( e.target.offsetParent.offsetTop > window.innerHeight ){
        //    setScrollPos( e.target.offsetParent.offsetTop - window.innerHeight );
        //}
    }

    /*React.useEffect( () => {
        console.log('HRM', props.open, scrollPos, drawerRef.current);
        if ( props.open === true && drawerRef.current !== undefined ){
            if ( scrollPos > 0 ){
                drawerRef.current.scrollTo(scrollPos);
            }
        } 
    }, [drawerRef.current]);*/

    return (
        <Drawer
            ref={drawerRef}
            anchor="right"
            open={props.open}
            onClose={props.close}
        >
            <div
                className={props.classes.drawerHeader}
                style={{ minWidth: '250px' }}
            >
                <IconButton onClick={props.close}>
                    <ChevronRightIcon />
                </IconButton>{' '}
                <span className={props.classes.playersTitle}>Pieces</span>
            </div>
            <List
                className={`${props.classes.relativeList} ${props.classes.doNotPadTop}`}
                style={{ width: '100%' }}
            >
                <ListItem className={props.classes.doNotPad}>
                    <List
                        className={`${props.classes.horizontalList} ${props.classes.borderTop}`}
                        style={{ width: '100%' }}
                    >
                        {props.pieces.map((piece, ind) => {
                            if (!props.hasControl) {
                                return (
                                    <ListItem
                                        key={ind}
                                        className={props.classes.stretchToFit}
                                        onClick={(e) =>
                                            props.handlePop(e, piece)
                                        }
                                    >
                                        <img
                                            alt=""
                                            draggable="true"
                                            style={{
                                                width: piece.previewSize.width,
                                                maxHeight: '150px',
                                                display: 'block',
                                                margin: '10px auto'
                                            }}
                                            piece={piece.id}
                                            pwidth={piece.size.width}
                                            pheight={piece.size.height}
                                            src={piece.image}
                                        />
                                    </ListItem>
                                )
                            } else {
                                return (
                                    <ListItem
                                        key={ind}
                                        className={props.classes.stretchToFit}
                                    >
                                        <div style={{ width: '100%' }}>
                                            <img
                                                alt=""
                                                draggable="true"
                                                style={{
                                                    width: piece.previewSize
                                                        .width,
                                                    maxHeight: '150px',
                                                    display: 'block',
                                                    margin: '10px auto'
                                                }}
                                                piece={piece.id}
                                                pwidth={piece.size.width}
                                                pheight={piece.size.height}
                                                onDragStart={(ev) =>
                                                    props.dragStart(ev)
                                                }
                                                src={piece.image}
                                            />
                                        </div>
                                    </ListItem>
                                )
                            }
                        })}
                    </List>
                </ListItem>
            </List>
        </Drawer>
    )
}

export default PieceDrawer
