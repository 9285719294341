import React from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';





const useStyles = makeStyles({
    tCell: {
        '&:hover': {
        backgroundColor: 'rgba(103, 58, 183, 0.3)'
        },
        fontSize: "20px",        
    },
    normalTCell: {
        fontStyle: "italic",
        fontSize: "14px"
    },
    selectedtCell: {
        fontWeight: "bold"
    }

    });



const TurnDisplay = (props) => {
    
    

    return (
    <div>
        Current Turn: <span style={{fontWeight:"bold", fontSize: "1.3em"}}>{props.session.groups[props.turn].title}</span>
    </div>
    );
}


export default TurnDisplay
