import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const DistortionLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>This experiential activity requires participants to communicate a picture to someone else who will build the picture using tangram pieces.
                 Based on roles in the activity, different views of the information are available to the different users.
                   Participants use their communication skills to accomplish the task.
                    This activity can also be used to examine our limiting beliefs about what we have control over. </Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Build clear communication skills</li>
                    <li className={classes.properListItem}>Improve team cohesion by planning how to complete a task</li>
                    <li className={classes.properListItem}>Reframing mindset about what we have control over</li>
                    <li className={classes.properListItem}>Using common language to share information</li>
                    <li className={classes.properListItem}>Supporting team members in successful completion of a task</li>
                    <li className={classes.properListItem}>Delivering customer satisfaction and achieving results</li>
                    <li className={classes.properListItem}>Step in to help out as soon as a team member is off track</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>In this activity we will be working on our communication skills and thinking about what we have control over </li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
                <Typography variant="body1" className={classes.LNProperStart}>Teams will work together to put together a set of tangram pieces so that they match the contractor’s design.</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>3-4 on a team
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>One person is the Lead engineer</li>
                            <li className={classes.properListItem}>One person is the Contractor</li>
                            <li className={classes.properListItem}>The rest are Support engineers (1-2)</li>
                        </ul>
                    </li>
                    <li className={classes.properListItem}>The lead Engineer will be able to see a bunch of tangram pieces</li>
                    <li className={classes.properListItem}>The contractor will be able to see a picture/design</li>
                    <li className={classes.properListItem}>The contractor and lead engineer will talk to each other to try to match the tangram pieces to the design</li>
                    <li className={classes.properListItem}>Support Engineers will be able to see both the contractor picture and the tangram pieces <b>BUT CAN NOT SPEAK</b> unless they tap in and trade places with the Lead Engineer</li>
                    <li className={classes.properListItem}><b>ONLY THE CONTRACTOR and LEAD ENGINEER CAN SPEAK</b> to each other. Support engineers may not communicate verbally or nonverbally unless they tap in and switch places with the Lead Engineer</li>
                    <li className={classes.properListItem}>Press the “Finished” button when you think you are finished</li>  
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief Round 1:</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Contractors what was your role like in this exercise?</li>
                    <li className={classes.properListItem}>Seated Engineers how did you feel in this exercise?</li>   
                    <li className={classes.properListItem}>Standing Engineers how did you feel in this exercise?</li>   
                </ul>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What were the things that you had no control over in that first round? </li>
                    <li className={classes.properListItem}>Record your answers</li>   
                    <li className={classes.properListItem}>What did you have control over? </li>   
                    <li className={classes.properListItem}>Talk at your table</li>                              
                </ul>
                <Typography variant="h5">Did not have control</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Interpretation of the instructions</li>
                    <li className={classes.properListItem}>Nothing!</li>   
                    <li className={classes.properListItem}>Time</li>   
                    <li className={classes.properListItem}>Language</li>
                    <li className={classes.properListItem}>Rules</li>                              
                    <li className={classes.properListItem}>Language of geometry</li>                              
                    <li className={classes.properListItem}>Orientation</li>                              
                </ul>
                <Typography variant="h5">What did you have control over?</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Tapping in</li>
                    <li className={classes.properListItem}>Amount of questions the contractor could ask</li>   
                    <li className={classes.properListItem}>movement</li>   
                </ul>
                <Typography variant="h5">Round 2</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>How will you improve your process, take control and cut your time in half?</li>                              
                </ul>
                <Typography variant="h5">Prepare for Round 2 - ideas</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Use common language</li>
                    <li className={classes.properListItem}>Tap in frequently</li>   
                    <li className={classes.properListItem}>Affirm the speaker</li>   
                    <li className={classes.properListItem}>Use words picture so make the complex simple</li>
                    <li className={classes.properListItem}>Give the big picture upfront</li>
                    <li className={classes.properListItem}>Do frequent understanding checks</li>
                    <li className={classes.properListItem}>Plan to understand</li>
                    <li className={classes.properListItem}>Ask questions</li>                              
                </ul>
                <Typography variant="h5">Distortion – Team Discussion</Typography>
                <Typography variant="h5">Round 2 Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>How did you improve your process to improve your time and ensure understanding?</li>
                    <li className={classes.properListItem}>What did you not have control over? </li>   
                    <li className={classes.properListItem}>What did you have control over?</li>   
                </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
            
           
        </div> 
    )
}

export default withStyles(styles)(DistortionLN);