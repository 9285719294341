import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';


const EmotionalIntelligenceLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>This activity is used to practice the Emotional Intelligence (EI) model to ensure understanding and comprehension.
                 The EI model comes to life as participants review a series of scenarios of each quadrant demonstrating various levels of EI.
                  This helps participants identify the EI behaviours of each quadrant. </Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Clear understanding and identification of the Emotional Intelligence Model</li>
                    <li className={classes.properListItem}>Developing effective relationships using Emotional Intelligence</li>
                    <li className={classes.properListItem}>Increase understanding of Emotional Intelligence and the link to effective leadership </li>
                    <li className={classes.properListItem}>Improving organizational performance through Emotional Intelligence</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <Typography variant="h5">Our Two Minds</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>Our two minds – always competing</b></li>
                </ul>    
                <Typography variant="h5">Rational</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Weighing the costs and the benefits</li>
                    <li className={classes.properListItem}>Organizing our behaviors to suit us</li>
                </ul>
                <Typography variant="h5">Emotional</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Impulsive: letting emotions dominate our decision making</li>
                    <li className={classes.properListItem}>Reactive - in the moment</li>
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b>Voices overcrowd each other, and though we think about it, we typically act on our immediate emotional state</b></Typography>   
            
                <Typography variant="body1" className={classes.LNProperStart}><b>Books began being published in the mid 90’s and since then young students have been introduced to emotional intelligence studies have been done on this group and this is what they found:</b></Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Disruptive behaviour  (anti social behaviour) down 10%</li>
                    <li className={classes.properListItem}>Pro social behavior – linking school – up 10%</li>
                    <li className={classes.properListItem}>Academic achievement - up  11%</li>
                </ul> 
                <Typography variant="h5">Intelligence consists of:</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>IQ</li>
                    <li className={classes.properListItem}>EQ</li>
                    <li className={classes.properListItem}>Personality</li>
                </ul>
                <Typography variant="h5">EI Model</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Self Awareness– moral compass</li>
                    <li className={classes.properListItem}>Self-Management – handle your distressing emotions in effective way not crippling; every emotion has a function</li>
                    <li className={classes.properListItem}>Empathy – know what someone else is feeling </li>
                    <li className={classes.properListItem}>Relationship Management (or social skills) – bringing it all together</li>
                </ul>      
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Participants will work in small groups (3-4 people)</li>
                    <li className={classes.properListItem}>Each group will read through 8 profile cards</li>
                    <li className={classes.properListItem}>For each profile card, they will identify the Core Skills (done well/not done well)</li>
                    <li className={classes.properListItem}>After deciding what core skill is reflected well or not well on each card, they will move that card to the appropriate quadrant of the EI model</li>
                </ul>
                <Typography variant="h5">Answers</Typography>     
                <ul className={classes.properList}>
                    <li >Joanna – Self-Awareness</li>
                    <li>Roger – Lack of Self-Awareness </li>
                    <li>Miriam – Social-Awareness</li>
                    <li>Marco – Lack of Social-Awareness </li>
                    <li>Rue– Self-Management</li>
                    <li>Steve – Lack of Self-Management </li>
                    <li>Jacob – Relationship Mgmt</li>
                    <li>Nora – Lack of Relationship Mgmt</li>
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>How can you take some of these ideas and apply it in your own work?</li>
                            <li className={classes.properListItem}>What stands out for you from these ideas and success stories?</li>   
                            <li className={classes.properListItem}>Where are there opportunities to collaborate?</li>                                
                        </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
          
           
        </div> 
    )
}

export default withStyles(styles)(EmotionalIntelligenceLN);