import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import React, { Component } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { getFirebase } from 'react-redux-firebase'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
    startGame,
    getActivityLogo,
    saveActivityOptions,
    doneUpdatingImage,
    saveSpecificActivityOption,
    saveActivityOptionFile,
    newActivityOptionSet,
    setSelectedOptionSet,
    saveSpecificActivityOptionPath,
    saveSpecificActivityOptionName,
    saveActivityOptionSetFile
} from '../../store/actions/gameActions'
import styles from '../Styles'

import TextField from '@material-ui/core/TextField'
import { DropzoneDialog } from 'material-ui-dropzone'

import { gameOptions } from './../Game/gameServer'

import GameOptionsList from './game_options_components/game_options_list'
import GameOptionsSet from './game_options_components/game_options_set'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'

const INITIAL_STATE = {
    optionsDisplay: undefined,
    deleteDialogOpen: false,
    optionToDelete: false,
    otherRef: undefined,
    imageOpen: false,
    logoURLs: {},
    imagesLoaded: false,
    isSaving: false,
    view: 'list',
    setId: null
}

const ImageFileList = ({ files }) => {
    const filenames = files.map((f) => {
        return f.name
    })

    return (
        <p>
            <span style={{ fontWeight: 'bold' }}>New Image:</span> {filenames}
        </p>
    )
}

class GameOptions extends Component {
    constructor(props) {
        super(props)
        let o = null

        this.state = {
            ...INITIAL_STATE
        }
    }

    startNewGame = (index, name, game) => {
        this.props.startGame(index, name, game)
    }

    handleImageClose = (event) => {
        this.setState({
            imageOpen: false
        })
    }

    handleImageOpen = (event) => {
        this.setState({
            imageOpen: true
        })
    }

    imageChanged = (files, key) => {
        this.setState({
            ...this.state,
            imageOpen: false
        })
        this.props.saveActivityOptionFile(
            key,
            files,
            this.props.game_id,
            this.props.game_name,
            'game_images'
        )
    }

    saveText = (event, key) => {
        const t = this.state.game_texts[key]
        this.setState({
            ...this.state,
            isSaving: true
        })
        if (!this.props.options.hasOwnProperty(this.props.auth.uid)) {
            this.saveOptions(false)
        } else {
            this.props.saveSpecificActivityOption(
                key,
                t,
                this.props.game_id,
                this.props.game_name,
                'game_texts'
            )
        }
    }

    saveMisc = (event, key) => {
        const t = this.state.game_misc[key]
        this.setState({
            ...this.state,
            isSaving: true
        })
        if (!this.props.options.hasOwnProperty(this.props.auth.uid)) {
            this.saveOptions(false)
        } else {
            this.props.saveSpecificActivityOption(
                key,
                t,
                this.props.game_id,
                this.props.game_name,
                'game_misc'
            )
        }
    }

    saveOption = (doc, hostID, game_id, type, key, val) => {
        this.props.saveSpecificActivityOptionPath(
            doc,
            hostID,
            game_id,
            type,
            key,
            val
        )
    }
    saveOptionSetName = (doc, hostID, game_id, type, val) => {
        this.props.saveSpecificActivityOptionName(
            doc,
            hostID,
            game_id,
            type,
            val
        )
    }

    saveOptionSetFile = (doc, hostID, key, file, game_id) => {
        this.props.saveActivityOptionSetFile(doc, hostID, key, file, game_id)
    }

    createNewSet = () => {
        /*this.setState({
      ...this.state,
      view: 'set',
      setId: null
    })*/
        this.props.newActivityOptionSet(
            this.props.auth.uid,
            this.props.game_id,
            this.props.options.default
        )
    }

    setSelectedSet = (e, cur, next) => {
        e.preventDefault()
        e.stopPropagation()
        //console.log(cur, next);
        if (cur === next) {
            return
        }
        this.props.setSelectedOptionSet(
            this.props.auth.uid,
            this.props.game_id,
            next
        )
    }

    editSet = (setId) => {
        this.setState({
            ...this.state,
            view: 'set',
            setId: setId
        })
    }

    returnToSetList = () => {
        this.setState({
            ...this.saveText,
            view: 'list',
            setId: null
        })
    }

    handleOpenDeleteDialog = (setID) => {
        this.setState({
            ...this.state,
            deleteOptionSet: setID,
            deleteDialogOpen: true
        })
    }
    handleCloseDeleteDialog = () => {
        this.setState({
            ...this.state,
            deleteOptionSet: false,
            deleteDialogOpen: false
        })
    }
    deleteOptionSet = () => {
        console.log(
            'Values: ',
            this.state.deleteOptionSet,
            this.props.auth.uid,
            this.props.game.id,
            'isDeleted',
            true
        )

        this.props.saveSpecificActivityOptionName(
            this.state.deleteOptionSet,
            this.props.auth.uid,
            this.props.game.id,
            'isDeleted',
            true
        )
        this.handleCloseDeleteDialog()
    }

    render() {
        const { auth, options, game_name, classes } = this.props

        let o = null
        if (options[auth.uid] !== undefined) {
            // we have host specific saved options
            o = options[auth.uid]
        } else {
            // we want to use the default
            o = options.default
        }

        let g = gameOptions(this.props.game_id)

        return (
            <div>
                <Backdrop
                    className={classes.backdrop}
                    open={this.props.game_state.isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography variant="h4" className={classes.spacingTop}>
                    Options
                </Typography>
                <Typography variant="body2">{game_name}</Typography>

                <Dialog
                    open={this.state.deleteDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This action will{' '}
                            <span className={classes.boldedText}>
                                delete this option set.
                            </span>{' '}
                            <br />
                            <br />
                            Are you sure you want to do this?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleCloseDeleteDialog}
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.deleteOptionSet}
                            variant="contained"
                            color="primary"
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.state.view === 'list' ? (
                    <GameOptionsList
                        startNewGame={this.startNewGame}
                        editSet={this.editSet}
                        setSelectedSet={this.setSelectedSet}
                        myCollection={this.props.user_options}
                        optionsDefault={this.props.options.default}
                        optionsMine={
                            this.props.options[this.props.auth.uid] ===
                            undefined
                                ? {}
                                : this.props.options[this.props.auth.uid]
                        }
                        game_name={game_name}
                        returnToGameList={this.props.returnToGameList}
                        game={this.props.game}
                        classes={classes}
                        createNewSet={this.createNewSet}
                        deleteSet={this.handleOpenDeleteDialog}
                    />
                ) : (
                    <GameOptionsSet
                        g={g}
                        startNewGame={this.startNewGame}
                        saveActivityOptionSetFile={this.saveOptionSetFile}
                        hostID={this.props.auth.uid}
                        saveOptionSetName={this.saveOptionSetName}
                        saveOption={this.saveOption}
                        returnToSetList={this.returnToSetList}
                        set={this.state.setId}
                        myCollection={this.props.user_optionsO}
                        game_name={game_name}
                        returnToGameList={this.props.returnToGameList}
                        game={this.props.game}
                        optionsDefault={this.props.options.default}
                        optionsMine={
                            this.props.options[this.props.auth.uid] ===
                            undefined
                                ? {}
                                : this.props.options[this.props.auth.uid]
                        }
                        classes={classes}
                    />
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        game_state: state.game_state,
        user_options: state.firestore.ordered.user_options,
        user_optionsO: state.firestore.data.user_options
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startGame: (creds, name, game) =>
            dispatch(startGame(creds, name, game)),
        getActivityLogo: (url) => dispatch(getActivityLogo(url)),
        saveActivityOptions: (options, file, game_id, game_name, shouldStart) =>
            dispatch(
                saveActivityOptions(
                    options,
                    file,
                    game_id,
                    game_name,
                    shouldStart
                )
            ),
        doneUpdatingImage: () => dispatch(doneUpdatingImage()),
        saveSpecificActivityOption: (key, t, game_id, game_name, property) =>
            dispatch(
                saveSpecificActivityOption(key, t, game_id, game_name, property)
            ),
        saveActivityOptionFile: (key, file, game_id, game_name, property) =>
            dispatch(
                saveActivityOptionFile(key, file, game_id, game_name, property)
            ),
        newActivityOptionSet: (hostID, game_id, defaultOptions) =>
            dispatch(newActivityOptionSet(hostID, game_id, defaultOptions)),
        setSelectedOptionSet: (hostID, game_id, setDoc) =>
            dispatch(setSelectedOptionSet(hostID, game_id, setDoc)),
        saveSpecificActivityOptionPath: (
            doc,
            hostID,
            game_id,
            type,
            key,
            val
        ) =>
            dispatch(
                saveSpecificActivityOptionPath(
                    doc,
                    hostID,
                    game_id,
                    type,
                    key,
                    val
                )
            ),
        saveSpecificActivityOptionName: (doc, hostID, game_id, type, val) =>
            dispatch(
                saveSpecificActivityOptionName(doc, hostID, game_id, type, val)
            ),
        saveActivityOptionSetFile: (doc, hostID, key, file, game_id) =>
            dispatch(saveActivityOptionSetFile(doc, hostID, key, file, game_id))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles),
    withTranslation(),
    firestoreConnect((props) => {
        return [
            {
                collection: 'user_options',
                where: [
                    ['uid', '==', props.auth.uid],
                    ['game', '==', props.game_id]
                ]
            }
        ]
    })
)(GameOptions)
