import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const WalkAwhileInMyShoesLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}>Using the book authored by Eric Harvey and Steve Ventura, participants gain a deeper understanding of the perspectives of employees versus managers on a variety of topics.
                 In small groups, teams will read through a series of examples that build empathy, understanding and cooperation between different levels in an organization.
                  This provides a new level of insight to participants.  Specific topics can be chosen to focus on.</Typography>

            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Appreciate that each area of work in no easier than another</li>
                    <li className={classes.properListItem}>Don’t assume the worst of a manager or an employee</li>
                    <li className={classes.properListItem}>Continue to perform your job as best you can and share insights along the journey</li>
                    <li className={classes.properListItem}>Adopt the mindset that to be successful at work, we need managers and employees</li>                
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Often bosses and employees struggle with the same issues but have different perspectives on the same topic </li>
                    <li className={classes.properListItem}>This exercise helps us think about how to find common ground and see things from the other point of view</li>
                    <li className={classes.properListItem}>This is a reflective exercise and provides an opportunity to think about our own automatic judgements and how to shift our thinking to increase understanding and empathy</li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>In small groups, read through the two perspectives for each topic</li>
                    <li className={classes.properListItem}>Record your learnings from the readings</li>
                </ul>
                <Typography variant="h5"> </Typography>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Summary from the Book about how to create Empathy </b></Typography>
                <Typography variant="body1"  className={classes.LNProperStart}><b>What I Ask of You</b></Typography>      
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Appreciate that my work in no Easier than yours. </li>
                    <li className={classes.properListItem}>Don’t assume the worst of Me.</li>
                    <li className={classes.properListItem}>Continue to Perform your job as best you can. </li>
                    <li className={classes.properListItem}>Adopt the mindset that to be successful at work, you need me as much as I need you. </li>
                    <li className={classes.properListItem}>Assume half The responsibility for our working relationship. </li>
                    <li className={classes.properListItem}>Remember that I’m Human. </li>
                    <li className={classes.properListItem}>If You do, I think you’ll find…</li>
                    <li className={classes.properListItem}>With more understanding and great appreciation, we can meet in the middle and walk toward a more successful future TOGETHER</li>
                </ul>
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>•	What did you notice from reading these perspectives? </li>
                    <li className={classes.properListItem}>•	How does this relate to your role at work? </li>
                    <li className={classes.properListItem}>•	What can you do to increase your level of empathy for others on your team? </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
           
           
        </div> 
    )
}

export default withStyles(styles)(WalkAwhileInMyShoesLN);