import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper, determineHost } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import { CardList } from '../cards/cardList';


import ForwardIcon from '@material-ui/icons/Forward';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  
  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }


const INITIAL_STATE = {
    open: false,
    tab: 0,
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

  

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleTabChange = (event, newValue) => {
    console.log('????', event, newValue);
        this.setState({
            ...this.state,
            tab: newValue
        })
    };


    render() {
    const {
      session,
      classes,
      auth,
      profile
    } = this.props;
    const { diff } = this.state;


        return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{session.active_game.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                <Paper square className={classes.root}  style={{minHeight: "700px", minWidth: "920px"}}>
                        <AppBar position="static">
                        <Tabs
                        value={this.state.tab}
                        onChange={this.handleTabChange}
                        variant="fullWidth"
                        indicatorColor="secondary"
                        
                        aria-label="icon label tabs example"
                        >
                        { Object.keys(session.groups).sort().map((groupKey, ind) => {
                            if ( groupKey !== 'group-0' ){
                                return (
                                    <Tab label={session.groups[groupKey].title} key={ind-1} />
                                )
                            } else {
                                return null;
                            }
                        })}
                    
                        </Tabs>
                        </AppBar>
                        { Object.keys(session.groups).sort().map((groupKey, ind) => {
                            let results = session.active_game.results[groupKey] === undefined ? {} : session.active_game.results[groupKey];

                            let res1 = results[1] === undefined ? [] : results[1];
                            let res2 = results[2] === undefined ? [] : results[2];
                            let res3 = results[3] === undefined ? [] : results[3];
                            let res4 = results[4] === undefined ? [] : results[4];
                            let res5 = results[5] === undefined ? [] : results[5];

                            if ( groupKey !== 'group-0' ){
                                
                               
                                return (
                                    <TabPanel value={this.state.tab} index={ind-1} key={ind-1} >
                                        <div style={{padding: "8px", color: "#fff", background: "#2e75b5"}}><Typography variant="h5">5 (Always)</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res5.length > 0 ?
                                                    res5.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>

                                        <div style={{padding: "8px", color: "#fff", background: "#51c3a1"}}><Typography variant="h5">4</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res4.length > 0 ?
                                                    res4.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>

                                        <div style={{padding: "8px", color: "#fff", background: "#fff100"}}><Typography variant="h5">3 (Sometimes)</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res3.length > 0 ?
                                                    res3.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>
                                        
                                        <div style={{padding: "8px", color: "#fff", background: "#f78f19"}}><Typography variant="h5">2</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res2.length > 0 ?
                                                    res2.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>

                                        <div style={{padding: "8px", color: "#fff", background: "#f14a24"}}><Typography variant="h5">1 (Never)</Typography></div>
                                        <div style={{marginTop: "15px", marginBottom: "30px", padding: "5px 20px"}}>
                                            { 
                                                res1.length > 0 ?
                                                    res1.map((card, ind) => {
                                                        let the_card = CardList.filter(v => v.id === card )[0];
                                                        return (
                                                            <div key={ind} style={{marginBottom:"15px"}}>
                                                                <div style={{fontWeight: "bold"}}>Trust Card #{card}</div>
                                                                <div><Typography variant="body1">{the_card.card_words}</Typography></div>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                <div>No items to display.</div>
                                            }
                                        </div>
                                    </TabPanel>
                                )
                            } else {
                                return null;
                            }
                            
                        })}                    
                    </Paper>    
                </Grid>

            </Grid>
        </div>
        )
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
