import React, { Component } from 'react'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import styles from '../../../../Styles'

const PLUS = 'plus'
const PLUS_FS = 'Success'

class ActivityListPlus extends Component {
    render() {
        const { session, profile, auth, group_id, classes, debrief, ind } =
            this.props

        let t = PLUS
        let t_fs = PLUS_FS
        if (ind !== undefined) {
            t = `${t}_${ind}`
            t_fs = `${t_fs}_${ind}`
        }
        return (
            <List>
                {session.active_game.results.hasOwnProperty(group_id)
                    ? session.active_game.results[group_id].hasOwnProperty(t)
                        ? session.active_game.results[group_id][t].map(
                              (item, index) => {
                                  if (debrief === false) {
                                      return this.props.hasGameControl(
                                          session.groups[group_id].leadPlayer,
                                          auth.uid,
                                          profile
                                      ) ? (
                                          <ListItem
                                              key={index}
                                              button
                                              onClick={() =>
                                                  this.props.itemAction(
                                                      index,
                                                      item,
                                                      t,
                                                      t_fs
                                                  )
                                              }
                                          >
                                              <div
                                                  dangerouslySetInnerHTML={{
                                                      __html: item
                                                  }}
                                                  className={
                                                      classes.bodyListText
                                                  }
                                              ></div>
                                          </ListItem>
                                      ) : (
                                          <ListItem key={index}>
                                              <div
                                                  dangerouslySetInnerHTML={{
                                                      __html: item
                                                  }}
                                                  className={
                                                      classes.bodyListText
                                                  }
                                              ></div>
                                          </ListItem>
                                      )
                                  } else {
                                      return (
                                          <ListItem key={index}>
                                              <div
                                                  dangerouslySetInnerHTML={{
                                                      __html: item
                                                  }}
                                                  className={
                                                      classes.bodyListText
                                                  }
                                              ></div>
                                          </ListItem>
                                      )
                                  }
                              }
                          )
                        : null
                    : null}
            </List>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(ActivityListPlus)
