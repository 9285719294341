const initState = {
    game_state: null,
    isLoading: false,
    footer_loading: false,
    newImage: false,
    error: null,
};

const gameReducer = (state = initState, action) => {
    switch (action.type) {
        case "GAME_ZERO_OUT":
            return {
                ...state,
                isLoading: false
            }
        case "LOADING_DONE":
            return {
                ...state,
                isLoading: false
            }
        case "OPTIONS_SAVE_LOADING":
            return {
                ...state,
                ...action.s,
                isLoading: true,
            }    
        case "OPTIONS_SAVE_DONE":
            return {
                ...state,
                ...action.s,
                isLoading: false,
                newImage: action.newImage
            }
        case "OPTIONS_IMAGE_RELOADED":
            return {
                ...state,
                ...action.s,
                isLoading: false,
                newImage: false
            }    
        
        case "GAME_CREATED":
            return {
                ...state,
                isLoading: false,
            }              
        case "FOOTER_LOADING":
            return {
                ...state,
                footer_loading: true
            }
        case "FOOTER_LOADING_DONE":
            return {
                ...state,
                footer_loading: false
            }
        case "GAME_GROUPS_UPDATED":
            return {
                ...state,
                isLoading: false,
                game_state: 'group_update'
            };
        case "GAME_GROUPS_UPDATE_ERROR":
            return {
                ...state,
                game_state: 'error',
                isLoading: false,
                error: action.err.message
            };
        case "GAME_STARTED":
            return {
                ...state,
                isLoading: false
            };   
        case "GAME_START_ERROR":
            return {
                ...state,
                isLoading: false,
                game_state: 'error',
                error: action.err.message
            }; 
        case "HOST_STATE_UPDATED":
            return {
                ...state,
                isLoading: false
            };   
        case "HOST_STATE_UPDATE_ERROR":
            return {
                ...state,
                isLoading: false,
                game_state: 'error',
                error: action.err.message
            }; 
        case "GROUP_STATE_UPDATED":
            return {
                ...state,
                isLoading: false
            };   
        case "GROUP_STATE_UPDATE_ERROR":
            return {
                ...state,
                isLoading: false,
                game_state: 'error',
                error: action.err.message
            }; 
        case "LOADING":
            return {
                ...state,
                isLoading: true
            };
        default :
            return state;
    }
};

export default gameReducer;