import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import ActivityListPlus from '../specific_components/activityListPlus';
import ActivityListDelta from '../specific_components/activityListDelta';
import ActivityListPlusTwo from '../specific_components/activityListPlusTwo';
import ActivityListDeltaTwo from '../specific_components/activityListDeltaTwo';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
    }

    moveToFloorExercise = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor' });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.session.active_game.resultSnapshot === undefined ? {} : this.props.session.active_game.resultSnapshot, this.props.session.active_game.results)
        })
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      session,
      classes,
      auth,
      profile
    } = this.props;

        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{session.active_game.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>

                <Typography variant="h6">Questions</Typography>
                <Typography variant="body1">1. What types of universal learning will benefit cross functional areas? (Consider curriculum for a broader audience.)</Typography> 
                <Typography variant="body1">2. What needs to be in place to ensure success of the project and the final University learning?</Typography>
                <Typography variant="body1">3. What potential obstacles will get in the way of success and how will you overcome these things?</Typography>
                <Typography variant="body1">4. How are you willing to support the learning structure of Architectural University?</Typography>   
                          
                { /*: null */ }
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(session.active_game.results)
                .sort()
                .map((groupKey, ind) => {
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[groupKey].title}</Typography>
                        
                        <Grid container spacing={2}>                                  
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}  align="center" className={classes.tableHead}>  
                                    Solutions (#1)
                                </Grid>
                                <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListPlus session={session} profile={profile} auth={auth} group_id={groupKey} debrief={true} hasGameControl={false} showList={true}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}  align="center" className={classes.tableHead}>   
                                    Challenges (#3)  
                                </Grid>  
                                <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListDelta session={session} profile={profile} auth={auth} group_id={groupKey} debrief={true} hasGameControl={false} showList={true} />     
                                </Grid>  
                            </Grid>  
                        </Grid>
                        <Grid container spacing={2}>        
                            <Grid item xs={12} sm={6}>
                                <Grid item xs={12}  align="center" className={classes.tableHead}>  
                                    Success (#2)
                                </Grid>
                                <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListPlusTwo session={session} profile={profile} auth={auth} group_id={groupKey} debrief={true} hasGameControl={false} showList={true}/>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <Grid item xs={12}  align="center" className={classes.tableHead}>   
                                    Your Role (#4)  
                                </Grid>  
                            <Grid item xs={12}  align="center" className={classes.tableBody}>
                                    <ActivityListDeltaTwo session={session} profile={profile} auth={auth} group_id={groupKey} debrief={true} hasGameControl={false} showList={true} />     
                            </Grid>  
                            </Grid>  
                        </Grid>
                    </div>
                    );
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
