import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';
import { connect } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { determineHost } from './../Game/games/game_functions/functions';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { removePlayer, changeLeader, removePlayerProfile, removeAllPlayers } from '../../store/actions/gameActions';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import styles from '../Styles';
import { deepPurple } from '@material-ui/core/colors';

const INITIAL_STATE = {
  open: false,
};

export const getPlayersList = (session) => {
  let players_list = [];
  Object.keys(session.playerProfiles).map((p, ind) => {
    players_list.push(session.playerProfiles[p]);
    return null;
  });
  return players_list;
}
export const getPlayersListO = (session) => {
  return session.playerProfiles;
}

const StyledBadge = withStyles((theme) => ({
  badge: {
    background: deepPurple[500],
    color: theme.palette.getContrastText(deepPurple[500]),
  },
}))(Badge);

class PlayersBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
  }
  handleDrawerOpen = () => {
    this.setState({
      ...this.state,
      open: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      open: false,
    });
  };

  changeLeader = (group, player, host) => {
    this.props.changeLeader(group, player, host);
  };

  removePlayer = (player, groupKey, host) => {
    console.log('removing a player', player, groupKey, host);
    this.props.removePlayer(player, groupKey, getPlayersListO(this.props.session), this.props.session.active_game.id === undefined ? false : true, host);
    setTimeout(() => { 
      //this.props.removePlayerProfile(player)
    }, 1000);
  };

  removeAllPlayers = () => {
    if ( window.confirm('Are you sure you want to do this? All players will be removed from the session, and this action cannot be undone.') === true ){
      this.props.removeAllPlayers(determineHost(this.props.profile, this.props.auth));
    }
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) { }

  componentWillUnmount() { }

  render() {
    const { open } = this.state;
    const { players, auth, profile, classes, session } = this.props;

    const players_list = getPlayersList(session);
    const players_list_O = session.playerProfiles;

    //console.log('players list o: ', players_list_O);

    if (players_list && players_list_O) {
      if (session !== undefined && session.groups !== undefined && session.groups['group-0'] !== undefined) {
        return (
          <div className={classes.menuContain}>
            <div className={`${classes.buttonContain} ${classes.extraPaddingForIcon}`}>
              <StyledBadge badgeContent={players.length} aria-label='open players' onClick={this.handleDrawerOpen} className={clsx(open && classes.hide)}>
                <PeopleIcon/>
              </StyledBadge>
            </div>
            <Drawer
              className={classes.drawer}
              variant='persistent'
              anchor='right'
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}>
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronRightIcon className={classes.lightGreyText} />
                </IconButton>{' '}
                <span className={`${classes.playersTitle} ${classes.glassPurple}`}>Players ({players.length})</span>
              </div>              
              {Object.keys(session.groups)
                .sort()
                .map((group_key, ind) =>
                  session.groups[group_key].playerIds.length > 0 ? (
                    <div key={ind}>
                      <div className={`${classes.secondGroupTitle} ${classes.greyText}`}>{session.groups[group_key].title}</div>
                      <List>
                        {(session.groups[group_key].leadPlayer === auth.uid || profile.role === 'host' || profile.role === 'moderator')
                          ? session.groups[group_key].playerIds.map((player, index) => (
                              <ListItem key={index}>
                                {profile.role === 'host' ? (
                                  <ListItemIcon>
                                    <RemoveCircleIcon
                                      className={classes.red}
                                      onClick={() => {
                                        this.removePlayer(player, group_key, determineHost(this.props.profile, this.props.auth));
                                      }}
                                    />
                                  </ListItemIcon>
                                ) : null}
                                { group_key !== 'group-0' ?
                                session.groups[group_key].leadPlayer === player ? (
                                  <ListItemIcon>
                                    <StarIcon className={classes.goldColor}/>
                                  </ListItemIcon>
                                ) : (
                                  <ListItemIcon onClick={() => this.changeLeader(group_key, player, determineHost(this.props.profile, this.props.auth))}>
                                    <StarBorderIcon />
                                  </ListItemIcon>
                                )
                                : null }
                                <ListItemText primary={players_list_O[player] !== undefined ? players_list_O[player].displayName === undefined ? 'Entering Name..' : players_list_O[player].displayName : 'Entering Name...'} />
                              </ListItem>
                            ))
                          : session.groups[group_key].playerIds.map((player, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  {profile.role === 'host' ? (
                                    <RemoveCircleIcon
                                      className={classes.red}
                                      onClick={() => {
                                        this.removePlayer(player, group_key, determineHost(this.props.profile, this.props.auth));
                                      }}/>
                                  ) : (
                                    <PersonIcon className={classes.glassPurple} />
                                  )}
                                </ListItemIcon>
                                <ListItemText className={classes.greyText} primary={players_list_O[player] !== undefined ? players_list_O[player].displayName === undefined ? 'Entering Name..' : players_list_O[player].displayName : 'Entering Name...'} />
                              </ListItem>
                            ))}
                      </List>
                    </div>
                  ) : null
                )}
            </Drawer>
          </div>
        );
      } else {
        return (
          <div className={classes.menuContain}>
            <div className={classes.extraPaddingForIcon}>
            <Badge badgeContent={players.length} color='primary' aria-label='open players' onClick={this.handleDrawerOpen} className={clsx(open && classes.hide)}>
              <PeopleIcon/>
            </Badge>
            </div>
            <Drawer
              className={classes.drawer}
              variant='persistent'
              anchor='right'
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}>
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleDrawerClose}>
                  <ChevronRightIcon />
                </IconButton>{' '}
                <span className={classes.playersTitle}>Players ({players_list.length})</span>
              </div>
              {
                (profile.role === 'host' || profile.role === 'moderator') && session.active_game.id === undefined ?
                  <div className={classes.drawerHeader} style={{textAlign: "center"}}>        
                    <span onClick={() => this.removeAllPlayers()} className={`${classes.playersTitle}`} style={{fontSize: "0.8em", textDecoration: "underline", padding: "10px 0", cursor: "pointer", display: "inline-block"}}>Remove All Players</span>
                </div>
                : null
              }
              <Divider />
              <List>
                {Object.keys(players_list_O).map((player, index) => {
                  //console.log('player', player);
                  if ( !session.players.includes(player) ){
                    return null;
                  }
                   return (
                    <ListItem key={index}>
                      <ListItemIcon>
                        {profile.role === 'host' ? 
                          <RemoveCircleIcon
                          className={classes.red}
                            onClick={() => {
                              this.removePlayer(player, null, determineHost(this.props.profile, this.props.auth));
                            }}
                          />
                        : 
                          <PersonIcon />
                      }
                      </ListItemIcon>
                      <ListItemText primary={players_list_O[player].displayName ? players_list_O[player].displayName : 'Entering Name...'} />
                    </ListItem>
                  )
                 
                })}
              </List>
            </Drawer>
          </div>
        );
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    players_list: state.firestore.ordered.current_sessions[0].playerProfiles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removePlayer: (player, groupKey, playersList, isGameActive, host) => dispatch(removePlayer(player, groupKey, playersList, isGameActive, host)),
    changeLeader: (group, player, host) => dispatch(changeLeader(group, player, host)),
    removePlayerProfile: (player) => dispatch(removePlayerProfile(player)),
    removeAllPlayers: (host) => dispatch(removeAllPlayers(host))
  };
};

export default compose( connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(PlayersBase);