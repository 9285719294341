import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut, getModeratorHosts, updateModeratorHost } from '../../store/actions/authActions';
import { removePlayerSignOut } from '../../store/actions/gameActions';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import styles from './../Styles';
import { getUserGroup_hc } from './../../components/Game/games/game_functions/functions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LiquidLoadingBlock from './../Loading/loadingCup';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { deepPurple } from '@material-ui/core/colors';
import LogoAlt from './images/Virtual-Glass-Wordmark-OneLine_Clear.png';


const signOutLinks = (auth, session, t, classes) => {
  if ( auth === undefined ) {
    return false;
  }
  if ( window.location.pathname !== '/p' && window.location.pathname.substring(0, 3) !== '/p/' ){
    return true;
  }
  return false;
}

const INITIAL_STATE = {
  switchOpen: false,
  hostsLoaded: false
}

class Footer extends Component{
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

  }

  signUserOut = () => {
    if ( this.props.profile.role === 'host' || this.props.profile.role === 'moderator' ){
      this.props.signOut();
    } else {
      if ( this.props.session === undefined ){
        this.props.signOut();
      } else {
        if ( this.props.session.active_game.id === undefined ){
          this.props.removePlayerSignOut(this.props.auth.uid, getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role), this.props.session.playerProfiles, false);
        } else {
          this.props.removePlayerSignOut(this.props.auth.uid, getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role), this.props.session.playerProfiles, true);
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState){
    if ( prevProps.gol_auth.modInfo !== this.props.gol_auth.modInfo ){
      console.log('NEW INFO:', this.props.gol_auth.modInfo );
      this.setState({
        ...this.state,
        hostsLoaded: this.props.gol_auth.modInfo
      })
    }
  }

  openSwitchDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.getModeratorHosts(this.props.profile.moderatesFor)
    this.setState({
      ...this.state,
      switchOpen: true
    });

  }
  closeSwitchDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      ...this.state,
      switchOpen: false
    })
  }

  switchHost = (hostID) => {
    console.log("HOSTID:", hostID);
    this.props.updateModeratorHost(hostID);
    this.setState({
      ...this.state,
      switchOpen: false
    })
  }

  render() {

    const { t, classes, session, auth, profile, loading, hostInfo, gol_auth, theme } = this.props;
    //console.log('ze auth', gol_auth);
    const hInfo = hostInfo === undefined ? {} : hostInfo;

    let color = deepPurple[500];
    if ( theme !== undefined ){
      color = theme.color
    }

    return (
      <div className={`${classes.footer} footer`} style={{background: color, minHeight: "25vh" }}>
        <Dialog
              open={this.state.switchOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth='md' fullWidth
              onClose={this.closeSwitchDialog}
          >
              <AppBar position="static" className={classes.LNNoShadow}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={this.closeSwitchDialog} aria-label="close">
                <CloseIcon />
                </IconButton>
                    <Typography variant="h6">
                    Switch to a Different Host          
                    </Typography>
            </Toolbar>
            </AppBar>
              <DialogContent>
                <div style={{padding:"30px", flex: 1, flexDirection: "row", display: "flex"}}>
                  { this.state.hostsLoaded === false ?
                  <LiquidLoadingBlock/> : 
                    this.state.hostsLoaded.map((h, ind) => {
                        const c = h.id === profile.moderates ? 'current' : '';
                        return (
                          <div onClick={() => this.switchHost(h.id)} key={ind} style={{padding:"30px", border: "1px solid #000", flex: 1, marginRight: "10px", borderRadius: "5px", textAlign: "center", cursor: "pointer"}}><Typography variant="h6">{h.displayName}</Typography><div style={{fontStyle:"italic"}}>{c}</div></div>
                        )
                    })
                    
                  }

                 
                </div>
              </DialogContent>

          </Dialog>

      <div >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <Grid container spacing={3}>
                <Grid item sm={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid container item xs={6} spacing={1} className={classes.languageTop}>
                      <Grid item xl={1} lg={2} md={2} xs={2}></Grid>
                    </Grid>
                    
                    <Grid container spacing={2}> 
                      
                      <Grid item xs={12} sm={6}>
                        <Typography variant='body2' className={classes.copy}>
                          {t('footer.finePrintOne')}
                        </Typography>
                        <Typography variant='body2' className={classes.copy}>
                          {t('footer.finePrintTwo')}
                        </Typography>
                        <Typography variant='body2' className={classes.copy}>
                          {t('footer.finePrintThree', { year: moment().format('YYYY') })}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      { 
                          profile !== undefined ?
                            profile.role === "moderator" ?
                              <Typography style={{color:"#fff"}} variant="h6">Facilitating For: {hInfo.displayName}</Typography>                         
                            : null
                          : null
                        }
                        {
                          profile !== undefined ?
                            profile.moderatesFor !== undefined ?
                              <Typography  style={{color:"#fff"}}  variant="body2"><a href="#" onClick={(event) => this.openSwitchDialog(event)} style={{color:"#fff", textDecoration: "underline"}}>Switch to Other Host</a></Typography>
                            : null
                          : null
                        }
                        
                      </Grid>
                      <Grid item xs={12} sm={6}>
                      { profile !== undefined ?
                          profile.role === 'player' ?
                          <Typography variant="body2" className={classes.copy}>Welcome, <span style={{fontSize: "0.9rem"}}>{profile.displayName}</span> <span style={{textDecoration: "underline", fontSize: "0.6rem", cursor: "pointer"}} onClick={() => { window.location = '/name'; }}>(Change Name)</span></Typography>
                          :
                          <Typography variant="body2" className={classes.copy}>You are signed in as, <span style={{fontSize: "0.9rem"}}>{profile.displayName}</span> <span style={{textDecoration: "underline", fontSize: "0.6rem", cursor: "pointer"}} onClick={() => { window.location = '/name'; }}>(Change Name)</span></Typography>
                        : null }

                        { signOutLinks(auth, session, t, classes ) ? 
                          <div style={{marginTop: "15px"}}>
                            <Button onClick={() => this.signUserOut()} className={classes.white}>
                              {t('navigation.signOutButton')}
                            </Button>

                            <span style={{color: "#fff", display: "inline-block", padding: "0 8px"}}>|</span> 
                            <Button onClick={() => {window.open('https://virtual.glassoflearning.com/contact', '_blank').focus()}} className={classes.white}>
                            Contact Us
                          </Button>
                          </div>
                        : null }
                      </Grid>
                      <Grid item sm={6} xs={12} align="right">
                          <img src={LogoAlt} alt="Clear Logo" style={{width: "100%", maxWidth: "220px"}}/>
                      </Grid>
                    </Grid>


                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        
      </div>
        <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    loading: state.game_state.footer_loading,
    gol_auth: state.gol_auth
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    removePlayerSignOut: (player, group, playersList, isGameActive) => dispatch(removePlayerSignOut(player,group, playersList, isGameActive)),
    getModeratorHosts: (hosts) => dispatch(getModeratorHosts(hosts)),
    updateModeratorHost: (hostID) => dispatch(updateModeratorHost(hostID))
  };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDisptachToProps), withStyles(styles))(Footer);
