import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
//import { GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
//    HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS } from '../../../../store/actions/gameActions';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
//import ListItemIcon from '@material-ui/core/ListItemIcon';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import LiquidLoadingBlock from '../../../../Loading/loadingCup';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
import { moveToPhaseWithParamsOneGroup, GROUP_STATE_ACTIVE } from './../../../../../store/actions/gameActions';
import Button from '@material-ui/core/Button';
import { wordGroups } from './../data/data';
import LoadingDots from './../../game_components/loadingDots';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import WordActionCenter, { getPuzzle, getNextPuzzle, defaultDifficulty, defaultId } from './../specific_components/wordActionCenter.js';


class Results extends Component {
  /*
  constructor(props) {
    super(props);
  }
*/
    componentDidMount() {
        window.scrollTo(0, 0)
    }

  sendNext(){
      const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
      const host = determineHost(this.props.profile, this.props.auth);

      console.log("Session >>>>>>>>> ", this.props.session)
      const timeelapsed = this.props.session.active_game.groupStates[group_id].params.finishedOn - this.props.session.active_game.groupStates[group_id].params.startedOn;
      console.log('Time elapsed' , timeelapsed);
      let secondsElapsed = Math.floor(timeelapsed / 1000);

      if ( isNaN(secondsElapsed) ){
        secondsElapsed = 0;
      }

      const minutes = Math.floor(secondsElapsed / 60);
      const remainder = secondsElapsed % 60;

      let time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;

      let completions = [];

      let diff = defaultDifficulty;
      let pId = defaultId;
      if ( this.props.session.active_game.groupStates[group_id].params.puzzle !== undefined ){
          diff = this.props.session.active_game.groupStates[group_id].params.puzzle.puzzleDifficulty;
          pId = this.props.session.active_game.groupStates[group_id].params.puzzle.puzzleId;
      }

      let completion =  { 
        puzzleDifficulty: diff,
        puzzleId : pId,
        finishedIn: time
      };
      if ( this.props.session.active_game.groupStates[group_id].params.completions !== undefined ){        
        completions = [
          ...this.props.session.active_game.groupStates[group_id].params.completions,
        ];
      }
      completions.push(completion);
      
       
      let nextData = getNextPuzzle(this.props.session, wordGroups, group_id);
      let params = {
        'puzzle' : {
            'puzzleDifficulty' : nextData.puzzleDifficulty,
            'puzzleId' : nextData.puzzleId
        },
        'startedOn' : Date.now(),
        'completions' : completions
      };

      this.props.moveToPhaseWithParamsOneGroup(this.props.session, host, params, GROUP_STATE_ACTIVE, group_id);
  }

  render() {
    const {
      //game_id, game_title,
      session,
      classes,
      profile,
      auth
      //profile, auth, group_state, game_state
    } = this.props;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    const timeelapsed = session.active_game.groupStates[group_id].params.finishedOn - session.active_game.groupStates[group_id].params.startedOn;
    let secondsElapsed = Math.floor(timeelapsed / 1000);

    if ( isNaN(secondsElapsed) ){
      secondsElapsed = 0;
    }

    const minutes = Math.floor(secondsElapsed / 60);
    const remainder = secondsElapsed % 60;

    const results = session.active_game.results[group_id] === undefined ?
                      {} :
                      session.active_game.results[group_id].choices === undefined ?
                      {} :
                      session.active_game.results[group_id].choices;

    const host = determineHost(profile, auth);
    let data = getPuzzle(this.props.session, wordGroups, group_id);
    let nextData = getNextPuzzle(this.props.session, wordGroups, group_id);
    console.log("session in results.js render >>>>>>", this.props.session);
    console.log('NEXT DATA', nextData);

    return (
      <div>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5" >You're Done!</Typography>
              <Typography variant="body1" className={classes.spacingBottom}>You completed the puzzle in <span style={{fontWeight: "bold"}}>{ minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds` }</span>.</Typography>
              <WordActionCenter session={session} hasControl={false} profile={profile} host={host} auth={auth} wordsList={data.words} group_id={group_id} correct_order={data.correct_order} associations={data.associations} results={results} />
              <div className={classes.spacingTop}>
                  {
                    nextData === false ? 
                      <div>
                        <Typography variant="h5">Congratulations!</Typography>
                        <Typography variant="body1">You have completed all puzzles.</Typography>
                      </div>
                    :
                    <Button variant="contained" color="primary" onClick={() => this.sendNext()}>Proceed to Next Puzzle</Button>
                  }
                  
              </div>
            </Grid>
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveToPhaseWithParamsOneGroup: (session, host, parameters, groupState, group) => dispatch(moveToPhaseWithParamsOneGroup(session, host, parameters, groupState, group)),
  };
  
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Results);
