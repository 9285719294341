import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import GameServer from '../gameServer';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

class GameDashboard extends Component {

  render() {
    const { game_id, game_title, session, profile, auth, group_state, game_state, game_options } = this.props;

    return <GameServer game_options={game_options} game_id={game_id} game_title={game_title} session={session} profile={profile} auth={auth} game_state={game_state} group_state={group_state} view='dashboard' />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameDashboard);
