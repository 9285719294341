import { deepPurple, grey, red } from '@material-ui/core/colors'
import { CardCategoryColors } from '../Game/games/discCards/cards/cardList'
import {
    CARD_CAT_DOMINANCE,
    CARD_CAT_INFLUENCE,
    CARD_CAT_STEADINESS,
    CARD_CAT_CONSCIENTIOUSNESS
} from '../Game/games/discCards/cards/cardList'
import {
    CARD_CAT_THINKER,
    CARD_CAT_FEELER,
    CARD_CAT_INTUITOR,
    CARD_CAT_SENSOR,
    CardCategoryCSColors
} from '../Game/games/communicationStyles/cards/cardList'
import {
    PROFILE_VALUES,
    PROFILE_RELATIONSHIP_MANAGEMENT,
    PROFILE_SELF_AWARENESS,
    PROFILE_SELF_MANAGEMENT,
    PROFILE_SOCIAL_AWARENESS
} from '../Game/games/emotionalIntelligence/profiles/profileList'
import {
    URGENT_AND_NOT_IMPORTANT,
    URGENT_AND_IMPORTANT,
    NOT_URGENT_AND_NOT_IMPORTANT,
    IMPORTANT_AND_NOT_URGENT,
    QUADRANT_COLORS
} from '../Game/games/managingTime/data/data'

const webkitTapHighlightColor = '-webkit-tap-highlight-color'

const styles = (theme) => ({
    root: {
        flexGrow: 1
    },
    flexParent: {
        display: 'flex',
        flexDirection: 'column'
    },
    buttonGroup: {
        marginTop: theme.spacing(3)
    },
    glassButton: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500]
    },
    greyText: {
        color: grey[600]
    },
    lightGreyText: {
        color: grey[400]
    },
    avatarRoot: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1)
        },
        flexGrow: 1
    },
    postItRoot: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1)
        },
        flexGrow: 1
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    large: {
        width: theme.spacing(8),
        height: theme.spacing(8)
    },
    medium: {
        width: theme.spacing(4),
        height: theme.spacing(4)
    },
    nameSpacing: {
        marginTop: theme.spacing(1)
    },
    glassPurple: {
        color: deepPurple[500]
    },
    groupTitle: {
        marginTop: theme.spacing(3)
    },
    inlineEl: {
        display: 'inline-block'
    },
    groupTitleMed: {
        marginTop: theme.spacing(1)
    },
    glassPurpleAvatar: {
        backgroundColor: deepPurple[500]
    },
    spacingBottom: {
        marginBottom: theme.spacing(3)
    },
    spacingTop: {
        marginTop: theme.spacing(3)
    },
    doubleSpacingTop: {
        marginTop: theme.spacing(10)
    },
    whitebg: {
        background: '#fff'
    },
    session: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    sessionContain: {
        fontSize: '10px',
        marginBottom: '30px'
    },
    playerSession: {
        display: 'block'
    },
    playersTitle: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    hide: {
        display: 'none'
    },
    menuContain: {
        marginLeft: '20px',
        textAlign: 'left',
        display: 'inline-block'
    },
    drawerHeader: {
        textAlign: 'left'
    },
    secondGroupTitle: {
        textAlign: 'left',
        padding: '10px 20px',
        fontWeight: 'bold',
        borderTop: '1px solid #ccc',
        borderBottom: '1px solid #ccc'
    },
    buttonContain: {
        textAlign: 'right'
    },
    drawerPaper: {
        maxWidth: '300px',
        minWidth: '200px'
    },
    red: {
        color: red[500]
    },
    gameListRoot: {
        width: '300px',
        maxWidth: '300px',
        alignSelf: 'stretch',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex'
    },
    extraPaddingForIcon: {
        padding: '12px'
    },
    media: {
        minHeight: '200px',
        backgroundSize: 'contain'
    },
    lightPurple: {
        color: deepPurple[100]
    },
    medPurple: {
        color: deepPurple[400]
    },
    lightPurpleBackground: {
        background: deepPurple[100]
    },
    imageSize: {
        maxWidth: '100%'
    },
    smallImageSize: {
        maxWidth: '50%'
    },
    imageStyle: {
        maxWidth: '90%',
        display: 'block'
    },
    cofImageStyle: {
        maxWidth: '100%',
        display: 'block',
        width: '400px'
    },
    spaceBottom: {
        marginBottom: '30px'
    },
    pSpaceBottom: {
        marginBottom: '0.5rem'
    },
    imgContainer: { position: 'relative' },
    outerRing: {
        height: '378px',
        width: '376px',
        borderRadius: '50%',
        display: 'inline-block',
        position: 'absolute',
        top: '8px',
        left: '13px',
        zIndex: '100',
        cursor: 'pointer'
    },
    innerRing: {
        height: '147px',
        width: '147px',
        borderRadius: '50%',
        display: 'inline-block',
        position: 'absolute',
        top: '124px',
        left: '127px',
        zIndex: '120',
        cursor: 'pointer'
    },
    bodyText: {
        fontSize: '1.2rem'
    },
    medBodyText: {
        fontSize: '1.5rem'
    },
    boldedText: {
        fontWeight: 'bold'
    },
    listClass: {
        border: '1px solid #ccc',
        borderRadius: '15px',
        minHeight: '100px'
    },
    listClassExtra: {
        border: '1px solid #fff',
        borderRadius: '15px',
        minHeight: '100px'
    },
    setHeightListItem: {
        height: '50px'
    },
    setHeightListItemPadded: {
        height: '50px',
        padding: '0 35px'
    },
    bulletPoint: {
        fontSize: '2rem'
    },
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
        background: deepPurple[50]
    },
    paddedEl: {
        padding: '20px'
    },
    paperExtra: {
        marginTop: '20px',
        marginBottom: '20px',
        padding: '20px',
        borderRadius: '15px',
        background: deepPurple[100],
        border: '1px solid #ccc'
    },
    descriptiveText: {
        fontStyle: 'italic'
    },
    dialogueGrid: {
        maxWidth: '90%',
        margin: '0 auto'
    },
    textField: {
        width: '100%'
    },
    button: {
        width: '100%',
        textAlign: 'left'
    },
    forgot: {
        opacity: '0.5'
    },
    whiteButton: {
        color: '#ffffff'
    },
    persistentSnackbar: {
        background: '#333',
        '@media (max-width: 600px)': {
            maxWidth: '75vw'
        }
    },
    popover: {
        padding: '20px',
        maxWidth: '350px'
    },
    goldColor: {
        color: '#FFDF00'
    },
    deleted: {
        textDecoration: 'line-through'
    },
    updated: {
        textDecoration: 'underline'
    },
    created: {
        color: '#000'
    },
    horizontalList: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    relativeList: {
        position: 'relative'
    },
    fixedList: {
        position: 'fixed',
        left: 0
    },
    minimumHeight: {
        minHeight: '60px'
    },
    borderTop: {
        borderTop: '1px solid #333',
        padding: '8px'
    },
    doNotPad: {
        padding: 0
    },
    doNotPadTop: {
        paddingTop: 0
    },
    fullWidthListItem: {
        width: '100%'
    },
    stretchMe: {
        alignSelf: 'stretch',
        marginBottom: '20px'
    },
    gameCard: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex'
    },
    stretchContainer: {
        flexGrow: 1
    },
    lightPurpleBG: {
        backgroundColor: deepPurple[100]
    },
    discCard: {
        width: '250px',
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '120px'
    },
    discCardDraggable: {
        width: '250px',
        alignSelf: 'stretch',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '120px',
        margin: '10px auto'
    },
    alignVerticalCenter: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    stretchToFit: {
        alignSelf: 'stretch'
    },
    cardActionPadding: {
        padding: '10px 10px 10px 20px'
    },
    justifiedGrid: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    iconStyle: {
        maxWidth: '100%',
        width: '75px',
        display: 'block'
    },
    cardPadding: {
        padding: '0 10px 0 20px'
    },
    [CARD_CAT_DOMINANCE]: {
        backgroundColor: CardCategoryColors[CARD_CAT_DOMINANCE]
    },
    [CARD_CAT_INFLUENCE]: {
        backgroundColor: CardCategoryColors[CARD_CAT_INFLUENCE]
    },
    [CARD_CAT_STEADINESS]: {
        backgroundColor: CardCategoryColors[CARD_CAT_STEADINESS]
    },
    [CARD_CAT_CONSCIENTIOUSNESS]: {
        backgroundColor: CardCategoryColors[CARD_CAT_CONSCIENTIOUSNESS]
    },
    [CARD_CAT_FEELER]: {
        backgroundColor: CardCategoryCSColors[CARD_CAT_FEELER]
    },
    [CARD_CAT_INTUITOR]: {
        backgroundColor: CardCategoryCSColors[CARD_CAT_INTUITOR]
    },
    [CARD_CAT_THINKER]: {
        backgroundColor: CardCategoryCSColors[CARD_CAT_THINKER]
    },
    [CARD_CAT_SENSOR]: {
        backgroundColor: CardCategoryCSColors[CARD_CAT_SENSOR]
    },
    floorWrap: {
        position: 'relative',
        cursor: 'pointer',
        [webkitTapHighlightColor]: 'transparent',
        maxWidth: '800px'
    },
    floorWrap2: {
        position: 'relative',
        cursor: 'pointer',
        [webkitTapHighlightColor]: 'transparent',
        maxWidth: '100%'
    },
    floorItself: {
        position: 'absolute',
        zIndex: '100',
        width: '100%',
        height: '100%',
        [webkitTapHighlightColor]: 'transparent',
        maxWidth: '800px'
    },
    floorItself2: {
        position: 'absolute',
        zIndex: '100',
        width: '100%',
        height: '100%',
        [webkitTapHighlightColor]: 'transparent',
        maxWidth: '100%'
    },
    floorStar: {
        position: 'absolute',
        zIndex: '101'
    },
    starSelected: {
        color: deepPurple[500],
        marginTop: '-7px',
        marginLeft: '-7px'
    },
    personSelected: {
        backgroundColor: deepPurple[500]
    },
    floorPerson: {
        padding: '5px 10px'
    },
    floorList: {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px'
    },
    properList: {
        listStyleType: 'disc !important',
        paddingLeft: '1em !important'
    },
    properListItem: {
        listStyleType: 'disc !important'
    },
    properOrderedList: {
        listStyleType: 'decimal !important',
        paddingLeft: '1em !important'
    },
    properOrderedListItem: {
        listStyleType: 'decimal !important'
    },
    footer: {
        background: deepPurple[500],
        paddingTop: 30,
        paddingBottom: 30,
        marginTop: 0
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        color: '#fff'
    },
    right: {
        textAlign: 'right'
    },
    copy: {
        color: '#fff',
        fontSize: 11
    },
    white: {
        color: '#fff !important',
        padding: 0
    },
    languageTop: {
        paddingTop: '15px !important'
    },
    links: {
        color: '#fff !important',
        cursor: 'pointer'
    },
    logo: {
        maxWidth: 250,
        display: 'block',
        '@media (max-width: 599px)': {
            width: 150
        }
    },
    nonDragPostIt: {
        margin: '12px 12px 0 12px'
    },
    cupContainerLeft: {
        width: '100px',
        textAlign: 'left',
        display: 'block',
        marginBottom: theme.spacing(2)
    },
    walkStep: {
        width: '250px',
        height: '250px',
        display: 'inline-block',
        position: 'relative',
        cursor: 'pointer'
    },
    heightLimitedImage: {
        maxHeight: '300px',
        maxWidth: '100%',
        display: 'block',
        margin: '0 auto'
    },
    marginStep: {
        marginRight: '15px'
    },
    menuZ: {
        position: 'relative',
        zIndex: 1600
    },
    board: {
        width: '100%',
        height: '340px',
        marginBottom: '30px'
    },
    boardScroller: {
        height: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
            width: '0.4em',
            height: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)'
        },
        scrollbarWidth: 'none'
    },
    arrowPos: {
        position: 'absolute',
        width: '100%'
    },
    padWalkStep: {
        paddingTop: '60px'
    },
    doNotShow: {
        visibility: 'hidden !important'
    },
    noPad: {
        padding: 0
    },
    padMe: {
        padding: '1.5em'
    },
    profilePic: {
        backgroundImage: 'url(./Game/games/walkAwhileInMyShoes/images/1.jpg)'
    },
    profileAvatar: {
        width: '120px',
        height: '120px',
        position: 'relative'
    },
    profileTypeDescriptor: {
        fontWeight: 'bold'
    },
    minorMargin: {
        margin: '10px 0'
    },
    buttonMargin: {
        margin: '0 10px 0 0'
    },
    profileScenario: {
        fontWeight: 'normal',
        fontStyle: 'italic',
        fontSize: '1.5em'
    },
    inlineProfileScenario: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontSize: '1.2em'
    },
    inlineProfileDescriptor: {
        fontStyle: 'italic'
    },
    relativeIconParent: {
        position: 'relative',
        width: '120px',
        height: '120px'
    },
    reduceLineHeight: {
        lineHeight: '1em !important'
    },
    absoluteIcon: {
        position: 'absolute',
        right: -10,
        top: -10,
        zIndex: 100,
        color: '#50af00'
    },
    minDialogue: {
        minWidth: '400px'
    },
    successIcon: {
        color: '#50af00'
    },
    walkResult: {
        border: '1px solid #333',

        borderRadius: '5px'
    },
    eiSkill: {
        padding: '10px 20px',
        border: '1px solid #333',
        borderRadius: '5px',
        display: 'inline-block',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        margin: '5px'
    },
    eiVariantTop: {
        borderTop: '20px solid #ccc',
        borderBottom: '20px solid #ccc'
    },
    eiSkillVariant: {
        padding: '10px 20px',
        width: '100%',
        height: '100%',
        display: 'block',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#fff',
        margin: '0'
    },
    underlineText: {
        textDecoration: 'underline'
    },
    eiDropArea: {
        padding: '3px',
        borderBottom: '1px solid #333',
        display: 'inline-block',
        minWidth: '300px',
        marginRight: '2px',
        minHeight: '45px'
    },
    eiFullDropArea: {
        padding: '3px',
        borderBottom: '1px solid #333',
        display: 'inline-block',
        minWidth: '100%',
        marginRight: '2px',
        minHeight: '45px'
    },
    selectableBox: {
        padding: '8px 8px 8px 20px',
        border: '1px solid #333',
        borderRadius: '5px',
        textDecoration: 'underline',
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    borderImage: {
        border: '1px solid #333',
        borderRadius: '5px',
        padding: '10px'
    },
    [PROFILE_SELF_AWARENESS]: {
        backgroundColor: PROFILE_VALUES[PROFILE_SELF_AWARENESS].color
    },
    [PROFILE_SELF_MANAGEMENT]: {
        backgroundColor: PROFILE_VALUES[PROFILE_SELF_MANAGEMENT].color
    },
    [PROFILE_SOCIAL_AWARENESS]: {
        backgroundColor: PROFILE_VALUES[PROFILE_SOCIAL_AWARENESS].color
    },
    [PROFILE_RELATIONSHIP_MANAGEMENT]: {
        backgroundColor: PROFILE_VALUES[PROFILE_RELATIONSHIP_MANAGEMENT].color
    },
    backdrop: {
        zIndex: 1000
    },
    tableHead: {
        backgroundColor: '#673ab7',
        color: '#fff',
        padding: '15px',
        border: '1px solid #aaa',
        borderRadius: '5px 5px 0 0',
        paddingLeft: 40,
        paddingRight: 40,
        fontSize: '1.5rem',
        textTransform: 'uppercase',
        position: 'relative'
    },
    tableHeadSup: {
        textTransform: 'lowercase',
        fontSize: '1rem'
    },
    bodyListText: {
        padding: '0 20px',
        fontSize: '1.1rem'
    },
    createButton: {
        position: 'absolute',
        left: '25px',
        top: '20px',
        zIndex: 100,
        width: '30px',
        height: '30px',
        cursor: 'pointer'
    },
    tableBody: {
        border: '1px solid #aaa',
        borderRadius: '0 0 5px 5px',
        borderTop: '0',
        minHeight: '30vh',
        padding: '40px 0px'
    },
    tableControls: {
        border: '1px solid #aaa',
        borderTop: '0',
        padding: '10px 20px'
    },
    grid_quadrant: {
        minHeight: '250px'
    },
    [URGENT_AND_NOT_IMPORTANT]: {
        background: QUADRANT_COLORS[URGENT_AND_NOT_IMPORTANT].color
    },
    [URGENT_AND_IMPORTANT]: {
        background: QUADRANT_COLORS[URGENT_AND_IMPORTANT].color
    },
    [NOT_URGENT_AND_NOT_IMPORTANT]: {
        background: QUADRANT_COLORS[NOT_URGENT_AND_NOT_IMPORTANT].color
    },
    [IMPORTANT_AND_NOT_URGENT]: {
        background: QUADRANT_COLORS[IMPORTANT_AND_NOT_URGENT].color
    },
    unassigned_section: {
        backgroundColor: 'rgba(253,223,82,0.2)',
        border: '1px solid #999',
        borderRadius: '5px'
    },
    unassigned_quadrant: {
        minHeight: '500px'
    },
    unassigned_quadrant_two: {
        minHeight: '105px'
    },
    quadrant_ul: {
        borderRadius: '5px 0 0 0',
        borderTop: '2px solid #000',
        borderLeft: '2px solid #000',
        borderRight: '1px solid #000',
        borderBottom: '1px solid #000'
    },
    quadrant_ur: {
        borderRadius: '0 5px 0 0',
        borderTop: '2px solid #000',
        borderRight: '2px solid #000',
        borderLeft: '1px solid #000',
        borderBottom: '1px solid #000'
    },
    quadrant_bl: {
        borderRadius: '0 0 0 5px',
        borderBottom: '2px solid #000',
        borderLeft: '2px solid #000',
        borderRight: '1px solid #000',
        borderTop: '1px solid #000'
    },
    quadrant_br: {
        borderRadius: '0 0 5px 0',
        borderBottom: '2px solid #000',
        borderRight: '2px solid #000',
        borderLeft: '1px solid #000',
        borderTop: '1px solid #000'
    },
    quadrant_inside: {
        display: 'flex',
        height: '100%',
        minHeight: '100%',
        flexGrow: 1
    },
    dropImage: {
        maxWidth: '250px',
        margin: '40px 0 20px 0'
    },
    gridB: {
        border: '1px solid #000'
    },
    gridA: {
        border: '1px solid #000',
        borderBottom: '0'
    },
    gridC: {
        border: '1px solid #000',
        borderTop: '0'
    },
    gridD: {
        border: '1px solid #000',
        borderTop: '0',
        borderBottom: '0'
    },
    clueCardRoot: {
        fontSize: '1rem',
        maxWidth: '300px',
        display: 'inline-block',
        margin: '0 20px 20px 0',
        minHeight: '250px'
    },
    importance1: {
        backgroundColor: '#5cb85c'
    },
    importance2: {
        backgroundColor: '#d9534f'
    },
    importance0: {
        backgroundColor: '#f0ad4e'
    },
    textimportance1: {
        color: '#5cb85c'
    },
    testimportance2: {
        color: '#d9534f'
    },
    testimportance0: {
        color: '#f0ad4e'
    },

    cwNumber: {
        fontSize: '24px',
        fontWeight: 'bold',
        display: 'inline-block',
        minWidth: '30px'
    },
    wordSpot: {
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '5px',
        border: '1px solid #333',
        minWidth: '130px',
        display: 'inline-block',
        cursor: 'pointer'
    },
    wordSpotEmpty: {
        color: '#ccc',
        fontStyle: 'italic'
    },
    interimRow: {
        padding: '5px 0px',
        display: 'inline-block',
        fontSize: '1.1rem',
        color: '#673ab7',
        fontWeight: 'bold'
    },
    centerVertically: {
        verticalAlign: 'middle',
        lineHeight: '24px'
    },
    diffIcon: {
        fontSize: '1.1rem',
        marginBottom: '-3px'
    },
    note: {
        fontSize: '0.8rem',
        paddingBottom: '10px'
    },
    trustSpectrumBase: {
        width: '100%',
        minHeight: '50vw'
    },
    suggestionWindow: {
        width: '350px',
        background: 'rgba(0,0,0,0.3)',
        color: '#fff',
        border: '2px solid #333',
        borderRadius: '5px',
        opacity: 1,
        transition: 'opacity 1s'
    },
    suggestionContainer: {
        position: 'fixed',
        right: '10%',
        top: '120px',
        zIndex: '1199'
    },
    suggestionContainerLeft: {
        position: 'fixed',
        right: 'calc(10% + 360px)',
        top: '120px',
        zIndex: '1199'
    },
    suggestionFaded: {
        opacity: '0 !important',
        border: '0 !imporant'
    },
    suggestionWindowAlt: {
        border: '2px solid #333',
        borderRadius: '5px',
        width: '350px',
        background: '#fff'
    },
    suggestionBrowser: {
        transition: 'height 0.3s'
    },
    choiceOption: {
        width: '100%',
        textAlign: 'center',
        color: '#fff',
        fontWeight: 'bold',
        background: '#599e96',
        borderRadius: '10px',
        border: '3px solid #333',
        padding: '30px',
        fontSize: '1.3rem',
        cursor: 'pointer',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)'
    },
    choiceOptionPulse: {
        transform: 'scale(1)',
        animation: 'pulse 2s infinite'
    },
    historyItem: {
        padding: '15px 45px',
        border: '1px solid #000',
        borderRadius: '10px',
        background: 'rgb(89,158,150)',
        color: '#fff',
        fontSize: '1.2rem',
        background:
            'linear-gradient(90deg, rgba(89,158,150,1) 0%, rgba(55,53,53,1) 100%)'
    },
    historyItemChoice: {
        background: '#599e96',
        padding: '15px 45px',
        border: '1px solid #000',
        borderRadius: '10px',
        color: '#fff',
        fontSize: '1.2rem'
    },
    historyChoice: {
        border: '1px solid #000',
        borderRadius: '10px 10px 0px 0',
        padding: '2px 10px',
        fontWeight: 'bold',
        fontSize: '1.3rem',
        display: 'inline-block',
        borderBottom: '0'
    },
    historyRestart: {
        margin: '30px 0',
        fontWeight: 'bold',
        color: 'red'
    },
    tabsRoot: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: '100%',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    tabsLN: {
        borderRight: `1px solid ${theme.palette.divider}`,
        minWidth: '195px',
        paddingBottom: '80px'
    },
    tabsLNTab: {
        fontFamily: "'Bebas Neue', 'Montserrat', 'Roboto'",
        fontSize: '1.3rem'
    },
    LNToolbar: {
        width: '100%'
    },
    LNNoShadow: {
        boxShadow: 'none'
    },
    LNProperStart: {
        marginTop: '1rem'
    },
    LNBoldTitle: {
        fontFamily: "'Bebas Neue'",
        fontSize: '1.1rem'
    },
    LNFooterButton: {
        margin: '0 10px',
        backgroundColor: 'transparent !important',
        boxShadow: 'none',
        padding: '0 5px'
    },
    LNTabPanel: {
        width: 'calc(100% - 195px)'
    },
    LNOVERFLOWTabPanel: {
        width: 'calc(100% - 195px)',
        overflow: 'auto',
        height: '100%'
    },
    cardCustom: {},
    cardPredefined: {},
    navHeader: {
        background: deepPurple[300],
        color: '#fff'
    },
    eiradiocheckedicon: {
        backgroundColor: '#137cbd',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 25,
            height: 25,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""'
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3'
        }
    },
    eiradioicon: {
        borderRadius: '50%',
        width: 25,
        height: 25,
        paddingLeft: '0 !important',
        boxShadow:
            'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage:
            'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5'
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)'
        }
    },
    eiradioroot: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
})

export default styles
