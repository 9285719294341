import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Logo from '../images/Logo.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import {
    hasGameControl,
    getUserGroup_hc,
    determineHost
} from '../../game_functions/functions'
import {
    updateGroupState,
    editResultsPath,
    editResultsPaths
} from './../../../../../store/actions/gameActions'
import { CARDS } from '../data/data'
import Typography from '@material-ui/core/Typography'
import styles from '../../../../Styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const INITIAL_STATE = {
    engOpen: false,
    eng: '',
    conOpen: false,
    con: ''
}

class Instructions extends Component {
    constructor(props) {
        super(props)
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.setState({
            ...this.state,
            con:
                this.props.session.active_game.groupStates[this.props.group_id]
                    .contractor === undefined
                    ? ''
                    : this.props.session.active_game.groupStates[
                        this.props.group_id
                    ].contractor,
            eng:
                this.props.session.active_game.groupStates[this.props.group_id]
                    .engineer === undefined
                    ? ''
                    : this.props.session.active_game.groupStates[
                        this.props.group_id
                    ].engineer
        })
    }

    shuffle = (array) => {
        var currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle...
        while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }

        return array;
    }

    dealCardsToPlayers = () => {
        const playersList = {};

        //console.log(CARDS);
        this.props.session.groups[this.props.group_id].playerIds.forEach((playerId, ind) => {
            playersList[playerId] = []
        })


        //console.log(playersList);

        let cards = this.shuffle(CARDS);
        //console.log('cards: ', cards);
        let numPlayers = Object.keys(playersList).length;
        //console.log('num players: ', numPlayers);
        let cardsToHave = 1;
        let cardAdded = false;
        let i = 0;
        cards.forEach((card, ind) => {
            cardAdded = false;
            i += 1;
            if (i > numPlayers) {
                i = 1;
                cardsToHave += 1;
            }
            Object.keys(playersList).forEach((playerId, ind) => {
                if (playersList[playerId].length < cardsToHave && cardAdded === false) {
                    playersList[playerId].push(card);
                    cardAdded = true;
                }
            })
        })


        return playersList;
    }

    sendToNextStep = (event) => {
        let groupID = getUserGroup_hc(
            this.props.session,
            this.props.auth.uid,
            this.props.profile.role
        )

        let startedOn =
            this.props.session.active_game.groupStates[groupID].params
                .startedOn === undefined ||
                this.props.session.active_game.groupStates[groupID].params
                    .startedOn === null
                ? Date.now()
                : this.props.session.active_game.groupStates[groupID].params
                    .startedOn

        if (this.props.session.active_game.results[groupID] === undefined) {


            let p = `active_game.results.${this.props.group_id}.startedOn`;
            let p2 = `active_game.results.${this.props.group_id}.cards`;


            let v2 = this.dealCardsToPlayers();
            //console.log(v2);

            let paths = [p, p2];
            let vals = [startedOn, v2];

            this.props.editResultsPaths(
                paths,
                vals,
                determineHost(this.props.profile, this.props.auth)
            )
        }

        let s = {
            state: GROUP_STATE_ACTIVE,
            params: {
                contractor: this.state.con,
                engineer: this.state.eng,
                startedOn: startedOn
            }
        }
        this.props.updateGroupState(
            groupID,
            s,
            determineHost(this.props.profile, this.props.auth)
        )
    }





    /*getCardsForCurrentPlayer = () => {
        const cards = []
        for (let player of playersList) {
            if (player.id === currentPlayerId) {
                for (let i in player.cards) {
                    cards.push(player.cards[i])
                }
            }
        }
        return cards
    }*/




    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        const { eng, con } = this.state
        const { session, profile, auth, classes, leader } = this.props

        return (
            <div>
                <Typography variant="h3" className={classes.spaceBottom}>
                    {session.active_game.name}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img
                            src={Logo}
                            className={classes.imageStyle}
                            alt="Game"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant="body1"
                            className={classes.spacingBottom}
                        >
                            In this exercise, you're going to make a wall  that is 6 rows high, with windows, and all blocks must be used.
                        </Typography>
                        <Typography
                            variant="body1"
                            className={classes.spacingBottom}
                        >
                            NOTE:
                        </Typography>
                        <ul className="browser-default">
                            <li>Each team member has 1 to 4 hidden agendas.</li>
                            <li>Do not show your hidden agenda cards.</li>
                            <li>Share your hidden agenda through conversations and be certain this is incorporated into the wall.</li>
                        </ul><br /><br />

                        {hasGameControl(leader, auth.uid, profile) ? (
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                onClick={this.sendToNextStep}
                            >
                                Continue
                            </Button>
                        ) : (
                            <Button
                                type="submit"
                                disabled={true}
                                variant="contained"
                            >
                                Leader can hit Continue
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState: (group, groupState, hostID) =>
            dispatch(updateGroupState(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) =>
            dispatch(editResultsPath(path, value, hostID)),
        editResultsPaths: (paths, values, hostID) =>
            dispatch(editResultsPaths(paths, values, hostID))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Instructions)
