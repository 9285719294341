import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button'; 
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MessageIcon from '@material-ui/icons/Message';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
export const a11yProps = (index) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

export default function TutorialVideoPlayer(props) {
    const { classes, open } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
    const goToContact = () => {
        window.open('/contact', '_blank');
    }
    return (
        <Dialog open={open} onClose={props.closeTutorial} fullWidth maxWidth='md' TransitionComponent={Transition}>
            <AppBar position="static" className={classes.LNNoShadow}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.closeTutorial} aria-label="close">
                <CloseIcon />
                </IconButton>
                    <Typography variant="h6">
                    Tutorial Videos                
                    </Typography>
            </Toolbar>
            </AppBar>
         
            <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Overview" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Intro Video" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Intro Video 2" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Moderator Info Video" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="VG Admin Video" {...a11yProps(4)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Welcome to GOL Virtual Training</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>Get to know the GOL Virtual platform better! Along the left hand side of this window are a number of training videos that are available to be viewed at your leisure.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Intro Video</Typography>   
                <ReactPlayer url='https://youtu.be/Nf6UUKTeWUY' controls={true} />          
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Intro Video 2</Typography>    
                <ReactPlayer url='https://youtu.be/2VYO46DfhJQ' controls={true} />  
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Moderator Information</Typography>     
                <ReactPlayer url='https://youtu.be/yotTd36Li0Q' controls={true} />
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">VG Admin</Typography> 
                <ReactPlayer url='https://youtu.be/Z-WePVzD8s0' controls={true} />
            </TabPanel>
            
           
        </div> 
           
          
            <AppBar position="static" style={{textAlign: 'right', alignItems: 'flex-end'}}>
            <Toolbar>                
                    <Typography variant="h6">
                        Questions? 
                    </Typography> 
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className={`${classes.LNFooterButton} ${classes.LNButtonLeft}`}
                            startIcon={<MessageIcon />}
                        >
                            Text 226-337-4997
                        </Button> 
                        <Typography variant="h6"> OR </Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {goToContact()}}
                            size="large"
                            className={`${classes.LNFooterButton} ${classes.LNButtonRight}`}
                            startIcon={<ContactMailIcon />}
                        >
                            Contact Us
                        </Button>
                                       
            </Toolbar>
            </AppBar>    
        </Dialog>

    );
}