import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import styles from '../../../../Styles';

const getItemStyle = ( isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: "0",
  margin: "12px 12px 0 12px",
  ...draggableStyle,
  background: 'none'
});

const truncateStr = (str, n) => {
  return (str.length > n) ? str.substr(0, n-1) + '...' : str;
}

class PostIt extends Component {
  
  handleClick = () => {  }

  render() {
    const { classes } = this.props;
    if ( this.props.control === true ){
      return (      
        <Draggable draggableId={`${this.props.id}`} index={this.props.index}>
        {(provided, snapshot) => (
          <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                <div className="postit" onClick={() => this.props.itemAction(this.props.id, this.props.text, this.props.col)}>
                  <div className={classes.nameSpacing} >
                    {truncateStr(this.props.text, 50)}
                  </div>
                </div>
          </div>
          )}
        </Draggable>      
      );
    } else {
      return (      
                <div className={`postit ${classes.nonDragPostIt}`}>
                  <div className={classes.nameSpacing} >
                    {truncateStr(this.props.text, 50)}
                  </div>
                </div> 
      );
    }
  }
}

PostIt.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PostIt);
