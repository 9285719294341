import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PlayersBase from '../Players';
import LogoAlt from '../../images/LogoAlt.png';
import SessionControls from '../Session/sessionControls';
import { deepPurple } from '@material-ui/core/colors';
import { getFirebase } from 'react-redux-firebase';

const HeaderStyles = makeStyles((theme) => ({
  logo: {
    margin: '0 auto 10px auto',
    display: 'block',
    maxWidth: "40vw",   
    maxHeight: "100px",
    textAlign: 'center',

  },
  title: {
    fontWeight: 300,
    marginLeft: 40,
    color: '#656366',
  },
  padBottom: {
    paddingBottom: "0"
  },
  logoHeader: {
    backgroundColor: '#fff',
    paddingTop: 10,
  },
  textRight: {
    paddingTop: 30,
    textAlign: 'right',
  },
  purpleLine: {
    background: deepPurple[500],
    display: 'block',
    height: 10,
  },
  purpleGradient: {
    height: 60,
    display: 'block',
  },
  menu: {
    textAlign: 'right',
  },
  navContain: {
    padding: '0 30px',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  gridAlign: {
    justifyContent: 'center',
    alignSelf: 'center',
  }
}));

const LogoHeader = ({ t, session, profile, auth, theme }) => {
  const classes = HeaderStyles();
  //console.log('The Theme: ', theme);
  const [logoURL, setLogoURL] = React.useState(LogoAlt);

  


  React.useEffect(() => {
    const processImages = async () => {
      let logos = {};
      const sto = getFirebase().storage();
      let ref = sto.ref(theme.logo);
      let url = await ref.getDownloadURL();
      logos['logo'] = url;      
      return Promise.resolve(logos);
    }
    processImages().then((logos) => {
      //console.log(logos);
      setLogoURL(logos.logo);
    });
  },[theme]);



  let color = deepPurple[500];
  //console.l0
  if ( theme !== undefined ){
    color = theme.color
  }

  return (
    <div>
      <div className="print-logo"><img src={LogoAlt} alt='Solta' className={classes.logo} /></div>
      <div className='outer-header-container'>
        <div className={`${classes.logoHeader} ${classes.padBottom}`}>
            <Grid container>
              <Grid item xs={4} className={classes.gridAlign}>
                {session !== undefined ? (
                  <div className={classes.navContain}>
                    <SessionControls profile={profile} t={t} session={session} auth={auth}/>
                  </div>
                ) : null}
              </Grid>
              <Grid item xs={4}>
                <img src={logoURL} alt='Solta' className={classes.logo} />
              </Grid>
              <Grid item xs={4} className={`${classes.menu} ${classes.gridAlign}`} >
                {session !== undefined && session.players.length > 0 ? (
                  <div className={classes.navContain}>
                    <PlayersBase players={session.players} session={session} profile={profile} auth={auth} />
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </div>
          <Grid container spacing={0} alignItems='center'>
            <Grid item xs={12}>
              <Paper square elevation={0} className={classes.purpleLine} style={{background: color}}></Paper>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Paper square elevation={0} className={classes.purpleGradient}></Paper>
            </Grid>
          </Grid>
        </div>
      </div>
  );
};

export default LogoHeader;