import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Avatar from '@material-ui/core/Avatar';
import {
    hasGameControl,
    determineHost
  } from '../../game_functions/functions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { editResultsPath, updateGroupStateParamsVal } from './../../../../../store/actions/gameActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { PROFILE_EMPLOYEE, PROFILE_MANAGER } from '../profiles/profileList_temp';
import styles from '../../../../Styles';
import Speech from 'speak-tts';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FolderSharedIcon from '@material-ui/icons/FolderShared';

/* updateGroupStateParams to toggle the profiles, etc */

const INITIAL_STATE = {
    open: false,
    speechBot: null,
    playing: false
}

function capitalizeFLetter(s) { 
    if ( s !== undefined ){
        return s[0].toUpperCase() + s.slice(1); 
    } else {
        return s;
    }
}


class WalkProfile extends Component {
    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    componentDidMount(){
        if ( this.props.session.active_game.groupStates[this.props.group_id].params.viewing === PROFILE_EMPLOYEE || this.props.session.active_game.groupStates[this.props.group_id].params.viewing === PROFILE_MANAGER ){
        }
        const speech = new Speech();
        if(speech.hasBrowserSupport()) { // returns a boolean
        }
        speech
        .init({
        volume: 0.5,
        lang: "en-GB",
        rate: 1,
        pitch: 1,
        })
        .then(data => {
            speech.setLanguage('en-GB');
            let isDefined = data.voices.find(obj => {
                return obj.name === this.props.walkProfile.profile_gender
            });
            if ( isDefined !== undefined ){
                speech.setVoice(this.props.walkProfile.profile_gender);
            } else {
                let isDefined2 = data.voices.find(obj => {
                    return obj.name === this.props.walkProfile.profile_gender2
                });
                if ( isDefined2 !== undefined ){
                    speech.setVoice(this.props.walkProfile.profile_gender2);
                }
            }
            this.setState({
                ...this.state,
                speechBot: speech
            })
        })
        .catch(e => {
            console.error("An error occured while initializing : ", e);
        });
    }

    componentWillUnmount(){
        this.state.speechBot.cancel();
    }

    componentDidUpdate(prevProps, prevState){
        if ( this.state.speechBot === null ){
            const speech = new Speech();
            if(speech.hasBrowserSupport()) { // returns a boolean
            }
            speech
            .init({
            volume: 0.5,
            lang: "en-GB",
            rate: 1,
            pitch: 1,            
            })
            .then(data => {
                speech.setLanguage('en-GB');
                let isDefined = data.voices.find(obj => {
                    return obj.name === this.props.walkProfile.profile_gender
                });
                if ( isDefined !== undefined ){
                    speech.setVoice(this.props.walkProfile.profile_gender);
                } else {
                    let isDefined2 = data.voices.find(obj => {
                        return obj.name === this.props.walkProfile.profile_gender2
                    });
                    if ( isDefined2 !== undefined ){
                        speech.setVoice(this.props.walkProfile.profile_gender2);
                    }
                }
                this.setState({
                    ...this.state,
                    speechBot: speech
                })
            })
            .catch(e => {
                console.error("An error occured while initializing : ", e);
            });
        }

        

       
    }


 

  playSpeech = () => {
    this.state.speechBot
    .speak({
      text: this.props.walkProfile.profile_text,
      queue: false,
      listeners: {
        onstart: () => {
          this.setState({
                ...this.state,
                playing: true
          })
        },
        onend: () => {
         if ( !this.state.speechBot.pending() ){
                this.setState({
                    ...this.state,
                    playing: false
                })
            }
        },
        onresume: () => {
            this.setState({
                ...this.state,
                playing: true
            })
        },
        onboundary: event => {
        }
      }
    })
    .then(data => {
    })
    .catch(e => {
    });
  }

  endSpeech = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.state.speechBot.cancel();      
  }

  render() {
      
    const { classes, walkProfile, session, profile, auth, type, stepTitle, group_id } = this.props;
    const { open } = this.state;
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);

    return (
        <div>
                          
            <Grid container className={classes.alignVerticalCenter}>
                <Grid item xs={12} className={classes.cardPadding} align="center">
                    <Card className={classes.root} style={{maxWidth: "800px"}}>
                        <CardContent  className={classes.noPad} style={{background: "linear-gradient(180deg, rgba(103,58,183,1) 0%, rgba(103,58,183,0.773546918767507) 100%)", color: "#fff"}}> 
                            <Grid container className={classes.alignVerticalCenter} style={{alignItems: 'stretch'}}>
                                <Grid item sm={4} xs={12} align="center">
                                <img src={walkProfile.profile_image} className={classes.imageSize} style={{display: "block"}}/>
                                </Grid>
                                <Grid item sm={8} xs={12} className={classes.padMe}>
                                    <Typography variant="body1">
                                        {walkProfile.profile_text}
                                    </Typography>
                                </Grid>                           
                            </Grid>
                        </CardContent>
                        <div  style={{textAlign:"right", borderTop: "1px solid #ccc", padding: "20px", background: "#ccc"}}>
                            
                                { this.state.playing ? 
                                    <Button size="small" variant="contained" color="primary" onClick={(e) => this.endSpeech(e)}>
                                        Stop Audio
                                    </Button>
                                : 
                                <Button size="small" variant="contained" color="primary" onClick={(e) => this.playSpeech(e)}>
                                    Play Audio
                                </Button>
                                }
                        </div>           
                    </Card>          
                </Grid>
            </Grid>     
        </div>
    );
  }
}

const mapStateToProps = (state) => {
        return {}
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {        
            editResultsPath: (path, value, host) => dispatch(editResultsPath(path, value, host)),
            updateGroupStateParamsVal: ( group, value, groupStateParams, hostID ) => dispatch(updateGroupStateParamsVal(group, value, groupStateParams, hostID) )
        };
    };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(WalkProfile);



