import React, { Component } from 'react';
import {
  GAME_STATE_INIT,
  GAME_STATE_ACTIVE,
  GAME_STATE_RESULTS,
  HOST_STATE_GROUP_SELECT,
  HOST_STATE_GROUP,
  HOST_STATE_MAIN,
  HOST_STATE_RESULTS,
} from '../../store/actions/gameActions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import GroupSelector from './groupSelection';
import GroupView from './groupView';
import GameDashboard from './hostViews/dashboard';
import ResultsDashboard from './hostViews/resultsDashboard';
import GameServer from './gameServer';
import styles from '../Styles';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { updateModState } from './../../store/actions/gameActions';

class ModeratorView extends Component {

  componentDidMount(){
    if ( this.props.session.moderatorStates[this.props.auth.uid] === undefined ){
      let s = { state: HOST_STATE_GROUP_SELECT, params: {} };
      if ( this.props.auth.uid && this.props.profile.moderates ){
        this.props.updateModState(this.props.auth.uid, s, this.props.profile.moderates);
      }
    }
    
  }

  componentDidUpdate(){
    if ( this.props.session.moderatorStates[this.props.auth.uid] === undefined ){
      let s = { state: HOST_STATE_GROUP_SELECT, params: {} };
      if ( this.props.auth.uid && this.props.profile.moderates ){
        this.props.updateModState(this.props.auth.uid, s, this.props.profile.moderates);
      }
    }
  }

  render() {
    const {
      game_id,
      game_title,
      session,
      profile,
      auth,
      game_state,
      classes,
      game_options
    } = this.props;


    if ( this.props.session.moderatorStates[this.props.auth.uid] === undefined ){
      return null;
    }

    let title = game_title;
    if ( game_options !== false ){
        title = game_options.game_texts.game_title;
    }

    switch (game_state) {
      case GAME_STATE_INIT:
        return (
          <div>
            
            <GroupSelector gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions}  game_options={game_options} session={session} profile={profile} auth={auth} players={session.players} game_state={session.active_game.gameState.state} />
          </div>
        );

      case GAME_STATE_ACTIVE:
        switch (this.props.session.moderatorStates[this.props.auth.uid].state) {
          case HOST_STATE_GROUP_SELECT:
            return (
              <div>
                
                <GroupSelector gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions}  game_options={game_options} session={session} profile={profile} auth={auth} players={session.players} game_state={session.active_game.gameState.state} />
              </div>
            );

          case HOST_STATE_GROUP:
            return <GroupView game_options={game_options} game_id={game_id} session={session} game_title={title} profile={profile} auth={auth} game_state={game_state} group_states={session.active_game.groupStates} />;
;
          case HOST_STATE_MAIN:
            return <GameDashboard game_options={game_options} game_id={game_id} session={session} game_title={title} profile={profile} auth={auth} game_state={game_state} />;

          case HOST_STATE_RESULTS:
            return <ResultsDashboard game_options={game_options} game_id={game_id} session={session} game_title={title} profile={profile} auth={auth} game_state={game_state} />;

          default:
            return null;
        }

      case GAME_STATE_RESULTS:

        switch (this.props.session.moderatorStates[this.props.auth.uid].state) {
          case HOST_STATE_GROUP_SELECT:
            return (
              <div>
                
                <GroupSelector gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions}  game_options={game_options} session={session} profile={profile} auth={auth} players={session.players} game_state={session.active_game.gameState.state} />
              </div>
            );
          default:
            return <GameServer game_options={game_options} game_id={game_id} game_title={title} session={session} profile={profile} auth={auth} game_state={game_state} />;
        }

      default:
        return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateModState: (mod, modState, host) => dispatch(updateModState(mod, modState, host)),    
  };
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ModeratorView);
