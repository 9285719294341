import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import {
    updateGroupStateParamsVal,
    editResultsPath,
    updateGroupStateStateVal,
    removeResultsPath,
    updateResultsPath
} from '../../../../../store/actions/gameActions'
//import { createPiecesGroup } from './../utils/createPiecesGroup';

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
    //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
    //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS,
    GROUP_STATE_ACTIVE,
    GAME_STATE_RESULTS
} from '../../../../../store/actions/gameActions'

import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt'
// import { pieces } from './../data/data';
import AddIcon from '@material-ui/icons/Add'
// import bbss_logo from './../images/bbss_logo.png'
import Popover from '@material-ui/core/Popover'
// import Suggestions from './suggestions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import { v4 as uuidv4 } from 'uuid'

import styles from '../../../../Styles'
import PieceDrawer from './pieceDrawer'
import { BLOCKS_SIDEBAR, BLOCK_COUNTS } from '../data/data'
import TangramMS from './tangram';
import { determineHost } from '../../game_functions/functions'
import { CARDS } from '../data/data'
import Grid from '@material-ui/core/Grid';
import CardList from './cardList'

const INITIAL_STATE = {
    mouseX: null,
    mouseY: null,
    currentSlot: null,
    pieceListOpen: false,
    popoverOpen: false,
    anchorEl: null,
    currentPiece: null,
    drawingEnabled: false,
    eraserEnabled: false,
    draggingSuggestion: false,
    scrollPosition: 0,
    scrollBound: false,
    suggestText: '',
    suggestOpen: false,
    cardListOpen: false,
}

class PieceList extends Component {
    constructor(props) {
        super(props)

        this.draggedEl = React.createRef()
        //this.scrollContainer = React.createRef();
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        //console.log('ok', this.scrollContainer);
        //if ( this.scrollContainer.current !== null ){
        //this.scrollContainer.current.addEventListener('scroll', this.scrollMonitor);
        //}
    }

    componentWillUnmount() {
        //this.scrollContainer.current.removeEventListener('scroll', this.scrollMonitor)
        //paper.project.remove()
    }

    openMenu(event, index, word) {
        event.preventDefault()
        event.stopPropagation()
        if (!this.props.hasControl) {
            return
        }

        this.setState({
            ...this.state,
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            currentSlot: index
        })
    }

    handleContextClose = () => {
        this.setState({
            ...this.state,
            mouseX: null,
            mouseY: null,
            currentSlot: null
        })
    }

    openPieceList = () => {
        this.setState({
            ...this.state,
            pieceListOpen: true
        })
    }
    openCardList = () => {
        this.setState({
            ...this.state,
            cardListOpen: true
        })
    }
    closePieceList = () => {
        this.setState({
            ...this.state,
            pieceListOpen: false
        })
    }
    closeCardList = () => {
        this.setState({
            ...this.state,
            cardListOpen: false
        })
    }
    handlePopoverClose = () => {
        this.setState({
            ...this.state,
            pieceListOpen: false,
            popoverOpen: false,
            anchorEl: null,
            currentPiece: null
        })
    }
    handlePopoverOpen = (event, piece) => {
        this.setState({
            ...this.state,
            anchorEl: event.currentTarget,
            popoverOpen: true,
            currentPiece: piece
        })
    }

    dragStart = (ev) => {
        console.log('dragStart: ', ev.target)

        let w = ev.target.attributes.pwidth.value
        let h = ev.target.attributes.pheight.value

        ev.dataTransfer.setData('text', ev.target.id)
        ev.dataTransfer.setDragImage(ev.target, 10, 10)

        let r = {
            src: ev.target.attributes.src.value,
            id: ev.target.attributes.piece.value,
            width: w,
            height: h
        }
        console.log('O: ', r, ev.target)

        this.draggedEl.current = r

        console.log('piece', ev.target.attributes.piece.value)
        setTimeout(() => this.closePieceList(), 150)
    }

    dragEnd = (ev) => {
        console.log('dragEnd: ', ev.target)

        // let w = ev.target.attributes.pwidth.value
        // let h = ev.target.attributes.pheight.value

        // ev.dataTransfer.setData('text', ev.target.id)
        // ev.dataTransfer.setDragImage(ev.target, 10, 10)

        // let r = {
        //     src: ev.target.attributes.src.value,
        //     id: ev.target.attributes.piece.value,
        //     width: w,
        //     height: h
        // }
        // console.log('O: ', r, ev.target)

        // this.draggedEl.current = r

        // console.log('piece', ev.target.attributes.piece.value)
        // setTimeout(() => this.closePieceList(), 150)
    }

    clearDraggedEl = () => {
        console.log('clear dragged el')
        this.draggedEl.current = null
        this.setState({
            ...this.state,
            draggingSuggestion: false
        })
    }

    updateLines = (type, line) => {
        let path = `active_game.results.${this.props.group_id}.lines`
        if (type === 'pop') {
            this.props.editResultsPath(path, line, this.props.host)
        } else if (type === 'add') {
            this.props.updateResultsPath(path, line, this.props.host)
        }
    }

    updateImages = (type, image) => {
        /* Types: add, update, remove */
        let path = `active_game.results.${this.props.group_id}.images`
        if (type === 'add') {
            this.props.updateResultsPath(path, image, this.props.host)
        } else if (type === 'remove') {
            this.props.editResultsPath(path, image, this.props.host)
        } else if (type === 'update') {
            this.props.editResultsPath(path, image, this.props.host)
        }
    }

    suggestThis = () => {
        let uniqid = uuidv4()
        let path = `active_game.results.${this.props.group_id}.suggestions.${uniqid}`
        this.props.editResultsPath(
            path,
            {
                ...this.state.currentPiece,
                suggestedBy: this.props.auth.uid,
                vetted: false,
                text: this.state.suggestText,
                suggestId: uniqid,
                added: Date.now()
            },
            this.props.host
        )
        this.handleClose()
    }
    openSuggest = () => {
        console.log('what?')
        this.handlePopoverClose()
        this.setState({
            ...this.state,
            suggestOpen: true,
            popoverOpen: false,
            suggestText: ''
        })
    }

    assignDraggedEl = (el) => {
        console.log('assigning?', el)
        this.draggedEl.current = el
        console.log('how is it not done? ', this.draggedEl.current)
    }

    toggleSuggestionDrag = (val) => {
        this.setState({
            ...this.state,
            draggingSuggestion: val
        })
    }

    onChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        })
    }
    handleClose = (event) => {
        this.setState({
            ...this.state,
            suggestOpen: false,
            pieceListOpen: false,
            popoverOpen: false
        })
    }

    scrollMonitor = (e) => {
        //e.target.scrollTop
        console.log('huh?')
        let s = e.target.scrollTop - this.state.scrollPosition
        if (s > 100 || s < 100) {
            console.log('test')
            this.setState({
                ...this.state,
                scrollPosition: e.target.scrollTop
            })
        }
    }

    render() {
        const { classes, session, hasControl, group_id, profile, host, auth } =
            this.props;

        const cards = session.active_game.results[group_id].cards[auth.uid] === undefined ? [] : session.active_game.results[group_id].cards[auth.uid];
        //console.log('cards: ', cards, session.active_game);

        return (
            <div>
                {/* BUTTON TO TOGGLE THE PIECE LIST DRAWER */}




                {/* THE DRAWER */}

                <PieceDrawer
                    open={this.state.pieceListOpen}
                    close={this.closePieceList}
                    classes={classes}
                    pieces={BLOCKS_SIDEBAR}
                    piecesCount={BLOCK_COUNTS}
                    currentPieceId={this.state.currentPieceId}
                    dragStart={this.dragStart}
                    handlePop={this.handlePopoverOpen}
                    hasControl={hasControl}
                    tangramCoords={
                        this.props.session.active_game.results[
                            this.props.group_id
                        ] !== undefined
                            ? this.props.session.active_game.results[
                                this.props.group_id
                            ].tangramCoords
                            : undefined
                    }
                />

                <Grid container>
                    <Grid item sm={6} xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={this.state.cardListOpen === true ? true : false}
                            startIcon={<ViewQuiltIcon />}
                            onClick={() => this.openCardList()}

                        >
                            View Your Hidden Agendas
                        </Button>
                    </Grid>
                    <Grid item sm={6} xs={12} align="right">
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={this.state.pieceListOpen === true ? true : false}
                            startIcon={<ViewQuiltIcon />}
                            onClick={() => this.openPieceList()}

                        >
                            Open Block List
                        </Button>
                    </Grid>
                </Grid>

                <CardList open={this.state.cardListOpen} session={session}
                    profile={profile}
                    auth={auth}
                    group_id={group_id}
                    handleClose={this.closeCardList}
                    cards={cards} />


                <div className={classes.spacingTop}>
                    <div>
                        <TangramMS
                            openPieceList={this.openPieceList}
                            session={session}
                            profile={profile}
                            auth={auth}
                            group_id={group_id}
                            host={determineHost(
                                this.props.profile,
                                this.props.auth
                            )}
                            draggedEl={this.draggedEl.current}
                            clearDraggedEl={this.clearDraggedEl}
                            hasControl={hasControl}
                            updateLines={this.updateLines}
                            updateImages={this.updateImages}
                            control={hasControl}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    //console.log('this is my state', state);
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupStateParamsVal: (group, val, groupStateParams, hostID) =>
            dispatch(
                updateGroupStateParamsVal(group, val, groupStateParams, hostID)
            ),
        updateGroupStateStateVal: (group, groupState, hostID) =>
            dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) =>
            dispatch(editResultsPath(path, value, hostID)),
        removeResultsPath: (path, value, hostID) =>
            dispatch(removeResultsPath(path, value, hostID)),
        updateResultsPath: (path, value, hostID) =>
            dispatch(updateResultsPath(path, value, hostID))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(PieceList)
