import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "1.1rem"
  },
  helpText: {
      fontWeight: "bold",
      padding: "30px 20px 0 20px",
      fontSize: "1.2rem"
  }

}));

export default function ActivityDescPlusTwo() {
    const classes = useStyles();  

   
    return (
        <div>
            <div className={classes.root}>
                2. What needs to be in place to ensure success of the project and the final University learning?
                &nbsp;
            </div>
            <div className={classes.helpText}>
                To begin adding an entry, tap on the pencil icon in the Success column.
            </div>
        </div>
    );
}
   
