import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import WalkProfileResult from './../specific_components/profileResult';
import { getUserGroup_hc } from '../../game_functions/functions';
import { PROFILE_EMPLOYEE, PROFILE_MANAGER, ProfileCategories } from './../profiles/profileList_temp';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    render() {
    const {
      session,
      classes,
      auth,
      profile
    } = this.props;

        if ( profile.role === 'host' ){
            return (
                <div>
                    <Typography variant="h3">{session.active_game.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                    { Object.keys(ProfileCategories).map((step, ind) => {
                        
                        return(
                        <Grid container spacing={3} key={ind} className={`${classes.spacingBottom} ${classes.walkResult}`}>
                            <Grid item xs={12}>
                                <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center">Scenario {step}<br/><span className={classes.profileScenario}>{ProfileCategories[step]}</span></Typography>  
                            </Grid>
                            <Grid item xs={6}>
                                <WalkProfileResult    
                                stepTitle={ProfileCategories[step]}
                                type={PROFILE_EMPLOYEE}
                                step={step}
                                session={session}
                                profile={profile}
                                auth={auth}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <WalkProfileResult 
                                stepTitle={ProfileCategories[step]}
                                type={PROFILE_MANAGER}
                                step={step}
                                session={session}
                                profile={profile}
                                auth={auth}
                                />
                            </Grid>
                        </Grid>)
                    })}
                </div>
            )
        }

        const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
        return (
        <div>
            <Typography variant="h3">{session.active_game.name}</Typography>
            <Typography variant='h5' className={classes.spaceBottom}>
                Debrief
            </Typography>
            <Typography variant="h4" className={classes.spacingBottom}>Your Notes:</Typography>
            { Object.keys(ProfileCategories).map((step, ind) => {        
                return(
                <Grid container spacing={3} key={ind} className={`${classes.spacingBottom} ${classes.walkResult}`}>
                    <Grid item xs={12}>
                        <Typography variant='h5' className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`} align="center">Scenario {step}<br/><span className={classes.profileScenario}>{ProfileCategories[step]}</span></Typography>  
                        <List className={classes.listClass}>
                            {
                            session.active_game.results.hasOwnProperty(group_id) ?
                                session.active_game.results[group_id].hasOwnProperty(`step_${step}`) ?
                                session.active_game.results[group_id][`step_${step}`].hasOwnProperty('notes')
                                    ? session.active_game.results[group_id][`step_${step}`].notes.map((item, index) => {
                                        return (        
                                            <ListItem key={index}>                 
                                                <ListItemText
                                                    className={classes.bodyText} 
                                                    primary={item}                                
                                                />
                                            </ListItem>
                                        )
                                    })
                                    : <div className={classes.padMe}>Your notes for this scenario are empty.</div> 
                                    : 
                                    <div className={classes.padMe}>Your notes for this scenario are empty.</div>
                                :
                                <div className={classes.padMe}>Your notes for this scenario are empty.</div>
                           }
                        </List>
                    </Grid>
                </Grid>)
            })}
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
