import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import ea_ln_1 from './../images/ea_ln_1.png';
import ea_ln_2 from './../images/ea_ln_2.png';
import ea_ln_3 from './../images/ea_ln_3.png';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const ExpecatationsAndAccountabilitiesLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>•	A 2-part activity that builds on the fundamentals of coaching to standards.
                 To lead others first we must set expectations.
                  Part 1: This activity helps participants work through the steps to setting clear expectations with their direct reports.
                   In groups, they select a work topic that needs to be addressed and use the 5-step model to outline the expectations.
                      This is debriefed to review understanding and application.
                       Part 2: The groups will take the expectations set, and work through the 5-step accountability model to establish how they will hold individuals accountable for expectations set.
                        Information recorded for this activity will be sent as follow up to ensure application of the learning.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Linking expectations and accountability to the fundamentals of leadership coaching</li>
                    <li className={classes.properListItem}>Application and use of the 5 -steps to set clear expectations</li>
                    <li className={classes.properListItem}>Application and use of the 5 -steps to foster accountability</li>
                    <li className={classes.properListItem}>The impact of using these tools to ensure success within their department and organization</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Leadership is about boundaries, standards, and consequences</li>
                    <li className={classes.properListItem}>Boundaries – A line that marks the limits of an area.</li>
                    <li className={classes.properListItem}>Standards – A level of quality or attainment.</li>
                    <li className={classes.properListItem}>Consequences - A result or effect of an action.</li>
                    <li className={classes.properListItem}>Ken Blanchard – a leadership author, said “Goals begin behaviors, consequences maintain behaviors “</li>
                    <li className={classes.properListItem}>Explain the coaching model (Demonstrate, Coach to Standards and Follow-up)</li>
                    <li className={classes.properListItem}>Too often we provide information to our employees and we expect behavior to change, yet this rarely happens</li>
                    <li className={classes.properListItem}>As leaders we need to do a few things</li>
                    <li className={classes.properListItem}>We need to demonstrate and set expectations for the behavior</li>
                    <li className={classes.properListItem}>We need to coach to close the gap between current and expected behavior</li>
                    <li className={classes.properListItem}>We need to follow up on the changed behavior and implementation of the information</li>
                </ul>
                <Typography variant="h5">Demonstrate -</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>STEP 1:  </li>
                    <li className={classes.properListItem}>Set an example for others to follow</li>
                    <li className={classes.properListItem}>Establish expectations</li>
                    <li className={classes.properListItem}>Walk the talk and demonstrate expected behaviors</li>
                    <li className={classes.properListItem}>Build relationships
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>The first step is to set expectations </li>
                            <li className={classes.properListItem}>We will put you in breakouts for this activity – first let’s look at the process for setting expectations</li>
                        </ul>
                    </li>
                </ul>
                <Typography variant="h5">Setting Expectations</Typography>    
                <ol>
                    <li>Define the task</li>
                    <li>Set boundaries</li>
                    <li>Clarify roles</li>
                    <li>Establish the standards</li>
                    <li>Provide feedback</li>
                </ol>
                <Typography variant="h5">STEP 2:  </Typography>    
                <ol >
                    <li><b>Observe</b> performance</li>
                    <li><b>Compare</b> performance to the CertainTeed <b>standards</b> and <b>expectations</b></li>
                    <li>Provide guidance/coach to <b>close the gap</b> in performance by teaching how to meet the standard</li>
                </ol>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Have participants add examples of company standards to the chat box</li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise 1</Typography>
                <Typography variant="h5">Setting Expectations</Typography>     
 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>In breakout groups of functional teams, choose a work topic that needs clear expectations</li>
                    <li className={classes.properListItem}>Follow these 5 steps to set the expectation</li>
                    <li className={classes.properListItem}>
                        <ol >
                            <li>Define the task</li>
                            <li>Set boundaries</li>
                            <li>Clarify roles</li>
                            <li>Establish the standards</li>
                            <li>Provide feedback</li>
                        </ol>
                    </li>
                    <li className={classes.properListItem}><b>Take notes and choose a spokesperson share with the group.</b></li>  
                </ul>
                <Typography variant="h5">Exercise 2</Typography>
                <Typography variant="h5">Fostering Accountability</Typography>     
 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>In the same groups, using the Expectation that you worked on in the 1st exercise, walk through these 5 steps to foster accountability.</li>
                    <li className={classes.properListItem}>Take the task you set expectations for the Supervisors</li>
                    <li className={classes.properListItem}>What will you do at each step listed here to hold the Supervisor accountable?</li>
                    <li className={classes.properListItem}>
                        <ol >
                            <li>Clear expectations</li>
                            <li>Clear capability</li>
                            <li>Clear measurement</li>
                            <li>Clear feedback</li>
                            <li>Clear consequences</li>
                        </ol>
                    </li> 
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What impact does setting clear expectations and fostering accountability have on running a safe and productive business?</li>                          
                </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
          
           
        </div> 
    )
}

export default withStyles(styles)(ExpecatationsAndAccountabilitiesLN);