
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DiSC_Icon from '../images/DiSC_Icon.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup } from '../../game_functions/functions'
import { updateGroupState } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';

class Instructions extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    sendToNextStep = (event) => {
        if ( this.props.profile.role === 'host' ){
            let groupID = this.props.session.hostState.params.group ? this.props.session.hostState.params.group : null;
            let s = { 
                    'state' : GROUP_STATE_ACTIVE,
                    'params' : {}
            };
            this.props.updateGroupState(groupID, s, this.props.auth.uid); 
        } else {
            let groupID = getUserGroup(this.props.session, this.props.auth.uid);
            let s = { 
                    'state' : GROUP_STATE_ACTIVE,
                    'params' : {}
            };
            this.props.updateGroupState(groupID, s, this.props.profile.sessionHost);
        }
    }

    render(){
        const { session, profile, auth, classes, leader } = this.props;

        return (
            <div>
               <Typography variant='h3' className={classes.spaceBottom}>
                    {session.active_game.name}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={DiSC_Icon} style={{width: '90%', maxWidth: '450px'}}alt="Game"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                       <Typography variant='body1' className={classes.spaceBottom}>Different people find different aspects of conflict to be most significant. These can be defined as what we value in our approach to conflict.<br/><br/>
                        The aspects of conflict that are particularly stressful for you, can drain your energy during conflict.  Refer to your conflict profile for this exercise.</Typography>
                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            <Button type='submit' color="primary" variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return { };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID))
    };
  };
  
  export default compose( connect(mapStateToProps, mapDispatchToProps), withStyles(styles) )(Instructions);