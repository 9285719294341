import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import bbss_logo_line from './../images/bbss_logo_line.png';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
    }

    moveToFloorExercise = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor' });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.session.resultSnapshot === undefined ? {} : this.props.session.resultSnapshot, this.props.session.results)
        })
    }

    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      session,
      classes,
    } = this.props;

        return (
        <div>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <img src={bbss_logo_line} alt='bring and brag share and steal logo' style={{maxWidth: "65%" }}/>
                <Typography variant='h5' className={classes.spaceBottom} style={{marginLeft: "8px"}}>
                    Debrief
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(session.active_game.results)
                .sort()
                .map((groupKey, ind) => {
                    if ( session.active_game.results[groupKey].boardresults !== undefined ){
                        return (
                        <div key={ind}>
                            <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[groupKey].title}</Typography>
                            <div>
                                <ul className="thumb-list">
                            { Object.keys(session.active_game.results[groupKey].boardresults).sort().map((objKey, index) => {
                                    const name = session.playerProfiles[session.active_game.results[groupKey].boardresults[objKey].uid] === undefined ?
                                    'Host / Moderator' :
                                    session.playerProfiles[session.active_game.results[groupKey].boardresults[objKey].uid].displayName;
                                return (
                                    <li key={index}>
                                        <Grid container>
                                            <Grid item xs={4} md={3} align="center" style={{padding:"15px", backgroundColor: "#ccc"}}>
                                                <Avatar className={` ${classes.medium} ${classes.glassPurpleAvatar}`}></Avatar>
                                                <Typography variant='body1' className={classes.nameSpacing} style={{fontWeight: "bold"}}>
                                                {name ? name : 'New Player'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={8} md={9} style={{padding: "15px"}}>
                                                <div dangerouslySetInnerHTML={{__html:session.active_game.results[groupKey].boardresults[objKey].text}}></div>
                                            </Grid>
                                        </Grid>  
                                    </li>
                                );
                            })}
                                </ul>
                            </div>
                        </div>
                        );
                    } else {
                        return null;
                    }
                })}
            </Grid>
            </Grid>
        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);