import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import { determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, editResultsPath, removeResultsPath, updateGroupStateStateVal } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Box from '@material-ui/core/Box';
import TapIn from './../specific_components/tapIn';
import PuzzleClue from './../specific_components/puzzleClue';
import TangramMS from './../specific_components/tangram';
import PopupAlert from './../specific_components/popupAlert';

const INITIAL_STATE = {
  open: false,
  curColumn: null,  
  width: window.innerWidth,
  height: window.innerHeight,
  wasOpen: false,
  tab: 0
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };   
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ 
      ...this.state, 
      width: window.innerWidth, 
      height: window.innerHeight 
    });
  }

  handleTabChange = (event, newValue) => {
    this.setState({
      ...this.state,
      tab: newValue
    })
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      ...this.state,     
      width: window.innerWidth, 
      height: window.innerHeight 
    });
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState) { }

  finishActivity = () => {
    let groupID = this.props.group_id;
  
    /* TODO: Update group state, but ensure it doesn't touch the params */
    this.props.updateGroupStateStateVal(groupID, GROUP_STATE_RESULTS, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });    
  };

  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  render() {
    const {
      session,
      profile,
      auth,
      group_id,
      classes,
      game_options,
    } = this.props;
    

    console.log('the options: ', game_options);
    return ( 
      <div>
        <Typography variant='h3' className={classes.spacingBottom} style={{display: "inline-block"}}>
            {game_options.game_texts.game_title}
        </Typography>
        <PopupAlert session={session}/>
        { 
        profile.role === 'host' || profile.role === 'moderator' ?
          <div>
              <PuzzleClue puzzle={session.active_game.Puzzle === undefined ? game_options.game_misc.Puzzle : session.active_game.Puzzle} session={session} profile={profile} auth={auth} group_id={group_id} role='host'/>
              <TangramMS puzzle={session.active_game.Puzzle === undefined ? game_options.game_misc.Puzzle : session.active_game.Puzzle} session={session} profile={profile} auth={auth} group_id={group_id} host={determineHost(this.props.profile, this.props.auth)} role='host'/>
            </div>
        :
          session.active_game.groupStates[group_id].params.engineer === auth.uid ?
            /* YOU ARE THE LEAD ENGINEER */
            <div>
              <TapIn puzzle={session.active_game.Puzzle === undefined ? game_options.game_misc.Puzzle : session.active_game.Puzzle} session={session} profile={profile} auth={auth} group_id={group_id} engineer={session.active_game.groupStates[group_id].params.engineer} contractor={session.active_game.groupStates[group_id].params.contractor} role="Lead Engineer" host={determineHost(this.props.profile, this.props.auth)}/>
              <PuzzleClue puzzle={session.active_game.Puzzle === undefined ? game_options.game_misc.Puzzle : session.active_game.Puzzle} session={session} profile={profile} auth={auth} group_id={group_id} role='engineer' />
            </div>
          : 
            /* YOU ARE THE CONTRACTOR, OR TAPPED-OUT ENGINEERS */
            <div>
              <TapIn puzzle={session.active_game.Puzzle === undefined ? game_options.game_misc.Puzzle : session.active_game.Puzzle} session={session} profile={profile} auth={auth} group_id={group_id} engineer={session.active_game.groupStates[group_id].params.engineer} host={determineHost(this.props.profile, this.props.auth)} contractor={session.active_game.groupStates[group_id].params.contractor} role={ session.active_game.groupStates[group_id].params.contractor === auth.uid ? 'Contractor' : profile.role === 'host' || profile.role === 'moderator' ? profile.role : 'Engineer' }/>
              <TangramMS puzzle={session.active_game.Puzzle === undefined ? game_options.game_misc.Puzzle : session.active_game.Puzzle} session={session} profile={profile} auth={auth} group_id={group_id} host={determineHost(this.props.profile, this.props.auth)} role={session.active_game.groupStates[group_id].params.contractor === auth.uid ? 'Contractor' : 'Engineer'}/>
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
    removeResultsPath: (group, value, hostID) => dispatch(removeResultsPath(group, value, hostID))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);