import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { updateGroupStateParamsVal, updateResultsParamsVal, editResultsPath, updateGroupStateStateVal } from '../../../../../store/actions/gameActions';
import paper from "paper/dist/paper-core"
import { Tangram } from './tangramImport';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { GROUP_STATE_RESULTS, GAME_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import styles from '../../../../Styles';

const INITIAL_STATE = {
    timerActive: true,
    currentInterruption: null
}

class TangramMS extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
        
    }

    componentWillUnmount(){
        paper.project.remove();      
    }

    finishActivity = () => {
        let groupID = this.props.group_id;
      
        let now = Date.now();
        this.props.updateGroupStateParamsVal(groupID, 'finishedOn', now, this.props.host); 
        let timeelapsed = now - this.props.session.active_game.groupStates[groupID].params.startedOn;
        let secondsElapsed = Math.floor(timeelapsed / 1000);

        this.props.updateResultsParamsVal(groupID, 'finishedIn', secondsElapsed, this.props.host); 
        /* TODO: Update group state, but ensure it doesn't touch the params */
        this.props.updateGroupStateStateVal(groupID, GROUP_STATE_RESULTS, this.props.host);
      };

   
    updateTangramCoords = (id, x, y, rotation, originalRotation = null) => {
        let path = `active_game.results.${this.props.group_id}.tangramCoords.${id}`;
        let val = ``;
        if ( originalRotation !== null ){
            val = {
                x: x,
                y: y,
                rotation: rotation,
                originalRotation: originalRotation
            }
        } else {
            val = {
                x: x,
                y: y,
                rotation: rotation,
                originalRotation: this.props.session.active_game.results[this.props.group_id].tangramCoords[id].originalRotation
            }
        }
        
        this.props.editResultsPath(path, val, this.props.host);
    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <div> 
                    { this.props.role === 'Contractor' 
                    ?
                        <Typography variant="body1"  style={{marginTop: "15px"}}>As the contractor, use the verbal clues given to you by the Lead Engineer to complete the puzzle below. When finished, hit the <span style={{fontWeight: "bold"}}>I'm Finished</span> button below the puzzle.</Typography>
                    :
                        this.props.profile.role !== 'host' && this.props.profile.role !== 'moderator' && this.props.session.active_game.gameState.state !== GAME_STATE_RESULTS ?
                        <Typography variant="body1" style={{marginTop: "15px"}}>As an engineer who hasn't tapped in for lead position, you can watch as the contractor attempts to commplete the puzzle. To tap-in and take a turn at describing how to complete the puzzle, hit the tap in button in the top right. The Lead Engineer can then either confirm or deny your request to tap in.<br/><br/>Only the Lead Engineer with the drawing can speak. Observing Engineers can only watch until they tap in.</Typography>
                        :
                        null
                    }
                </div>
                <div style={{height: "700px", width: "920px", display: "flex", border: "1px solid #000", borderRadius: "5px", marginTop: "30px"}}>
                    <Tangram role={this.props.role} tangramCoords={this.props.session.active_game.results[this.props.group_id] !== undefined ? this.props.session.active_game.results[this.props.group_id].tangramCoords : undefined} updateCoords={this.updateTangramCoords}></Tangram>
                </div>
                { this.props.role === 'Contractor' ?
                <div className={classes.spacingTop}>
                    <Button variant="contained" color="primary" onClick={() => this.finishActivity()}>I'm Finished</Button>
                </div>
                : null }
            </div>
        ); 
    
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {        
        updateGroupStateParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateGroupStateParamsVal(group, val, groupStateParams, hostID)),
        updateResultsParamsVal : (group, val, groupStateParams, hostID) => dispatch(updateResultsParamsVal(group, val, groupStateParams, hostID)),
        updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID))
    };
};
  
export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TangramMS);