import Logo from '../images/Logo.png'
import twoLongRed from '../images/svgs/red/2_long.svg'
import twoLongPink from '../images/twoLongPink.png'
import twoLongGrey from '../images/svgs/grey/2_long.svg'
import twoLongBlue from '../images/svgs/blue/2_long.svg'
import twoLongYellow from '../images/svgs/yellow/2_long.svg'
import twoLongHalfHeight from '../images/svgs/black_window_swoop/2_long.svg'
import threeLongRed from '../images/svgs/red/3_long.svg'
import threeLongPink from '../images/threeLongPink.png'
import threeLongGrey from '../images/svgs/grey/3_long.svg'
import threeLongBlue from '../images/svgs/blue/3_long.svg'
import threeLongYellow from '../images/svgs/yellow/3_long.svg'
import fourLongRed from '../images/svgs/red/4_long.svg'
import fourLongPink from '../images/fourLongPink.png'
import fourLongGrey from '../images/svgs/grey/4_long.svg'
import sixLongRed from '../images/svgs/red/6_long.svg'
import sixLongPink from '../images/sixLongPink.png'
import sixLongGrey from '../images/svgs/grey/6_long.svg'
import sixLongYellow from '../images/svgs/yellow/6_long.svg'
import sixLongHalfHeight from '../images/svgs/blue/6_long.svg'
import eightLongYellow from '../images/svgs/yellow/8_long.svg'
import tenLongRed from '../images/svgs/red/10_long.svg'
import tenLongBrown from '../images/svgs/black_window_swoop/10_long.svg'
import fourteenLongRed from '../images/svgs/red/14_long.svg'
import window from '../images/svgs/black_window_swoop/window.svg'
import swoop from '../images/svgs/black_window_swoop/swoop.svg'

import {
    BASE_PIECE_LENGTH,
    BASE_PIECE_HEIGHT,
    NUB_SIZE,
    NUB_RADIUS,
    COLLISION_MARGIN
} from './../utils/constants'

export const IMPORTANT = '1'
export const NOT_IMPORTANT = '0'
export const VERY_IMPORTANT = '2'

export const IMPORTANCE = {
    [IMPORTANT]: 'Important',
    [NOT_IMPORTANT]: 'Not Important',
    [VERY_IMPORTANT]: 'Very Important',
    [-1]: 'Unsorted'
}

export const PROFILE_MALE =
    'Microsoft Guy Online (Natural) - English (United States)'
export const PROFILE_FEMALE =
    'Microsoft Clara Online (Natural) - English (Canada)'

export const PROFILE_MALE2 = 'Google UK English Male'
export const PROFILE_FEMALE2 = 'Google UK English Female'

export const CARDS = [
    {
        id: 1,
        text: 'Make sure that no grey blocks are used in the first or last row'
    },
    {
        id: 2,
        text: 'Make sure that no white block touches a red block'
    },
    {
        id: 3,
        text: 'Make sure there are 4 white blocks touching each other horizontally in one row'
    },
    {
        id: 4,
        text: 'Make sure that no row contains more than 4 colors'
    },
    {
        id: 5,
        text: 'Make sure that the two windows are evenly spaced'
    }
]

export const BLOCKS_SIDEBAR = [
    {
        id: 1,
        type: 'twoLongRed',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 2,
        type: 'twoLongRed',   
        baseType: 'twoLong',     
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 3,
        type: 'twoLongRed',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 4,
        type: 'twoLongRed',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 5,
        type: 'twoLongRed',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 6,
        type: 'twoLongRed',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 7,
        type: 'twoLongPink',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 8,
        type: 'twoLongPink',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 9,
        type: 'twoLongPink',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 10,
        type: 'twoLongPink',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 11,
        type: 'twoLongPink',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 12,
        type: 'twoLongPink',
        baseType: 'twoLong',
        func: 'block',
        color: 'red',
        image: twoLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 13,
        type: 'twoLongGrey',
        baseType: 'twoLong',
        func: 'block',
        color: 'grey',
        image: twoLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 14,
        type: 'twoLongGrey',
        baseType: 'twoLong',
        func: 'block',
        color: 'grey',
        image: twoLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 15,
        type: 'twoLongGrey',
        baseType: 'twoLong',
        func: 'block',
        color: 'grey',
        image: twoLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 16,
        type: 'twoLongBlue',
        baseType: 'twoLong',
        func: 'block',
        color: 'blue',
        image: twoLongBlue,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 17,
        type: 'twoLongYellow',
        baseType: 'twoLong',
        func: 'block',
        color: 'yellow',
        image: twoLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 18,
        type: 'twoLongYellow',
        baseType: 'twoLong',
        func: 'block',
        color: 'yellow',
        image: twoLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 19,
        type: 'twoLongYellow',
        baseType: 'twoLong',
        func: 'block',
        color: 'yellow',
        image: twoLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 20,
        type: 'twoLongHalfHeight',
        baseType: 'twoLongHalfHeight',
        func: 'block',
        color: 'black',
        image: twoLongHalfHeight,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT / 2,
    },
    {
        id: 21,
        type: 'threeLongRed',
        baseType: 'threeLong',
        func: 'block',
        color: 'red',
        image: threeLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 22,
        type: 'threeLongRed',
        baseType: 'threeLong',
        func: 'block',
        color: 'red',
        image: threeLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 23,
        type: 'threeLongPink',
        baseType: 'threeLong',
        func: 'block',
        color: 'red',
        image: threeLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 24,
        type: 'threeLongPink',
        baseType: 'threeLong',
        func: 'block',
        color: 'red',
        image: threeLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 25,
        type: 'threeLongPink',
        baseType: 'threeLong',
        func: 'block',
        color: 'grey',
        image: threeLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 26,
        type: 'threeLongPink',
        baseType: 'threeLong',
        func: 'block',
        color: 'grey',
        image: threeLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 27,
        type: 'threeLongGrey',
        baseType: 'threeLong',
        func: 'block',
        color: 'grey',
        image: threeLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 28,
        type: 'threeLongBlue',
        baseType: 'threeLong',
        func: 'block',
        color: 'blue',
        image: threeLongBlue,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 29,
        type: 'threeLongBlue',
        baseType: 'threeLong',
        func: 'block',
        color: 'blue',
        image: threeLongBlue,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 30,
        type: 'threeLongBlue',
        baseType: 'threeLong',
        func: 'block',
        color: 'blue',
        image: threeLongBlue,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 31,
        type: 'threeLongYellow',
        baseType: 'threeLong',
        func: 'block',
        color: 'yellow',
        image: threeLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 32,
        type: 'threeLongYellow',
        baseType: 'threeLong',
        func: 'block',
        color: 'yellow',
        image: threeLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 33,
        type: 'threeLongYellow',
        baseType: 'threeLong',
        func: 'block',
        color: 'yellow',
        image: threeLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 2.5) + 2,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 34,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 35,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 36,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 37,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 38,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 39,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 40,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 41,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 101,
        type: 'fourLongRed',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 42,
        type: 'fourLongPink',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 43,
        type: 'fourLongPink',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 44,
        type: 'fourLongPink',
        baseType: 'fourLong',
        func: 'block',
        color: 'red',
        image: fourLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 45,
        type: 'fourLongGrey',
        baseType: 'fourLong',
        func: 'block',
        color: 'grey',
        image: fourLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 3) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 46,
        type: 'sixLongRed',
        baseType: 'sixLong',
        func: 'block',
        color: 'red',
        image: sixLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 47,
        type: 'sixLongRed',
        baseType: 'sixLong',
        func: 'block',
        color: 'red',
        image: sixLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 48,
        type: 'sixLongPink',
        baseType: 'sixLong',
        func: 'block',
        color: 'red',
        image: sixLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 49,
        type: 'sixLongPink',
        baseType: 'sixLong',
        func: 'block',
        color: 'red',
        image: sixLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 50,
        type: 'sixLongGrey',
        baseType: 'sixLong',
        func: 'block',
        color: 'grey',
        image: sixLongGrey,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 51,
        type: 'sixLongYellow',
        baseType: 'sixLong',
        func: 'block',
        color: 'yellow',
        image: sixLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 52,
        type: 'sixLongYellow',
        baseType: 'sixLong',
        func: 'block',
        color: 'yellow',
        image: sixLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 53,
        type: 'sixLongHalfHeight',
        baseType: 'sixLongHalfHeight',
        func: 'block',
        color: 'blue',
        image: sixLongHalfHeight,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 5) + 6,
        blockHeight: BASE_PIECE_HEIGHT / 2,
    },
    {
        id: 54,
        type: 'eightLongYellow',
        baseType: 'eightLong',
        func: 'block',
        color: 'yellow',
        image: eightLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 6) + 26,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 101,
        type: 'eightLongRed',
        baseType: 'eightLong',
        func: 'block',
        color: 'yellow',
        image: eightLongYellow,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 6) + 26,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 55,
        type: 'tenLongRed',
        baseType: 'tenLong',
        func: 'block',
        color: 'red',
        image: tenLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 8) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 56,
        type: 'tenLongBlack',
        baseType: 'tenLong',
        func: 'block',
        color: 'black',
        image: tenLongBrown,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 8) + 14,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 57,
        type: 'fourteenLongRed',
        baseType: 'fourteenLong',
        func: 'block',
        color: 'red',
        image: fourteenLongRed,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 11) + 26,
        blockHeight: BASE_PIECE_HEIGHT,
    },
    {
        id: 58,
        type: 'window',
        baseType: 'window',
        func: 'window',
        color: 'grey',
        image: window,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT * 2,
    },
    {
        id: 59,
        type: 'window',
        baseType: 'window',
        func: 'window',
        color: 'grey',
        image: window,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: BASE_PIECE_LENGTH * 2,
        blockHeight: BASE_PIECE_HEIGHT * 2,
    },
    {
        id: 60,
        type: 'swoop',
        baseType: 'swoop',
        func: 'swoop',
        color: 'green',
        image: swoop,
        size: {
            width: 100,
            height: 20
        },
        previewSize: {
            width: 100,
            height: 20
        },
        blockWidth: (BASE_PIECE_LENGTH * 4) + 9,
        blockHeight: BASE_PIECE_HEIGHT,
    }
]

export const BLOCK_COUNTS = {
    twoLongRed: 6,
    twoLongPink: 6,
    twoLongGrey: 3,
    twoLongBlue: 1,
    twoLongYellow: 3,
    twoLongHalfHeight: 1,
    threeLongRed: 2,
    threeLongPink: 4,
    threeLongGrey: 1,
    threeLongBlue: 3,
    threeLongYellow: 3,
    fourLongRed: 8,
    fourLongPink: 3,
    fourLongGrey: 1,
    sixLongRed: 2,
    sixLongPink: 2,
    sixLongGrey: 1,
    sixLongYellow: 2,
    sixLongHalfHeight: 1,
    eightLongYellow: 1,
    tenLongRed: 1,
    tenLongBrown: 1,
    fourteenLongRed: 1,
    window: 2,
    swoop: 1,
    ell: 1
}
