import React, { Component } from 'react';
import { compose } from 'redux';
import { firestoreConnect,  isLoaded, isEmpty  } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import GroupView from './groupView';
import HostView from './hostView';
import ModeratorView from './moderatorView';
import { determineHost } from './games/game_functions/functions';
import LoadingBlock from '../Loading';
import { gameHasOptions, gameOptions } from '../Game/gameServer';

const styles = {
  root: {
    flexGrow: 1,
  },
  session: {},
};

class Game extends Component {
  render() {
    const { id, title, session, profile, auth, gameInfo } = this.props;
    if ( this.props.game_options === undefined || !isLoaded(this.props.user_options) || this.props.user_options === undefined ){
      return <LoadingBlock/>;
    }

    const DEFAULT = 'default';

    let options = this.props.game_options !== undefined ? this.props.game_options[id] !== undefined ? this.props.game_options[id] : false : false;
    //console.log("first options: ", options);
    if ( options !== false && options !== undefined ){
      let host = determineHost(profile, auth);

      let usingSets = options[host] !== undefined ? 
        options[host].selectedSet === undefined ? false : true
        : false;

      


      if ( usingSets ){
        // we are using a new set
        let set = usingSets ? options[host].selectedSet : DEFAULT; 
        if ( set === DEFAULT ){
          options = options.default;
          //console.log('default: ', options);
        } else {
          options = this.props.user_options[set];
          //console.log('user opt: ', this.props.user_options, set);
        }
      } else {
        options = options[host] !== undefined ? options[host] : options.default;
      }


      
    }
    if (profile.role === 'host') {
      return <HostView gameHasOptions={gameHasOptions(id)} inlineGameOptions={gameOptions(id)} game_options={options} game_id={id} session={session} game_title={title} profile={profile} auth={auth} game_state={session.active_game.gameState.state} host_state={session.hostState} gameInfo={gameInfo} />;
    } else if (profile.role === 'moderator' ){ 
      return <ModeratorView gameHasOptions={gameHasOptions(id)} inlineGameOptions={gameOptions(id)} game_options={options} game_id={id} session={session} game_title={title} profile={profile} auth={auth} game_state={session.active_game.gameState.state} moderator_state={session.moderatorStates} gameInfo={gameInfo} />;
    } else if (profile.role === 'player') {
      return <GroupView game_options={options} game_id={id} session={session} game_title={title} profile={profile} auth={auth} game_state={session.active_game.gameState.state} group_states={session.active_game.groupStates} gameInfo={gameInfo} />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    gameInfo: state.game_state,
    game_options: state.firestore.data.game_options,
    user_options: state.firestore.data.user_options
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation(),
firestoreConnect((props) => {
  return [
    {
      collection: 'game_options'
    },
    {
      collection: 'user_options',
      where: [ ['game', '==', props.id], ['uid', '==', determineHost(props.profile, props.auth)] ]   
    }
  ];
}))(Game);