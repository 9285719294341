import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { compose } from 'redux'

import { withStyles } from '@material-ui/core/styles'
import {
    updateGroupStateParamsVal,
    editResultsPath,
    updateGroupStateStateVal,
    removeResultsPath,
    updateResultsPath
} from '../../../../../store/actions/gameActions'
//import { createPiecesGroup } from './../utils/createPiecesGroup';

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import {
    //GAME_STATE_INIT, GAME_STATE_ACTIVE, GAME_STATE_RESULTS, HOST_STATE_GROUP_SELECT, HOST_STATE_GROUP, HOST_STATE_MAIN,
    //HOST_STATE_RESULTS, GROUP_STATE_INIT, GROUP_STATE_ACTIVE,
    GROUP_STATE_RESULTS,
    GROUP_STATE_ACTIVE,
    GAME_STATE_RESULTS
} from '../../../../../store/actions/gameActions'

import Drawer from '@material-ui/core/Drawer'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import IconButton from '@material-ui/core/IconButton'
import VerticalAlignCenterIcon from '@material-ui/icons/VerticalAlignCenter'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExtensionIcon from '@material-ui/icons/Extension'
import { pieces } from './../data/data'
import AddIcon from '@material-ui/icons/Add'
import bbss_logo from './../images/bbss_logo.png'
import Popover from '@material-ui/core/Popover'
import Suggestions from './suggestions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import { v4 as uuidv4 } from 'uuid'

import WordPlayground from './wordPlayground'

import styles from '../../../../Styles'
import PieceDrawer from './pieceDrawer'

//import { wordGroups } from './../data/data';

const INITIAL_STATE = {
    mouseX: null,
    mouseY: null,
    currentSlot: null,
    pieceListOpen: false,
    popoverOpen: false,
    anchorEl: null,
    currentPiece: null,
    drawingEnabled: false,
    eraserEnabled: false,
    draggingSuggestion: false,
    scrollPosition: 0,
    scrollBound: false,
    suggestText: '',
    suggestOpen: false
}

class PieceList extends Component {
    constructor(props) {
        super(props)

        this.draggedEl = React.createRef()
        //this.scrollContainer = React.createRef();
        this.state = { ...INITIAL_STATE }
    }

    componentDidMount() {
        //console.log('ok', this.scrollContainer);
        //if ( this.scrollContainer.current !== null ){
        //this.scrollContainer.current.addEventListener('scroll', this.scrollMonitor);
        //}
    }

    componentWillUnmount() {
        //this.scrollContainer.current.removeEventListener('scroll', this.scrollMonitor)
        //paper.project.remove();
    }

    openMenu(event, index, word) {
        event.preventDefault()
        event.stopPropagation()
        if (!this.props.hasControl) {
            return
        }

        //console.log('trhe index?', index);

        this.setState({
            ...this.state,
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            currentSlot: index
        })
    }

    handleContextClose = () => {
        this.setState({
            ...this.state,
            mouseX: null,
            mouseY: null,
            currentSlot: null
        })
    }

    openPieceList = () => {
        this.setState({
            ...this.state,
            pieceListOpen: true
        })
    }
    closePieceList = () => {
        this.setState({
            ...this.state,
            pieceListOpen: false
        })
    }
    handlePopoverClose = () => {
        this.setState({
            ...this.state,
            pieceListOpen: false,
            popoverOpen: false,
            anchorEl: null,
            currentPiece: null
        })
    }
    handlePopoverOpen = (event, piece) => {
        this.setState({
            ...this.state,
            anchorEl: event.currentTarget,
            popoverOpen: true,
            currentPiece: piece
        })
    }

    dragStart = (ev) => {
        console.log('in here?')
        let w = ev.target.attributes.pwidth.value
        let h = ev.target.attributes.pheight.value

        ev.dataTransfer.setData('text', ev.target.id)
        ev.dataTransfer.setDragImage(ev.target, 10, 10)

        let r = {
            src: ev.target.attributes.src.value,
            id: ev.target.attributes.piece.value,
            width: w,
            height: h
        }
        console.log('O: ', r, ev.target)

        this.draggedEl.current = r

        console.log(this.draggedEl.current)

        console.log('piece', ev.target.attributes.piece.value)
        console.log('piece size', ev.target.attributes.pwidth.value)
        setTimeout(() => this.closePieceList(), 150)
    }

    clearDraggedEl = () => {
        this.draggedEl.current = null
        this.setState({
            ...this.state,
            draggingSuggestion: false
        })
    }

    updateLines = (type, line) => {
        let path = `active_game.results.${this.props.group_id}.lines`
        if (type === 'pop') {
            this.props.editResultsPath(path, line, this.props.host)
        } else if (type === 'add') {
            this.props.updateResultsPath(path, line, this.props.host)
        }
    }

    updateImages = (type, image) => {
        /* Types: add, update, remove */
        let path = `active_game.results.${this.props.group_id}.images`
        if (type === 'add') {
            this.props.updateResultsPath(path, image, this.props.host)
        } else if (type === 'remove') {
            this.props.editResultsPath(path, image, this.props.host)
        } else if (type === 'update') {
            this.props.editResultsPath(path, image, this.props.host)
        }
    }

    suggestThis = () => {
        let uniqid = uuidv4()
        let path = `active_game.results.${this.props.group_id}.suggestions.${uniqid}`
        this.props.editResultsPath(
            path,
            {
                ...this.state.currentPiece,
                suggestedBy: this.props.auth.uid,
                vetted: false,
                text: this.state.suggestText,
                suggestId: uniqid,
                added: Date.now()
            },
            this.props.host
        )
        this.handleClose()
    }
    openSuggest = () => {
        console.log('what?')
        this.handlePopoverClose()
        this.setState({
            ...this.state,
            suggestOpen: true,
            popoverOpen: false,
            suggestText: ''
        })
    }

    assignDraggedEl = (el) => {
        console.log('assigning?', el)
        this.draggedEl.current = el
        console.log('how is it not done? ', this.draggedEl.current)
    }

    toggleSuggestionDrag = (val) => {
        this.setState({
            ...this.state,
            draggingSuggestion: val
        })
    }

    onChange = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        })
    }
    handleClose = (event) => {
        this.setState({
            ...this.state,
            suggestOpen: false,
            pieceListOpen: false,
            popoverOpen: false
        })
    }

    scrollMonitor = (e) => {
        //e.target.scrollTop
        console.log('huh?')
        let s = e.target.scrollTop - this.state.scrollPosition
        if (s > 100 || s < 100) {
            console.log('test')
            this.setState({
                ...this.state,
                scrollPosition: e.target.scrollTop
            })
        }
    }

    /*
    deletePic (deleteId) {
  let docId = `${this.currentUser.uid}`

   //deleteId is the id from the post you want to delete

   fb.usersCollection.doc(docId).update({
     posts: posts.filter(post => post.id !== deleteId);
   })
  .catch(function(error) {
      console.error("Error removing document: ", error);
  });
}

    */

    render() {
        const { classes, session, hasControl, group_id, profile, host, auth } =
            this.props
        const pieceList = []

        return (
            <div>
                {/* DIALOG FOR SUGGESTIONS */}

                <Dialog
                    open={this.state.suggestOpen}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                    fullWidth
                >
                    <DialogContent>
                        <DialogContentText className={classes.bodyText}>
                            What does this piece represent and why?
                        </DialogContentText>
                        <TextField
                            multiline
                            autoFocus
                            autoComplete="off"
                            margin="dense"
                            id="suggestText"
                            name="suggestText"
                            value={this.state.suggestText}
                            onChange={this.onChange}
                            label={this.state.curColumn}
                            type="email"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <div>
                            <Button onClick={this.handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                disabled={
                                    this.state.suggestText.length > 0
                                        ? false
                                        : true
                                }
                                onClick={() => this.suggestThis()}
                                color="primary"
                            >
                                Save
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>

                {/* BUTTON TO TOGGLE THE PIECE LIST DRAWER */}

                <Button
                    variant="contained"
                    color="primary"
                    disabled={this.state.pieceListOpen === true ? true : false}
                    startIcon={<ExtensionIcon />}
                    onClick={() => this.openPieceList()}
                    style={{ marginRight: '20px' }}
                >
                    Open Piece List
                </Button>

                {/* THE POPOVER TO BE USED FOR NON-LEADERS */}

                <Popover
                    id={`pieceListMenu`}
                    open={this.state.popoverOpen}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handlePopoverClose}
                    PaperProps={{
                        style: {
                            padding: '20px'
                        }
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={() => this.openSuggest()}
                        >
                            Suggest this Piece
                        </Button>
                    </div>
                </Popover>

                {/* THE DRAWER */}

                <PieceDrawer
                    open={this.state.pieceListOpen}
                    close={this.closePieceList}
                    classes={classes}
                    pieces={pieces}
                    dragStart={this.dragStart}
                    handlePop={this.handlePopoverOpen}
                    hasControl={hasControl}
                />

                {/* THE CANVAS */}
                <Suggestions
                    session={session}
                    results={
                        session.active_game.results[this.props.group_id] ===
                        undefined
                            ? []
                            : session.active_game.results[this.props.group_id]
                                  .suggestions === undefined
                            ? []
                            : session.active_game.results[this.props.group_id]
                                  .suggestions
                    }
                    profile={this.props.profile}
                    auth={this.props.auth}
                    host={this.props.host}
                    control={hasControl}
                    group_id={this.props.group_id}
                    assignDragEl={this.assignDraggedEl}
                    updateDragState={this.toggleSuggestionDrag}
                />
                <div className={classes.spacingTop}>
                    <WordPlayground
                        session={session}
                        title={
                            session.groups[this.props.group_id]
                                .title
                        }
                        results={
                            session.active_game.results[this.props.group_id] ===
                            undefined
                                ? {}
                                : session.active_game.results[
                                      this.props.group_id
                                  ]
                        }
                        draggedEl={this.draggedEl.current}
                        clearDraggedEl={this.clearDraggedEl}
                        updateLines={this.updateLines}
                        updateImages={this.updateImages}
                        control={hasControl}
                        profile={profile}
                        host={host}
                        auth={auth}
                        group_id={group_id}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    //console.log('this is my state', state);
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupStateParamsVal: (group, val, groupStateParams, hostID) =>
            dispatch(
                updateGroupStateParamsVal(group, val, groupStateParams, hostID)
            ),
        updateGroupStateStateVal: (group, groupState, hostID) =>
            dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        editResultsPath: (path, value, hostID) =>
            dispatch(editResultsPath(path, value, hostID)),
        removeResultsPath: (path, value, hostID) =>
            dispatch(removeResultsPath(path, value, hostID)),
        updateResultsPath: (path, value, hostID) =>
            dispatch(updateResultsPath(path, value, hostID))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(PieceList)
