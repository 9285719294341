import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut } from '../../store/actions/authActions';
import { removePlayerSignOut } from '../../store/actions/gameActions';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import styles from './../Styles';
import { getUserGroup_hc } from './../../components/Game/games/game_functions/functions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import GOLLogo from './images/GoL-ClearGlass-Logo.png';


const signOutLinks = (auth, session, t, classes) => {
  if ( auth === undefined ) {
    return false;
  }
  if ( window.location.pathname !== '/p' && window.location.pathname.substring(0, 3) !== '/p/' ){
    return true;
  }
  return false;
}


class DefaultFooter extends Component{

  signUserOut = () => {
    if ( this.props.profile.role === 'host' || this.props.profile.role === 'moderator' ){
      this.props.signOut();
    } else {
      if ( this.props.session === undefined ){
        this.props.signOut();
      } else {
        if ( this.props.session.active_game.id === undefined ){
          this.props.removePlayerSignOut(this.props.auth.uid, getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role), this.props.session.playerProfiles, false);
        } else {
          this.props.removePlayerSignOut(this.props.auth.uid, getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role), this.props.session.playerProfiles, true);
        }
      }
    }
  }

  render() {

    const { t, classes, session, auth, profile, loading } = this.props;

    return (
      <div>
      <div className={`${classes.footer} footer`}>
        <div style={{maxWidth: "1800px", margin: "0 auto", padding: "0 30px"}}>
              <Grid container spacing={3}>
                <Grid item sm={9} xs={9}>
                  <Grid container spacing={2}>
                    <Grid container item xs={6} spacing={1} className={classes.languageTop}>
                      <Grid item xl={1} lg={2} md={2} xs={2}></Grid>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2' className={classes.copy}>
                        {t('footer.finePrintOne')}
                      </Typography>
                      <Typography variant='body2' className={classes.copy}>
                        {t('footer.finePrintTwo')}
                      </Typography>
                      <Typography variant='body2' className={classes.copy}>
                        {t('footer.finePrintThree', { year: moment().format('YYYY') })}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      { profile !== undefined ?
                        profile.role === 'player' ?
                        <Typography variant="body2" className={classes.copy}>Welcome, <span style={{fontSize: "0.9rem"}}>{profile.displayName}</span> <span style={{textDecoration: "underline", fontSize: "0.6rem", cursor: "pointer"}} onClick={() => { window.location = '/name'; }}>(Change Name)</span></Typography>
                        :
                        <Typography variant="body2" className={classes.copy}>You are signed in as, <span style={{fontSize: "0.9rem"}}>{profile.displayName}</span> <span style={{textDecoration: "underline", fontSize: "0.6rem", cursor: "pointer"}} onClick={() => { window.location = '/name'; }}>(Change Name)</span></Typography>
                      : null }
                    </Grid>
                    <Grid item xs={6}>
                      { signOutLinks(auth, session, t, classes ) ? 
                        <div>
                          <Button onClick={() => this.signUserOut()} className={classes.white}>
                            {t('navigation.signOutButton')}
                          </Button>

                          <span style={{color: "#fff", display: "inline-block", padding: "0 8px"}}>|</span> 
                          <Button onClick={() => {window.open('https://virtual.glassoflearning.com/contact', '_blank').focus()}} className={classes.white}>
                          Contact Us
                        </Button>
                        </div>
                      : null }
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={3} sm={3} align="right">
                    <img src={GOLLogo} style={{maxWidth: "250px", width: "100%"}}/>
                </Grid>
              </Grid>
           
        </div>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>

      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    loading: state.game_state.footer_loading
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    removePlayerSignOut: (player, group, playersList, isGameActive) => dispatch(removePlayerSignOut(player,group, playersList, isGameActive))
  };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDisptachToProps), withStyles(styles))(DefaultFooter);
