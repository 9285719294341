import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import firebase from 'firebase/app';

import { Provider, useSelector } from 'react-redux';


import { createFirestoreInstance } from 'redux-firestore';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import fbConfig from './config/fbConfig';
import store from './store/index';
import ZoomContext from './components/Zoom/zoomClientContext';
import ZoomVideo from '@zoom/videosdk';


import './i18n';

const client = ZoomVideo.createClient();
console.log(client);

const rrfConfig = {
  userProfile: 'players',
  useFirestoreForProfile: true,
  attachAuthIsReady: true,
  config: fbConfig,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  attachAuthIsReady: true,
  createFirestoreInstance,
};

const Loader = () => {
  return <div>loading...</div>;
};

function AuthIsLoaded({ children }) {
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth)) return <Loader />;
  return children;
}

ReactDOM.render(
  <Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
      <AuthIsLoaded>
        <Suspense fallback={<Loader />}>
          <ZoomContext.Provider value={client}>
            <App/>
          </ZoomContext.Provider>
        </Suspense>
      </AuthIsLoaded>
    </ReactReduxFirebaseProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
