import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Player from './player.js';
import { withStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';

import styles from '../../Styles';

const getListStyle = (isDraggingOver, ind) => ({
  background: ind ? '#eeeeee' : 'lightblue',

  display: 'flex',
  flexWrap: 'wrap',
  padding: '8px 16px',
  overflow: 'auto',
  margin: '0 0 10px 0',
  minHeight: '100px',
  width: '100%',
});

class Group extends Component {
  render() {
    const { classes } = this.props;

    let vs = false;
    //console.log('teams', this.props.teams );
    if ( this.props.teams === true && this.props.ind % 2 !== 0 ){
      vs = true;
    }

    if (this.props.player_list !== undefined) {
      return (
        <div>
          <Typography variant='h6' className={`${classes.glassPurple} ${classes.groupTitleMed}`}>
            {this.props.group.title} { this.props.group_id !== 'group-0' ? <CreateIcon onClick={() => this.props.renameGroup(this.props.group_id, this.props.group.title)}/> : null } { this.props.group_id !== 'group-0' ? <DeleteIcon onClick={() => this.props.deleteGroup(this.props.group_id)}/> : null }
          </Typography>
         
          <div style={getListStyle(false, this.props.ind)} onContextMenu={(event) => this.props.contextHandler(event, null)}>
            {this.props.players.map((player, index) => (
              <Player group_id={this.props.group_id} selectedPlayers={this.props.selectedPlayers} key={player.id} player={player} index={index} player_id={player.id} clickHandler={this.props.clickHandler} contextHandler={this.props.contextHandler}/>
            ))}               
          </div>
          { vs === true ? <div style={{padding: "5px 20px", fontSize: "28px", fontWeight: "bold"}}>VS</div> : this.props.teams === true ? <div style={{padding:"15px 0"}}>&nbsp;</div> : null }        
        </div>
      );
    } else {
      return '';
    }
  }
}

Group.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Group);
