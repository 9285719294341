import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Droppable, DragDropContext } from 'react-beautiful-dnd'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
    hasGameControl,
    getUserGroup_hc,
    determineHost
} from '../../game_functions/functions'
import {
    updateGroupState,
    updateGroupStateParams,
    updateResults,
    editResults,
    removeResults,
    updateGroupStateParamsVal,
    editResultsPath,
    updateResultsPath,
    removeResultsPath
} from './../../../../../store/actions/gameActions'
import Typography from '@material-ui/core/Typography'
import styles from '../../../../Styles'
import { PROFILE_VALUES } from '../profiles/profileList'
import EISkill from './../profiles/eiSkill'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import clsx from 'clsx'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

const INITIAL_STATE = {
    noteOpen: false,
    entry_text: '',
    isEdit: false,
    choiceSet: Object.keys(PROFILE_VALUES),
    choices: Object.keys(PROFILE_VALUES),
    answer: [],
    verb: null
}

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : '#fff',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '8px 16px',
    overflow: 'auto',
    margin: '0 0 10px 0',
    minHeight: '60px',
    width: '100%'
})
const getListStyle2 = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : '#fff',
    display: 'inline-block',
    flexWrap: 'wrap',
    padding: '8px 16px',
    overflow: 'auto',
    margin: '0 3px 0 0',
    minHeight: '60px',
    width: '200px'
})

class SkillPicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...INITIAL_STATE
        }
        this.onDragEnd = this.onDragEnd.bind(this)
    }

    componentDidMount() {
        let groupID = getUserGroup_hc(
            this.props.session,
            this.props.auth.uid,
            this.props.profile.role
        )
        if (this.props.session.active_game.results.hasOwnProperty(groupID)) {
            if (
                this.props.session.active_game.results[groupID].hasOwnProperty(
                    `step_${this.props.step}`
                )
            ) {
                const res =
                    this.props.session.active_game.results[this.props.group_id][
                        `step_${this.props.step}`
                    ]

                if (res.choice !== false && res.choice !== undefined) {
                    let c = [...Object.keys(PROFILE_VALUES)]
                    let ch = res.choice
                    let i = c.indexOf(ch)
                    c.splice(i, 1)

                    if (res.verb !== undefined) {
                        this.setState({
                            ...this.state,
                            answer: [ch],
                            choices: [...c],
                            verb: res.verb
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            answer: [ch],
                            choices: [...c]
                        })
                    }
                } else {
                    if (res.verb !== undefined) {
                        this.setState({
                            ...this.state,
                            verb: res.verb
                        })
                    }
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.session.active_game.results.hasOwnProperty(
                this.props.group_id
            )
        ) {
            if (
                this.props.session.active_game.results[
                    this.props.group_id
                ].hasOwnProperty(`step_${this.props.step}`)
            ) {
                const res =
                    this.props.session.active_game.results[this.props.group_id][
                        `step_${this.props.step}`
                    ]

                //if (res.choice !== undefined) {
                if (
                    prevProps.session.active_game.results[this.props.group_id][
                        `step_${this.props.step}`
                    ].choice !== res.choice ||
                    prevProps.session.active_game.results[this.props.group_id][
                        `step_${this.props.step}`
                    ].verb !== res.verb
                ) {
                    let c = [...Object.keys(PROFILE_VALUES)]
                    let ch = res.choice
                    let v = res.verb
                    if (ch === false || ch === undefined) {
                        if (v !== undefined) {
                            this.setState({
                                ...this.state,
                                answer: [],
                                choices: [...c],
                                verb: v
                            })
                        } else {
                            this.setState({
                                ...this.state,
                                answer: [],
                                choices: [...c]
                            })
                        }
                    } else {
                        let i = c.indexOf(ch)
                        if (i >= 0) {
                            c.splice(i, 1)
                        }
                        if (v !== undefined) {
                            this.setState({
                                ...this.state,
                                answer: [ch],
                                choices: [...c],
                                verb: v
                            })
                        } else {
                            this.setState({
                                ...this.state,
                                answer: [ch],
                                choices: [...c]
                            })
                        }
                    }
                }
                //}
            }
        }
    }

    onRadioSelect = (e) => {
        //console.log('here: ', e, e.target.value)
        const val = e.target.value
        let groupID = getUserGroup_hc(
            this.props.session,
            this.props.auth.uid,
            this.props.profile.role
        )
        this.setState({
            ...this.state,
            verb: val
        })
        this.props.editResultsPath(
            `active_game.results.${groupID}.step_${this.props.step}.verb`,
            val,
            determineHost(this.props.profile, this.props.auth)
        )
    }

    onDragEnd(result) {
        // dropped outside the list
        if (!result.destination) {
            return
        }
        if (
            !hasGameControl(
                this.props.session.groups[this.props.group_id].leadPlayer,
                this.props.auth.uid,
                this.props.profile
            )
        ) {
            return
        }
        if (
            result.destination.droppableId === 'step_answer' &&
            this.state.answer.length > 0
        ) {
            return
        }
        if (result.destination.droppableId === result.source.droppableId) {
            return
        }

        const c = [...Object.keys(PROFILE_VALUES)]
        let groupID = getUserGroup_hc(
            this.props.session,
            this.props.auth.uid,
            this.props.profile.role
        )

        if (result.destination.droppableId === 'step_answer') {
            const a = Object.keys(PROFILE_VALUES)[result.source.index]
            const answer = [a]

            c.splice(result.source.index, 1)
            this.setState({
                ...this.state,
                choices: [...c],
                answer: [...answer]
            })
            this.props.editResultsPath(
                `active_game.results.${groupID}.step_${this.props.step}.choice`,
                a,
                determineHost(this.props.profile, this.props.auth)
            )
        }

        if (result.destination.droppableId === 'step_eis') {
            if (result.source.droppableId === 'step_answer') {
                this.setState({
                    ...this.state,
                    choices: [...c],
                    answer: []
                })
                this.props.editResultsPath(
                    `active_game.results.${groupID}.step_${this.props.step}.choice`,
                    false,
                    determineHost(this.props.profile, this.props.auth)
                )
            }
        }
        return
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <div className={classes.spacingTop}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Typography variant="h5">
                            Now that you've read the profile...
                        </Typography>
                        <Typography
                            variant="h6"
                            className={classes.spacingBottom}
                        >
                            Complete the following by dragging the appropriate
                            answer into the blank space:
                        </Typography>
                        <Typography variant="body1">
                            The{' '}
                            <span className={classes.descriptiveText}>
                                Emotional Intelligence
                            </span>{' '}
                            skill that is:
                        </Typography>
                        <RadioGroup
                            aria-label="Skill"
                            name="skill"
                            style={{ padding: '20px 0' }}
                            value={this.state.verb ? this.state.verb : null}
                            onChange={(e) => {
                                this.onRadioSelect(e)
                            }}
                        >
                            <FormControlLabel
                                value="demonstrated"
                                style={{ color: 'rgba(65, 65, 65, 1)' }}
                                control={
                                    <Radio
                                        className={classes.eiradioroot}
                                        disableRipple
                                        color="default"
                                        checkedIcon={
                                            <span
                                                className={clsx(
                                                    classes.eiradioicon,
                                                    classes.eiradiocheckedIcon
                                                )}
                                            />
                                        }
                                        icon={
                                            <span
                                                className={classes.eiradioicon}
                                            />
                                        }
                                    />
                                }
                                label="Demonstrated"
                            />
                            <FormControlLabel
                                value="lacking"
                                style={{ color: 'rgba(65, 65, 65, 1)' }}
                                control={
                                    <Radio
                                        className={classes.eiradioroot}
                                        disableRipple
                                        color="default"
                                        checkedIcon={
                                            <span
                                                className={clsx(
                                                    classes.eiradioicon,
                                                    classes.eiradiocheckedIcon
                                                )}
                                            />
                                        }
                                        icon={
                                            <span
                                                className={classes.eiradioicon}
                                            />
                                        }
                                    />
                                }
                                label="Lacking"
                            />
                        </RadioGroup>
                        <Typography variant="body1">
                            in this scenario is:{' '}
                        </Typography>
                        <Droppable
                            droppableId={`step_answer`}
                            direction="horizontal"
                        >
                            {(provided, snapshot) => (
                                <span
                                    className={classes.eiDropArea}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={getListStyle2(
                                        snapshot.isDraggingOver
                                    )}
                                >
                                    {this.state.answer.map((p, ind) => {
                                        return (
                                            <EISkill
                                                classes={classes}
                                                p={p}
                                                index={ind}
                                                key={ind}
                                            />
                                        )
                                    })}
                                    {provided.placeholder}
                                </span>
                            )}
                        </Droppable>
                        .
                        <Grid container className={classes.spacingTop}>
                            <Grid item xs={12} sm={12}>
                                <Grid
                                    container
                                    className={classes.spacingBottom}
                                >
                                    <Grid item xs={12} align="left">
                                        <Typography
                                            variant="body1"
                                            className={classes.boldedText}
                                        >
                                            EI Core Skills:
                                        </Typography>
                                        <Droppable
                                            droppableId={`step_eis`}
                                            direction="horizontal"
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    className={
                                                        classes.eiFullDropArea
                                                    }
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    style={getListStyle(
                                                        snapshot.isDraggingOver
                                                    )}
                                                >
                                                    {this.state.choices.map(
                                                        (p, ind) => {
                                                            return (
                                                                <EISkill
                                                                    classes={
                                                                        classes
                                                                    }
                                                                    p={p}
                                                                    index={ind}
                                                                    key={p}
                                                                />
                                                            )
                                                        }
                                                    )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DragDropContext>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState: (group, groupState, hostID) =>
            dispatch(updateGroupState(group, groupState, hostID)),
        updateGroupStateParams: (group, groupStateParams, hostID) =>
            dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
        updateResults: (group, top_level, value, hostID) =>
            dispatch(updateResults(group, top_level, value, hostID)),
        editResults: (group, top_level, value, hostID) =>
            dispatch(editResults(group, top_level, value, hostID)),
        removeResults: (group, top_level, value, hostID) =>
            dispatch(removeResults(group, top_level, value, hostID)),
        updateGroupStateParamsVal: (group, value, groupStateParams, hostID) =>
            dispatch(
                updateGroupStateParamsVal(
                    group,
                    value,
                    groupStateParams,
                    hostID
                )
            ),
        editResultsPath: (path, value, host) =>
            dispatch(editResultsPath(path, value, host)),
        updateResultsPath: (path, value, host) =>
            dispatch(updateResultsPath(path, value, host)),
        removeResultsPath: (path, value, host) =>
            dispatch(removeResultsPath(path, value, host))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(SkillPicker)
