import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { Droppable } from 'react-beautiful-dnd'
import PostIt from './postIt'

import { withStyles } from '@material-ui/core/styles'

import styles from '../../../../Styles'

const getListStyle = (isDraggingOver, dir) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    display: 'flex',
    flexWrap: 'wrap',
    padding: dir ? '0' : '15px',
    overflow: 'auto',
    margin: '0',
    minHeight: '100px',
    width: '100%'
})

class DropArea extends Component {
    render() {
        const { classes } = this.props
        console.log('WHAT', this.props.postits)

        return (
            <Droppable
                droppableId={this.props.id}
                direction={this.props.direction}
                isDropDisabled={this.props.disabled}
                key={this.props.id}
            >
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(
                            snapshot.isDraggingOver,
                            this.props.direction === 'vertical' ? true : false
                        )}
                    >
                        {Object.keys(this.props.postits)
                            .sort()
                            .map((key, index) => {
                                //console.log('AAA', key, this.props.postits[key]);
                                return (
                                    <PostIt
                                        auth={this.props.auth}
                                        author={this.props.postits[key].uid}
                                        playerProfiles={
                                            this.props.playerProfiles
                                        }
                                        control={this.props.control}
                                        key={key}
                                        index={index}
                                        id={key}
                                        text={this.props.postits[key].text}
                                        col={this.props.id}
                                        itemAction={this.props.itemAction}
                                    />
                                )
                            })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        )
    }
}

DropArea.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DropArea)
