import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  hasGameControl,
  getUserGroup_hc,
  determineHost
} from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../../store/actions/gameActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Cards from '../specific_components/cards';
import DiscCardDraggable from '../specific_components/cardDraggable';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import Hidden from '@material-ui/core/Hidden';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import DoneIcon from '@material-ui/icons/Done';
import Slide from '@material-ui/core/Slide';

const INITIAL_STATE = {
  open: false,
  helpOpen: false,
  circle: null,
  entry_text: '',
  isEdit: false,
  choices: []
}

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : '#ffffff',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results[group_id] !== undefined ){
      this.setState({
          ...this.state,
          choices: this.props.session.active_game.results[group_id].card_choices
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( (prevProps.session.active_game.results[group_id] !== this.props.session.active_game.results[group_id]) ){
        if ( this.state.choices !== this.props.session.active_game.results[group_id].card_choices ){
          this.setState({
            ...this.state,
            choices: this.props.session.active_game.results[group_id].card_choices
        });
        }
    }
  }

  handleClose = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    this.props.updateResults(groupID, this.state.circle, this.state.entry_text, determineHost(this.props.profile, this.props.auth));
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
    });
  };

  itemAction = (index, value, circle) => {
    this.setState({
      ...this.state,
      isEdit: index,
      open: true,
      circle: circle,
      entry_text: value,
    });
  };

  updateRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.circle]];
    c[row] = this.state.entry_text;

    this.props.editResults(groupID, this.state.circle, c, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.circle]];
    c.splice(row, 1);

    this.props.removeResults(groupID, this.state.circle, c, determineHost(this.props.profile, this.props.auth));
    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_ACTIVE,
      params: {
        step: 2
      },
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const choices = reorder(
      this.state.choices,
      result.source.index,
      result.destination.index
    );

    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    this.props.editResults(groupID, 'card_choices', choices, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      choices
    });

    return;
  }

  render() {
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;
    const { helpOpen } = this.state;
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const numResults = (session.active_game.results[group_id] === undefined) ? 0 : (session.active_game.results[group_id].card_choices === undefined ? 0 : session.active_game.results[group_id].card_choices.length);
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);

    return (
      <div>
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Typography variant='h4' className={classes.spaceBottom}>{session.active_game.name}</Typography>   
        <Hidden smDown>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>Your Choices ({numResults}/5)</Typography>
                  <Droppable droppableId={group_id} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        { this.state.choices.map((card_id, index) => (                                        
                            <DiscCardDraggable session={session} profile={profile} auth={auth} card_id={card_id} key={card_id} index={index} control={hasControl} />                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>            
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>Options</Typography>
                <Cards session={session} profile={profile} auth={auth} numResults={numResults}/> 
                <div className={classes.spacingTop}>
                <Button 
                    variant="contained"
                    color="primary"
                    startIcon={<LiveHelpIcon />}
                    onClick={() => this.openHelp()}
                    >
                What Should I Do?
                </Button>
                </div> 
                { numResults === 5 && hasControl ?
                <div className={classes.spacingTop}>                  
                  <Button type='submit' color="primary" variant='contained' onClick={this.finishActivity} startIcon={<DoneIcon/>}>
                    Finish Activity, Move to Next Step
                  </Button>
                </div>
                : null }
            </Grid>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container spacing={3}>            
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>Options</Typography>
                <Cards session={session} profile={profile} auth={auth} numResults={numResults}/> 
                <div className={classes.spacingTop}>
                <Button 
                    variant="contained"
                    color="primary"
                    startIcon={<LiveHelpIcon />}
                    onClick={() => this.openHelp()}
                    >
                What Should I Do?
                </Button>
                </div> 
                { numResults === 5 && hasControl ?
                <div className={classes.spacingTop}>
                  <Button type='submit' color="primary" variant='contained' onClick={this.finishActivity} startIcon={<DoneIcon/>}>
                    Finish Activity, Move to Next Step
                  </Button>
                </div>
                : null }
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant='h6'>Your Choices ({numResults}/5)</Typography>
                <Droppable droppableId={group_id} direction='vertical'>
                    {(provided, snapshot) => (
                      <List ref={provided.innerRef} className={`${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
                        
                        { this.state.choices.map((card_id, index) => (                                        
                            <DiscCardDraggable session={session} profile={profile} auth={auth} card_id={card_id} key={card_id} index={index} control={hasControl}/>                          
                        ))
                        }
                        {provided.placeholder}
                      </List>
                    )}
                  </Droppable>
            </Grid>
          </Grid>
        </Hidden>
        
      </DragDropContext>
      <Dialog
          open={helpOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleHelpClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
              <Typography variant="h4">What Should I Do?</Typography>
              <Typography variant="body1">This exercise features picking from a set of cards to make a choice of 5 that best represent your group. At any time, click on the Browse Cards button to pull up the list of cards along the right hand side of your screen.</Typography>
              <Typography variant="body1" className={classes.spacingTop}>As a leader, you can tap on the cards to present the option to add to your list of choices. Tapping Add to Choices will move the card from the card list over into your choices.</Typography>
              <Typography variant="body1" className={`${classes.spacingTop}`}>Additionally, the leader can sort the cards within the choice area by dragging and dropping, as well as remove a card by tapping on it and selecting remove.</Typography>
              <Typography variant="body1" className={`${classes.spacingTop} ${classes.spacingBottom}`}>When you have made 5 choices that you are happy with, proceed to the next step via the Finish Activity, Move to Next Step button.</Typography> 
          </DialogContent>                  
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);
