import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import image1 from '../images/Picture1.png';
import image2 from '../images/Picture2.png';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const OnDeckLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Round 1 Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(5)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(6)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Each team has a deck of cards</li>
                    <li className={classes.properListItem}>Teams of 2-4 people per deck of cards; each person needs to be able to move the cards (create chaos)</li>
                    <li className={classes.properListItem}>Begin with a deck of 52 cards piled together</li>
                    <li className={classes.properListItem}>Teams have a specific sequence they need to recreate</li>
                    <li className={classes.properListItem}>Teams move the cards from the deck into specific sequences</li>
                    <li className={classes.properListItem}>Place the cards in a 4x4 grid pattern</li>
                    <li className={classes.properListItem}>Slide the cards into sequence like sudoku</li>
                    <li className={classes.properListItem}>No row or column of the grid has the same suit or the same number/rank</li>
                    <li className={classes.properListItem}>
                        <ul className={classes.properList}>There are three rounds to divide the cards: 
                            <li className={classes.properListItem}>First set: Aces, Jacks, Queen, Kings. </li>
                            <li className={classes.properListItem}>Second set: Twos, Threes, Fours, Fives. </li>
                            <li className={classes.properListItem}>Third set: Sixes, Sevens, Eights, Nines. </li>
                        </ul>
                    </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Improving communication and listening skills</li>
                    <li className={classes.properListItem}>Finding solutions to improve time and process</li>
                    <li className={classes.properListItem}>Developing group solutions to solve a problem</li>
                    <li className={classes.properListItem}>Managing through chaos</li>
                    <li className={classes.properListItem}>Changing process to improve performance</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Let’s look at how we organize ourselves through an experience similar to sudoku</li>
                    <li className={classes.properListItem}>Each team has a deck of cards, and your assignment is to use the whole team to lay out the cards exactly as they are listed in each round</li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Guidelines</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>This activity is Sudoku-like </li>
                    <li className={classes.properListItem}>The deck is laid out by suit</li>
                    <li className={classes.properListItem}>Cards will be placed in a 4x4 grid pattern</li>
                    <li className={classes.properListItem}>By sliding the cards into different positions, teams need to figure out the puzzle </li>
                    <li className={classes.properListItem}>No row or column of the grid has the same suit or the same number/rank</li>
                </ul>
                <Typography variant="h5">Rules</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Lay out the cards in the deck in the pattern outlined for each round</li>
                    <li className={classes.properListItem}>Place the cards in a 4x4 grid pattern</li>
                    <li className={classes.properListItem}>Slide the cards into sequence like sudoku – (move one card to move another card)</li>
                    <li className={classes.properListItem}>No row or column of the grid can have the same suit or the same number/rank (e.g. no 2 Queens in the same row or column)</li>
                    <li className={classes.properListItem}>Goal – complete the task as fast as possible</li>
                    <li className={classes.properListItem}>Teams are competing – <i>each round is timed</i></li>
                    <li className={classes.properListItem}>After Round 1, teams will re-evaluate their strategy for Round 2&3</li>
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Let’s consider Sudoku Strategy</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Three ways to set patterns</li>
                    <li className={classes.properListItem}>There is a 2 in the third row and a 3 in the third column</li>   
                    <li className={classes.properListItem}>The only possibilities for the cell in the (3,3) position are 1 and 4</li>                            
                </ul>
                <img src={image1} alt="Sudoku example" />               
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={5}>
                <Typography variant="h5">Debriefing Topics – options for questions</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What strategies did you and your team come up with to be successful?</li>
                    <li className={classes.properListItem}>Were all the ideas heard/listened to in the group?</li>   
                    <li className={classes.properListItem}>How did you communicate your ideas to others?</li>   
                    <li className={classes.properListItem}>Why did all the groups not come up with the same solution?</li>   
                    <li className={classes.properListItem}>Did anyone in the group try to do it a different way?</li>
                    <li className={classes.properListItem}>How did you leverage the strengths of the team?</li>
                    <li className={classes.properListItem}>Did you improve your time each round? How?</li>
                    <li className={classes.properListItem}>How does this relate back to solving challenges in your department?</li>                           
                </ul>
                <Typography variant="h5"> </Typography>
                <Typography variant="h5">Key Points to Cover:</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Importance of planning and delegating tasks to effectively and efficiently execute what needs to be done</li>
                    <li className={classes.properListItem}>Once you have a good plan in advance, you can complete the required tasks in the shortest possible time</li>   
                    <li className={classes.properListItem}>Planning minimizes energy and effort</li>                              
                </ul>
                <Typography variant="h5">Application:</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What principles from this activity can you apply to your work environment?</li>                           
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Answer for Ace, Queen, Jack, King – shows example of what we are looking for in each round</b></Typography>               
                <img src={image2} alt="example solution"/>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={6}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
           
           
        </div> 
    )
}

export default withStyles(styles)(OnDeckLN);