import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const TrustSpectrumIndividualLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}>Evaluate trust in your work environment.
                 Each person will review a series of actions members of their team do to support both the department and the leader.
                   In reviewing these actions, individuals will assess if they would readily complete these actions for their leader and if their team would complete these actions for them.   </Typography>

            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>The impact on department and organizational performance when individuals do not feely share their trust</li>
                    <li className={classes.properListItem}>How we are showing up as both an employee and a leader</li>
                    <li className={classes.properListItem}>Obstacles that may be preventing trust in the workplace </li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Like many topics that we have discussed, trust covers a spectrum</li>
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}>Would I trust this person ranges from:</Typography>
                <ul>
                    <li>1-Never: <b>I would NEVER trust this leader.</b></li>
                    <li>3-Sometimes: <b>I would SOMETIMES trust this leader.</b></li>
                    <li>5-Always: <b>I would ALWAYS try to trust this leader.</b> </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Individually</b></Typography>      
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Review the individual cards – you have 2 colors</li>
                    <li className={classes.properListItem}>One color - think of your leader, place your cards on the spectrum</li>
                    <li className={classes.properListItem}>Second color – what would your team say about you? Place your cards on the spectrum.</li>
                </ul>
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Do you have everything at 5?  </li>
                    <li className={classes.properListItem}>If not, think about what you are missing out on.</li>
                    <li className={classes.properListItem}>Make some notes in your journal</li>
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Trust Spectrum - Actions</b></Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What is this highlighting for you?</li>
                    <li className={classes.properListItem}>What actions would you want to take to improve trust with your teams?</li>
                </ul> 
                <Typography variant="body1"  className={classes.LNProperStart}> </Typography>
                <Typography variant="body1"  className={classes.LNProperStart}>“Can you look in the mirror in the morning and be proud of what you’re doing?” Jack Welch –  Author and Former CEO of General Electric </Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
           
           
        </div> 
    )
}

export default withStyles(styles)(TrustSpectrumIndividualLN);