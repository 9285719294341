import React, { Component } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import StarBorder from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CreateIcon from '@material-ui/icons/Create'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 752
    },
    demo: {
        backgroundColor: theme.palette.background.paper
    },
    title: {
        margin: theme.spacing(4, 0, 2)
    },

    icon: {
        pointEvents: 'auto',
        cursor: 'pointer'
    }
}))

export default function GameOptionsList(props) {
    const selectedSet =
        props.optionsMine.selectedSet === undefined
            ? 'default'
            : props.optionsMine.selectedSet
    const sets =
        props.myCollection === undefined
            ? []
            : props.myCollection.filter((data) => data.isDeleted !== true)

    const classes = useStyles()

    return (
        <div style={{ marginTop: '50px' }} className={classes.demo}>
            <Typography variant="h6">Option Sets</Typography>
            <List dense={false}>
                <ListItem
                    button
                    className={classes.nested}
                    onClick={() => {
                        props.editSet('default')
                    }}
                >
                    <ListItemIcon
                        className={classes.icon}
                        onClick={(e) => {
                            props.setSelectedSet(e, selectedSet, 'default')
                        }}
                    >
                        {selectedSet === 'default' ? (
                            <StarIcon
                                className={classes.icon}
                                style={{ color: 'yellow' }}
                            />
                        ) : (
                            <StarBorder className={classes.icon} />
                        )}
                    </ListItemIcon>
                    <ListItemText
                        primary="Default"
                        secondary="This is the default set of options for this activity."
                    />
                </ListItem>

                {sets.map((set, index) => {
                    //console.log(set);
                    const updatedOn = set.lastUpdatedOn
                        ? set.lastUpdatedOn
                        : 'Unknown'
                    return (
                        <ListItem
                            key={index}
                            button
                            className={classes.nested}
                            onClick={() => {
                                props.editSet(set.id)
                            }}
                        >
                            <ListItemIcon
                                onClick={(e) => {
                                    props.setSelectedSet(e, selectedSet, set.id)
                                }}
                            >
                                {selectedSet === set.id ? (
                                    <StarIcon style={{ color: 'yellow' }} />
                                ) : (
                                    <StarBorder />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={set.name}
                                secondary={`Last Updated: ${updatedOn}`}
                            />
                            {selectedSet !== set.id ? (
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => props.deleteSet(set.id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            ) : null}
                        </ListItem>
                    )
                })}
            </List>
            <Button
                size="small"
                onClick={() => props.createNewSet()}
                variant="contained"
                color="primary"
                className={props.classes.glassPurple}
                startIcon={<AddIcon />}
                style={{ marginRight: '10px', color: '#fff' }}
            >
                Create New Set
            </Button>

            <div style={{ marginTop: '50px' }}>
                <Button
                    size="small"
                    onClick={() => props.returnToGameList()}
                    variant="contained"
                    className={props.classes.glassPurple}
                    startIcon={<AssignmentReturnIcon />}
                    style={{ marginRight: '10px' }}
                >
                    Return to Game List
                </Button>
                <Button
                    size="small"
                    onClick={() =>
                        props.startNewGame(
                            props.game.id,
                            props.game_name,
                            props.game
                        )
                    }
                    variant="contained"
                    color="primary"
                    className={props.classes.glassPurple}
                    startIcon={<PlayCircleFilledWhiteIcon />}
                    style={{ marginRight: '10px', color: '#fff' }}
                >
                    Start {props.game_name}
                </Button>
            </div>
        </div>
    )
}
