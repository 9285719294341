import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
//New sign in method
import { connect } from 'react-redux';
import { signInAnon, anonProfileCreate, updateProfileName, updateProfileNameSimple, endLoading } from '../../store/actions/authActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Footer from '../Footer';
import LogoHeader from '../Header';
import LoadingBlock from '../Loading';
import styles from '../Styles';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { determineHost } from './../Game/games/game_functions/functions';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Loading from './../Loading';
import { sortGroupStrings } from './../Game/games/game_functions/functions';

const INITIAL_STATE = {
  name: '',
  groupNo: 'metastudiosinc-_-custom',
  groupName: '',
  setName: true,
  nameLoaded: null,
  loading: false
};

class ChangeName extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount(){
   this.setState({
     ...this.state,
     namedLoaded: false
   })
  }

  componentDidUpdate(prevProps, prevState){
    if ( prevProps.user.isLoaded !== this.props.user.isLoaded && this.props.user.displayName !== undefined ){
        this.setState({
            ...this.state,
            name: this.props.user.displayName
        });
    } else if ( ( this.state.nameLoaded === false || this.state.nameLoaded === null ) && this.props.user.displayName !== undefined ){
      this.setState({
        ...this.state,
        nameLoaded: true,
        name: this.props.user.displayName
    });
    }
  }

  onSubmit = (event) => {
    event.preventDefault();
      console.log('hm?', this.state.groupNo, this.state.groupName);
      this.props.updateProfileName(this.state.name, this.props.user, this.state.groupNo, this.state.groupName, this.props.current_session);
      this.setState({
          ...this.state,
          setName: false
      });
  };

  onSubmitSimple = (event) => {
    event.preventDefault();
    console.log('here');
    this.props.updateProfileNameSimple(this.state.name, this.props.user, this.props.current_session);
    this.setState({
      ...this.state,
      setName: false
  });
  }

  

  onChange = (event) => {
    console.log('changing: ', [event.target.name], event.target.value);
    this.setState({ [event.target.name]: event.target.value });
  };

  handleGroupChange = (event) => {

  }

  render() {
    const {
      name,
      setName
    } = this.state;

    const isInvalid = name === '';
    const { auth, t, classes } = this.props;

    if (auth.uid) {
      /* We don't want to be at this page if we're already signed in. 
        If signed in, that means you're already in as a host, or you're already connected to a game */
      
      /* TODO: We need to wait here and not allow them to enter their name unless their profile is loaded && they exist in the players list */
      if ( !isLoaded(this.props.theme) || !isLoaded(this.props.defaultTheme)  ){
        return (
          <div className='meta-react'>
             
              <div className='outer-container' style={{paddingTop: 150}}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-12'>
                      Loading, hold on a moment...
                      <LoadingBlock />
                    </div>
                  </div>
                </div>
              </div>
        
            </div>);
      }



      let theTheme = this.props.defaultTheme;
        if ( this.props.theme !== null && this.props.theme !== undefined ){
          theTheme = this.props.theme;
        }
    
        if ( theTheme.color === undefined ){
          theTheme = {
            ...theTheme,
            color: this.props.defaultTheme.color
          };
        }
        if ( theTheme.logo === undefined ){
          theTheme = {
            ...theTheme,
            logo: this.props.defaultTheme.logo
          }
        }

        console.log('HMM ', setName, this.props.authMessage );
      if (!this.props.user.isEmpty) {
        if (setName === false && this.props.authMessage === 'PROFILE_UPDATED') {
            return <Redirect to={ROUTES.LANDING} />;
        }


        if ( this.props.current_session === undefined ){
          return <Loading/>
        }

        console.log('session', this.props.current_session);
    
        
        if ( this.props.user.displayName !== undefined ){
            return (
                <div className='meta-react'>
                    <LogoHeader t={t} session={this.props.current_session} profile={this.props.user} auth={this.props.auth}  theme={theTheme}/>
                    <div className='outer-container'>
                    <div className='container'>
                        <div className='row'>
                        <div className='col-12'>
                            <form onSubmit={this.onSubmit}>
                            <Grid item container spacing={3} justify='center'>
                                <Grid item xs={12}>

                                    <Grid container spacing={3}>
                                      <Grid item xs={4} align="right">
                                        <Avatar style={{height: 250, width: 250}}>
                                        </Avatar>   
                                      </Grid>
                                      <Grid item xs={8} align="left" style={{paddingTop: 20}}>
                                        { this.props.user.role === 'host' ? 
                                        <Typography variant="h6" className={classes.spacingBottom} style={{fontWeight: "bold"}}>NOTE: YOU ARE CURRENTLY SIGNED IN AS A HOST.</Typography>
                                        : null}
                                        { this.props.user.role === 'moderator' ? 
                                        <Typography variant="h6" className={classes.spacingBottom} style={{fontWeight: "bold"}}>NOTE: YOU ARE CURRENTLY SIGNED IN AS A MODERATOR.</Typography>
                                        : null}
                                        <Typography variant="h5">Changing Your Display Name:</Typography>
                                        <Typography varaint="body1" style={{marginBottom: 20}}>Enter the name you wish you use for the session.</Typography>

                                        <TextField name='name' onChange={this.onChange} className={classes.textField} value={name} type='text' label={t('signIn.displayName')} />

                                        <Button type='submit' disabled={isInvalid} color="primary" variant='contained' onClick={(event) => this.onSubmitSimple(event)} style={{marginTop: 20}}>
                                            Change Name
                                        </Button>
                                      </Grid>
                                    </Grid>
                                    
                                </Grid>                                
                            </Grid>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                    <Footer session={this.props.current_session} profile={this.props.user} auth={this.props.auth} hostInfo={undefined} theme={theTheme}/>
                </div>
                );
        } else {
            return (
            <div className='meta-react'>
                <LogoHeader t={t} session={this.props.current_session} profile={this.props.user} auth={this.props.auth}  theme={theTheme}/>
                <div className='outer-container'>
                <div className='container'>
                    <div className='row'>
                    <div className='col-12'>
                        <form onSubmit={this.onSubmit}>
                        <Grid item container spacing={3} justify='center'>
                            <Grid item xs={12}>
                                <Typography variant="h3">Almost There!</Typography>
                                <Typography varaint="body1">Enter the name you wish you use for the session.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name='name' onChange={this.onChange} className={classes.textField} value={name} type='text' label={t('signIn.displayName')} />
                            </Grid>
                            <Grid item xs={12}>
                                
                                <FormControl className={classes.formControl}>
                                  <InputLabel id="demo-simple-select-helper-label">{t('signIn.groupNo')}</InputLabel>
                                  <Select
                                    labelId="groupNo"
                                    name="groupNo"
                                    id="groupNo"
                                    value={this.state.groupNo}
                                    onChange={this.onChange}
                                    style={{minWidth: 300}}
                                  >
                                    <MenuItem value="unknown" >
                                      <em>Unknown</em>
                                    </MenuItem>
                                    { Object.keys(this.props.current_session.groups).sort(sortGroupStrings).map((groupKey, ind) => {
                                        if ( groupKey === 'group-0' ){
                                          return null;
                                        }
                                        return (
                                          <MenuItem key={ind} value={groupKey}>{this.props.current_session.groups[groupKey].title}</MenuItem>
                                        )
                                    })}
                                    <MenuItem value={`metastudiosinc-_-custom`} default>Custom (Enter your own)</MenuItem>
                                  </Select>
                                  
                                </FormControl>
                                { this.state.groupNo === 'metastudiosinc-_-custom' ?
                                <div>
                                <TextField  style={{maxWidth: 300}} name='groupName' id="groupName" onChange={this.onChange} className={classes.textField} value={this.state.groupName} type='number' label={t('signIn.groupNo')} />
                                </div>
                                : null }
                            </Grid>
                            <Grid item xs={12} align='center'>
                                <Button type='submit' disabled={isInvalid} variant='contained' onClick={(event) => { this.setState({...this.state, loading: true}, () => {this.onSubmit(event)})}}>
                                    {t('signIn.anonButton')}
                                </Button>
                            </Grid>
                        </Grid>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
                <Footer session={this.props.current_session} profile={this.props.user} auth={this.props.auth} hostInfo={undefined} theme={theTheme}/>
            </div>
            );
        }   
      } else {
            return null;
      }
    } else {
        return (
        <div className='meta-react'>
           
            <div className='outer-container' style={{paddingTop: 150}}>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    Loading, hold on a moment...
                    <LoadingBlock />
                  </div>
                </div>
              </div>
            </div>
      
          </div>);
    }
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.gol_auth.authError,
    authMessage: state.gol_auth.authMessage,
    authPopUp: state.gol_auth.authPopUp,
    authErrorPopUp: state.gol_auth.authErrorPopUp,
    authCodeValid: state.gol_auth.authCodeValid,
    user: state.firebase.profile,
    sessionHost: state.gol_auth.sessionHost,
    authCode: state.gol_auth.authCode,
    isLoading: state.gol_auth.isLoading,
    setName: state.gol_auth.setName,
    current_session: state.firestore.ordered.current_sessions === undefined || state.firestore.ordered.current_sessions === null ? undefined : state.firestore.ordered.current_sessions[0],
    theme: state.firestore.data.theme,
    defaultTheme: state.firestore.data.defaultTheme
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signInAnon: (creds) => dispatch(signInAnon(creds)),
    anonProfileCreate: (authState) => dispatch(anonProfileCreate(authState)),
    updateProfileName: (name, profile, groupNo, groupName, session) => dispatch(updateProfileName(name, profile, groupNo, groupName, session)),
    updateProfileNameSimple: (name, profile, session) => dispatch(updateProfileNameSimple(name, profile, session)),
    endLoading: () => dispatch(endLoading())
  };
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps), firestoreConnect((props) => {
  if ( props.auth.uid === undefined || !isLoaded(props.user) ){
    return [];
  }
  return [
  {
    collection: 'current_sessions',
    where: ['host', '==', determineHost(props.user, props.auth)],
  },
  {
      collection: 'theme',
      doc: determineHost(props.user, props.auth),
      storeAs: 'theme'
  },
  {
      collection: 'theme',
      doc: 'default',
      storeAs: 'defaultTheme'
  }
]
}))(ChangeName);
