import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GROUP_STATE_RESULTS } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults } from './../../../../../store/actions/gameActions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import LoadingDots from './../../game_components/loadingDots';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ActivityDescDelta from '../specific_components/activityDescDelta';
import ActivityDescPlus from '../specific_components/activityDescPlus';
import ActivityHelpPlus from '../specific_components/activityHelpPlus';
import ActivityHelpDelta from '../specific_components/activityHelpDelta';
import ActivityListPlus from '../specific_components/activityListPlus';
import ActivityListDelta from '../specific_components/activityListDelta';
import { CSSTransition } from "react-transition-group";
import CreateIcon from '@material-ui/icons/Create';


const INITIAL_STATE = {
  noItems: true,
  plusItemsExist: false,
  deltaItemsExist: false,
  open: false,
  curColumn: null,
  curColumn_FS: null,
  isEdit: false,
  showList: false
};

export const PLUS = 'Plus';
export const DELTA = 'Delta';
export const PLUS_FS = 'plus';
export const DELTA_FS = 'delta';

const withAnimation = WrappedComponent => {
  class _AnimatedComponent extends React.Component {
    state = {
      mount: this.props.mount,
      inProgress: this.props.mount && this.props.animateOnLoad,
      blockAnimation: !this.props.animateOnLoad
    };

    static getDerivedStateFromProps(props, state) {
      if (!state.blockAnimation && props.mount !== state.mount) {
        return {
          inProgress: true,
          mount: props.mount
        };
      }

      return null;
    }

    constructor(props) {
      super(props);
      this.wrapperRef = React.createRef();
    }

    componentDidMount() {
      this.setState({
        blockAnimation: false
      });
    }

    shouldComponentUpdate(nextProps, nextState) {
      return this.state.blockAnimation === nextState.blockAnimation;
    }

    onAnimationEnd = event => {
      const { target } = event;
      const { current } = this.wrapperRef;

      if (target === current) {
        this.setState({
          inProgress: false
        });
      }
    };

    render() {
      const { mount, inProgress, blockAnimation } = this.state;
      const { onMount, onUnmount, defaultClass } = this.props;
      const animationClass = mount ? onMount : onUnmount;

      return  (inProgress || mount) ? (
        <div
          ref={this.wrapperRef}
          className={`${defaultClass} ${!blockAnimation ? animationClass : ''}`}
          onAnimationEnd={this.onAnimationEnd}
        >
          <WrappedComponent {...this.props} />
        </div>
      ) : null;
    }
  };

  _AnimatedComponent.defaultProps = {
    animateOnLoad: true,
    defaultClass: ''
  };
  return _AnimatedComponent;
};

const AnimatedActivityDescPlus = withAnimation(ActivityDescPlus);
const AnimatedActivityDescDelta = withAnimation(ActivityDescDelta);
const AnimatedActivityHelpPlus = withAnimation(ActivityHelpPlus);
const AnimatedActivityHelpDelta = withAnimation(ActivityHelpDelta);

class Activity extends Component {
  constructor(props) {
    super(props);
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results.hasOwnProperty(group_id) ){
      this.state = {
        ...INITIAL_STATE,
        noItems: false,
        showList: true
      };
    } else {
      this.state = {
        ...INITIAL_STATE,
      };
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    if ( this.props.session.active_game.results.hasOwnProperty(group_id) && this.state.noItems === true ){
      this.setState({
        ...this.state,
        noItems: false,
        showList: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
      let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
      if ( this.props.session.active_game.results.hasOwnProperty(group_id) && this.state.noItems === true ){
        this.setState({
          ...this.state,
          noItems: false
        });
        setTimeout(() => {
          this.setState({
            ...this.state,
            noItems: false,
            showList: true
          });
        },700);
      } else {
        if ( !this.props.session.active_game.results.hasOwnProperty(group_id) && this.state.noItems === false ){
          this.setState({
            ...this.state,
            noItems: true,
            showList: false
          })
        }
      }
  }

  addToQ = (q, qfs) => {
    let group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const hasControl = hasGameControl(this.props.session.groups[group_id].leadPlayer, this.props.auth.uid, this.props.profile);

    if ( !hasControl ){
      return;
    }
    this.setState({
        ...this.state,
        entry_text: '',
        open: true,
        curColumn: q,
        curColumn_FS: qfs
      });
  
      let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
      let s = {
        ...this.props.session.active_game.groupStates[groupID].params,
        writing: qfs,
      };
      this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));
  }

  handleClose = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  handleSave = (event) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);

    this.props.updateResults(groupID, this.state.curColumn_FS, this.state.entry_text.replaceAll('\n', '<br/>'), determineHost(this.props.profile, this.props.auth));

    let s = {
      ...this.props.session.active_game.groupStates[groupID].params,
      writing: null,
    };
    this.props.updateGroupStateParams(groupID, s, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
    });
  };

  

  itemAction = (index, value, curQFS, Q) => {
    this.setState({
      ...this.state,
      isEdit: index,
      open: true,
      curColumn_FS: curQFS,
      curColumn: Q,
      entry_text: value.replaceAll('<br/>', '\n'),
    });
  };

  updateRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.curColumn_FS]];

    c[row] = this.state.entry_text.replaceAll('\n', '<br/>');

    this.props.editResults(groupID, this.state.curColumn_FS, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  deleteRow = (row) => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let c = [...this.props.session.active_game.results[groupID][this.state.curColumn_FS]];
    c.splice(row, 1);

    this.props.removeResults(groupID, this.state.curColumn_FS, c, determineHost(this.props.profile, this.props.auth));

    this.setState({
      ...this.state,
      open: false,
      isEdit: false,
    });
  };

  finishActivity = () => {
    let groupID = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    let s = {
      state: GROUP_STATE_RESULTS,
      params: {}
    };
    this.props.updateGroupState(groupID, s, determineHost(this.props.profile, this.props.auth));
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleHelpClose = () => {
    this.setState({
      ...this.state,
      helpOpen: false
    });
  }

  openHelp = () => {
    this.setState({
      ...this.state,
      helpOpen: true
    })
  }

  render() {
    const {
      session,
      profile,
      auth,
      classes,
      game_options
    } = this.props;
    const { entry_text } = this.state;
    const isInvalid = entry_text === '';
    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const hasControl = hasGameControl(session.groups[group_id].leadPlayer, auth.uid, profile);

    return (
      <div>
         <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
            <DialogContent>
              <DialogContentText className={classes.bodyText}>{this.state.isEdit !== false ? `Editing.` : `Type your entry below and hit save.`}</DialogContentText>
              <TextField multiline autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label={this.state.curColumn} type='email' fullWidth />
            </DialogContent>
            <DialogActions>
              {this.state.isEdit !== false ? (
                <div>
                  <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                    Delete
                  </Button>
                  <Button onClick={this.handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                    Save
                  </Button>
                </div>
              ) : (
                <div>
                  <Button onClick={this.handleClose} color='primary'>
                    Cancel
                  </Button>
                  <Button disabled={isInvalid} onClick={this.handleSave} color='primary'>
                    Save
                  </Button>
                </div>
              )}
            </DialogActions>
          </Dialog>
        <Typography variant='h3' className={classes.spacingBottom}>
            {game_options.game_texts.game_title}
        </Typography>
        <Grid container spacing={2}>          
            <Grid item xs={12} sm={6}>
                <Grid item xs={12}  align="center" className={classes.tableHead}>
                    { this.state.noItems ? <div className={`pulse ${classes.createButton}`} onClick={() => this.addToQ(PLUS, PLUS_FS)}><CreateIcon/></div> : <div className={`${classes.createButton}`}><CreateIcon onClick={() => this.addToQ(PLUS, PLUS_FS)}/></div> }
                    {game_options.game_texts.plus.replace(/<\/?[^>]+(>|$)/g, "")}  {game_options.game_texts.plus === 'Plus' ? <AddIcon/> : null }
                    <AnimatedActivityHelpPlus
                      mount={!this.state.noItems  || ( false === (session.active_game.results.hasOwnProperty(group_id) ? session.active_game.results[group_id].hasOwnProperty(PLUS_FS) : false ))}
                      onMount="in"
                      onUnmount="out"
                      defaultClass="tableHelp"
                      animateOnLoad={true}
                      game_options={game_options}
                    />
                </Grid>
                <Grid item xs={12}  align="center" className={classes.tableBody}>
                    <AnimatedActivityDescPlus
                      mount={this.state.noItems  || ( false === (session.active_game.results.hasOwnProperty(group_id) ? session.active_game.results[group_id].hasOwnProperty(PLUS_FS) : false ))}
                      onMount="in"
                      onUnmount="out"
                      defaultClass="wrapper"
                      animateOnLoad={true}
                      game_options={game_options}
                    />
                    <CSSTransition
                        in={!this.state.noItems && this.state.showList && ( true === (session.active_game.results.hasOwnProperty(group_id) ? session.active_game.results[group_id].hasOwnProperty(PLUS_FS) : false )) }
                        timeout={1500}
                        classNames="list-transition"
                        unmountOnExit
                        appear
                      >
                      <ActivityListPlus game_options={game_options} session={session} profile={profile} auth={auth} group_id={group_id} itemAction={this.itemAction}  debrief={false} hasGameControl={hasGameControl} showList={this.state.showList}/>
                    </CSSTransition>
                    {session.active_game.groupStates[group_id].params.writing === PLUS_FS ? <List><ListItem className={classes.setHeightListItemPadded}><LoadingDots /></ListItem></List> : null}
                </Grid>   
            </Grid>
            <Grid item xs={12} sm={6}>
            <Grid item xs={12}  align="center" className={classes.tableHead}>
                    { this.state.noItems ? <div className={`pulse ${classes.createButton}`}><CreateIcon onClick={() => this.addToQ(DELTA, DELTA_FS)}/></div> : <div className={`${classes.createButton}`}><CreateIcon onClick={() => this.addToQ(DELTA, DELTA_FS)}/></div> }
                    {game_options.game_texts.delta.replace(/<\/?[^>]+(>|$)/g, "")} {game_options.game_texts.delta === 'Delta' ? <span><ChangeHistoryIcon/> <sup className={classes.tableHeadSup}>(change)</sup></span> : null }
                    <AnimatedActivityHelpDelta
                      mount={!this.state.noItems || ( false === (session.active_game.results.hasOwnProperty(group_id) ? session.active_game.results[group_id].hasOwnProperty(DELTA_FS) : false ))}
                      onMount="in"
                      onUnmount="out"
                      defaultClass="tableHelp"
                      animateOnLoad={true}
                      game_options={game_options}
                    />
                </Grid>   
              <Grid item xs={12}  align="center" className={classes.tableBody}>
                  <AnimatedActivityDescDelta
                    mount={this.state.noItems || ( false === (session.active_game.results.hasOwnProperty(group_id) ? session.active_game.results[group_id].hasOwnProperty(DELTA_FS) : false ))}
                    onMount="in"
                    onUnmount="out"
                    defaultClass="wrapper"
                    animateOnLoad={true}
                    game_options={game_options}
                  /> 
                    <CSSTransition
                      in={(!this.state.noItems && this.state.showList) && ( true === (session.active_game.results.hasOwnProperty(group_id) ? session.active_game.results[group_id].hasOwnProperty(DELTA_FS) : false ))}
                      timeout={1500}
                      classNames="list-transition"
                      unmountOnExit
                      appear
                    >
                      <ActivityListDelta game_options={game_options} session={session} profile={profile} auth={auth} group_id={group_id} itemAction={this.itemAction} debrief={false} hasGameControl={hasGameControl} showList={this.state.showList} />
                    </CSSTransition>
                  {session.active_game.groupStates[group_id].params.writing === DELTA_FS ? <List><ListItem className={classes.setHeightListItemPadded}><LoadingDots /></ListItem></List> : null}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.spacingTop}>
                {!this.state.noItems && this.state.showList && hasControl ? 
                  <div>
                  <div className={classes.spacingTop}>When you are finished, click on the finish activity button.</div>
                  <Button type='submit' color="primary" variant='contained' onClick={this.finishActivity} startIcon={<DoneIcon/>} className={classes.spacingTop}>
                    Finish Activity
                  </Button>
                  </div>
                : null
                }
            </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
    updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
    updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
    editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
    removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Activity);