import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams, updateGroupStateStateVal, moveBackToInitPhaseWithOption } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import ActivityOptions from './../../../activityOptions';
import Button from '@material-ui/core/Button';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';

import DropArea from './../specific_components/dropArea';
import AggregateBackground from './../images/AggregateBackground.png';

import AggregateBoard from './../images/AggregateBoard.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { gameOptions } from './../../../gameServer';
import { ComparisonTable } from './../specific_components/comparisonTable';


const INITIAL_STATE = {
    dialogOpen: false,
    newPuzzleId: false,
    newPuzzleTitle: false,
    width: window.innerWidth,
    height: window.innerHeight
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    setOption = (id, title) => {
      this.setState({
          ...this.state,
          dialogOpen: true,
          newPuzzleId: id,
          newPuzzleTitle: title
      })
    }

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        })
    }

    endGame = () => {
        this.props.moveBackToInitPhaseWithOption(this.props.session, this.props.auth.uid, 'Puzzle', this.state.newPuzzleId);
    }
    

    

    render() {
    const {
      session,
      classes,
      profile
    } = this.props;

    let g = gameOptions(this.props.session.active_game.id);
    

   
      return (
        <div>
        <Dialog
            open={this.state.dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" >
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to restart the activity and use the <span style={{fontWeight: "bold"}}>{this.state.newPuzzleTitle}</span> puzzle?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={this.handleDialogClose} variant="contained" color="primary">
                Cancel
            </Button>
            <Button onClick={this.endGame} variant="contained" color="primary">
                Confirm
            </Button>
            </DialogActions>
        </Dialog>

        <Typography variant="h3">{session.active_game.name}</Typography>
        <Typography variant='h5' className={classes.spaceBottom}>
            Debrief
        </Typography>


        <ComparisonTable session={session}/>

          { profile.role === 'host' ?
          <div style={{marginTop: "60px"}}>
              <Typography variant="h4">Reset Activity with New Puzzle</Typography>
              <Typography variant="body1" className={classes.spacingBottom}>Clicking a puzzle title will re-start activity using that puzzle.</Typography>
              <ActivityOptions session={session} title="Reset Activity with new Puzzle" setOption={this.setOption}/>
          </div>
          : null } 
      </div>
      );    
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params)),
    moveBackToInitPhaseWithOption : (session,host,optionName,optionVal) => dispatch(moveBackToInitPhaseWithOption(session,host,optionName,optionVal))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);