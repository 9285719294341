import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams, startNewRoundActive } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import AddIcon from '@material-ui/icons/Add';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import TurnsManager from './../specific_components/turns.js';
import { ComparisonTable } from './../specific_components/comparisonTable';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup_hc, determineHost } from '../../game_functions/functions';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        // session.active_game.results
        this.state = {
          ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }   

    nextRound() {
      this.props.startNewRoundActive(this.props.session, determineHost(this.props.profile, this.props.auth));
    }


    render() {
    const {
      session,
      classes,
      auth,
      profile,
      game_options
    } = this.props;

    let title = session.active_game.name;
    if ( game_options !== false ){
        title = game_options.game_texts.game_title;
    }   
        return (
        <div>
         


            <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
                <Typography variant="h3">{title}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>
                { /*: null */ }

                { profile.role === 'host' ?
            <Button type='submit' color="primary" variant='contained' onClick={() => this.nextRound()}>
                Next Round
            </Button>
            : null }
            </Grid>
            <Grid item xs={12} sm={12}>
                {Object.keys(session.active_game.results)
                .sort()
                .map((groupKey, ind) => {

                  if ( groupKey === 'group-0' ){
                    return null;
                  }
                  console.log('huh',groupKey);
                 
                  const split = groupKey.split(":");
                  console.log(split);
                  if ( split.length < 2 ){
                    return null;
                  }
                    return (
                    <div key={ind}>
                        <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[split[0]].title} & {session.groups[split[1]].title}</Typography>
                       
            
                          <TurnsManager 
                            debrief={true}
                            session={session} 
                            profile={profile} 
                            auth={auth} 
                            control={false} 
                            results={session.active_game.results}
                            vsKey={groupKey}
                          />
                                 
                    </div>
                    );
                })}
            </Grid>
            </Grid>



        </div>
        );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    startNewRoundActive : (session,host) => dispatch(startNewRoundActive(session,host)),
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);