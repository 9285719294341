
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import DiSC_Profile from '../images/DiSC_Profile.png'
import { GROUP_STATE_ACTIVE } from '../../../../../store/actions/gameActions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'; 
import { hasGameControl, getUserGroup } from '../../game_functions/functions'
import { updateGroupState } from './../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';

import styles from '../../../../Styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';




class Instructions extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    sendToNextStep = (event) => {
        if ( this.props.profile.role === 'host' ){
            let groupID = this.props.session.hostState.params.group ? this.props.session.hostState.params.group : null;
            let s = { 
                    'state' : GROUP_STATE_ACTIVE,
                    'params' : {}
            };
            this.props.updateGroupState(groupID, s, this.props.auth.uid);
            
        } else {
            let groupID = getUserGroup(this.props.session, this.props.auth.uid);
            let s = { 
                    'state' : GROUP_STATE_ACTIVE,
                    'params' : {}
            };
            this.props.updateGroupState(groupID, s, this.props.profile.sessionHost);
        }
    }

    render(){
        const { session, profile, auth, classes, leader } = this.props;


        return (
            <div>
               <Typography variant='h3' className={classes.spaceBottom}>
                    {session.active_game.name}
                </Typography>
               <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <img src={DiSC_Profile} className={classes.imageStyle} alt="Game"/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant='h5'>Instructions</Typography>
                        <Typography variant='body1' className={classes.spaceBottom}>In Part 1 of this exercise, you'll choose from the set of cards that you are provided 
                        to find and select the 5 cards that are most like you (your style).                        
                        </Typography>
                        <Typography variant='body1' className={classes.spaceBottom}>In Part 2 of this exercise, you'll answer a couple of questions as prompted.                        
                        </Typography>
                        <Typography variant='body1' className={classes.spaceBottom}>Detailed instructions will be available at every step. Look for the <LiveHelpIcon/> icon.                
                        </Typography>



                        <br/><br/>
                        { hasGameControl(leader, auth.uid, profile)
                            ?
                            <Button type='submit' variant='contained' onClick={this.sendToNextStep}>
                                Continue
                            </Button>
                            :
                            <Button type='submit' disabled={true} variant='contained'>
                                Leader can hit Continue
                            </Button>
                        }
                    </Grid>
                </Grid>
               
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState : (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID))
    };
  };
  
  export default compose( connect(mapStateToProps, mapDispatchToProps), withStyles(styles) )(Instructions);