import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styles from '../../../Styles';
import { GameGroupStates } from './../../gameServer';
import { updateGroupStateParamsVal, updateGroupStateVal, updateGroupStateValResetResults } from './../../../../store/actions/gameActions';
import { determineHost } from './../game_functions/functions';

function LinearProgressWithLabel(props) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const INITIAL_STATE = {
  progress: 0,
  mouseX: null,
  mouseY: null,
  selectType: null,
  paramToEdit: null
};

class GroupStatus extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  handleClose = () => {
    this.setState({
      ...this.state,
      mouseX: null,
      mouseY: null,
      selectType: null,
      paramToEdit: null
    });
  }

  changeState = (type, val) => {
      if ( type === 'state' ){
        this.props.updateGroupStateValResetResults(this.props.group_id, val, determineHost(this.props.profile, this.props.auth));
      }
      if ( type === 'param' ){
        this.props.updateGroupStateParamsVal(this.props.group_id, this.state.paramToEdit, val, determineHost(this.props.profile, this.props.auth));
      }
      this.setState({
        ...this.state,
        mouseX: null,
        mouseY: null,
        selectType: null,
        paramToEdit: null
      })
  }

  openStateChanger = (event, type, p) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      ...this.state,
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      selectType: type,
      paramToEdit: p
    });
  }

  render() {
    const {
      group_id,
      classes,
      session,
    } = this.props;

    const groupStates = GameGroupStates(session.active_game.id);

    console.log('IN HERE: ', group_id, session);

    if (group_id === 'group-0') {
      return null;
    } else {
      return (
        <div>
          <Menu
            keepMounted
            open={this.state.mouseY !== null}
            onClose={this.handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              this.state.mouseY !== null && this.state.mouseX !== null
                ? { top: this.state.mouseY, left: this.state.mouseX }
                : undefined
            } >
            { this.state.selectType === 'state' && groupStates.states.length > 1 ? 
              groupStates.states.map((state, ind) => {
                  return (
                  <MenuItem key={ind} onClick={() => this.changeState('state', state)}>Change to&nbsp;<span className={classes.boldedText}>{state.charAt(0).toUpperCase() + state.slice(1)}</span></MenuItem>
                  );       
              })
            : null }
            {
              this.state.selectType === 'param' ?
                groupStates.stateParams[session.active_game.groupStates[group_id].state] !== undefined ?
                    groupStates.stateParams[session.active_game.groupStates[group_id].state][this.state.paramToEdit].options.map((choice, ind) => {
                        if ( choice !== session.active_game.groupStates[group_id].params[this.state.paramToEdit] ){
                          return (
                            <MenuItem key={ind} onClick={() => this.changeState('param', choice)}>Change to&nbsp;<span className={classes.boldedText}>{choice}</span></MenuItem>
                          );
                        }
                        return null;
                    })
                : null
              : null
            }
            <MenuItem onClick={() => this.handleClose()}>Cancel</MenuItem>
          </Menu>
          <Paper elevation={3} className={classes.paper}>
            <div className={`${classes.bodyText} ${classes.boldedText} ${classes.spacingBottom}`}>{session.groups[group_id].title}</div>
            <div className={classes.spacingBottom}>
                <Typography variant="h6">Players ({session.groups[group_id].playerIds.length})</Typography>
                {
                  session.groups[group_id].playerIds.map((playerId, ind) => {
                    let c = (ind > 0 ? ', ' : '');
                    return (
                        `${c}${session.playerProfiles[playerId].displayName}`
                    )
                  })
                }
            </div>
            { groupStates.states.length > 1 ?
            <div>
              <div className={`${classes.descriptiveText} ${classes.spacingTop}`}>Group State:</div>
              <div className={classes.selectableBox} onClick={(e) => this.openStateChanger(e, 'state', null)}>{session.active_game.groupStates[group_id].state.charAt(0).toUpperCase() + session.active_game.groupStates[group_id].state.slice(1)}</div>
            </div>
            : null }
            {
              groupStates.stateParams[session.active_game.groupStates[group_id].state] !== undefined ?
                  Object.keys(groupStates.stateParams[session.active_game.groupStates[group_id].state]).map((paramKey, index) => {
                    return (
                    <div key={index}>
                      <div className={`${classes.descriptiveText} ${classes.spacingTop}`}>{paramKey.charAt(0).toUpperCase() + paramKey.slice(1)}:</div>
                      <div className={classes.selectableBox} onClick={(e) => this.openStateChanger(e, 'param', paramKey)}>{session.active_game.groupStates[group_id].params[paramKey] !== undefined ? session.active_game.groupStates[group_id].params[paramKey] : groupStates.stateParams[session.active_game.groupStates[group_id].state][paramKey].default}</div>
                    </div>
                    );
                  })
              : null
            }
          </Paper>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGroupStateVal:  (group, val, hostID) => dispatch(updateGroupStateVal(group, val, hostID)),
    updateGroupStateValResetResults:  (group, val, hostID) => dispatch(updateGroupStateValResetResults(group, val, hostID)),
    updateGroupStateParamsVal:  (group, param, val, hostID) => dispatch(updateGroupStateParamsVal(group, param, val, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GroupStatus);
