import React from 'react';
//import { Link } from 'react-router-dom';
//import * as ROUTES from '../../constants/routes';
//import firebase from 'firebase/app';
//import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { grey } from '@material-ui/core/colors';
import { Divider } from '@material-ui/core';
import PlayersLobby from '../Players/lobby';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  greyText: {
    color: grey[600],
  },
}));

const AwaitingGame = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} align='center'>
          <Typography variant='h6' className={classes.greyText}>
            Thanks for joining up. <br/>Stay tuned... your host{props.hostInfo === undefined ? '' : `, ${props.hostInfo.displayName},` } will launch the activity shortly...
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <PlayersLobby session={props.session} />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDisptachToProps = (dispatch) => {
  return {};
};

export default compose(withTranslation(), connect(mapStateToProps, mapDisptachToProps))(AwaitingGame);
