import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import image1 from '../images/Picture1.png';
import image2 from '../images/Picture2.png';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const PersonifyLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>This activity highlights the fact that when we’re divided into teams, we often compete against each other instead of working together to achieve more. </li>
                    <li className={classes.properListItem}>In teams, participants take turns placing their game pieces on a grid. </li>
                    <li className={classes.properListItem}>The score 10 points for every 3-game shapes in a row (vertical, horizontal or diagonal)</li>
                    <li className={classes.properListItem}>The goal is to get as many points as possible. Teams tend to compete for points and try to block/sabotage other teams instead of working together to reach the goal</li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Working collaboratively vs competitively</li>
                    <li className={classes.properListItem}>Maximizing results to benefit the whole team</li>
                    <li className={classes.properListItem}>Sharing information rather than practicing knowledge is power</li>
                    <li className={classes.properListItem}>Understanding who the real competition is</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <Typography variant="body1"  className={classes.LNProperStart}><b>Announce to the group: Congratulations, you are all members of the new company, Personify (give a cheer and clap).</b></Typography>            
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Divide the group into 2 groups (6-8 per group)</li>
                    <li className={classes.properListItem}>Once the groups are formed, ask them to split into 2 smaller teams (you will want to have 3-4 per smaller team)</li>
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}><b>Goal: </b></Typography>            
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Accumulate as many points as possible </li>
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}><i><b>Facilitators note</b> – if team asks if this is by team, just refer back to this statement “Congratulations, you are all members of the new company, Personify”</i></Typography>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Rules:</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>No talking to the other team on the opposite side of your table, at any time during the game</li>
                    <li className={classes.properListItem}>10 points scored for 3-game shapes in a row; rows can be vertical, horizontal, or diagonal</li>
                    <li className={classes.properListItem}>Teams alternate placing shapes on the grid until all shapes placed</li>
                    <li className={classes.properListItem}>A different team starts each round of play</li>
                    <li className={classes.properListItem}>Wild cubes can be played any time and counted by either team</li>
                    <li className={classes.properListItem}>Once a shape is placed it can’t be moved</li>
                    <li className={classes.properListItem}>Teams have 3minutes to plan between rounds of play</li>
                    <li className={classes.properListItem}>Any questions?</li>
                </ul> 
                <Typography variant="body1"  className={classes.LNProperStart}><b>Scoring Flip Chart – after each round, record the scores; keep the flipchart visible</b></Typography>            
                  <img src={image1} alt='flipchart example'/>     
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief:</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What were your initial thoughts regarding the game?</li>
                    <li className={classes.properListItem}>What plan took shape?</li>   
                    <li className={classes.properListItem}>Did you see this as a competition?</li>   
                    <li className={classes.properListItem}>How was trust a factor in the game?</li>   
                    <li className={classes.properListItem}>Did the energy change when you began to work as one team?</li>
                    <li className={classes.properListItem}>Who’s the real competition? The other companies – who do we often compete with – other shifts, or departments </li>
                    <li className={classes.properListItem}>What if instead we shared our knowledge and all won – getting stronger as an organization?</li>
                    <li className={classes.properListItem}>How can you work cross functionally to make this happen?</li>                           
                </ul>
                <Typography variant="body1"  className={classes.LNProperStart}><i><b>Facilitators note:</b>we want the teams to realize they are competing with each other and just blocking the other team – stop after round 2 and ask them what they are doing in their strategy – they will say we are blocking the other team. Ask how you can do this differently and achieve the goal. Teams will realize they need to work together.</i></Typography>            
                <Typography variant="h5"> </Typography> 
                <Typography variant="body1"  className={classes.LNProperStart}><b>How to Maximize Points:</b></Typography>
                <img src={image2} alt="max points example" />            

            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
          
           
        </div> 
    )
}

export default withStyles(styles)(PersonifyLN);