import React, { Component } from 'react';
import { firestoreConnect, isEmpty, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { startGame, zeroGameState } from '../../store/actions/gameActions';
//import CircleOfInfluenceDebrief from '../Game/games/circleOfInfluence/images/CircleOfInfluenceDebrief.png';
import walkawhileCropped from '../Game/games/walkAwhileInMyShoes/images/walkawhileCropped.png';
import plus_delta from '../Game/games/journey/images/tuatj.png';
import stopDrop from '../Game/games/managingTime/images/stopDrop.png';
import Logo from '../Game/games/distortion/images/Logo.png';
import HiddenAgendaLogo from '../Game/games/hiddenAgenda/images/Logo.png';
import SeeingThePointLogo from '../Game/games/seeingThePoint/images/logo.png';
import SILogo from '../Game/games/engagingStakeholders/images/si_logo.png';
import LTLogo from '../Game/games/learningTransfer/images/logo.png';
import DiSC_Icon from '../Game/games/discCards/images/DiSC_Icon.png';
import bbss_logo from '../Game/games/bbss/images/bbss_logo.png';
import scenario from '../Game/games/emotionalIntelligence/images/scenario.png';
import AggregateLogo from '../Game/games/aggregate/images/AggregateLogo.png';
import CircleLogo from '../Game/games/circleWords/images/Circle-Words-Wordmark.png';
import COFLogo from '../Game/games/circleOfInfluence/images/circleLogo.png';
import goldf from '../Game/games/discussionForum/images/goldf.png';
import CommunicationStyles from '../Game/games/communicationStyles/images/CommunicationsStyles.png';
import on_deck from '../Game/games/ondeck/images/on_deck.png';
import placeholder from '../../images/placeholder.jpg';
import TrustLogo from '../Game/games/trustSpectrum/images/Trust-Spectrum-Logo.png';
import BWBLogo from '../Game/games/bestWorstBoss/images/Best-Worst-Leader-wordmark.png';
import CountyFairLogo from '../Game/games/countyFair/images/county_fair.png';
import ptb from '../Game/games/transitionPeerToBoss/images/peertoboss.png';
import styles from '../Styles';
import { getFirebase } from 'react-redux-firebase';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { deepPurple } from '@material-ui/core/colors';
import set_expectations_square from '../Game/games/expectationsAndAccountabilities/images/set_expectations_square.png';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import GameOptions from './gameOptions';
import ioc from '../Game/games/conflictScenarios/images/ioc.png';
import Feedback from '../Game/games/feedback/images/feedback.png';
import personifyLogo from '../Game/games/personify/images/logo.png';
import discussionForum from '../Game/games/discussionForum/images/Discussion_Forum_Wordmark.png';
import TrustBuild from '../Game/games/trustBehaviors/images/Trust-Build.jpg';
import TimerIcon from '@material-ui/icons/Timer';
import EditIcon from '@material-ui/icons/Edit';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import LeaderNotes from './leaderNotes';
import LiquidLoadingBlock from './../Loading/loadingCup';
import { determineHost } from './../Game/games/game_functions/functions';

const GameCard = withStyles((theme) => ({
  root: {
    '&:focused': {
      background: deepPurple[100],
    },
  }
}))(CardContent);

const DEFAULT = 'default';
const MAIN = 'main'

const INITIAL_STATE = {
  optionsDisplay: undefined,
  optionsGame: undefined,
  leaderNotesOpen: false,
  leaderNotesID: null,
  leaderNotesName: null,
  imagesLoaded: false,
  logoURLs: [],
  activityNames: []
}

class GamesList extends Component {
  constructor(props) {
      super(props);
      this.state = {
          ...INITIAL_STATE
      }
  }

  processImages = async () => {
    let logos = {};
    let names = {};
    const sto = getFirebase().storage();

    let host = determineHost(this.props.profile, this.props.auth);

    


    //console.log(this.props.game_options);
    await Promise.all( Object.keys(this.props.game_options).map( async (gameDoc, ind) => {
        let ref = false;
        let nRef = false;

        //console.log(this.props.user_options);
        let usingSets = this.props.game_options[gameDoc][host] !== undefined ? 
          this.props.game_options[gameDoc][host].selectedSet === undefined ? false : true
            : false;

        if ( usingSets ){
          let set = this.props.game_options[gameDoc][host].selectedSet;
          //console.log('set: ', set, this.props.userOptions);
          let o = undefined;
          if ( this.props.user_options === null ){
            o = undefined;
          } else {
            o = this.props.user_options[set];
          }
          
          if ( o === undefined ){
            if ( this.props.game_options[gameDoc][DEFAULT].game_images[MAIN] !== undefined ){
              ref = sto.ref(this.props.game_options[gameDoc][DEFAULT].game_images[MAIN]);
            } else {
              ref = false;
            }

            if ( this.props.game_options[gameDoc][DEFAULT].game_texts['game_title'] !== undefined ){
              nRef = this.props.game_options[gameDoc][DEFAULT].game_texts['game_title'];
            } else {
              nRef = false;
            }

          } else {
            // use YOURS
            //console.log('wtf', o);
            if ( o.game_images[MAIN] !== undefined ){
              ref = sto.ref(o.game_images[MAIN]);
            } else {
              ref = false;
            }   
            
            if ( o.game_texts['game_title'] !== undefined ){
              nRef = o.game_texts['game_title'];
            } else {
              nRef = false;
            }
          }

        } else {
          //console.log('GAME DOC:', gameDoc);
          if ( this.props.game_options[gameDoc][this.props.auth.uid] === undefined ){
            // use default
            if ( this.props.game_options[gameDoc][DEFAULT].game_images[MAIN] !== undefined ){
              ref = sto.ref(this.props.game_options[gameDoc][DEFAULT].game_images[MAIN]);
            } else {
              ref = false;
            }

            if ( this.props.game_options[gameDoc][DEFAULT].game_texts['game_title'] !== undefined ){
              nRef = this.props.game_options[gameDoc][DEFAULT].game_texts['game_title'];
            } else {
              nRef = false;
            }

          } else {
            // use YOURS
            if ( this.props.game_options[gameDoc][this.props.auth.uid].game_images[MAIN] !== undefined ){
              ref = sto.ref(this.props.game_options[gameDoc][this.props.auth.uid].game_images[MAIN]);
            } else {
              ref = false;
            }   
            
            if ( this.props.game_options[gameDoc][this.props.auth.uid].game_texts['game_title'] !== undefined ){
              nRef = this.props.game_options[gameDoc][this.props.auth.uid].game_texts['game_title'];
            } else {
              nRef = false;
            }
          }
        }



        if ( ref !== false ){
          let url = await ref.getDownloadURL();
          logos[gameDoc] = url;
        }

        if ( nRef !== false ){
          names[gameDoc] = nRef;
        }
    }));
    return Promise.resolve({ logos: logos, names: names });
}

    async componentDidMount() {
      if ( !this.state.imagesLoaded && isLoaded(this.props.game_options) && isLoaded(this.props.user_options) ){
        try {
            await this.processImages().then((logos) => {
                window.scrollTo(0, 0)
                console.log('the logos', logos);
                this.setState({
                    ...this.state,
                    logoURLs : logos.logos,
                    activityNames: logos.names,
                    imagesLoaded: true,
                }); 
                return Promise.resolve();
            });
        } catch (error) {
            console.log('error', error);
        }
      }
        
    }

    componentDidUpdate() {
      if ( !this.state.imagesLoaded && isLoaded(this.props.game_options) && isLoaded(this.props.user_options) ){
        try {
            this.processImages().then((logos) => {
                window.scrollTo(0, 0);
                //console.log('the logos', logos);
                this.setState({
                    ...this.state,
                    logoURLs : logos.logos,
                    activityNames: logos.names,
                    imagesLoaded: true,
                }); 
                return Promise.resolve();
            });
        } catch (error) {
            console.log('error', error);
        }
      }
    }

  

  startNewGame = (index, name, game) => {
    this.props.startGame(index, name, game);
  };

  getGameImage(game_id) {

    if ( this.state.logoURLs[game_id] !== undefined ){
      return this.state.logoURLs[game_id];
    }

    switch (game_id) {
      case 'Cd43w7C4VTHMhxaCefY8':
        // Learning Transfer
        return LTLogo;
    case 'eGS0SyLeRo5kSeDh4Vom':
        // Engaging Stakeholders
        return SILogo;
      case 'zj8FYS7xS9bAqZRKsxDu':
        // Trust Behaviors
        return TrustBuild;
      case 'uTeZJmY1zc6PDDvMC1Xk':
        // Showcase Discussion Forum
        return Feedback;
      case 'f3Ec5g6amsoAaFr34U9J':
        // Personify
        return personifyLogo;
    case 'hKfvgwPfyOg1DKZ5WrFf':
        // County Fair
        return CountyFairLogo;
      case 'Rh4R3HbDo5J2WetudJrn':
        // Seeing the Point
        return SeeingThePointLogo;
      case 'BfxbeGXDrjAgfP9Y0ljZ':
        // Managing Time - Supervisors
        return stopDrop;
      case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return HiddenAgendaLogo;
      case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
        return on_deck;
      case 'ynJQDyus16D9CIjtdyIN':
        // Conflict Scenarios
        return ioc;
      case 'FUm6uMZ9QhLkbsnmEbQN': 
        // Trust Spectrum - Individual
        return TrustLogo;
      case 'oAOQt7YfZzDTa2WiQ7ZR':
        // Trust Spectrum
        return TrustLogo;
      case 'QFbnz1m3ydc20VbuGVHP':
        // Bring, Brag, Show, Steal
        return bbss_logo;
      case 'vcZv6f1cOktHSds2kdme':
        // Emotional Intelligence
        return scenario;

      case 'YCSJs4yYGYTK5N6sLjZG':
        // Walk a While in my Shoes
        return walkawhileCropped;
      case '6HkVJoZZBa6JeZYrNKoz':
        // Best/Worst Boss
        return BWBLogo;
      case 'gMuD2xH6Kl37u9WbxYxN':
          // DiSC Conflict
          return DiSC_Icon;
      case 'VoJYJGwzxiUQap9ZJZEu':
        // DISC Cards
        return DiSC_Icon;
      case 'PiFZGYehVialCu1eRDvt':
        // Communication Styles
        return CommunicationStyles;
      case '3f19Vfx31DCuCRC9W3Fw':
        // Circle of Influence
        return COFLogo;
      case 'd0UzzPqrpkeiqdmONmYD':
        // Transition Peer to Boss
        return ptb;
      case 'i9VYXjMHierYCCFwcDvV':
        return set_expectations_square;  
      case 'ojQicb2jdFh77nNPn3Tv':
          // Feedback
          return Feedback;     
      case '7xgKmmwM9RlvOmYtY9Qc':
        // Tell Us About The Journey
        return plus_delta;
      case 'XHfImKbo8S2UDPl2cerz':
        // Tell Us About The Journey - Mod 3&4
        return plus_delta;
      case 'JP2iV9t6J2KGkX7hDQIb':
          return discussionForum;
      case 'zjgMvCBlfUo25Xalulti':
          // Discussion Forum
          return discussionForum;
      case 'bJhzgqZsI9lzXZFsMHdi':
        // Discussion Forum (showcase)
        return discussionForum;
      case 'CFDb34wvDq0sC5BWfMPL':
        // Managing Time
        return stopDrop;
      case '6MK8K4f5uenoAFBBShto':
          // Possibly Aggregate
        return AggregateLogo;
      case 'VzVPeJxDCqc2K5Juo14e':
        // Distortion
        return Logo;
      case '4njCJXU1wrNjpWRToxvo':
          // Circle Words
          return CircleLogo;
      default:
        return placeholder;
    }
  }

  goToGameOptions = (game_id, game) => {
    this.props.zeroGameState();
    this.setState({
      ...this.state,
      optionsDisplay: game_id,
      optionsGame: game
    });
  }

  returnToGameList = () => {
    this.setState({
      ...this.state,
      optionsDisplay: undefined,
      optionsGame: undefined,
      imagesLoaded: false
    })
  }

  openLeaderNotes = (activityID, activityName) => {
    this.setState({
      ...this.state,
      leaderNotesOpen: true,
      leaderNotesID: activityID,
      leaderNotesName: activityName
    })
  }

  closeLeaderNotes = () => {
    this.setState({
      ...this.state,
      leaderNotesOpen: false,
      leaderNotesID: null,
      leaderNotesName: null
    })
  }

  sortGames = (a,b) => { var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0; 
  }

  render() {
    const {
      classes,
      games,
      game_options,
      session,
      profile,
      gameData,
      auth
    } = this.props;


    //console.log('?', profile, profile.activitiesAvailable);
    const activitiesAvailable = profile.activitiesAvailable === undefined ? this.props.gameData === undefined ? [] : Object.keys(this.props.gameData) : profile.activitiesAvailable;
    //console.log('test',activitiesAvailable);

    if ( this.state.imagesLoaded === false ){
      return <div style={{marginTop: "80px"}}><LiquidLoadingBlock/></div>; 
    }


    if ( this.state.optionsDisplay !== undefined ){
      return <GameOptions game_id={this.state.optionsDisplay} game={this.state.optionsGame} game_name={gameData[this.state.optionsDisplay].name} session={session} auth={auth} profile={profile} options={game_options[this.state.optionsDisplay]} returnToGameList={this.returnToGameList}/>;
    } else {
      if (games && game_options !== undefined) {
        if (games.length > 0) {
          if (profile.isLoaded && profile.role === 'host') {
            return (
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  <Typography variant='h4' className={`${classes.greyText}  ${classes.spacingBottom} ${classes.spacingTop}`}>
                    Activities
                  </Typography>

                  <LeaderNotes open={this.state.leaderNotesOpen} activityID={this.state.leaderNotesID} activityName={this.state.leaderNotesName} closeNotes={this.closeLeaderNotes} classes={classes}/>

                  <Grid container spacing={2} className={classes.stretchContainer}>

                    {
                      games === undefined ? null :                        
                        games.slice().sort(this.sortGames).map((game, index) =>
                          (game.available || process.env.NODE_ENV === 'development') && (activitiesAvailable.includes(game.id) || activitiesAvailable[0] === 'ALL' ) ?                           
                          (
                            <Grid item lg={4} key={index} className={classes.stretchMe}>
                              <Card className={classes.gameListRoot}>
                                <CardActionArea>
                                  <div className={classes.lightPurpleBG} style={{padding: "20px"}}><CardMedia className={`${classes.media} ${classes.lightPurpleBG}`} image={this.getGameImage(game.id)} title={game.name} /></div>
                                  <GameCard >
                                    <Typography gutterBottom variant='h5' component='h2' className={classes.glassPurple}>
                                      {this.state.activityNames[game.id] !== undefined ? this.state.activityNames[game.id] : game.name} {!game.available ? ' - Dev' : null}
                                    </Typography>
                                    <Typography variant='body1' color='textSecondary' component='p'>
                                      {game.description}
                                    </Typography>
                                  </GameCard>
                                </CardActionArea>
                                <CardActions style={{borderTop:"3px solid #d1c4e9"}}>
                                  {session !== undefined && Object.keys(session.active_game).length === 0 ? (
                                    <div style={{textAlign: 'right', width: "100%"}}>
                                      <Grid container>
                                        <Grid item xs={3} align="left">
                                          <Tooltip title="Activity Time">
                                            <IconButton aria-label="Start Activity">
                                              <TimerIcon fontSize="large" /> <span style={{fontSize: "12px"}}>{ game.time === undefined ? `? M` : game.time}</span>
                                            </IconButton>
                                          </Tooltip>
                                        </Grid>
                                        <Grid item xs={9}>
                                        
                                        { game_options !== undefined ? 
                                        game_options[game.id] !== undefined ? 
                                      <Tooltip title="Activity Options"><IconButton  aria-label="Activity Options" onClick={() => this.goToGameOptions(game.id, game)} className={classes.glassPurple} >
                                        <EditIcon fontSize="large"  />
                                      </IconButton>
                                      </Tooltip>
                                        : null
                                      : null }
                                        <Tooltip title="Leader Notes"><IconButton  aria-label="Activity Options" onClick={() => this.openLeaderNotes(game.id, game.name)} className={classes.glassPurple} >
                                          <SpeakerNotesIcon fontSize="large"  />
                                        </IconButton>
                                        </Tooltip>
                                      <Tooltip title="Start Activity"><IconButton aria-label="Start Activity" onClick={() => this.startNewGame(game.id, game.name, game)} className={classes.glassPurple}>
                                        <PlayCircleFilledWhiteIcon fontSize="large" />
                                      </IconButton>
                                      </Tooltip>
                                        </Grid>
                                      </Grid>
                                      
                                    
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </CardActions>
                              </Card>
                            </Grid>
                          ) : null
                        )
                      }
                  </Grid>
                </Grid>
              </Grid>
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    games: state.firestore.ordered.games,
    gameData: state.firestore.data.games,
    game_options: state.firestore.data.game_options,
    user_options: state.firestore.data.user_options
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startGame: (creds, name, game) => dispatch(startGame(creds, name, game)),
    zeroGameState: () => dispatch(zeroGameState())
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => {
    return [
      {
        collection: 'games',
      },
      {
        collection: 'game_options'
      },
      { 
        collection: 'user_options',
        where: [ 'uid', '==', determineHost(props.profile, props.auth) ]     
      }
    ];
  })
)(GamesList);
