import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import MenuIcon from '@material-ui/icons/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import TextField from '@material-ui/core/TextField'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import { endSession } from '../../store/actions/sessionActions'
import {
    updateHostState,
    endGame,
    updateModState
} from '../../store/actions/gameActions'
import { renameSession } from '../../store/actions/sessionActions'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import DashboardIcon from '@material-ui/icons/Dashboard'
import GroupIcon from '@material-ui/icons/Group'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import EditIcon from '@material-ui/icons/Edit'
import {
    GAME_STATE_ACTIVE,
    GAME_STATE_RESULTS,
    HOST_STATE_GROUP_SELECT,
    HOST_STATE_MAIN,
    HOST_STATE_GROUP,
    HOST_STATE_RESULTS,
    GAME_STATE_INIT
} from '../../store/actions/gameActions'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import TutorialVideoPlayer from './tutorialVideos'
import ListIcon from '@material-ui/icons/List'
import StopIcon from '@material-ui/icons/Stop'
import { sortGroupStrings } from './../Game/games/game_functions/functions'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import styles from '../Styles'
import TimeToLeave from '@material-ui/icons/TimeToLeave'

export default function HostControls(props) {
    const { session, profile, classes, open } = props

    const gameActive = session.active_game.name === undefined ? false : true
    const hostState =
        session.hostState === undefined ? false : session.hostState.state
    const gameState = gameActive ? session.active_game.gameState.state : false

    return (
        <div>
            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItem className={classes.navHeader}>
                    <ListItemText primary="Options" />
                </ListItem>
                <ListItem button onClick={props.openTutorial}>
                    <ListItemIcon>
                        <VideoLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tutorial Videos" />
                </ListItem>
                <Divider />
                <ListItem button onClick={props.goToAdmin}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Go To Admin" />
                </ListItem>
                <ListItem className={classes.navHeader}>
                    <ListItemText primary="Navigation" />
                </ListItem>

                <div>
                    <ListItem
                        button
                        onClick={() => {
                            props.editGroups()
                        }}
                        style={{
                            background:
                                hostState === HOST_STATE_GROUP_SELECT
                                    ? '#d1c4e9'
                                    : '#fff'
                        }}
                    >
                        <ListItemIcon>
                            <GroupIcon />
                        </ListItemIcon>
                        <ListItemText primary="Edit Groups" />
                    </ListItem>

                    {gameState !== GAME_STATE_INIT &&
                    gameState !== GAME_STATE_ACTIVE &&
                    gameState !== GAME_STATE_RESULTS ? (
                        <div>
                            <Divider />
                            <ListItem
                                button
                                onClick={() => {
                                    props.backToList()
                                }}
                                style={{
                                    background:
                                        hostState === '' ? '#d1c4e9' : '#fff'
                                }}
                            >
                                <ListItemIcon>
                                    <ListIcon />
                                </ListItemIcon>
                                <ListItemText primary="Activities List" />
                            </ListItem>
                        </div>
                    ) : null}
                </div>

                {session !== undefined && gameState === GAME_STATE_ACTIVE ? (
                    <div>
                        <Divider />
                        <ListItem
                            button
                            onClick={() => {
                                props.goToDashboard()
                            }}
                            style={{
                                background:
                                    hostState === HOST_STATE_MAIN
                                        ? '#d1c4e9'
                                        : '#fff'
                            }}
                        >
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Activity Dashboard" />
                        </ListItem>
                    </div>
                ) : null}
                {session !== undefined && gameState === GAME_STATE_RESULTS ? (
                    <div>
                        <Divider />
                        <ListItem
                            button
                            onClick={() => {
                                props.goToResults()
                            }}
                            style={{
                                background:
                                    gameState === GAME_STATE_RESULTS &&
                                    hostState === HOST_STATE_RESULTS
                                        ? '#d1c4e9'
                                        : '#fff'
                            }}
                        >
                            <ListItemIcon>
                                <EqualizerIcon />
                            </ListItemIcon>
                            <ListItemText primary="Activity Results" />
                        </ListItem>
                    </div>
                ) : null}

                {session !== undefined &&
                gameState === GAME_STATE_ACTIVE &&
                Object.keys(session.groups).length > 0 ? (
                    <div>
                        <ListItem className={classes.navHeader}>
                            <ListItemText primary="View Groups" />
                        </ListItem>
                        <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                            {Object.keys(session.groups)
                                .sort(sortGroupStrings)
                                .map((groupKey, index) => {
                                    if (
                                        session.groups[groupKey].id !==
                                        'group-0'
                                    ) {
                                        return (
                                            <ListItem
                                                button
                                                key={index}
                                                onClick={() => {
                                                    props.goToGroup(groupKey)
                                                }}
                                                style={{
                                                    background:
                                                        hostState ===
                                                            HOST_STATE_GROUP &&
                                                        session.hostState.params
                                                            .group === groupKey
                                                            ? '#d1c4e9'
                                                            : '#fff'
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <PeopleOutlineIcon />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={
                                                        session.groups[groupKey]
                                                            .title
                                                    }
                                                />
                                            </ListItem>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                        </List>
                    </div>
                ) : null}

                <ListItem className={classes.navHeader}>
                    <ListItemText primary="Session Options" />
                </ListItem>
                {Object.keys(session.active_game).length > 0 &&
                profile.role === 'host' ? (
                    <div>
                        <Divider />
                        <ListItem
                            button
                            onClick={() => props.handleDialogOpen()}
                        >
                            <ListItemIcon>
                                <StopIcon className={classes.red} />
                            </ListItemIcon>
                            <ListItemText primary="End Current Activity" />
                        </ListItem>
                    </div>
                ) : null}
                {session !== undefined ? (
                    <div>
                        <Divider />
                        <ListItem button onClick={() => props.renameSession()}>
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Rename Session" />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            onClick={(event) => props.handleSessionDialogOpen()}
                        >
                            <ListItemIcon>
                                <HighlightOffIcon />
                            </ListItemIcon>
                            <ListItemText primary="End Session" />
                        </ListItem>
                        <Divider />
                    </div>
                ) : null}
            </List>
        </div>
    )
}

/*

<div>
          <TutorialVideoPlayer open={this.state.dialogueTutorialOpen} closeTutorial={this.closeTutorial} classes={classes}/>
          <Dialog
              open={this.state.dialogueOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  This action will <span className={classes.boldedText}>end the current activity</span> and <span className={classes.boldedText}>take you back to the activity selection list.</span> Are you sure you want to do this?
              </DialogContentText>
              </DialogContent>
              <DialogActions>
              <Button onClick={this.handleDialogueClose} variant="contained" color="primary">
                  Cancel
              </Button>
              <Button onClick={this.endGame} variant="contained" color="primary" autoFocus>
                  Confirm
              </Button>
              </DialogActions>
          </Dialog>
          <Dialog
              open={this.state.dialogueNameOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth='md' fullWidth
          >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Enter a new name for the session:

                </DialogContentText>              
                <TextField multiline autoFocus autoComplete="off" margin='dense' id='renameText' name='renameText' value={renameText} onChange={this.onChange} label="Session Name" type='text' fullWidth />
              </DialogContent>
              <DialogActions>
              <Button onClick={this.cancelRenameSession} variant="contained" color="primary">
                  Cancel
              </Button>
              <Button onClick={this.saveRename} variant="contained" color="primary" autoFocus>
                  Confirm
              </Button>
              </DialogActions>
          </Dialog>
          <IconButton color='inherit' aria-label='open drawer' edge='end' onClick={this.handleDrawerOpen} className={`${clsx(open && classes.hide)} ${classes.menuZ}`}>
            <MenuIcon className={classes.menuZ} />
          </IconButton>
          <Drawer
            className={`${classes.drawer} ${classes.menuZ}`}
            variant='persistent'
            anchor='left'
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>{' '}
              <span className={classes.playersTitle}>Controls</span>
            </div>
            <Divider />
            <List>
              {session !== undefined && session.players.length > 0 && session.hasGroups !== false ? (
                <ListItem
                  button
                  onClick={() => {
                    this.editGroups();
                  }}
                >
                  <ListItemIcon>
                    <GroupIcon className={classes.lightPurple} />
                  </ListItemIcon>
                  <ListItemText primary='Edit Groups' />
                </ListItem>
              ) : null}
              {session !== undefined && session.active_game.gameState.state === GAME_STATE_ACTIVE ? (
                <ListItem
                  button
                  onClick={() => {
                    this.goToDashboard();
                  }}
                >
                  <ListItemIcon>
                    <DashboardIcon className={classes.medPurple} />
                  </ListItemIcon>
                  <ListItemText primary='Activity Dashboard' className={classes.boldedText}/>
                </ListItem>
              ) : null}
              {session !== undefined && session.active_game.gameState.state === GAME_STATE_RESULTS ? (
                <ListItem
                  button
                  onClick={() => {
                    this.goToResults();
                  }}
                >
                  <ListItemIcon>
                    <EqualizerIcon />
                  </ListItemIcon>
                  <ListItemText primary='Activity Results' />
                </ListItem>
              ) : null}
              {session !== undefined && session.active_game.gameState.state === GAME_STATE_ACTIVE && Object.keys(session.active_game.groups).length > 0 ? (
                <div>
                  <ListItem>
                    <ListItemText primary='View Groups' />
                  </ListItem>
                  <List>
                    {Object.keys(session.active_game.groups)
                      .sort()
                      .map((groupKey, index) => {
                        if (session.active_game.groups[groupKey].id !== 'group-0') {
                          return (
                            <ListItem
                              button
                              key={index}
                              onClick={() => {
                                this.gotoGroup(groupKey);
                              }}
                            >
                              <ListItemIcon>
                                <PeopleOutlineIcon />
                              </ListItemIcon>
                              <ListItemText primary={session.active_game.groups[groupKey].title} />
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </List>
                </div>
              ) : null}
            </List>
            <List className={classes.spacingTop}>
              {profile.role === 'moderator' || profile.role === 'host' ? (
                <div>
                  <Divider />
                  <ListItem button onClick={this.openTutorial}>
                    <ListItemIcon>
                      <VideoLibraryIcon  />
                    </ListItemIcon>
                    <ListItemText primary='Tutorial Videos' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={this.goToAdmin}>
                    <ListItemIcon>
                      <ExitToAppIcon  />
                    </ListItemIcon>
                    <ListItemText primary='Go To Admin' />
                  </ListItem>
                </div>
              ) : null}
              {session.active_game !== undefined && profile.role === 'host' ? (
                <div>
                  <Divider />
                  <ListItem button onClick={this.handleDialogueOpen}>
                    <ListItemIcon>
                      <HighlightOffIcon className={classes.red} />
                    </ListItemIcon>
                    <ListItemText primary='End Activity' />
                  </ListItem>
                </div>
              ) : null}
              {session !== undefined && profile.role === 'host' ? (
                <div>
                  <Divider />
                  <ListItem button onClick={() => this.renameSession()}>
                    <ListItemIcon>
                    <EditIcon/>
                    </ListItemIcon>
                    <ListItemText primary='Rename Session' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={this.endSession}>
                    <ListItemIcon>
                      <HighlightOffIcon  className={classes.red} />
                    </ListItemIcon>
                    <ListItemText primary='End Session' />
                  </ListItem>
                  <Divider />
                </div>
              ) : null}
            </List>
          </Drawer>
        </div>

    */
