import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withStyles } from '@material-ui/core/styles'
import { updateGroupState } from './../../../../../store/actions/gameActions'
import Typography from '@material-ui/core/Typography'
import styles from '../../../../Styles'
import WalkStep from './../specific_components/walkStep'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

class Board extends Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef()
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        const x = this.props.step * 350
        let t = 500 + this.props.step * 75
        setTimeout(() => {
            this.scrollToMyRef(x)
        }, t)
    }

    scrollToMyRef = (x) => {
        if (this.myRef !== null && this.myRef !== undefined) {
            if (
                this.myRef.current !== undefined &&
                this.myRef.current !== null
            ) {
                this.myRef.current.scrollLeft = x
            }
        }
    }

    render() {
        const {
            session,
            profile,
            auth,
            classes,
            step,
            group_id,
            params,
            stepsDone
        } = this.props

        return (
            <div>
                <Typography variant="h4">{session.active_game.name}</Typography>
                <Typography variant="body1" className={classes.spaceBottom}>
                    People with a high degree of emotional intelligence know:
                    <br />
                    <br />
                    - What they're feeling
                    <br />
                    - What their emotions mean
                    <br />
                    - How these emotions can affect other people
                    <br />
                    - Use this information to guide behavior
                    <br />
                    <br />
                    For leaders, having emotional intelligence is essential for
                    success. Tap on the scenario indicated by the arrow to view
                    that example.
                </Typography>

                <div className={classes.board}>
                    <div className={classes.boardScroller} ref={this.myRef}>
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={1}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="10deg"
                        />
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={2}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="-10deg"
                        />
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={3}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="10deg"
                        />
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={4}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="-10deg"
                        />
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={5}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="10deg"
                        />
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={6}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="10deg"
                        />
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={7}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="10deg"
                        />
                        <WalkStep
                            params={params}
                            cur_step={step}
                            step={8}
                            session={session}
                            group_id={group_id}
                            profile={profile}
                            auth={auth}
                            leader={session.groups[group_id].leadPlayer}
                            pos={{ x: 0, y: 0 }}
                            rotation="10deg"
                        />
                    </div>
                </div>
                <Typography variant="h5">
                    Scenarios Completed {stepsDone}/8{' '}
                    {stepsDone === 8 ? (
                        <CheckCircleIcon
                            fontSize="large"
                            className={classes.successIcon}
                        />
                    ) : null}
                </Typography>
                {stepsDone === 8 ? (
                    <div className={classes.spacingTop}>
                        <Typography variant="body1">
                            Great Job! Feel free to continue reviewing the
                            different scenarios. When all the groups are done,
                            your host will end the activity and take you to the
                            debrief.
                        </Typography>
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupState: (group, groupState, hostID) =>
            dispatch(updateGroupState(group, groupState, hostID))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(Board)
