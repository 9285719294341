import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import mt_ln_1 from './../images/mt_ln_1.png';
import mt_ln_2 from './../images/mt_ln_2.png';
import mt_ln_3 from './../images/mt_ln_3.png';
import mt_ln_4 from './../images/mt_ln_4.png';
import mt_ln_5 from './../images/mt_ln_5.png';
import mt_ln_6 from './../images/mt_ln_6.png';

import image1 from '../images/Picture1.png';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import GetAppIcon from '@material-ui/icons/GetApp';

const ManagingTimeLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Defining Priorities" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="How to Know what’s Crucial" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Case Study" {...a11yProps(5)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(6)} />
                <Tab className={classes.tabsLNTab} label="Download PDF" {...a11yProps(7)} />
                <Tab className={classes.tabsLNTab} label="Slide Deck" {...a11yProps(8)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>This managing time activity helps participants go through the experience of prioritizing daily tasks in the Priority Matrix. </li>
                    <li className={classes.properListItem}>Participants will work in small groups to read a case study of a day in the life of a supervisor. They will then create a list of tasks from the case study and plot them on the Priority Matrix. </li>
                    <li className={classes.properListItem}>While this is happening, a series of additional tasks will flash on the screen that the group will need to deal with. </li>
                    <li className={classes.properListItem}>Participants need to consider these additional tasks are happening in real time as their day unfolds – which can mean that their priorities change.</li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Managing shifting priorities in a hectic work environment</li>
                    <li className={classes.properListItem}>How to focus on important and not urgent tasks to avoid fire fighting mode</li>
                    <li className={classes.properListItem}>Delegation of not important, not urgent tasks</li>
                    <li className={classes.properListItem}>Recognition that not all issues are as urgent as they appear</li>
                    <li className={classes.properListItem}>Effectively managing work time to ensure key tasks are completed on time</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Review the quadrants of the Priority Matrix. Important/Urgent, Important/Not Urgent, Not Important/Urgent, Not Important/Not Urgent</li>
                    <li className={classes.properListItem}>Highlight that when planning and prioritizing our daily tasks, we need to be able to focus on the important and not urgent tasks in order to avoid constantly working in a fire fighting mode</li>
                    <li className={classes.properListItem}>Anything that is important, will become urgent if these taks are put aside too often a deadline will become imminent – and suddenly this task will become urgent</li>
                    <li className={classes.properListItem}>An important leadership skill is to be able to delegate tasks appropriately so that you can focus on the right line level of responsibility</li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">How to Know What’s Crucial</Typography>
                <Typography variant="h5"></Typography>
                <Typography variant="body1" className={classes.LNProperStart}><b>Set Up</b></Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Stephen R Covey wrote the book “The 7 Habits of Highly Effective People”</li>
                    <li className={classes.properListItem}>Published in 1989 and was considered a ground-breaking book </li>
                    <li className={classes.properListItem}>Both a business and self-help book </li>
                    <li className={classes.properListItem}>He presents an approach on being effective in attaining goals by coordinating your values with timeless principles</li> 
                    <li className={classes.properListItem}>This matrix helps us to define priorities</li>
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b>Priority Matrix</b></Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>In the book he popularized</b> the concept of a Time Management Matrix for prioritizing </li>
                    <li className={classes.properListItem}>The matrix focuses on four quadrants to determine the tasks you “need” to do and deciding what should be made a priority. </li>  
                </ul> 
                <Typography variant="body1" className={classes.LNProperStart}><b>Note that this isn’t exactly as Covey’s matrix, we have added in the high/low to help illustrate where these lie in the matrix. However, we label the 4 quadrants the same as Covey</b></Typography>  
                <Typography variant="body1" className={classes.LNProperStart}><b>Detail found on the Key Learning Card</b></Typography>
                <img src={image1} alt='Key Learning Card'/>                    
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">How to Know What’s Crucial</Typography>     
                <Typography variant="body1" className={classes.LNProperStart}><b>Defining Priorities</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>It is important in an organization to have consistent methods to define priorities</li>  
                </ul>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>When determining what’s crucial we often hear referenced in time management articles, the questions:  Is this urgent? Is this important?</li>  
                </ul>
                <Typography variant="h5">The Matrix Defined:</Typography>     
                <Typography variant="body1" className={classes.LNProperStart}><b>Urgent:</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Calling for immediate action or attention</li>  
                    <li className={classes.properListItem}>Things that came up after you left work yesterday</li>  
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b>Important:</b></Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Significant value or consequence</li>  
                    <li className={classes.properListItem}>Deserving or requiring serious attention </li>
                    <li className={classes.properListItem}>Activities that help you to achieve your goals. </li>  
                    <li className={classes.properListItem}>Most of us have learned to respond to urgent requests at the expense of important tasks.</li>  
                </ul>
                <Typography variant="h5">Quadrant 1 (top right)</Typography>     
                <Typography variant="body1" className={classes.LNProperStart}><b>Urgent & Important - examples</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>Need to be dealt with immediately</b></li>  
                    <li className={classes.properListItem}>Real major emergencies and crisis issues </li>
                    <li className={classes.properListItem}>Safety incident</li>  
                    <li className={classes.properListItem}>Significant demands for information from superiors or guests </li>
                    <li className={classes.properListItem}>Staff issues or needs </li>  
                    <li className={classes.properListItem}>Problem resolution, fire-fighting, fixes </li>
                    <li className={classes.properListItem}>Serious urgent complaints </li>  
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b>Actions:</b></Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Once you have confirmed the importance and the urgency of these tasks, do these tasks now. </li>
                    <li className={classes.properListItem}>Prioritize these tasks according to their relative urgency.</li>  
                </ul>
                <Typography variant="h5">Quadrant 2</Typography>     
                <Typography variant="body1" className={classes.LNProperStart}><b>Important & Not Urgent - examples</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>need to be planned for</b></li>  
                    <li className={classes.properListItem}>planning, preparation, scheduling </li>
                    <li className={classes.properListItem}>relationship building </li> 
                    <li className={classes.properListItem}>anticipation and prevention </li> 
                    <li className={classes.properListItem}><b><i>This</i></b> developing change, direction, strategy </li>   
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}>(bottom right) <b><i>quadrant is highlighted because Covey emphasizes this is the quadrant that we should focus on long term achievement of goals for</i></b> </Typography>                
                
                <Typography variant="body1" className={classes.LNProperStart}>Actions:</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Critical to success: planning, strategic thinking, deciding direction and aims, etc</li>  
                    <li className={classes.properListItem}>Plan timeslots and personal space for these tasks.</li>  
                </ul>
                <Typography variant="h5">Quadrant 3 (top left)</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>Urgent & Not Important - examples</b>
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}><b>should be minimized or eliminated</b></li>  
                            <li className={classes.properListItem}>trivial requests from others </li>
                            <li className={classes.properListItem}>apparent emergencies </li> 
                            <li className={classes.properListItem}>interruptions and distractions </li> 
                            <li className={classes.properListItem}>misunderstandings appearing as complaints </li>
                            <li className={classes.properListItem}>pointless routines or activities </li>   
                        </ul>
                    </li>
                    <li className={classes.properListItem}>These are the time sucks, the “poor planning on your part does not constitute an emergency on my part” variety of tasks.</li>
                    <li className={classes.properListItem}>So while the crisis of the moment always appears urgent, it may not be the most important issue. </li>
                </ul>
                <Typography variant="h5">Crisis of the Moment</Typography>
                <Typography variant="body1" className={classes.LNProperStart}><b>Actions:</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Always <b>appears urgent</b> yet may not be important.</li>  
                    <li className={classes.properListItem}>Scrutinize and probe demands.  </li>
                    <li className={classes.properListItem}>Help originators to re-assess. </li> 
                    <li className={classes.properListItem}>Wherever possible reject and avoid these tasks sensitively and immediately. </li>   
                </ul>
                <Typography variant="h5">Quadrant 4 </Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>Not Urgent & Not Important - examples</b>
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}><b>should be minimized or eliminated.  </b></li>  
                            <li className={classes.properListItem}>'comfort' activities, net surfing, excessive breaks </li>
                            <li className={classes.properListItem}>chat, gossip, social communications </li> 
                            <li className={classes.properListItem}>excessive time on a low-level activity</li> 
                            <li className={classes.properListItem}>these are often trivial time wasters that add little or no value</li>
                        </ul>
                    </li>
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b>Actions:</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Delegate or eliminate these tasks</li>
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b>Summary:</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Urgent items are usually time sensitive, and they often are accompanied by employees or other associates urging you to take care of them ASAP. </li>
                    <li className={classes.properListItem}>But these items may not, at any specific moment, be the most "important" activity that you could undertake for the success of your business.</li>
                    <li className={classes.properListItem}>The reality at work today is that almost everything at the moment seems urgent, which causes a lot of stress. </li>
                    <li className={classes.properListItem}>In fact, research shows that the number one cause of stress facing senior executives and managers involves meeting deadlines. </li>
                    <li className={classes.properListItem}>Yet deadlines are a way of life in business</li>
                    <li className={classes.properListItem}>So the key is to know how to manage our deadlines</li>
                    <li className={classes.properListItem}>If we find that we are spending most of our time in Quadrant 1 (Urgent and Not Important) then your focus lies too much on the operative aspect while the strategic perspective is left behind.</li>
                </ul>
                <Typography variant="body1" className={classes.LNProperStart}><b> </b></Typography> 
                <Typography variant="body1" className={classes.LNProperStart}>Important and Not Urgent is the strategic perspective and where we need to focus most of our time to eliminate being constantly caught on the treadmill of fighting fires</Typography> 

            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={5}>
                <Typography variant="h5">Let’s Practice this Technique in a Case Study</Typography>
                <Typography variant="h5"> </Typography>      
                <Typography variant="body1" className={classes.LNProperStart}>Set Up</Typography> 

                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Let’s practice utilizing this matrix</li>
                    <li className={classes.properListItem}>In your guide is a case study – this is typically sent as prework</li>
                    <li className={classes.properListItem}>Work in teams</li>
                    <li className={classes.properListItem}>Read the case study and decide the issues and what to do area of the matrix they belong</li>  
                </ul>
                <Typography variant="h5"> </Typography>      
                <Typography variant="body1" className={classes.LNProperStart}><b>Read the Case Study</b></Typography>
                <Typography variant="h5"> </Typography>      
                <Typography variant="body1" className={classes.LNProperStart}><b>In your teams decide…</b></Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What are the issues you need to deal with and in what quadrant should they be?</li>
                    <li className={classes.properListItem}>Use the priority matrix to sort your priorities.</li>
                    <li className={classes.properListItem}>Record the issues from the case study as post it notes; </li>
                    <li className={classes.properListItem}>Then sort the post its into the quardrants</li>
                    <li className={classes.properListItem}>Once you have completed this, a box will appar to add in your  overall recommendations for this manager</li>  
                </ul>
                <Typography variant="h5"> </Typography>      
                <Typography variant="body1" className={classes.LNProperStart}><b>Use the Priority Matrix to sort the priorities</b></Typography> 
                <Typography variant="h5"> </Typography>      
                <Typography variant="body1" className={classes.LNProperStart}><b>Facilitator Note:</b> Give teams 30min to complete the case study.  12 times while they are working they will be interrupted by another task. Once they click on it, it will automatically be added as a sticky note in their taks list. Below is the list of interruptions.</Typography> 
                <Typography variant="h5"> </Typography>
                <Typography variant="body1" className={classes.LNProperStart}><b>Case Study Interruptions List</b></Typography>
                <ol>
                    <li>A process engineer has just brought an ongoing concern to your attention. Although he repeatedly tries to work with your production supervisor, the supervisor continues to disrespect him and  ignores his requests. The enginee is very upset; you need to address this.</li>
                    <li>Your Plant Manager has quarterly meetings with the management team and expects you to be doing the same with your team. It’s nearly the end of the quarter and you’ve yet to have this meeting and he’s looking for an update on the results.</li>
                    <li>Your Plant Manager has stopped you on your rounds and told you “You’re not working at the right level. You’re not a worker on the floor, you are a manager!”  You were only trying to get the right knife blades in place, which seemed like a safety issue to you.</li>
                    <li>Please call your son’s school principal.  It’s important you call back as soon as possible.</li>
                    <li>The Environmental Health and Safety (EHS) engineer just called to tell you that for the second time this month, your Supervisors have not sent anyone to training, despite his follow up.  </li>
                    <li>The AM Tags have been sitting for months in the AIB room. The Maintenance Engineer has requested a meeting with you and the Plant Manager to discuss.</li>
                    <li>The Plant Manager stops by to see how you’re doing and if you’ve had a chance to finish this weeks Learning Roadmap and progress on your project, as he’d love to discuss it with you.</li>
                    <li>Your partner/spouse wants you to call back as soon as possible.</li>
                    <li>There is a jam on the board line and you’re hearing alarms going off.</li>
                    <li>You just got a message from the Plant Manager that the CEO is coming tomorrow for a tour, and your area better be clean and while he’s here he wants your Supervisors to present their 2018 graduation projects.</li>
                    <li>The Warehouse Manager calls you  and says you need to talk to the take off drivers because they are damaging  board and putting board in improper locations. </li>
                    <li>The Plant Manager just stopped by and questions why the EWO are not detailed enough as the way they are now will not get to the root cause – he  asks you to get personally involved and get your Supervisors back on track.</li>
                </ol> 
 

            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={6}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Teams present their findings on how they prioritized the tasks</li>
                    <li className={classes.properListItem}>What was it like being interrupted?</li>   
                    <li className={classes.properListItem}>How did you use the matrix to guide you?</li>   
                    <li className={classes.properListItem}>What general recommendations do you have for this manager?</li>                           
                </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={7}>
                <Typography variant="h5">Download Leader Notes</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download PDF
                </Button>
                </a>
            </TabPanel>
           
            <TabPanel className={classes.LNTabPanel} value={value} index={8}>
                <Typography variant="h5">Slide Deck</Typography>  
                              
            </TabPanel>
           
        </div> 
    )
}

export default withStyles(styles)(ManagingTimeLN);