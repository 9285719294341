import React, { Component } from 'react'
import {
    GAME_STATE_INIT,
    GAME_STATE_ACTIVE,
    GAME_STATE_RESULTS,
    HOST_STATE_GROUP_SELECT,
    HOST_STATE_GROUP,
    HOST_STATE_MAIN,
    HOST_STATE_RESULTS,
    HOST_STATE_GAME_OPTIONS
} from '../../store/actions/gameActions'
import GroupSelector from './groupSelection'
import GroupView from './groupView'
import GameDashboard from './hostViews/dashboard'
import ResultsDashboard from './hostViews/resultsDashboard'
import GameServer from './gameServer'
import styles from '../Styles'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import OptionsPage from './optionPage'

class HostView extends Component {
    render() {
        const {
            game_id,
            game_title,
            session,
            profile,
            auth,
            game_state,
            host_state,
            classes,
            game_options
        } = this.props

        //console.log('aaa', this.props.inlineGameOptions);

        let title = game_title
        if (game_options !== false) {
            title = game_options.game_texts.game_title
        }

        switch (game_state) {
            case GAME_STATE_INIT:
                /* ADD HOST STATE MAPPING HERE */
                switch (host_state.state) {
                    case HOST_STATE_GROUP_SELECT:
                        return (
                            <div>
                                <GroupSelector
                                    gameHasOptions={this.props.gameHasOptions}
                                    inlineGameOptions={
                                        this.props.inlineGameOptions
                                    }
                                    game_options={game_options}
                                    session={session}
                                    profile={profile}
                                    auth={auth}
                                    players={session.players}
                                    game_state={
                                        session.active_game.gameState.state
                                    }
                                />
                            </div>
                        )
                    case HOST_STATE_MAIN:
                        /* DISPLAY GAME DASHBOARD */
                        return (
                            <GameDashboard
                                game_options={game_options}
                                game_id={game_id}
                                session={session}
                                game_title={title}
                                profile={profile}
                                auth={auth}
                                game_state={game_state}
                            />
                        )
                    //break;
                    case HOST_STATE_GAME_OPTIONS:
                        return (
                            <div>
                                <OptionsPage
                                    game_options={game_options}
                                    session={session}
                                    profile={profile}
                                    auth={auth}
                                    players={session.players}
                                    game_state={
                                        session.active_game.gameState.state
                                    }
                                />
                            </div>
                        )
                    default:
                        return (
                            <div>
                                <GroupSelector
                                    gameHasOptions={this.props.gameHasOptions}
                                    inlineGameOptions={
                                        this.props.inlineGameOptions
                                    }
                                    game_options={game_options}
                                    session={session}
                                    profile={profile}
                                    auth={auth}
                                    players={session.players}
                                    game_state={
                                        session.active_game.gameState.state
                                    }
                                />
                            </div>
                        )
                }
            //break;
            case GAME_STATE_ACTIVE:
                switch (host_state.state) {
                    case HOST_STATE_GROUP_SELECT:
                        return (
                            <div>
                                <GroupSelector
                                    gameHasOptions={this.props.gameHasOptions}
                                    inlineGameOptions={
                                        this.props.inlineGameOptions
                                    }
                                    game_options={game_options}
                                    session={session}
                                    profile={profile}
                                    auth={auth}
                                    players={session.players}
                                    game_state={
                                        session.active_game.gameState.state
                                    }
                                />
                            </div>
                        )
                    //break;
                    case HOST_STATE_GROUP:
                        return (
                            <GroupView
                                game_options={game_options}
                                game_id={game_id}
                                session={session}
                                game_title={title}
                                profile={profile}
                                auth={auth}
                                game_state={game_state}
                                group_states={session.active_game.groupStates}
                            />
                        )
                    //break;
                    case HOST_STATE_MAIN:
                        /* DISPLAY GAME DASHBOARD */
                        return (
                            <GameDashboard
                                game_options={game_options}
                                game_id={game_id}
                                session={session}
                                game_title={title}
                                profile={profile}
                                auth={auth}
                                game_state={game_state}
                            />
                        )
                    //break;
                    case HOST_STATE_RESULTS:
                        /* DISPLAY GAME RESULTS DASHBOARD */
                        return (
                            <ResultsDashboard
                                game_options={game_options}
                                game_id={game_id}
                                session={session}
                                game_title={title}
                                profile={profile}
                                auth={auth}
                                game_state={game_state}
                            />
                        )
                    //break;
                    default:
                        return null
                    //break;
                }

            //break;
            case GAME_STATE_RESULTS:
                switch (host_state.state) {
                    case HOST_STATE_GROUP_SELECT:
                        return (
                            <div>
                                <GroupSelector
                                    gameHasOptions={this.props.gameHasOptions}
                                    inlineGameOptions={
                                        this.props.inlineGameOptions
                                    }
                                    game_options={game_options}
                                    session={session}
                                    profile={profile}
                                    auth={auth}
                                    players={session.players}
                                    game_state={
                                        session.active_game.gameState.state
                                    }
                                />
                            </div>
                        )
                    //break;
                    default:
                        /* Display Game Results */
                        return (
                            <GameServer
                                gameHasOptions={this.props.gameHasOptions}
                                inlineGameOptions={this.props.inlineGameOptions}
                                game_options={game_options}
                                game_id={game_id}
                                game_title={title}
                                session={session}
                                profile={profile}
                                auth={auth}
                                game_state={game_state}
                            />
                        )
                    //break;
                }
            default:
                return null
        }
    }
}

HostView.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(HostView)
