const initState = {
  authError: null,
  authMessage: null,
  authPopUp: false,
  authErrorPopUp: false,
  userChecked: false,
  sessionHost: null,
  authCodeValid: null,
  isLoading: false,
  myCode: false,
  planned_session: false,
  authCode: "",
  modInfo: []
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        isLoading: true
      };
    case "GOT_HOSTS_OK":
      return {
          ...state,
          modInfo: action.data
      }
    case 'SEND_CONTACT_EMAIL':
      return {
          ...state,
          authMessage: 'Email Sent Successfully.',
          isLoading: false,
      }
    case 'SEND_CONTACT_EMAIL_ERROR': 
      return{ 
          ...state,
          authError: action.err.message,
          authErrorPopUp: true,
          isLoading: false
      }
    case 'CODE_VALIDATED':
      return action.ret;
    case 'CODE_ALREADY_USED':
      return action.ret;
    case 'LOGIN_PROFILE_CREATED':
      return action.ret;
    case 'SESSION_NOT_FOUND': 
      return action.ret;
    case 'PRE_PLANNED_SESSION_NOT_YET_STARTED':
      return action.ret;
    case 'CODE_VALIDATED_NO_PREPLAN':
      return action.ret;
    case 'LOADING_DONE':
        return {
          ...state,
          isLoading: false
        };
    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'PROFILE_UPDATED':
        return action.ret;
    case 'LOGIN_PROFILE_UPDATED':
      return {
        setName: false
      }
    case 'LOGIN_ANON_ERROR':
        return {
          ...state,
          authError: action.err.message,
          authErrorPopUp: true,
          authCodeValid: true
        };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null,
      };
    case 'LOGIN_CODE_ERROR':
        console.log('login_code_error?');
        return {
          ...state,
          authCodeValid: false,
        };
    case 'LOGIN_ANON_SUCCESS':
      return action.d;
    case 'SIGNOUT_SUCCESS':
      return action.ret;
    case 'NEW_USER_CREATED':
      return {
        ...state,
        authError: null,
      };
    case 'NEW_USER_ERROR':
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'USER_DB_INFO_UPDATED':
      return {
        ...state,
        authError: null,
      };
    case 'USER_DB_INFO_ERROR':
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_EMAIL_HAS_BEEN_UPDATED':
      return {
        ...state,
        authMessage: 'Your email has been updated',
        authPopUp: true,
      };
    case 'USER_EMAIL_NOT_UPDATED':
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_ID_EMAIL_NOT_UPDATED':
      return {
        ...state,
        authError: action.err.message,
      };
    case 'CHANGE_EMAIL_REAUTHENTICATION_ERROR':
      return {
        ...state,
        authError: action.err.message,
      };
    case 'USER_PASSWORD_WAS_NOT_UPDATED':
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR':
      return {
        ...state,
        authError: action.err.message,
        authErrorPopUp: true,
      };
    case 'USER_PASSWORD_WAS_UPDATED':
      return {
        ...state,
        authMessage: 'Your password has beend updated.',
        authPopUp: true,
      };
    case 'PASSWORD_RESET_SENT':
      return {
        ...state,
        isLoading: false,
        authMessage: 'An email has been sent to reset your password',
        authPopUp: true,
      };
    case 'ERROR_SENDING_PASSWORD_RESET':
      return {
        ...state,
        isLoading: false,
        authError: 'An email reset failed to be sent',
        authErrorPopUp: true,
      };
    case 'CLOSE_AUTH_POPUPS':
      return {
        ...state,
        authError: null,
        authErrorPopUp: false,
        authMessage: null,
        authPopUp: false,
      };
    case 'USER_IS_CLINIC':
      return {
        ...state,
        userChecked: true,
      };
    default:
      return state;
  }
};

export default authReducer;
