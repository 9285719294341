import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import TangramMS from './../specific_components/tangram';
import { determineHost } from '../../game_functions/functions';

import { moveBackToResultsPhase, moveBackToActiveWithParams, updateGroupStateStateVal, moveBackToInitPhaseWithOption } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Box from '@material-ui/core/Box';
import ActivityOptions from './../../../activityOptions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ComparisonTable } from './../specific_components/comparisonTable';
import { ComparisonTapTable } from './../specific_components/comparisonTapTable';
import { gameOptions } from './../../../gameServer';


const INITIAL_STATE = {
    open: false,
    tab: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    dialogOpen: false,
    newPuzzleId: null,
    newPuzzleTitle: null
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other} >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
        };
    }

    handleTabChange = (event, newValue) => {
        this.setState({
            ...this.state,
            tab: newValue
        })
    };

    setOption = (id, title) => {
        this.setState({
            ...this.state,
            dialogOpen: true,
            newPuzzleId: id,
            newPuzzleTitle: title
        })
    }

    handleDialogClose = () => {
        this.setState({
            ...this.state,
            dialogOpen: false
        })
    }

    endGame = () => {
        this.props.moveBackToInitPhaseWithOption(this.props.session, this.props.auth.uid, 'Puzzle', this.state.newPuzzleId);
    }

    render() {
        const { session, profile, auth, classes } = this.props;
        let oImg = null;
        let p = this.props.session.active_game.Puzzle;
        let g = gameOptions(this.props.session.active_game.id);
        if (p !== undefined) {
            g['Puzzle'].map((option, ind) => {
                if (option.id === p) {
                    oImg = option.img;
                }
                return null;
            })
        }

        //const theImg = this.props.session.active_game.Puzzle === undefined ? ImgPuzzleClue : oImg

        return (
            <div>

                <Typography variant="h3">{session.active_game.name}</Typography>
                <Typography variant='h5' className={classes.spaceBottom}>
                    Debrief
                </Typography>


                <Paper square className={classes.root} style={{ minHeight: "700px", minWidth: "920px" }}>
                    <AppBar position="static">
                        <Tabs
                            value={this.state.tab}
                            onChange={this.handleTabChange}
                            variant="fullWidth"
                            indicatorColor="secondary"
                            aria-label="icon label tabs example" >
                            {Object.keys(session.groups).sort().map((groupKey, ind) => {
                                if (groupKey !== 'group-0') {
                                    return (
                                        <Tab label={session.groups[groupKey].title} key={ind - 1} />
                                    )
                                } else {
                                    return null;
                                }
                            })}
                        </Tabs>
                    </AppBar>
                    {Object.keys(session.groups).sort().map((groupKey, ind) => {
                        if (groupKey !== 'group-0') {
                            let minutes = 0;
                            let secondsElapsed = 0;
                            let remainder = 0;
                            let timeelapsed = 0;
                            let time = "";
                            let timeMessage = "";
                            if (session.active_game.groupStates[groupKey].params.finishedOn !== undefined) {
                                timeelapsed = session.active_game.groupStates[groupKey].params.finishedOn - session.active_game.groupStates[groupKey].params.startedOn;
                                secondsElapsed = Math.floor(timeelapsed / 1000);
                                minutes = Math.floor(secondsElapsed / 60);
                                remainder = secondsElapsed % 60;

                                time = minutes > 0 ? `${minutes} minute(s) and ${remainder} seconds` : `${secondsElapsed} seconds`;
                                timeMessage = `Puzzle completed in `;
                            } else {
                                time = "";
                                timeMessage = "Puzzle not completed";
                            }
                            return (
                                <TabPanel value={this.state.tab} index={ind - 1} key={ind - 1} >
                                    <TangramMS session={session} profile={profile} auth={auth} group_id={groupKey} host={determineHost(profile, auth)} role='Engineer' isDebrief={true} />
                                </TabPanel>
                            )
                        } else {
                            return null;
                        }
                    })}
                </Paper>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
        moveBackToResultsPhase: (session, host) => dispatch(moveBackToResultsPhase(session, host)),
        moveBackToActiveWithParams: (session, host, params) => dispatch(moveBackToActiveWithParams(session, host, params)),
        moveBackToInitPhaseWithOption: (session, host, optionName, optionVal) => dispatch(moveBackToInitPhaseWithOption(session, host, optionName, optionVal))
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);