import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';

import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const SeeingThePointLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Finding the Point is a quick (typically 15 minutes) and simple activity to include in a workshop about creative thinking or creative problem-solving. It is a short individual or small group activity (up to 16 people) that explores some of the thinking skills that enable us to break old patterns and establish new ones. It makes an excellent introduction to any training around innovation</li>
                    <li className={classes.properListItem}>This activity looks at how we react when we feel that there are limited resources to complete a task.</li>
                    <li className={classes.properListItem}>In this puzzle participants are given a collection of puzzle pieces, that when properly assembled, will create a total of five arrowheads, all the same size. </li>
                    <li className={classes.properListItem}>One arrowhead is already complete and provides a size template for the remaining arrowheads. Most teams quickly discover that there are only six remaining pieces, which collectively create only three more arrowheads, for a total of four. </li>
                    <li className={classes.properListItem}>The challenge of this puzzle is to use the four arrowheads in the proper configuration to create the outline of a fifth arrowhead, thus completing the task. </li>
                </ul>
                <Typography variant="h5">Note:</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>We do <b>not</b> provide any additional clues to teams as they are working on this so that it is possible some will be successful, and some will not</li>
                    <li className={classes.properListItem}>This will be covered in the debrief</li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Reframing the situation to get out of our own thinking</li>
                    <li className={classes.properListItem}>Innovative approaches to problem solving </li>
                    <li className={classes.properListItem}>Breaking old thinking patterns/establishing new ones</li>
                    <li className={classes.properListItem}>Thinking outside of the box</li>
                    <li className={classes.properListItem}>New ways of seeing a problem</li>
                    <li className={classes.properListItem}>Overcoming our own mindset </li>
                    <li className={classes.properListItem}>Collaborating with team members</li>
                    <li className={classes.properListItem}>Adjusting our thinking to accept change</li>
                    <li className={classes.properListItem}>Managing through with limited resources</li>
                    <li className={classes.properListItem}>The necessity of alignment and synergy of a team</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>In this activity, teams will solve a puzzle</li>
                    <li className={classes.properListItem}>You will need to stretch your thinking style to find the solution. The problem solution may come quickly or may seem insurmountable. . </li>
                    <li className={classes.properListItem}>You will be given a collection of puzzle pieces, that when properly assembled, will create a number of arrowheads, all the same size</li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Instructions</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>There are 7 pieces to create five arrowhead shapes. All arrowheads must be the exact same size and shape. </li>
                    <li className={classes.properListItem}>One arrowhead is completed and provides the size template</li>
                    <li className={classes.properListItem}>You will have 7 puzzle pieces to work with </li>
                    <li className={classes.properListItem}>Once you have used a piece in one arrowhead, it cannot be used to make another arrowhead. </li>  
                </ul>
                <Typography variant="h5">Facilitator Note:</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>If a team feel that they are complete, but do not yet have 5 arrowheads showing, remind the groups that one arrowhead is there which shows the size template, each of the remaining 4 arrowheads will be the same size as this </li>
                    <li className={classes.properListItem}>Remind participants that they have enough pieces to complete the five arrowheads required</li>  
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Team Discussion</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Were you successful in completing 5 arrowheads?</li>
                    <li className={classes.properListItem}>What got in the way of success?</li>   
                    <li className={classes.properListItem}>What helped with success?</li>   
                    <li className={classes.properListItem}>How did your group work together?  </li>   
                    <li className={classes.properListItem}>How did you share ideas to solve the problem?</li>
                    <li className={classes.properListItem}>Understanding how to get things done, especially when limited resources are available, is a valuable life skill. In this case, how was your team able to identify how to accomplish the task?</li> 
                    <li className={classes.properListItem}>How did you leverage the strengths of different members of the group? </li>                            
                </ul> 
                <Typography variant="h5">Group Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Were you able to come up with more than one solution?</li>
                    <li className={classes.properListItem}>How was your team able to identify how to accomplish the task?</li>   
                    <li className={classes.properListItem}>How did you involve everyone in your group?</li>   
                    <li className={classes.properListItem}>Who was the leader in your group? What leadership qualities did they portray that helped the team?</li>   
                    <li className={classes.properListItem}>At any point did you feel there was a lack of resources?</li>
                    <li className={classes.properListItem}>How are the limited resources of this situation like other situations in your life, work, institution, or corporation? Typically, we would all like more “resources” to do our job (time, money, people, etc.)</li>
                    <li className={classes.properListItem}>Did you have a blinding flash of the obvious – a moment between the puzzle presented and the puzzle solved?</li>                           
                </ul>
                <Typography variant="h5">Summary</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>This puzzle illustrates that we can do more with less when we apply creativity and work together</li>
                    <li className={classes.properListItem}>What other projects do you have that feel like you have limited resources? How could you apply creativity and teamwork to find different solutions?</li>   
                    <li className={classes.properListItem}>We each process information differently and therefore some will see the answer to this challenge quickly and some will not</li>   
                    <li className={classes.properListItem}>This does not make it right or wrong, it helps us to see the power of a team – rather than giving up and thinking we can’t do this, how can we leverage the strengths on our team?</li>   
                    <li className={classes.properListItem}>This activity demonstrates synergy – which can be described as 1+1=3</li>
                    <li className={classes.properListItem}>Synergy exists when the output of the team is greater than the results of each individual combined</li>
                    <li className={classes.properListItem}>Meaning that as a team you were able to more efficiently and effectively produce results than if you each were doing this individually</li>
                    <li className={classes.properListItem}>Why is this important in the workplace?</li>
                    <li className={classes.properListItem}>The skills required to solve puzzles are the same skills required in other areas of our life – patience, creativity, problem solving, collaboration, determination, perseverance are all useful skills that are likely to improve our individual lives and our value as a team member</li>                           
                </ul>              
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
           
        </div> 
    )
}

export default withStyles(styles)(SeeingThePointLN);