import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import ViewListIcon from '@material-ui/icons/ViewList';
import TurnDisplay from './turnDisplay';
import TurnTimer from './turnTimer';
import PersonifyActivitySheet from './personifyActivitySheet';
import {ComparisonTable, PointCalculation} from './comparisonTable';
import {
    editResultsPaths, updateGroupStateStateVal
} from './../../../../../store/actions/gameActions'
import { determineHost } from '../../game_functions/functions';
import {
    GROUP_STATE_RESULTS,
} from '../../../../../store/actions/gameActions'




/*
    PROPS:
    session: current session
    results: result section from current session, keyed
    vsKey: the key of the current group

    
*/

const INITIAL_STATE = {
    lines: undefined
}

class TurnsManager extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    updateResult = (val, id) => {
        const vsResult = this.props.results[this.props.vsKey] === undefined ? {} : this.props.results[this.props.vsKey];
       
        const rounds = Object.keys(this.props.session.active_game.resultSnapshot).length;
        const teams = this.props.vsKey.split(':');

        //console.log('rounds: ', rounds, rounds % 2);
        let turn = "";
        if ( rounds === 0 || rounds % 2 === 0 ){
            turn = (vsResult === undefined) ? this.props.vsKey.substring(0, this.props.vsKey.indexOf(':')) : vsResult.turn === undefined ? this.props.vsKey.substring(0, this.props.vsKey.indexOf(':')) : vsResult.turn;
        } else {
           // console.log("ASADASDASDASAS", vsResult);
            turn = (vsResult === undefined) ? teams[1] : vsResult.turn === undefined ? teams[1] : vsResult.turn;
        }
        let newTurn = this.getWhoseTurnNext(turn);

        let paths = [`active_game.results.${this.props.vsKey}.grid.${id}`, `active_game.results.${this.props.vsKey}.turn`];
        let vals = [val, newTurn];

        this.props.editResultsPaths(paths, vals, determineHost(this.props.profile, this.props.auth) );
    }

    getWhoseTurnNext = (curTurn) => {
        let groups = this.props.vsKey.split(':');
        if ( groups[0] === curTurn ){
            return groups[1];
        } else {
            return groups[0];
        }
    }

    updateCurrentSpot = (spot) => {
        let paths = [`active_game.results.${this.props.vsKey}.selected`,];
        let vals = [spot];

        this.props.editResultsPaths(paths, vals, determineHost(this.props.profile, this.props.auth) );
    }

    getOccurrence = (array, value) => {
        return array.filter((v) => (v === value)).length;
    }

    finishActivity = () => {
        let groupID = this.props.group_id

        /* TODO: Update group state, but ensure it doesn't touch the params */
        this.props.updateGroupStateStateVal(
            groupID,
            GROUP_STATE_RESULTS,
            determineHost(this.props.profile, this.props.auth)
        )
    }

    updateGrid = (lines) => {
        console.log('asdfasd', lines);
        let paths = [`active_game.results.${this.props.vsKey}.lines`,];
        let vals = [lines];

        this.props.editResultsPaths(paths, vals, determineHost(this.props.profile, this.props.auth) );
    }

    render() {
        const { classes } = this.props;
        console.log('vsKey', this.props.vsKey);
        console.log('props', this.props);
        const vsResult = this.props.results[this.props.vsKey] === undefined ? {} : this.props.results[this.props.vsKey];
        console.log('vsResult', vsResult);
        const teams = this.props.vsKey.split(':');

        const rounds = Object.keys(this.props.session.active_game.resultSnapshot).length;
        //console.log('rounds: ', rounds, rounds % 2);
        let turn = "";
        if ( rounds === 0 || rounds % 2 === 0 ){
            turn = (vsResult === undefined) ? this.props.vsKey.substring(0, this.props.vsKey.indexOf(':')) : vsResult.turn === undefined ? this.props.vsKey.substring(0, this.props.vsKey.indexOf(':')) : vsResult.turn;
        } else {
            //console.log("ASADASDASDASAS", vsResult);
            turn = (vsResult === undefined) ? teams[1] : vsResult.turn === undefined ? teams[1] : vsResult.turn;
        }


        
       // console.log('turn', turn);
        const lastActionTaken = vsResult === undefined ? this.props.session.active_game.startedOn : vsResult.lastActionTaken === undefined ? this.props.session.active_game.startedOn : vsResult.lastActionTaken;
       // console.log('lastActionTaken', lastActionTaken);
        const currentSpot = vsResult === undefined ? false : vsResult.selected === undefined ? false : vsResult.selected;
        

        const myTurn = turn === this.props.group_id ? true : false;
        
        const isHostOrMod = this.props.profile.role === 'host' || this.props.profile.role === 'moderator' ? true : false;

        const values = vsResult.grid === undefined ? [] : Object.values(vsResult.grid);
        const regPiecesUsed = isHostOrMod ? this.getOccurrence(values, turn) : this.getOccurrence(values, turn);
        const wildPiecesUsed = isHostOrMod ? this.getOccurrence(values, `wild-${turn}`) : this.getOccurrence(values, `wild-${turn}`);

        console.log(this.props.session.groups, this.props.group_id);

        return (
        <div>
            { this.props.debrief === false ? 
                <div>
                    <TurnDisplay session={this.props.session} turn={turn} myTurn={myTurn}/>
                    
                </div>
            : null }

            <ComparisonTable                 
                session={this.props.session} 
                auth={this.props.auth} 
                profile={this.props.profile} 
                vsKey={this.props.vsKey !== undefined ? this.props.vsKey : this.props.session.groups[this.props.group_id].vsKey} 
                group_id={this.props.group_id} 
            />

            <PersonifyActivitySheet 
                debrief={this.props.debrief}
                session={this.props.session} 
                auth={this.props.auth} 
                profile={this.props.profile} 
                turn={turn}
                isHostOrMod={isHostOrMod}
                myTurn={myTurn} 
                results={vsResult.grid === undefined ? {} : vsResult.grid} 
                group_id={this.props.group_id} 
                teams={teams} 
                control={this.props.control}
                currentSpot={currentSpot}
                updateSpot={this.updateCurrentSpot}
                regPiecesUsed={regPiecesUsed}
                wildPiecesUsed={wildPiecesUsed}
                updateResult={this.updateResult}
                finishActivity={this.finishActivity}
                vsKey={this.props.vsKey !== undefined ? this.props.vsKey : this.props.session.groups[this.props.group_id].vsKey} 
            />
        </div>
        );
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    editResultsPaths: (paths, values, hostID) => dispatch(editResultsPaths(paths, values, hostID)),
    updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TurnsManager);

