import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';

const TransitionPeerToBossLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>This is a choose your own adventure type of activity</li>
                    <li className={classes.properListItem}>Participants work through a series of scenarios related to transitioning from being a peer to being a boss</li>
                    <li className={classes.properListItem}>Each scenario offers two options that they choose between</li>
                    <li className={classes.properListItem}>Depending on which decisions the team makes, the outcome will let them know whether they are ready for and know which skills to use when transitioning from being a peer to being a boss</li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Boundaries for peer and boss relationships</li>
                    <li className={classes.properListItem}>Skills that are necessary to use as a boss versus a peer</li>
                    <li className={classes.properListItem}>Solutions to tricky peer/boss situations</li>
                    <li className={classes.properListItem}>Managing through role transitions</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}><b>How many of you have experienced the transition from peer to boss?</b></li>
                    <li className={classes.properListItem}>Let’s look at how to manage this transition effectively </li>
                </ul>    
                <Typography variant="body1"  className={classes.LNProperStart}><b>Peer to Boss – Forbes Mag – background info</b></Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Peers can joke around, gossip, gripe, and poke fun at each other. </li>
                    <li className={classes.properListItem}>But when one of those peers is promoted, these behaviors need to be tempered. </li>
                    <li className={classes.properListItem}>The former peer is now responsible for setting direction, handing out assignments, holding people to deadlines, assessing performance, and determining pay. </li>
                    <li className={classes.properListItem}>Yes, she/he can still be friendly with these subordinates, but only to a point. </li>
                    <li className={classes.properListItem}>Some amount of distance needs to be created so that the new boss can give feedback and make decisions that the former peers might not agree with. </li>
                    <li className={classes.properListItem}>To do this, the new boss needs to re-contract the rules of their relationships with each member of the team; and if anyone cannot accept the new contract, then they will need to go elsewhere.</li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Guidelines</Typography>      
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Each team begins with one scenario</li>
                    <li className={classes.properListItem}>Read the scenario and then read the two options for action</li>
                    <li className={classes.properListItem}>Decide, as a team, which option you will choose</li>
                    <li className={classes.properListItem}>Once you’ve made a choice, you will see the next scenario</li>
                    <li className={classes.properListItem}>After you have moved through the adventure, you will see how well you did with using approaches that are appropriate as a boss versus a peer</li>
                </ul>
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debriefing Topics – options for questions</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What challenges did you recognize in these scenarios?</li>
                    <li className={classes.properListItem}>Why is it important to adjust your behavior when you become a leader?</li>                              
                </ul>
                <Typography variant="h5">Tips for Transitioning from Peer to Boss</Typography> 
                <ol>
                    <li>Mentally promote yourself.  Work on changing your perception of the job.  Let go of your previous teammate role, and welcoming your new leadership role.  Remind yourself that this is a great opportunity for you.  You’ve earned this and you are on your way to success.</li>
                    <li>Talk to your manager to find out what is expected of you in your new role.  Be certain you understand the expectations and what success looks like.</li>   
                    <li>Avoid favoritism and the appearance of this.  Consider what others may see as special treatment (even if this is not the intent).  </li>   
                    <li>Socially and mentally address the former peer-friend issue.  As a leader you will need to make unpopular decisions, hold people accountable, provide performance evaluations and perform discipline.  This is difficult to do if you are gossiping about work.  Focus social conversations on non-work issues so that you are able to also be the leader.</li>   
                    <li>Avoid any immediate big changes.  There is no need to prove yourself by immediately making changes as this can result in a lack of trust and respect.  </li>
                    <li>Clearly define your role with your team and what your expectations of them are. You are the leader, take on your new role</li>
                    <li>Start by getting your team engaged with you as their leader – take time to understand what they each do and get agreement on what you expect of each of them.</li>   
                    <li>Admit when you make mistakes, and move forward, don’t dwell on the past.</li>   
                    <li>Set expectations and reinforce them.  Follow up to ensure these are being met.</li>   
                    <li>Expect early tests of your authority and address them by being fair and firm.  Handle any issues immediately, but with a light touch, humour, or private correction, which will send the right signal without being heavy handed.</li>                           
                </ol>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
           
           
        </div> 
    )
}

export default withStyles(styles)(TransitionPeerToBossLN);