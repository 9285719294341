import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

/* Activity Imports */
import EmotionalIntelligence from './games/emotionalIntelligence';
import WalkAwhileInMyShoes from './games/walkAwhileInMyShoes';
import ExpectationsAndAccountabilities from './games/expectationsAndAccountabilities';
import DiscCards from './games/discCards';
import CircleOfInfluence from './games/circleOfInfluence';
import BBSS from './games/bbss.js';
import Distortion from './games/distortion';
import Aggregate from './games/aggregate';
import Journey from './games/journey';
import Journey2 from './games/journey2';
import Feedback from './games/feedback';
import CircleWords from './games/circleWords';
import ManagingTime from './games/managingTime';
import ManagingTimeSupervisor from './games/managingTimeSupervisor';
import CountyFair from './games/countyFair';
import DiscussionForum from './games/discussionForum';
import DiscussionForumShowcase from './games/discussionForumShowcase';
import TrustSpectrum from './games/trustSpectrum';
import OnDeck from './games/ondeck';
import CommunicationStyles from './games/communicationStyles';
import Personify from './games/personify';
import TrustSpectrumIndividual from './games/trustSpectrumIndividual';
import HiddenAgenda from './games/hiddenAgenda';
import TransitionPeerToBoss from './games/transitionPeerToBoss';
import ConflictScenarios from './games/conflictScenarios';
import DiscConflict from './games/discConflict';
import BestWorstBoss from './games/bestWorstBoss';
import SeeingThePoint from './games/seeingThePoint';
import ShowcaseFeedbackDiscussion from './games/showcaseFeedbackDiscussion';
import TrustBehaviors from './games/trustBehaviors';
import DiscussionForumUniversity from './games/discussionForumUniversity';
import LearningTransfer from './games/learningTransfer';
import EngagingStakeholders from './games/engagingStakeholders';


/* Leader Notes Imports */
import EmotionalIntelligenceLN from './games/emotionalIntelligence/specific_components/leaderNotes';
import WalkAwhileInMyShoesLN from './games/walkAwhileInMyShoes/specific_components/leaderNotes';
import ExpectationsAndAccountabilitiesLN from './games/expectationsAndAccountabilities/specific_components/leaderNotes';
import DiscCardsLN from './games/discCards/specific_components/leaderNotes';
import CircleOfInfluenceLN from './games/circleOfInfluence/specific_components/leaderNotes';
import BBSSLN from './games/bbss/specific_components/leaderNotes';
import DistortionLN from './games/distortion/specific_components/leaderNotes';
import AggregateLN from './games/aggregate/specific_components/leaderNotes';
import JourneyLN from './games/journey/specific_components/leaderNotes';
import Journey2LN from './games/journey2/specific_components/leaderNotes';
import FeedbackLN from './games/feedback/specific_components/leaderNotes';
import CircleWordsLN from './games/circleWords/specific_components/leaderNotes';
import ManagingTimeLN from './games/managingTime/specific_components/leaderNotes';
import ManagingTimeSupervisorLN from './games/managingTimeSupervisor/specific_components/leaderNotes';
import CountyFairLN from './games/countyFair/specific_components/leaderNotes';
import DiscussionForumLN from './games/discussionForum/specific_components/leaderNotes';
import DiscussionForumShowcaseLN from './games/discussionForumShowcase/specific_components/leaderNotes';
import TrustSpectrumLN from './games/trustSpectrum/specific_components/leaderNotes';
import OnDeckLN from './games/ondeck/specific_components/leaderNotes';
import CommunicationStylesLN from './games/communicationStyles/specific_components/leaderNotes';
import PersonifyLN from './games/personify/specific_components/leaderNotes';
import TrustSpectrumIndividualLN from './games/trustSpectrumIndividual/specific_components/leaderNotes';
import HiddenAgendaLN from './games/hiddenAgenda/specific_components/leaderNotes';
import TransitionPeerToBossLN from './games/transitionPeerToBoss/specific_components/leaderNotes';
import ConflictScenariosLN from './games/conflictScenarios/specific_components/leaderNotes';
import DiscConflictLN from './games/discConflict/specific_components/leaderNotes';
import BestWorstBossLN from './games/bestWorstBoss/specific_components/leaderNotes';
import SeeingThePointLN from './games/seeingThePoint/specific_components/leaderNotes';
import ShowcaseFeedbackDiscussionLN from './games/showcaseFeedbackDiscussion/specific_components/leaderNotes';
import TrustBehaviorsLN from './games/trustBehaviors/specific_components/leaderNotes';
import LearningTransferLN from './games/learningTransfer/specific_components/leaderNotes';
import EngagingStakeholdersLN from './games/engagingStakeholders/specific_components/leaderNotes';





import { withStyles } from '@material-ui/core/styles';
import { wordGroups } from './games/circleWords/data/data.js';
import puzzleClue1 from './games/distortion/images/puzzleClue1.png';
import puzzleClue2 from './games/distortion/images/puzzleClue2.png';
import puzzleClue3 from './games/distortion/images/puzzleClue3.png';
import puzzleClue4 from './games/distortion/images/puzzleClue4.png';
import puzzleClue5 from './games/distortion/images/puzzleClue5.png';
import puzzleClue6 from './games/distortion/images/puzzleClue6.png';
import { GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS, GAME_STATE_INIT } from './../../store/actions/gameActions';

const styles = {};




export const GameGroupDefaultStates = (game_id) => {
  switch (game_id) {
    case 'Cd43w7C4VTHMhxaCefY8':
      // Learning Transfer
      return GROUP_STATE_INIT;
    case 'eGS0SyLeRo5kSeDh4Vom':
      // Engaging Stakeholders
      return GROUP_STATE_INIT;
    case 'JP2iV9t6J2KGkX7hDQIb':
      // Discussion Forum University
      return GROUP_STATE_INIT;
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return GROUP_STATE_INIT;
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return GROUP_STATE_INIT;
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      return GROUP_STATE_INIT;
    case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return GROUP_STATE_INIT;
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return GROUP_STATE_ACTIVE;
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return GAME_STATE_INIT;
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return GROUP_STATE_ACTIVE;
    case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return GROUP_STATE_INIT;
    case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
      return GROUP_STATE_INIT;
    case 'FUm6uMZ9QhLkbsnmEbQN':
      // Trust Spectrum Individual
      return GROUP_STATE_ACTIVE;
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return GROUP_STATE_ACTIVE;
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return GROUP_STATE_ACTIVE;
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return GROUP_STATE_INIT;
    case 'i9VYXjMHierYCCFwcDvV':
      // Expectations and Accountabilities
      return GROUP_STATE_INIT;
    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return GROUP_STATE_INIT;
    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return GROUP_STATE_INIT;
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return GROUP_STATE_INIT;
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return GROUP_STATE_INIT;
    case '6MK8K4f5uenoAFBBShto':
      // Possibly Aggregate
      return GROUP_STATE_INIT;
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return GROUP_STATE_INIT;
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return GROUP_STATE_INIT;
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return GROUP_STATE_INIT;
    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return GROUP_STATE_INIT;
    case 'gMuD2xH6Kl37u9WbxYxN':
      // DiSC Conflict
      return GROUP_STATE_INIT;
    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return GROUP_STATE_ACTIVE;
    case 'zjgMvCBlfUo25Xalulti':
      // Discussion Forum
      return GROUP_STATE_INIT;
    case '4njCJXU1wrNjpWRToxvo':
      // Circle Words
      return GROUP_STATE_INIT;
    case '6HkVJoZZBa6JeZYrNKoz':
      // Best/Worst Boss
      return GROUP_STATE_INIT;
    case 'ojQicb2jdFh77nNPn3Tv':
      // Feedback
      return GROUP_STATE_INIT;
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return GROUP_STATE_INIT;
    default:
      return GROUP_STATE_INIT;
  }
}


export const getLeaderNotes = (game_id) => {
  switch (game_id) {
    case 'Cd43w7C4VTHMhxaCefY8':
      // Learning Transfer
      return <LearningTransferLN />;
    case 'eGS0SyLeRo5kSeDh4Vom':
      // Engaging Stakeholders
      return <EngagingStakeholdersLN />;
    case 'JP2iV9t6J2KGkX7hDQIb':
      // Discussion Forum University
      return <DiscussionForumLN />;
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return <TrustBehaviorsLN />;
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return <ShowcaseFeedbackDiscussionLN />;
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      //console.log('bbb', gameOptions(game_id), game_id);
      return <PersonifyLN />;
    case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return <CountyFairLN />;
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return <ManagingTimeSupervisorLN />;
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return <SeeingThePointLN />;
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return <ConflictScenariosLN />;
    case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return <HiddenAgendaLN />;
    case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
      return <OnDeckLN />;
    case '6HkVJoZZBa6JeZYrNKoz':
      // Best/Worst Boss
      return <BestWorstBossLN />;
    case 'FUm6uMZ9QhLkbsnmEbQN':
      // Trust Spectrum Individual
      return <TrustSpectrumIndividualLN />;
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return <TrustSpectrumLN />;
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return <EmotionalIntelligenceLN />;

    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return <BBSSLN />;
    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return <WalkAwhileInMyShoesLN />;

    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return <DiscCardsLN />;
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return <CommunicationStylesLN />;
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return <CircleOfInfluenceLN />;
    case 'i9VYXjMHierYCCFwcDvV':
      // Expectations and Accountabilities
      return <ExpectationsAndAccountabilitiesLN />
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return <JourneyLN />
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return <Journey2LN />
    case '6MK8K4f5uenoAFBBShto':
      // Possibly Aggregate
      return <AggregateLN />
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return <ManagingTimeLN />
    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return <DistortionLN />
    case 'zjgMvCBlfUo25Xalulti':
      // Discussion Forum
      return <DiscussionForumLN />
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return <DiscussionForumShowcaseLN />;
    case 'ojQicb2jdFh77nNPn3Tv':
      // Feedback
      return <FeedbackLN />
    case 'gMuD2xH6Kl37u9WbxYxN':
      // DiSC Conflict
      return <DiscConflictLN />
    case '4njCJXU1wrNjpWRToxvo':
      // Circle Words
      return <CircleWordsLN />
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return <TransitionPeerToBossLN />
    default:
      return null;
  }
}


/*
  ynJQDyus16D9CIjtdyIN : conflict scenarios
  jYz1SgcVNJQ6n4c6JT3j : hidden agenda
  I5vxqN2knisqFS2wJ3Hk : On Deck
*/

export const GameGroupStates = (game_id) => {
  switch (game_id) {
    case 'Cd43w7C4VTHMhxaCefY8':
      // Learning Transfer
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'eGS0SyLeRo5kSeDh4Vom':
      // Engaging Stakeholders
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'JP2iV9t6J2KGkX7hDQIb':
      // Discussion Forum University
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return {
        states: [GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return {
        states: [GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'FUm6uMZ9QhLkbsnmEbQN':
      // Trust Spectrum Individual
      return {
        states: [GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return {
        states: [GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return {
        states: [GROUP_STATE_INIT],
        stateParams: {
          [GROUP_STATE_INIT]: {
            phase: { options: ['board', 'activity'], default: 'board' },
            step: { options: [1, 2, 3, 4, 5], default: 1 }
          }
        }
      };

    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return {
        states: [GROUP_STATE_INIT],
        stateParams: {
          [GROUP_STATE_INIT]: {
            phase: { options: ['board', 'activity'], default: 'board' },
            step: { options: [1, 2, 3, 4, 5, 6, 7, 8], default: 1 }
          }
        }
      };
    case 'gMuD2xH6Kl37u9WbxYxN':
      // DiSC Conflict
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {
          [GROUP_STATE_ACTIVE]: {
            step: { options: [1, 2], default: 1 }
          }
        }
      };
    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {
          [GROUP_STATE_ACTIVE]: {
            step: { options: [1, 2], default: 1 }
          }
        }
      };
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {
          [GROUP_STATE_ACTIVE]: {
            step: { options: [1, 2], default: 1 }
          }
        }
      };
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'i9VYXjMHierYCCFwcDvV':
      // Expectations and Accountabilities
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {
          [GROUP_STATE_ACTIVE]: {
            phase: { options: ['Expectations', 'Accountabilities'], default: 'Expectations' }
          }
        }
      };
    case '6MK8K4f5uenoAFBBShto':
      // Possibly Aggregate
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'zjgMvCBlfUo25Xalulti':
      // Discussion Forum
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'ojQicb2jdFh77nNPn3Tv':
      // Feedback
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case '4njCJXU1wrNjpWRToxvo':
      // Circle Words
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case '6HkVJoZZBa6JeZYrNKoz':
      // Best/Worst Boss
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return {
        states: [GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return {
        states: [GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };

    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
    default:
      return {
        states: [GROUP_STATE_INIT, GROUP_STATE_ACTIVE, GROUP_STATE_RESULTS],
        stateParams: {}
      };
  }
}

export function gameGroupServer(game_id, players) {
  switch (game_id) {
    case 'Cd43w7C4VTHMhxaCefY8':
      // Learning Transfer
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        }
      };
    case 'eGS0SyLeRo5kSeDh4Vom':
      // Engaging Stakeholders
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        }
      };
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        }
      };
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      ////////////////////////////////////////////////////////////////////////////////////////////////
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          vsKey: 'group-1:group-2',
          playerIds: [],
          leadPlayer: '',
        },
        'group-2': {
          title: 'Group 2',
          id: 'group-2',
          vsKey: 'group-1:group-2',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'FUm6uMZ9QhLkbsnmEbQN':
      // Trust Spectrum Individual
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case '4njCJXU1wrNjpWRToxvo':
      // Circle Words
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case '6HkVJoZZBa6JeZYrNKoz':
      // Best/Worst Boss
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
          gameKey: "N/A"
        },
        'group-1': {
          title: 'Worst Leader (1)',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'thinker'
        },
        'group-2': {
          title: 'Best Leader (1)',
          id: 'group-2',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'sensor'
        },
        'group-3': {
          title: 'Worst Leader (2)',
          id: 'group-3',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'feeler'
        },
        'group-4': {
          title: 'Best Leader (2)',
          id: 'group-4',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'intuitor'
        },
      };
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };

    case 'i9VYXjMHierYCCFwcDvV':
      // Expectations and Accountabilities
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };

    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-01': {
          title: 'Group 1',
          id: 'group-01',
          playerIds: [],
          leadPlayer: '',
        },
        'group-02': {
          title: 'Group 2',
          id: 'group-02',
          playerIds: [],
          leadPlayer: '',
        },
        'group-03': {
          title: 'Group 3',
          id: 'group-03',
          playerIds: [],
          leadPlayer: '',
        },
        'group-04': {
          title: 'Group 4',
          id: 'group-04',
          playerIds: [],
          leadPlayer: '',
        },
        'group-05': {
          title: 'Group 5',
          id: 'group-05',
          playerIds: [],
          leadPlayer: '',
        },
        'group-06': {
          title: 'Group 6',
          id: 'group-06',
          playerIds: [],
          leadPlayer: '',
        },
        'group-07': {
          title: 'Group 7',
          id: 'group-07',
          playerIds: [],
          leadPlayer: '',
        },
        'group-08': {
          title: 'Group 8',
          id: 'group-08',
          playerIds: [],
          leadPlayer: '',
        },
        'group-09': {
          title: 'Group 9',
          id: 'group-09',
          playerIds: [],
          leadPlayer: '',
        },
        'group-10': {
          title: 'Group 10',
          id: 'group-10',
          playerIds: [],
          leadPlayer: '',
        },
        'group-11': {
          title: 'Group 11',
          id: 'group-11',
          playerIds: [],
          leadPlayer: '',
        },
      };

    case 'gMuD2xH6Kl37u9WbxYxN':
      // DiSC Conflict
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
          gameKey: "N/A"
        },
        'group-1': {
          title: 'Dominance',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'dominance'
        },
        'group-2': {
          title: 'Influence',
          id: 'group-2',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'influence'
        },
        'group-3': {
          title: 'Steadiness',
          id: 'group-3',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'steadiness'
        },
        'group-4': {
          title: 'Conscientiousness',
          id: 'group-4',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'conscientiousness'
        },
      };
    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
          gameKey: "N/A"
        },
        'group-1': {
          title: 'Dominance',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'dominance'
        },
        'group-2': {
          title: 'Influence',
          id: 'group-2',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'influence'
        },
        'group-3': {
          title: 'Steadiness',
          id: 'group-3',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'steadiness'
        },
        'group-4': {
          title: 'Conscientiousness',
          id: 'group-4',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'conscientiousness'
        },
      };
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
          gameKey: "N/A"
        },
        'group-1': {
          title: 'Thinker',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'thinker'
        },
        'group-2': {
          title: 'Sensor',
          id: 'group-2',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'sensor'
        },
        'group-3': {
          title: 'Feeler',
          id: 'group-3',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'feeler'
        },
        'group-4': {
          title: 'Intuitor',
          id: 'group-4',
          playerIds: [],
          leadPlayer: '',
          gameKey: 'intuitor'
        },
      };
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
          gameKey: "N/A"
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
        'group-2': {
          title: 'Group 2',
          id: 'group-2',
          playerIds: [],
          leadPlayer: '',
        },
        'group-3': {
          title: 'Group 3',
          id: 'group-3',
          playerIds: [],
          leadPlayer: '',
        },
        'group-4': {
          title: 'Group 4',
          id: 'group-4',
          playerIds: [],
          leadPlayer: '',
        }
      };
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case '6MK8K4f5uenoAFBBShto':
      // Possibly Aggregate
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'JP2iV9t6J2KGkX7hDQIb':
      // Discussion Forum University
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'zjgMvCBlfUo25Xalulti':
      // Discussion Forum
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'ojQicb2jdFh77nNPn3Tv':
      // Feedback
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
    default:
      return {
        'group-0': {
          title: 'Users not in groups',
          id: 'group-0',
          playerIds: players,
          leadPlayer: '',
        },
        'group-1': {
          title: 'Group 1',
          id: 'group-1',
          playerIds: [],
          leadPlayer: '',
        },
      };
  }
}

export function gameGroupStateServer(game_id) {
  switch (game_id) {
    case 'Cd43w7C4VTHMhxaCefY8':
      // Learning Transfer
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'eGS0SyLeRo5kSeDh4Vom':
      // Engaging Stakeholders
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
        'group-2': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return {
        'group-1': { state: GROUP_STATE_ACTIVE, params: {} },
      }
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'FUm6uMZ9QhLkbsnmEbQN':
      // Trust Spectrum Individual
      return {
        'group-0': { state: GROUP_STATE_ACTIVE, params: {} },
        'group-1': { state: GROUP_STATE_ACTIVE, params: {} },
      }
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return {
        'group-1': { state: GROUP_STATE_ACTIVE, params: {} },
      }
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'i9VYXjMHierYCCFwcDvV':
      // Expectations and Accountabilities
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return {
        'group-01': { state: GROUP_STATE_INIT, params: {} },
        'group-02': { state: GROUP_STATE_INIT, params: {} },
        'group-03': { state: GROUP_STATE_INIT, params: {} },
        'group-04': { state: GROUP_STATE_INIT, params: {} },
        'group-05': { state: GROUP_STATE_INIT, params: {} },
        'group-06': { state: GROUP_STATE_INIT, params: {} },
        'group-07': { state: GROUP_STATE_INIT, params: {} },
        'group-08': { state: GROUP_STATE_INIT, params: {} },
        'group-09': { state: GROUP_STATE_INIT, params: {} },
        'group-10': { state: GROUP_STATE_INIT, params: {} },
        'group-11': { state: GROUP_STATE_INIT, params: {} },
      };

    case 'gMuD2xH6Kl37u9WbxYxN':
      // DiSC Conflict
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
        'group-2': { state: GROUP_STATE_INIT, params: {} },
        'group-3': { state: GROUP_STATE_INIT, params: {} },
        'group-4': { state: GROUP_STATE_INIT, params: {} },
      };
    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
        'group-2': { state: GROUP_STATE_INIT, params: {} },
        'group-3': { state: GROUP_STATE_INIT, params: {} },
        'group-4': { state: GROUP_STATE_INIT, params: {} },
      };
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
        'group-2': { state: GROUP_STATE_INIT, params: {} },
        'group-3': { state: GROUP_STATE_INIT, params: {} },
        'group-4': { state: GROUP_STATE_INIT, params: {} },
      };
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
        'group-2': { state: GROUP_STATE_INIT, params: {} },
        'group-3': { state: GROUP_STATE_INIT, params: {} },
        'group-4': { state: GROUP_STATE_INIT, params: {} },
      };
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case '6MK8K4f5uenoAFBBShto':
      // Possibly Aggregate
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'JP2iV9t6J2KGkX7hDQIb':
      // Discussion Forum University
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'zjgMvCBlfUo25Xalulti':
      // Discussion Forum
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'ojQicb2jdFh77nNPn3Tv':
      // Feedback
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return {
        'group-1': { state: GROUP_STATE_ACTIVE, params: {} },
      }
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return {
        'group-1': { state: GROUP_STATE_ACTIVE, params: {} },
      }
    case '4njCJXU1wrNjpWRToxvo':
      // Circle Words
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    case '6HkVJoZZBa6JeZYrNKoz':
      // Best/Worst Boss
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
        'group-2': { state: GROUP_STATE_INIT, params: {} },
        'group-3': { state: GROUP_STATE_INIT, params: {} },
        'group-4': { state: GROUP_STATE_INIT, params: {} },
      };
    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
    default:
      return {
        'group-1': { state: GROUP_STATE_INIT, params: {} },
      }
  }
}

export function gameHasOptions(game_id) {
  switch (game_id) {
    case 'Cd43w7C4VTHMhxaCefY8':
      // Learning Transfer
      return false;
    case 'eGS0SyLeRo5kSeDh4Vom':
      // Engaging Stakeholders
      return false;
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return false;
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return false;
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      return false;
    case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return false;
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return false;
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return false;
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return false;
    case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return false;
    case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
      return false;
    case 'FUm6uMZ9QhLkbsnmEbQN':
      // Trust Spectrum Individual
      return false;
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return false;
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return false;
    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return false;
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return false;
    case 'i9VYXjMHierYCCFwcDvV':
      // Expectations and Accountabilities
      return false;
    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return false;
    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return false;
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return false;
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return false;
    case '6MK8K4f5uenoAFBBShto':
      // Possibly Aggregate
      return false;
    case 'JP2iV9t6J2KGkX7hDQIb':
      // Discussion Forum University
      return false;
    case 'zjgMvCBlfUo25Xalulti':
      // Discussion Forum
      return false;
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return false;
    case 'ojQicb2jdFh77nNPn3Tv':
      // Feedback
      return false;
    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return false;
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return false;
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return false;
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return false;
    case 'gMuD2xH6Kl37u9WbxYxN':
      // DiSC Conflict
      return false;
    case '4njCJXU1wrNjpWRToxvo':
      // Circle Words
      return false;
    case '6HkVJoZZBa6JeZYrNKoz':
      // Best/Worst Boss
      return false;
    default:
      return false;
  }

}

export function gameOptions(game_id) {
  switch (game_id) {
    case 'Cd43w7C4VTHMhxaCefY8':
      // Learning Transfer
      return false;
    case 'eGS0SyLeRo5kSeDh4Vom':
      // Engaging Stakeholders
      return false;
    case 'zj8FYS7xS9bAqZRKsxDu':
      // Trust Behaviors
      return false;
    case 'uTeZJmY1zc6PDDvMC1Xk':
      // Showcase Discussion Forum
      return {
        select__Same_questions_for_each_team: {
          options: [
            { id: false, title: 'No' },
            { id: true, title: 'Yes' }
          ],
          type: 'select',
          description:
            'If yes, all groups will see all questions. If no, then groups will be assigned questions in order.'
        }
      }
    case 'f3Ec5g6amsoAaFr34U9J':
      // Personify
      return {
        'Teams': true,
        'Versus': true,
      };
    case 'hKfvgwPfyOg1DKZ5WrFf':
      // County Fair
      return {
        'select__everyone_has_all_clues': {
          'options': [
            { id: true, title: 'True' },
            { id: false, title: 'False' }
          ],
          'type': 'select',
          'description': 'Should all users be able to see all clues?'
        }
      };
    case 'BfxbeGXDrjAgfP9Y0ljZ':
      // Managing Time - Supervisors
      return false;
    case 'Rh4R3HbDo5J2WetudJrn':
      // Seeing the Point
      return false;
    case 'ynJQDyus16D9CIjtdyIN':
      // Conflict Scenarios
      return false;
    case 'jYz1SgcVNJQ6n4c6JT3j':
      // Hidden Agenda
      return false;
    case 'I5vxqN2knisqFS2wJ3Hk':
      // On Deck
      return {
        'Sets': {
          'options': [
            { id: 1, title: "Aces, Kings, Queens, and Jacks" },
            { id: 2, title: "Twos, Threes, Fours, and Fives" },
            { id: 3, title: "Sixes, Sevens, Eights, and Nines" },
          ],
          'type': 'select',
          'description': 'Allows for selection of which puzzle will be used to start the activity'
        }
      };
    case 'FUm6uMZ9QhLkbsnmEbQN':
      // Trust Spectrum Individual
      return false;
    case 'oAOQt7YfZzDTa2WiQ7ZR':
      // Trust Spectrum
      return false;
    case 'VzVPeJxDCqc2K5Juo14e':
      // Distortion
      return {
        'Puzzle': {
          'options': [
            { id: 1, img: puzzleClue1, title: "Cat" },
            { id: 2, img: puzzleClue2, title: "Dancer" },
            { id: 3, img: puzzleClue3, title: "Horse" },
            { id: 4, img: puzzleClue4, title: "Bird" },
            { id: 5, img: puzzleClue5, title: "Sitting Person" },
            { id: 6, img: puzzleClue6, title: "Bunny" },
          ],
          'type': 'select',
          'description': 'Allows for selection of which puzzle will be used to start the activity.'
        }
      };
    case 'vcZv6f1cOktHSds2kdme':
      // Emotional Intelligence
      return false;
    case 'd0UzzPqrpkeiqdmONmYD':
      // Transition Peer to Boss
      return false;
    case 'i9VYXjMHierYCCFwcDvV':
      // Expectations and Accountabilities
      return false;
    case 'YCSJs4yYGYTK5N6sLjZG':
      // Walk a While in my Shoes
      return false;
    case 'VoJYJGwzxiUQap9ZJZEu':
      // DISC Cards
      return false;
    case 'PiFZGYehVialCu1eRDvt':
      // Communication Styles
      return false;
    case '3f19Vfx31DCuCRC9W3Fw':
      // Circle of Influence
      return false;
    case '6MK8K4f5uenoAFBBShto':
      // Possibly Aggregate
      return false;
    case 'JP2iV9t6J2KGkX7hDQIb':
      // Discussion Forum University
      return false;
    case 'zjgMvCBlfUo25Xalulti':
      // Discussion Forum
      return false;
    case 'bJhzgqZsI9lzXZFsMHdi':
      // Discussion Forum (showcase)
      return false;
    case 'QFbnz1m3ydc20VbuGVHP':
      // Bring, Brag, Show, Steal
      return false;
    case 'ojQicb2jdFh77nNPn3Tv':
      // Feedback
      return false;
    case '7xgKmmwM9RlvOmYtY9Qc':
      // Tell Us About The Journey
      return false;
    case 'XHfImKbo8S2UDPl2cerz':
      // Tell Us About The Journey - Mod 3&4
      return false;
    case 'CFDb34wvDq0sC5BWfMPL':
      // Managing Time
      return false;
    case 'gMuD2xH6Kl37u9WbxYxN':
      // DiSC Conflict
      return false;
    case '4njCJXU1wrNjpWRToxvo':
      // Circle Words
      return wordGroups;
    case '6HkVJoZZBa6JeZYrNKoz':
    // Best/Worst Boss
    default:
      return false;
  }

}

class GameServer extends Component {
  render() {
    const {
      game_id,
      game_title,
      session,
      profile,
      auth,
      players,
      game_options,
      //group_state, game_state,
      view,
    } = this.props;


    switch (game_id) {
      case 'Cd43w7C4VTHMhxaCefY8':
        // Learning Transfer
        return <LearningTransfer gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;;
      case 'eGS0SyLeRo5kSeDh4Vom':
        // Engaging Stakeholders
        return <EngagingStakeholders gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;;
      case 'zj8FYS7xS9bAqZRKsxDu':
        // Trust Behaviors
        return <TrustBehaviors gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;;
      case 'uTeZJmY1zc6PDDvMC1Xk':
        // Showcase Discussion Forum
        return <ShowcaseFeedbackDiscussion gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'f3Ec5g6amsoAaFr34U9J':
        // Personify
        //console.log('bbb', gameOptions(game_id), game_id);
        return <Personify gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;;
      case 'hKfvgwPfyOg1DKZ5WrFf':
        // County Fair
        return <CountyFair gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'BfxbeGXDrjAgfP9Y0ljZ':
        // Managing Time - Supervisors
        return <ManagingTimeSupervisor gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'Rh4R3HbDo5J2WetudJrn':
        // Seeing the Point
        return <SeeingThePoint gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'ynJQDyus16D9CIjtdyIN':
        // Conflict Scenarios
        return <ConflictScenarios gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'jYz1SgcVNJQ6n4c6JT3j':
        // Hidden Agenda
        return <HiddenAgenda gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'I5vxqN2knisqFS2wJ3Hk':
        // On Deck
        return <OnDeck gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case '6HkVJoZZBa6JeZYrNKoz':
        // Best/Worst Boss
        return <BestWorstBoss gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'FUm6uMZ9QhLkbsnmEbQN':
        // Trust Spectrum Individual
        return <TrustSpectrumIndividual gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'oAOQt7YfZzDTa2WiQ7ZR':
        // Trust Spectrum
        return <TrustSpectrum gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'vcZv6f1cOktHSds2kdme':
        // Emotional Intelligence
        return <EmotionalIntelligence gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;

      case 'QFbnz1m3ydc20VbuGVHP':
        // Bring, Brag, Show, Steal
        return <BBSS gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'YCSJs4yYGYTK5N6sLjZG':
        // Walk a While in my Shoes
        return <WalkAwhileInMyShoes gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'JP2iV9t6J2KGkX7hDQIb':
        // Discussion Forum University
        return <DiscussionForumUniversity gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'VoJYJGwzxiUQap9ZJZEu':
        // DISC Cards
        return <DiscCards gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} players={players} />;
      case 'PiFZGYehVialCu1eRDvt':
        // Communication Styles
        return <CommunicationStyles gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} players={players} />;
      case '3f19Vfx31DCuCRC9W3Fw':
        // Circle of Influence
        return <CircleOfInfluence gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'i9VYXjMHierYCCFwcDvV':
        // Expectations and Accountabilities
        return <ExpectationsAndAccountabilities gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case '7xgKmmwM9RlvOmYtY9Qc':
        // Tell Us About The Journey
        return <Journey gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'XHfImKbo8S2UDPl2cerz':
        // Tell Us About The Journey - Mod 3&4
        return <Journey2 gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case '6MK8K4f5uenoAFBBShto':
        // Possibly Aggregate
        return <Aggregate gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'CFDb34wvDq0sC5BWfMPL':
        // Managing Time
        return <ManagingTime gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'VzVPeJxDCqc2K5Juo14e':
        // Distortion
        return <Distortion gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'zjgMvCBlfUo25Xalulti':
        // Discussion Forum
        return <DiscussionForum gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'bJhzgqZsI9lzXZFsMHdi':
        // Discussion Forum (showcase)
        return <DiscussionForumShowcase gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />;
      case 'ojQicb2jdFh77nNPn3Tv':
        // Feedback
        return <Feedback gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'gMuD2xH6Kl37u9WbxYxN':
        // DiSC Conflict
        return <DiscConflict gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case '4njCJXU1wrNjpWRToxvo':
        // Circle Words
        return <CircleWords gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      case 'd0UzzPqrpkeiqdmONmYD':
        // Transition Peer to Boss
        return <TransitionPeerToBoss gameHasOptions={this.props.gameHasOptions} inlineGameOptions={this.props.inlineGameOptions} game_options={game_options} game_title={game_title} session={session} profile={profile} auth={auth} view={view} />
      default:
        return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    players: state.firestore.data.players,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameServer);
