import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import styles from './../../../../Styles'
import Grid from '@material-ui/core/Grid'
import image1 from '../images/Picture1.png'
import image2 from '../images/Picture2.png'
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Notes from './../images/leader_notes.pdf'
import Notes_Detailed from './../images/leader_notes_detailed.pdf'
import Presentation from './../images/presentation.pptx'
import GetAppIcon from '@material-ui/icons/GetApp'
import Clues from './../images/facilitator_clues.pdf'

const CountyFairLN = (props) => {
    const { classes } = props
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab
                    style={{ paddingTop: '20px' }}
                    className={classes.tabsLNTab}
                    label="Activity Explanation"
                    {...a11yProps(0)}
                />
                <Tab
                    className={classes.tabsLNTab}
                    label="Key Learnings"
                    {...a11yProps(1)}
                />
                <Tab
                    className={classes.tabsLNTab}
                    label="Introduction"
                    {...a11yProps(2)}
                />
                <Tab
                    className={classes.tabsLNTab}
                    label="Instructions and Rules"
                    {...a11yProps(3)}
                />
                <Tab
                    className={classes.tabsLNTab}
                    label="Debrief"
                    {...a11yProps(4)}
                />
                <Tab
                    className={classes.tabsLNTab}
                    label="Download Resources"
                    {...a11yProps(5)}
                />
            </Tabs>
            <TabPanel className={classes.LNTabPanel} value={value} index={0}>
                <Typography variant="h5">
                    Facilitator Information on the Activity
                </Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>
                        A problem-solving activity for groups to review and find
                        the answers.
                    </li>
                    <li className={classes.properListItem}>
                        The mystery presented here is in the form of a logic
                        puzzle. Participants discuss the clue cards to solve the
                        mystery. The solution can be found using a Sudoku-like
                        grid with some combination of the three known factors:
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>
                                places 1 through 6,{' '}
                            </li>
                            <li className={classes.properListItem}>
                                the names of the bakers and{' '}
                            </li>
                            <li className={classes.properListItem}>
                                the six flavors of pie.{' '}
                            </li>
                            <li>
                                <img src={image1} alt="not filled"></img>
                                <img src={image2} alt="filled in"></img>
                            </li>
                        </ul>
                    </li>
                    <li className={classes.properListItem}>
                        One example is shown in the photo below, featuring the
                        six flavors of pie on the horizontal axis and the five
                        pie makers on the vertical axis. The potential places
                        are then listed from the information provided (apple=1st
                        prize, peach = 2nd prize, cherry = 6th prize, blueberry
                        = 5th prize, leaving only 3rd and 4th prize. Chocolate
                        placed one place higher than pecan, so chocolate = 3rd
                        prize and pecan = 4th prize).{' '}
                    </li>
                    <li className={classes.properListItem}>
                        Next, we need to eliminate some of the possibilities
                        shown on the grid. Andy made the chocolate or pecan pie,
                        eliminating all other fruit pie flavors for Andy (so we
                        place X’s there). Sarah did not receive 1st or 6th
                        prizes, so cross those off for her. Betty placed lower
                        than Andy (so we can cross of 1st, 2nd and 3rd place for
                        her) and higher than Dave (so we can cross off 6th place
                        as well). Betty placed lower than Andy (who either took
                        3rd or 4th prize), meaning Betty took 4th or 5th prize,
                        and higher than Dave (who either took 5th or 6th prize).{' '}
                    </li>
                    <li className={classes.properListItem}>
                        We know that the person taking 5th prize also takes 6th
                        prize, so Dave is the double pie baker, winning 5th and
                        6th prizes. Ellen is the only pie maker left in the
                        first column and wins 1st prize for her Apple pie. Betty
                        wins 4th prize for her Pecan pie and Andy is one place
                        above her, in 3rd place for his Chocolate pie. Leaving
                        the final pie maker Sarah in 2nd place with her Peach
                        pie.{' '}
                    </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>
                        Overcoming our mindset when we feel we have limited
                        resources to solve a problem
                    </li>
                    <li className={classes.properListItem}>
                        Leveraging the skills and insights of the team
                    </li>
                    <li className={classes.properListItem}>
                        Collaborating on challenges to develop solutions
                    </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>
                    Problem solving in teams can be challenging, but if we
                    leverage the skills of the team, we can find solutions that
                    might otherwise be missed.{' '}
                </Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={3}>
                <Typography variant="h5">Objective</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>
                    Who baked which pie? What prize did each pie win?{' '}
                </Typography>

                <Typography variant="h5">Instructions</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>
                        Using the 13 clues provided, together solve the mystery
                        of who baked each pie, and which prize each pie won
                    </li>
                    <li className={classes.properListItem}>
                        Each team member has different clues to share with the
                        team
                    </li>
                    <li className={classes.properListItem}>
                        With the clues, use the chart on the next screen to
                        identify the correct information
                    </li>
                    <li className={classes.properListItem}>
                        Eliminate possibilities by leaving an X in the grid
                    </li>
                    <li className={classes.properListItem}>
                        Determine how you will take notes to solve the mystery
                    </li>
                    <li className={classes.properListItem}>
                        Your group must work together to solve the mystery
                    </li>
                </ul>
                <Typography variant="h5">The County Fair Clues</Typography>
                <ol className={classes.properOrderedList}>
                    <li className={classes.properOrderedListItem}>
                        There were six entries in the pie contest at the county
                        fair.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        Judges awarded prizes for first, second, third, fourth,
                        fifth and sixth place.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        The contest judges awarded first prize to the apple pie.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        The peach pie won second place.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        The cherry pie was so sour the judges did not place it
                        in the top five places.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        Betty’s pie placed lower than Andy’s pie and higher than
                        Dave’s pie.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        Ellen’s pie was one of the best she ever made.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        Sarah substituted brown sugar for regular sugar in her
                        pie.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        Sarah did not receive either first or sixth prize.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        Andy has never entered a fruit pie in the county fair.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        The chocolate pie placed one place higher than the pecan
                        pie.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        One person won two prizes, for fifth and sixth places
                        and one of these pies was Blueberry.{' '}
                    </li>
                    <li className={classes.properOrderedListItem}>
                        Dave likes rhyming words.{' '}
                    </li>
                </ol>
                <Typography variant="body1" className={classes.LNProperStart}>
                    Obtaining the correct answer for this mystery in thirteen
                    parts is the ultimate goal of the activity, but along the
                    way, there are opportunities for additional teachable
                    moments in group dynamics.{' '}
                </Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={4}>
                <Typography variant="h5">Answers</Typography>
                <Grid container spacing={2}>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Ellen
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Apple{' '}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            1
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Sarah
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Peach{' '}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            2
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Andy
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Chocolate{' '}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            3
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Betty
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Pecan{' '}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            4
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Dave
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Blueberry{' '}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            5
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Dave
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            Cherry{' '}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        >
                            6
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Typography
                            variant="body1"
                            className={classes.LNProperStart}
                        ></Typography>
                    </Grid>
                </Grid>

                <Typography variant="h5">Group Debrief</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>
                    After completing the task, revisit the activity and discuss
                    with the members of the group the following questions:{' '}
                </Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>
                        Did everyone have the opportunity to contribute to the
                        success of the group?{' '}
                    </li>
                    <li className={classes.properListItem}>
                        Was each person in the group heard by the other members
                        of the group?{' '}
                    </li>
                    <li className={classes.properListItem}>
                        Did a leader emerge during the completion of the task?{' '}
                    </li>
                    <li className={classes.properListItem}>
                        What skills were required to complete the task?{' '}
                    </li>
                    <li className={classes.properListItem}>
                        Was there a significant ‘break through’ moment when the
                        solution became obvious?{' '}
                    </li>
                    <li className={classes.properListItem}>
                        If you were to repeat a similar task, what would you do
                        differently the next time?{' '}
                    </li>
                    <li className={classes.properListItem}>
                        What advice would you give to another group working on a
                        similar task?{' '}
                    </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
                <Typography variant="h5">Download Resources</Typography>
                <a href={Notes} download="leader_notes.pdf">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={`${classes.LNProperStart}`}
                        startIcon={<GetAppIcon />}
                        style={{ marginRight: 5 }}
                    >
                        Download Notes
                    </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={`${classes.LNProperStart}`}
                        startIcon={<GetAppIcon />}
                        style={{ marginRight: 5 }}
                    >
                        Download Detailed Notes
                    </Button>
                </a>
                <a href={Presentation} download="presentation.pptx">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={`${classes.LNProperStart}`}
                        startIcon={<GetAppIcon />}
                    >
                        Download Presentation
                    </Button>
                </a>
                <a href={Clues} download="facilitator_clues.pdf">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={`${classes.LNProperStart}`}
                        startIcon={<GetAppIcon />}
                    >
                        Download Facilitator Clues
                    </Button>
                </a>
            </TabPanel>
        </div>
    )
}

export default withStyles(styles)(CountyFairLN)
