import { Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { purple, grey } from '@material-ui/core/colors';
import Footer from '../Footer';
import GoogleLogo from '../../images/icons/GoogleLogo';
//import FacebookIcon from '@material-ui/icons/Facebook';
import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';
import { createEmailUser, signUpWithGoogle, closeAuthPopUps } from '../../store/actions/authActions';
import headerImage from '../../images/1x/header-image-desktop.jpg';
import ImageHeader from '../Header/imageHeader';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  logo: {
    marginTop: 30,
    width: 155,
    '@media (max-width: 1025px)': {
      width: 100,
      marginTop: 26,
    },
  },
  button: {
    width: '100%',
    textAlign: 'left',
  },
  textField: {
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  purpleLine: {
    background: 'linear-gradient(90deg, rgba(67,22,63,1) 0%, rgba(155,62,150,1) 100%)',
    display: 'block',
    height: 10,
  },
  headerImage: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerBlock: {
    backgroundImage: `url(${headerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 400,
    display: 'block',
  },
  header: {
    height: 400,
  },
  soltaLogo: {
    width: 250,
    marginRight: 50,
    marginTop: 50,
  },
  purpleGradient: {
    //background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const INITIAL_STATE = {
  //loading: false,
  email: false,
  firstName: '',
  lastName: '',
  emailAddress: '',
  password: '',
  reTypePassword: '',
  regPopUp: false,
  regMessage: null,
};

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {}

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  addEmailUser = () => {
    if (this.state.password === this.state.reTypePassword) {
      this.props.createEmailUser(this.state);
    } else {
      this.setState({
        regPopUp: true,
        regMessage: this.props.t('signUp.passwordsDontMatch'),
      });
    }
  };

  addFacebookUser = () => {
    this.props.signUpWithFacebook();
  };

  addGoogleUser = () => {
    this.props.signUpWithGoogle();
  };

  startEmail = () => {
    this.setState({
      email: true,
    });
  };

  closeAuthPopUps = () => {
    this.props.closeAuthPopUps();
  };

  closeRegPopUps = () => {
    this.setState({
      regPopUp: false,
      regMessage: null,
    });
  };

  render() {
    const { email, firstName, lastName, emailAddress, password, reTypePassword, regPopUp, regMessage } = this.state;
    const { auth, t, classes, authError, authMessage, authErrorPopUp, authPopUp } = this.props;

    if (this.props.match.params.id) {
    } else {
      if (auth.uid) return <Redirect to={ROUTES.LANDING} />;
    }

    const formFiledOut = firstName.length > 0 && lastName.length > 0 && emailAddress.length > 0 && password.length > 8 && reTypePassword.length > 8;
    return (
      <div className='meta-react'>
        <ImageHeader />
        <div className='outer-container'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item lg={12}></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h5' align='center'>
                        {t('signUp.instructions')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={3} alignItems='center' justify='center'>
                        <Grid item sm={1} xs={2}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </Grid>
                    {email ? (
                      <Grid item xs={12}>
                        <Grid container spacing={3} justify='center'>
                          <Grid item sm={4}></Grid>
                          <Grid item sm={4} xs={12} align='center'>
                            <form>
                              <Grid container spacing={3} alignItems='center' justify='center'>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='firstName' name='firstName' value={firstName} label='First Name' />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='lastName' name='lastName' value={lastName} label='Last Name' />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='emailAddress' name='emailAddress' autoComplete='username' value={emailAddress} label='Email' />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='password' name='password' type='password' helperText={t('signUp.passwordRestrictions')} autoComplete='new-password' value={password} label='Password' />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} id='reTypePassword' onChange={this.onChange} name='reTypePassword' autoComplete='new-password' type='password' value={reTypePassword} label='Re-Type Password' />
                                </Grid>
                                <Grid item xs={12}>
                                  <Paper elevation={0} className={classes.alignCenter}>
                                    <ThermageButton size='large' disabled={!formFiledOut} onClick={this.addEmailUser}>
                                      {t('signUp.button')}
                                    </ThermageButton>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </form>
                          </Grid>
                          <Grid item xs={4}></Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Grid container spacing={3} justify='center'>
                          <Grid item md={4} xs={12}>
                            <Grid container spacing={3} justify='center'>
                              <Grid item xs={12}>
                                <Button variant='contained' size='large' className={classes.button} onClick={this.startEmail} startIcon={<EmailIcon />}>
                                  {t('signUp.signUpEmail')}
                                </Button>
                              </Grid>
                              {/*
                            <Grid item xs={12}>
                              <Button variant='contained' size='large' className={classes.button} onClick={this.addFacebookUser} startIcon={<FacebookIcon />} disabled>
                                {t('signUp.signUpFacebook')}
                              </Button>
                            </Grid>
                            */}
                              <Grid item xs={12}>
                                <Button variant='contained' size='large' className={classes.button} onClick={this.addGoogleUser} startIcon={<GoogleLogo />}>
                                  {t('signUp.signUpGoogle')}
                                </Button>
                              </Grid>
                              <Grid item xs={12}></Grid>
                              <Grid item xs={12} align='center'>
                                <Typography variant='h6'>{t('signUp.alreadySignedUp')}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Divider />
                              </Grid>
                              <Grid item xs={12} align='center'>
                                <Button variant='contained' size='large' className={classes.button} component={Link} to={ROUTES.LOGIN}>
                                  {t('signUp.login')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </div>
                <Snackbar open={authPopUp} autoHideDuration={6000} onClose={this.closeAuthPopUps}>
                  <Alert onClose={this.closeAuthPopUps} severity='success'>
                    {authMessage}
                  </Alert>
                </Snackbar>
                <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={this.closeAuthPopUps}>
                  <Alert onClose={this.closeRegPopUps} severity='error'>
                    {authError}
                  </Alert>
                </Snackbar>
                <Snackbar open={regPopUp} autoHideDuration={6000} onClose={this.closeRegPopUps}>
                  <Alert onClose={this.closeRegPopUps} severity='error'>
                    {regMessage}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const ThermageButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: grey[500],
    },
  },
}))(Button);

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.gol_auth.authError,
    authMessage: state.gol_auth.authMessage,
    authPopUp: state.gol_auth.authPopUp,
    authErrorPopUp: state.gol_auth.authErrorPopUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createEmailUser: (newUser) => dispatch(createEmailUser(newUser)),
    signUpWithGoogle: () => dispatch(signUpWithGoogle()),
    closeAuthPopUps: () => dispatch(closeAuthPopUps()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(SignUp);
