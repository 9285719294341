import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';

import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';


const ConflictScenariosLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>The impact of conflict activity helps participants see how conflict has a ripple effect throughout an organization.</li>
                    <li className={classes.properListItem}>Participants will work in small groups to look at several conflict scenarios to talk about the impact these conflicts would have and discuss ways of addressing the conflict.  </li>
                </ul>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Impact of conflict – on you the leader, the team, and the organization</li>
                    <li className={classes.properListItem}>Strategies to resolve conflict and lessen the impact</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>    
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Conflict impacts more than just those directly involved in the situation.</li>
                    <li className={classes.properListItem}>It’s important to be aware of how conflict with a team member affects you, your team, and your organization</li>
                    <li className={classes.properListItem}>In conflict situations, think about what the effect is …
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>On the team member?</li>
                            <li className={classes.properListItem}>On you?</li>
                            <li className={classes.properListItem}>On the team?</li>
                            <li className={classes.properListItem}>On the organization?</li>
                        </ul>
                    </li>
                </ul>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Instructions</Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>You will have 5 conflict scenarios to look at in your small group</li>
                    <li className={classes.properListItem}>For each conflict scenario, discuss the following; 
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>What is the situation? </li>
                            <li className={classes.properListItem}>What is the impact? </li>
                            <li className={classes.properListItem}>How would you resolve this? </li>
                        </ul>
                    </li>
                    <li className={classes.properListItem}>Once you’ve completed all 5 scenarios, answer the following questions as a group;  
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>What are your key learnings about the impact of conflict from this activity? </li>
                            <li className={classes.properListItem}>What best practices can you use to reduce the impact of conflict? </li>
                        </ul>
                    </li>
                </ul>
                <Typography variant="h5">Impact of Conflict Scenarios </Typography>     
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Samantha never picks up after herself – she leaves housekeeping and shadow boards untidy; she just refuses to do this. </li>
                    <li className={classes.properListItem}>Matthew is storming around his workstation slamming boxes and throwing broken drywall. </li>
                    <li className={classes.properListItem}>Luke tends to avoid conflict and puts off talking about an issues that come up at work, hoping this will blow over. However instead the problems on the team are getting more intense and people are picking sides.</li>
                    <li className={classes.properListItem}>Andy has a great sense of humor, though he is often sarcastic in his remarks, which seems to put others on edge. </li>
                    <li className={classes.properListItem}>Joe openly dislikes Bruce.  They have some disagreement that doesn’t involve work but has carried over to work.  This has created tension whenever they work together. </li>
                </ul> 
                                       
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Team Discussion</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What are your key learnings about the impact of conflict from this activity? </li>
                    <li className={classes.properListItem}>What best practices can you use to reduce the impact of conflict? </li>                              
                </ul>
                <Typography variant="h5">Group Debrief</Typography> 
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>What did you notice about the impact that these scenarios had? </li>
                    <li className={classes.properListItem}>What steps can we take to lessen the impact of conflict in our work environments? </li>   
                    <li className={classes.properListItem}>What happens to you, the team or the organization if you do not manage these types of situations?</li>                              
                </ul>               
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
         
           
        </div> 
    )
}

export default withStyles(styles)(ConflictScenariosLN);