import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { deepDiffMapper } from '../../game_functions/functions';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import Button from '@material-ui/core/Button';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DiscCardDraggable from './../specific_components/cardDraggable';
import { Q1_FS, Q2_FS, Q1, Q2 } from './activityTwo';
import ForwardIcon from '@material-ui/icons/Forward';
import { Droppable } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';


const INITIAL_STATE = {
    open: false
};

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
  });

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    returnToResults = () => {
        this.props.moveBackToResultsPhase(this.props.session, this.props.auth.uid);
    }

    moveToFloorExercise = () => {
        this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor' });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setState({
            ...this.state,
            diff: deepDiffMapper.map(this.props.session.active_game.resultSnapshot === undefined ? {} : this.props.session.active_game.resultSnapshot, this.props.session.active_game.results)
        })
    }


    handleClickOpen = () => {
        this.setState({
            ...this.state,
            open: true
        });
    };
    
    handleClose = () => {
        this.setState({
            ...this.state,
            open: false
        });
    };

    render() {
    const {
      session,
      classes,
      auth,
      profile
    } = this.props;
    const { diff } = this.state;

    if ( diff === undefined ){
        return null;
    } else {
        return (
        <div>
             <DragDropContext>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h3">{session.active_game.name}</Typography>
                    <Typography variant='h5' className={classes.spaceBottom}>
                        Debrief
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {Object.keys(session.active_game.results)
                    .sort()
                    .map((group_id, ind) => {
                        if ( group_id === 'floor' ){
                            return null;
                        }
                        const before_choices = session.active_game.results[group_id] === undefined ? [] : session.active_game.results[group_id].before_choices === undefined ? [] : session.active_game.results[group_id].before_choices;
                      
                        return (
                        <div key={ind}>
                            <Typography variant="h4">{session.groups[group_id].title}</Typography>
                            <Grid container className={classes.paperExtra}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={12} xs={12}>
                                        <Typography variant='h6' style={{display:"inline-block", marginRight: 5}}>Your Choices</Typography> 
                                        <Droppable droppableId={`before_choices`} direction='vertical'>
                                            {(provided, snapshot) => (
                                                <List ref={provided.innerRef} className={`${classes.horizontalList} ${classes.listClass} ${classes.relativeList}`} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)} style={{display: 'flex', flexDirection: 'row', padding: 0, flexWrap: 'wrap', justifyContent: "flex-start"}}>
                                                
                                                { before_choices.map((card_id, index) => (                                        
                                                    <DiscCardDraggable session={session} profile={profile} col={`before_choices`} auth={auth} card_o={card_id} key={card_id.id} index={index} control={false}/>                          
                                                ))
                                                }
                                                {provided.placeholder}
                                                </List>
                                            )}
                                            </Droppable>
                                        </Grid>
                                       
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        );
                    })}
                </Grid>
                { profile.role === 'host' && 1==2 ?
                <Grid item xs={12} className={classes.spacingTop}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Button 
                                variant="contained"
                                color="primary"
                                startIcon={<AssignmentReturnIcon />}
                                onClick={this.returnToResults}
                                >Return to Activity For Another Round
                            </Button>
                        </Grid>
                        <Grid item xs={6} align="right">
                            <Button 
                                variant="contained"
                                color="primary"
                                startIcon={<ForwardIcon />}
                                onClick={this.moveToFloorExercise}
                                >Proceed to Floor Exercise
                            </Button>
                        </Grid>
                    </Grid>        
                </Grid>
                : null }
            </Grid>
            </DragDropContext>
        </div>
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
