import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Final_Image from '../images/final_image.png';
import Before_Image from '../images/before_image.png';
import { getUserGroup_hc, determineHost } from '../../game_functions/functions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import StarIcon from '@material-ui/icons/Star';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { editResultsFloor, moveBackToActiveWithParams } from './../../../../../store/actions/gameActions';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import ViewListIcon from '@material-ui/icons/ViewList';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { CARD_CAT_FEELER, CARD_CAT_SENSOR, CARD_CAT_INTUITOR, CARD_CAT_THINKER } from '../cards/cardList';
import { getPlayersListO } from './../../../../Players';

const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const discTendencies = ( group_key, classes ) => {
  switch (group_key) {
    case CARD_CAT_THINKER:
      return (
        <div>
          <Typography variant="h4">Thinker</Typography>
          <ul className={classes.properList}>
            <li className={classes.properListItem}><Typography variant="body1">Thinkers are typically detail-oriented and logical people.</Typography></li>
            <li className={classes.properListItem}><Typography variant="body1">They think about all aspects of an issue or project, and like to have clear instructions to follow.</Typography></li>
            <li className={classes.properListItem}><Typography variant="body1">They like to observe and see proof.</Typography></li>
          </ul>  
        </div>      
      );
    case CARD_CAT_SENSOR:
      return (
        <div>
          <Typography variant="h4">SENSOR</Typography>
          <ul className={classes.properList}>
            <li className={classes.properListItem}><Typography variant="body1">Sensors are typically results-oriented people who have a drive for productivity.</Typography></li>
            <li className={classes.properListItem}><Typography variant="body1">They jump to action quickly, and work efficiently in order to complete tasks.</Typography></li>
          </ul>  
        </div>      
      );
    case CARD_CAT_INTUITOR:
      return (
        <div>
          <Typography variant="h4">Intuitor</Typography>
          <ul className={classes.properList}>
            <li className={classes.properListItem}><Typography variant="body1">Intuitors are typically creative people.</Typography></li>
            <li className={classes.properListItem}><Typography variant="body1">They think big-picture and long-term.</Typography></li>
            <li className={classes.properListItem}><Typography variant="body1">They don’t like to get bogged down in specific details.</Typography></li>
          </ul>  
        </div>      
      );
    case CARD_CAT_FEELER:
      return (
        <div>
          <Typography variant="h4">Feeler</Typography>
          <ul className={classes.properList}>
            <li className={classes.properListItem}><Typography variant="body1">Feelers are typically team-oriented, sensitive people.</Typography></li>
            <li className={classes.properListItem}><Typography variant="body1">Are always considering the interests and comfort of others.</Typography></li>
            <li className={classes.properListItem}><Typography variant="body1">They work to motivate and understand others.</Typography></li>
          </ul>  
        </div>      
      );
    default:
      break;
  }
}

  const INITIAL_STATE = {
    selected : null,
    playersLoaded: true,
    open: true,
    tendenciesOpen: false
  }

class FloorExercise extends Component {
  constructor(props){
    super(props);

    this.state = {
      ...INITIAL_STATE
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0); 
    if ( this.props.players !== undefined ){ 
      
    }
  }

  revealFloor = () => {
    this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, { 'phase' : 'floor', 'done' : true });
}

  componentDidUpdate(prevProps, prevState){
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      open: false
    })
  }

  handleTendenciesClose = () => {
    this.setState({
      ...this.state,
      tendenciesOpen: false
    })
  }

  viewTendencies = () => {
    this.setState({
      ...this.state,
      tendenciesOpen: true
    })
  }

  returnToActive = () => {
    this.props.moveBackToActiveWithParams(this.props.session, this.props.auth.uid, {} );
  }

  maybePutIconDown = (event) => {
    if ( this.props.profile.role === 'host' || this.props.profile.role === 'moderator' ){
      return;
    }

    var bounds = event.target.getBoundingClientRect();
    var x = event.clientX - bounds.left;
    var y = event.clientY - bounds.top;

    x = (((x-9) / event.target.clientWidth) * 100);
    y = (((y-9) / event.target.clientHeight) * 100);

    let s = {
      x: `${x}%`,
      y: `${y}%`
    };
    this.props.editResultsFloor(this.props.auth.uid, s, determineHost(this.props.profile, this.props.auth));

  }

  onSelect = (id) => {
    if ( id !== undefined ){
        if ( this.state.selected === id ){
          this.setState({
            ...this.state,
            selected: null
          })
        } else {
          this.setState({
            ...this.state,
            selected: id
          })
        }
    }
  }

  Menu = (list, selected, players, classes) =>
  Object.keys(list).map(id => {
    return (
      <div align='center' key={id} onClick={(e) => this.onSelect(e)} className={classes.floorPerson}>
        <Avatar alt={players[id].displayName ? players[id].displayName : 'New Player'} className={this.state.selected === id ? classes.personSelected : null}></Avatar>
        <Typography variant='body1'>
          {players[id].displayName ? players[id].displayName : 'New Player'}
        </Typography>
      </div> 
    );
  });

  render() {
    
    const {
      session,
      profile,
      auth,
      classes,
    } = this.props;


    const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
    const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });
    const {selected, open, tendenciesOpen} = this.state;


    const players = getPlayersListO(session);

    if ( !this.state.playersLoaded ){
      return null;
    }

    const group_id = getUserGroup_hc(this.props.session, this.props.auth.uid, this.props.profile.role);
    const res = session.active_game.results.floor === undefined ? [] : session.active_game.results.floor;
    const list = this.Menu(res, selected, players, classes);

    
    return (
      <div>  
          <Typography variant='h4'>{session.active_game.name} - Floor Exercise</Typography>   
          <Typography variant="h6" className={classes.spaceBottom}>Your Style</Typography>
          <Typography variant="body1" className={classes.spaceBottom}>To plot your style, consider: Which style is your number closer to (second highest number)? Are all of your numbers close together? Is your primary style number much higher than the others?</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              { Object.keys(res).length > 0 ?
              <div><Typography variant="body1" className={classes.spaceBottom}>Players on the Diagram (Tap a Player to Highlight Their Star)</Typography>
              <ScrollMenu
                    menuClass={classes.floorList}
                    data={list}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={selected}
                    onSelect={this.onSelect}
                />
                </div>
              : null }
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classes.floorWrap2}>
                <div className={classes.floorItself2} onClick={(e) => this.maybePutIconDown(e)}>
                  {
                      session.active_game.results.hasOwnProperty('floor') ?                 
                        Object.keys(session.active_game.results.floor).map((star, ind) => {
                          
                          if ( this.state.selected === star ){
                            return <Tooltip key={ind} open={open} title={players[star].displayName}><StarIcon fontSize="large" className={`${classes.floorStar} ${classes.starSelected}`} style={{top: session.active_game.results.floor[star].y, left: session.active_game.results.floor[star].x}}/></Tooltip>
                          } else {
                            return <Tooltip key={`de_${ind}`} disableFocusListener title={players[star].displayName}><StarIcon fontSize="small" className={classes.floorStar} style={{top: session.active_game.results.floor[star].y, left: session.active_game.results.floor[star].x}}/></Tooltip>
                          }
                        }) 
                      : null
                  }
                </div>
                { this.props.session.active_game.gameState.params.done === undefined ? <img src={Before_Image} alt="DISC" className={classes.imageSize}/> : <img src={Final_Image} alt="DISC" className={classes.imageSize}/> }
              </div>
            </Grid>
            <Grid item xs={12}>
              { profile.role === 'host' ? 
                <Button 
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentReturnIcon />}
                    onClick={this.returnToActive}
                    style={{marginRight: "5px"}}
                    >
                Return to Cards Activity For Another Round
                </Button>
              : null }
              { profile.role === 'host' && this.props.session.active_game.gameState.params.done === undefined ? 
                <Button 
                    variant="contained"
                    color="primary"
                    startIcon={<AssignmentReturnIcon />}
                    onClick={this.revealFloor}
                    style={{marginRight: "5px"}}
                    >
                Reveal Wording
                </Button>
              : null }
              { session.active_game.results.hasOwnProperty('floor') ?        
               session.active_game.results.floor[auth.uid] !== undefined ?
                <div><Button 
                    variant="contained"
                    color="primary"
                    startIcon={<ViewListIcon />}
                    onClick={this.viewTendencies}
                    >
                View Your Style Tendencies
                </Button>
                <Dialog
                  open={tendenciesOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={this.handleTendenciesClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                      {discTendencies(session.groups[group_id].gameKey, classes)}
                  </DialogContent>                  
                </Dialog>
                </div>
                : null
                : null
              }
            </Grid>
          </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    editResultsFloor: (uid, value, hostID) => dispatch(editResultsFloor(uid, value, hostID)),
    moveBackToActiveWithParams: (session, host, parameters) => dispatch(moveBackToActiveWithParams(session, host, parameters))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(FloorExercise);