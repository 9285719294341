import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { moveBackToResultsPhase, moveBackToActiveWithParams } from '../../../../../store/actions/gameActions';
import Typography from '@material-ui/core/Typography';
import styles from '../../../../Styles';
import WalkProfileResult from './../specific_components/profileResult';
import { getUserGroup_hc } from '../../game_functions/functions';
import { PROFILE_EMPLOYEE, PROFILE_MANAGER, ProfileCategories } from './../profiles/profileList_temp';
import TextEntryList from './../specific_components/textEntryList';


const INITIAL_STATE = {
    open: false
};

class GameResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
          ...INITIAL_STATE,
        };
    }

    render() {
    const {
      session,
      classes,
      auth,
      profile
    } = this.props;

    return (
        
        <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
            <Typography variant="h3">{session.active_game.name}</Typography>
            <Typography variant='h5' className={classes.spaceBottom}>
                Debrief
            </Typography>
            <ul className="browser-default">
                    <li>What are your key learnings about the impact of conflict from this activity?</li>
                    <li>What best practices can you use to reduce the impact of conflict?</li>                   
            </ul>
            { /*: null */ }
        </Grid>
        <Grid item xs={12} sm={12}>
            {Object.keys(session.active_game.results)
            .sort()
            .map((groupKey, ind) => {
                return (
                <div key={ind}>
                    <Typography variant="h4" className={`${classes.spacingTop} ${classes.spacingBottom}`}>{session.groups[groupKey].title}</Typography>
                    <Grid container className={classes.spacingTop} spacing={3}>
                          <Grid item xs={12} sm={6}>
                              <Typography variant="body2" style={{fontWeight: "bold", marginBottom:"15px", fontSize: "14px"}}>What are your key learnings about the impact of conflict from this activity?</Typography>
                              <TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`notes`} debrief={true} hasControl={false}/>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <Typography variant="body2" style={{fontWeight: "bold", marginBottom:"15px", fontSize: "14px"}}>What best practices can you use to reduce the impact of conflict?</Typography>
                              <TextEntryList session={session} profile={profile} auth={auth} group_id={groupKey} resultsPath={session.active_game.results} finalPath={`notesBP`} debrief={true} hasControl={false}/>
                          </Grid>
                    </Grid>
                    
                    
                </div>
                );
            })}
        </Grid>
        </Grid>
        )    
    }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    moveBackToResultsPhase : (session,host) => dispatch(moveBackToResultsPhase(session,host)),
    moveBackToActiveWithParams : (session,host,params) => dispatch(moveBackToActiveWithParams(session,host,params))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GameResults);
