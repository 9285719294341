import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import {
    moveBackToResultsPhase,
    moveBackToActiveWithParams
} from '../../../../../store/actions/gameActions'
import Typography from '@material-ui/core/Typography'
import styles from '../../../../Styles'
import WalkProfileResult from './../specific_components/profileResult'
import { getUserGroup_hc } from '../../game_functions/functions'
import { ProfileList } from './../profiles/profileList'
import { PROFILE_VALUES } from '../profiles/profileList'

const INITIAL_STATE = {
    open: false
}

class GameResults extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...INITIAL_STATE
        }
    }

    render() {
        const { session, classes, auth, profile } = this.props

        return (
            <div>
                <Typography variant="h3">{session.active_game.name}</Typography>
                <Typography variant="h5" className={classes.spaceBottom}>
                    Debrief
                </Typography>

                {Object.keys(session.active_game.results)
                    .sort()
                    .map((group_id, ind) => (
                        <div key={ind}>
                            <Typography
                                variant="h4"
                                className={`${classes.spacingTop} ${classes.spacingBottom}`}
                            >
                                {session.groups[group_id].title}
                            </Typography>
                            {ProfileList.map((p, ind) => {
                                return (
                                    <Grid
                                        container
                                        spacing={3}
                                        key={ind}
                                        className={`${classes.spacingBottom} ${classes.walkResult}`}
                                    >
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h5"
                                                className={`${classes.spaceBottom} ${classes.profileTypeDescriptor} ${classes.minorMargin}`}
                                                align="center"
                                            >
                                                Scenario {p.profile_step}
                                                <br />
                                                <span
                                                    className={
                                                        classes.profileScenario
                                                    }
                                                ></span>
                                            </Typography>
                                            <div>
                                                <WalkProfileResult
                                                    step={p.profile_step}
                                                    session={session}
                                                    profile={profile}
                                                    auth={auth}
                                                />
                                            </div>
                                            <List className={classes.listClass}>
                                                {session.active_game.results[
                                                    group_id
                                                ].hasOwnProperty(
                                                    `step_${p.profile_step}`
                                                ) ? (
                                                    session.active_game.results[
                                                        group_id
                                                    ][`step_${p.profile_step}`]
                                                        .choice !== false &&
                                                    session.active_game.results[
                                                        group_id
                                                    ][`step_${p.profile_step}`]
                                                        .choice !==
                                                        undefined ? (
                                                        <div
                                                            className={
                                                                classes.padMe
                                                            }
                                                        >
                                                            <Typography variant="body1">
                                                                You chose that
                                                                they{' '}
                                                                {session
                                                                    .active_game
                                                                    .results[
                                                                    group_id
                                                                ][
                                                                    `step_${p.profile_step}`
                                                                ].verb ===
                                                                'lacking'
                                                                    ? 'were '
                                                                    : ''}
                                                                <span
                                                                    className={
                                                                        classes.boldedText
                                                                    }
                                                                >
                                                                    {
                                                                        session
                                                                            .active_game
                                                                            .results[
                                                                            group_id
                                                                        ][
                                                                            `step_${p.profile_step}`
                                                                        ].verb
                                                                    }
                                                                </span>{' '}
                                                                <span
                                                                    className={
                                                                        classes.boldedText
                                                                    }
                                                                >
                                                                    {
                                                                        PROFILE_VALUES[
                                                                            session
                                                                                .active_game
                                                                                .results[
                                                                                group_id
                                                                            ][
                                                                                `step_${p.profile_step}`
                                                                            ]
                                                                                .choice
                                                                        ].title
                                                                    }
                                                                </span>
                                                                .
                                                            </Typography>
                                                        </div>
                                                    ) : (
                                                        <Typography
                                                            variant="body1"
                                                            className={
                                                                classes.padMe
                                                            }
                                                        >
                                                            You didn't make a
                                                            choice for this
                                                            scenario.
                                                        </Typography>
                                                    )
                                                ) : (
                                                    <Typography
                                                        variant="body1"
                                                        className={
                                                            classes.padMe
                                                        }
                                                    >
                                                        You didn't make a choice
                                                        for this scenario.
                                                    </Typography>
                                                )}
                                            </List>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </div>
                    ))}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        moveBackToResultsPhase: (session, host) =>
            dispatch(moveBackToResultsPhase(session, host)),
        moveBackToActiveWithParams: (session, host, params) =>
            dispatch(moveBackToActiveWithParams(session, host, params))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withStyles(styles)
)(GameResults)
