
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogContentText, DialogTitle,DialogActions, Grid, IconButton, TextField, TableContainer, Table, 
  TableBody, TableCell, Collapse, TableHead, TableRow, Box, } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CreateIcon from '@material-ui/icons/Create';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { signOut } from '../../store/actions/authActions';
import Footer from '../Footer';
import LogoHeader from '../Header';
import styles from '../Styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import {sendContactEmail,closeAdminPopUps} from '../../store/actions/authActions';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LiquidLoadingBlock from './../Loading/loadingCup';

import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';


import ContactForm from './form';




const INITIAL_STATE = {
 formVisible: true
};

 

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
        ...INITIAL_STATE
        };

    }

    componentDidUpdate(){
      console.log('am', this.props.authMessage);
      if ( this.props.authMessage !== null && this.state.formVisible === true ){
          this.setState({
            ...this.state,
            formVisible: false
          })
      }
    }


    onSubmit = (name, company, email, phone, message) => {
      this.props.sendContactEmail(name, company, email, phone, message);
    }


    render() {
        const { t, authMessage, isLoading } = this.props;
        
       

        const name = this.props.profile === undefined ? '': this.props.profile.displayName;



        return (
            <div className='meta-react'>
               
                <LogoHeader t={t} session={this.props.current_session} profile={this.props.profile} auth={this.props.auth} />
                <div className='outer-container'>
                    <div className='container'>
                        <Typography variant="h2" style={{marginBottom:"15px"}}>Contact Us</Typography>

                        { isLoading === true ?
                        <LiquidLoadingBlock/>
                          :
                        <ContactForm visible={this.state.formVisible} onSubmit={this.onSubmit}/>
                        }
                    </div>
                </div>
               
                { this.props.auth.uid !== undefined ? <Footer profile={this.props.profile} auth={this.props.auth}  session={this.props.session}/> : null }
            </div>
        );

    }
}

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.gol_auth.isLoading,
    authError: state.gol_auth.authError,
    authMessage: state.gol_auth.authMessage,
    authPopUp: state.gol_auth.authPopUp,
    authErrorPopUp: state.gol_auth.authErrorPopUp,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAdminPopUps: () => dispatch(closeAdminPopUps()),
    sendContactEmail: (name, company, email, phone, message) => dispatch(sendContactEmail(name, company, email, phone, message))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation()     
)(Contact);