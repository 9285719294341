import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import styles from './../../../../Styles';
import Grid from '@material-ui/core/Grid';
import ImageGallery from 'react-image-gallery';
import Notes from './../images/leader_notes.pdf';
import Notes_Detailed from './../images/leader_notes_detailed.pdf';
import Presentation from './../images/presentation.pptx';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from './../../../../GamesList/leaderNotes';


const CircleOfInfluenceLN = (props) => {
    const { classes } = props;
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };


    return (
        <div className={classes.tabsRoot}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabsLN}
            >
                <Tab style={{paddingTop: "20px"}} className={classes.tabsLNTab} label="Activity Explanation" {...a11yProps(0)} />
                <Tab className={classes.tabsLNTab} label="Key Learnings" {...a11yProps(1)} />
                <Tab className={classes.tabsLNTab} label="Introduction" {...a11yProps(2)} />
                <Tab className={classes.tabsLNTab} label="Instructions and Rules" {...a11yProps(3)} />
                <Tab className={classes.tabsLNTab} label="Debrief" {...a11yProps(4)} />
                <Tab className={classes.tabsLNTab} label="Download Resources" {...a11yProps(5)} />
            </Tabs>
            <TabPanel className={classes.LNTabPanel}  value={value} index={0}>
                <Typography variant="h5">Facilitator Information on the Activity</Typography>
                <Typography variant="body1" className={classes.LNProperStart}>This is a 2-part activity. Part 1: Based on the work of Stephen Covey, teams
consider work issues that we have influence over and work areas that are
concerns with seemingly no influence. Teams make lists of these two areas
that will be debriefed with the whole group. Debriefing this activity involves
thinking about what happens if we are consumed with our circle of concern
and the negative energy that can surround us. Part 2: Participants go back into
teams, revisit their list of concerns, and identify how they can add influence in
these areas. This debrief looks at when we focus on what we can’t influence,
our circle of influence shrinks and when we focus on what we can influence,
our circle of concern shrinks. Covey notes that highly effective people think
and act primarily within their Circle of Influence.</Typography>
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={1}>
                <Typography variant="h5">Key Learnings</Typography>   
                <ul className={classes.properList}>
                    <li className={classes.properListItem}>Review your circle of concern</li>
                    <li className={classes.properListItem}>Learn how to limit what you can’t influence and focus on what you can
influence</li>
                    <li className={classes.properListItem}>Think about how you can expand your circle of influence</li>
                    <li className={classes.properListItem}>Focus on things you can control and don’t waste energy on the things you
cannot</li>
                    <li className={classes.properListItem}>Understand impact of negative energy in the workplace</li>
                </ul>             
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel} value={value} index={2}>
                <Typography variant="h5">Set-Up</Typography>  
                <Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>
                    Circle of Concern:
                </Typography>   
                <Typography variant="body1"  className={classes.LNProperStart}>Our health, our children, problems at work, future of the company, politics,
the economy, the threat of war</Typography>   
<Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>
                    Circle of Influence:
                </Typography>   
                <Typography variant="body1"  className={classes.LNProperStart}>Concerns that we can do something about or have control over</Typography>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel}  value={value} index={3}>
                <Typography variant="h5">Exercise</Typography>     
                    <ul className={classes.properList}>
                            <li className={classes.properListItem}>In teams, you will use the 2 circles in this activity to list your work concerns</li>
                            <li className={classes.properListItem}>First, add all of your work concerns to the outside circle</li>
                            <li className={classes.properListItem}>Second, think about what you have influence over - add to inside circle</li>
                        </ul> 
                                    
            </TabPanel>
            <TabPanel  className={classes.LNTabPanel}  value={value} index={4}>
                <Typography variant="h5">Debrief</Typography> 
                <ul className={classes.properList}>
                            <li className={classes.properListItem}>What happens if we are consumed with our circle of concern?</li>
                            <li className={classes.properListItem}>We have negative energy surrounding us</li>   
                            <li className={classes.properListItem}>How do we act when we are stressed or worried? What can negative
energy look like?</li>        
                            <li className={classes.properListItem}>Albert Einstein’s formula E = MC2.</li>
                            <li className={classes.properListItem}>What did we learn about EI? = we “catch” emotions</li>   
                            <li className={classes.properListItem}>When we focus on what we can’t influence, our circle of influence shrinks</li>  
                            <li className={classes.properListItem}>When we focus on what can influence, our circle of concern shrinks</li>
                            <li className={classes.properListItem}>Limit what you can’t influence; focus on what you can influence</li>   
                            <li className={classes.properListItem}>Covey notes that highly effective people think and act primarily within
their Circle of Influence</li>                      
                        </ul>    
                        <Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>
                            Exercise:
                        </Typography>  
                        <ul className={classes.properList}>
                            <li className={classes.properListItem}>Review your circle of concern</li>
                            <li className={classes.properListItem}>What can you influence?</li>
                            <li className={classes.properListItem}>Expand your circle of influence</li>
                        </ul>  
                        <Typography variant="body1"  className={`${classes.LNProperStart} ${classes.LNBoldTitle}`}>
                        Focus on things you can control and don’t waste energy on the things you cannot.
                        </Typography>            
            </TabPanel>
            <TabPanel className={classes.LNTabPanel} value={value} index={5}>
            <Typography variant="h5">Download Resources</Typography>  
                <a href={Notes} download="leader_notes.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Notes
                </Button>
                </a>
                <a href={Notes_Detailed} download="leader_notes_detailed.pdf"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                    style={{marginRight: 5}}
                >
                    Download Detailed Notes
                </Button>
                </a>
                <a href={Presentation} download="presentation.pptx"><Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={`${classes.LNProperStart}`}
                    startIcon={<GetAppIcon />}
                >
                    Download Presentation
                </Button>
                </a>
            </TabPanel>
           
         
           
        </div>         
    )
}

export default withStyles(styles)(CircleOfInfluenceLN);