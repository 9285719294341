import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
//import { firestoreConnect } from 'react-redux-firebase';

//import { makeStyles, useTheme } from '@material-ui/core/styles';
//import { getFirebase } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
//import Grid from '@material-ui/core/Grid';
//import Footer from '../Footer';
//import LogoHeader from '../Header';
//import { maybeCreateSession } from '../../store/actions/sessionActions';
import SessionBase from '../Session';
//import PlayersBase from '../Players';

//import GamesList from '../GamesList';

import { endGame } from '../../store/actions/gameActions';
import { endSession, logError } from '../../store/actions/sessionActions';
//import Game from '../Game';

import LoadingBlock from '../Loading';
import ErrorBoundary from './errorBoundary';
//import MuiAlert from '@material-ui/lab/Alert';
//import { auth } from 'firebase';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
};

/*function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}
*/
const INITIAL_STATE = {
  //pagePromo: 'olff8ViVEXKmuBK179Zs', //This is the id of the promo, this will change to load from the url once we have more then one promo,
  loading: true,
};

class HomePageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });

    if (this.props.auth.uid && !this.props.auth.isAnonymous && this.props.user.isLoaded) {

    }
  }

  endTheGame = () => {
    console.log('ENDING THE GAME');
    this.props.endGame();
  }

  logTheInfo = (error, info) => {
    this.props.logError(error, info);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    //console.log(this.props);
    const {
      auth,
      classes
    } = this.props;

    if (!auth.uid) return <Redirect to={ROUTES.DEFAULT} />;
    if (auth.uid && this.props.user.isLoaded) {
      if ( this.props.user.isLoaded && this.props.user.status === 'Disabled' ){
        return <div>Your account has been disabled.</div>;
      }
      if (this.props.user.isLoaded && !this.props.user.displayName && this.props.user.role === 'player') {
        return <Redirect to={ROUTES.ANON_SIGN_IN_NID} />;
      }
      return <ErrorBoundary endGame={this.endTheGame} classes={classes} profile={this.props.user} logTheInfo={this.logTheInfo}><SessionBase auth={this.props.auth} profile={this.props.user} /></ErrorBoundary>;
    } else {
      return <LoadingBlock />;
    }
  }
}

HomePageBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    endGame: () => dispatch(endGame()),
    endSession: () => dispatch(endSession()),
    logError: (error, info) => dispatch(logError(error, info))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(HomePageBase);