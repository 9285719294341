export const CARDS_LIST = {
    1 : 'club_1',
    2 : 'club_2', 
    3 : 'club_3',
    4 : 'club_4',
    5 : 'club_5',
    6 : 'club_6',
    7 : 'club_7',
    8 : 'club_8',
    9 : 'club_9',
    10 : 'club_10',
    11 : 'club_jack',
    12 : 'club_queen',
    13 : 'club_king',
    14 : 'heart_1',
    15 : 'heart_2', 
    16 : 'heart_3',
    17 : 'heart_4',
    18 : 'heart_5',
    19 : 'heart_6',
    20 : 'heart_7',
    21 : 'heart_8',
    22 : 'heart_9',
    23 : 'heart_10',
    24 : 'heart_jack',
    25 : 'heart_queen',
    26 : 'heart_king',
    27 : 'spade_1',
    28 : 'spade_2', 
    29 : 'spade_3',
    30 : 'spade_4',
    31 : 'spade_5',
    32 : 'spade_6',
    33 : 'spade_7',
    34 : 'spade_8',
    35 : 'spade_9',
    36 : 'spade_10',
    37 : 'spade_jack',
    38 : 'spade_queen',
    39 : 'spade_king',
    40 : 'diamond_1',
    41 : 'diamond_2', 
    42 : 'diamond_3',
    43 : 'diamond_4',
    44 : 'diamond_5',
    45 : 'diamond_6',
    46 : 'diamond_7',
    47 : 'diamond_8',
    48 : 'diamond_9',
    49 : 'diamond_10',
    50 : 'diamond_jack',
    51 : 'diamond_queen',
    52 : 'diamond_king',
};



export const CLUBS = [1,2,3,4,5,6,7,8,9,10,11,12,13];
export const HEARTS = [14,15,16,17,18,19,20,21,22,23,24,25,26];
export const SPADES = [27,28,29,30,31,32,33,34,35,36,37,38,39];
export const DIAMONDS = [40,41,42,43,44,45,46,47,48,49,50,51,52];

export const ACES = [1,14,27,40];
export const TWOS = [2,15,28,41];
export const THREES = [3,16,29,42];
export const FOURS = [4,17,30,43];
export const FIVES = [5,18,31,44];
export const SIXES = [6,19,32,45];
export const SEVENS = [7,20,33,46];
export const EIGHTS = [8,21,34,47];
export const NINES = [9,22,35,48];
export const TENS = [10,23,36,49];
export const JACKS = [11,24,37,50];
export const QUEENS = [12,25,38,51];
export const KINGS = [13,26,39,52];


export const WON = {
    1 : [...ACES, ...KINGS, ...QUEENS, ...JACKS],
    2 : [...TWOS, ...THREES, ...FOURS, ...FIVES],
    3 : [...SIXES, ...SEVENS, ...EIGHTS, ...NINES]
};
//console.log('won', WON);

export const SETS = {
    1 : 'Aces, Kings, Queens, and Jacks',
    2 : 'Twos, Threes, Fours, and Fives',
    3 : 'Sixes, Sevens, Eights, and Nines'
}
export const SETS_EVALUATE = {
    1 : {
        1 : [...ACES],
        2 : [...KINGS],
        3 : [...QUEENS],
        4 : [...JACKS]
    },
    2 : {
        1 : [...TWOS],
        2 : [...THREES],
        3 : [...FOURS],
        4 : [...FIVES]
    },
    3: {
        1 : [...SIXES],
        2 : [...SEVENS],
        3 : [...EIGHTS],
        4 : [...NINES]
    }
}

export const ROWS = [
    ['1x1','1x2','1x3','1x4'],
    ['2x1','2x2','2x3','2x4'],
    ['3x1','3x2','3x3','3x4'],
    ['4x1','4x2','4x3','4x4']
];

export const COLS = [
    ['1x1','2x1','3x1','4x1'],
    ['1x2','2x2','3x2','4x2'],
    ['1x3','2x3','3x3','4x3'],
    ['1x4','2x4','3x4','4x4']
];

export const CARDS = [
    'club_1',
    'club_2', 
    'club_3',
    'club_4',
    'club_5',
    'club_6',
    'club_7',
    'club_8',
    'club_9',
    'club_10',
    'club_jack',
    'club_queen',
    'club_king',
    'heart_1',
    'heart_2', 
    'heart_3',
    'heart_4',
    'heart_5',
    'heart_6',
    'heart_7',
    'heart_8',
    'heart_9',
    'heart_10',
    'heart_jack',
    'heart_queen',
    'heart_king',
    'spade_1',
    'spade_2', 
    'spade_3',
    'spade_4',
    'spade_5',
    'spade_6',
    'spade_7',
    'spade_8',
    'spade_9',
    'spade_10',
    'spade_jack',
    'spade_queen',
    'spade_king',
    'diamond_1',
    'diamond_2', 
    'diamond_3',
    'diamond_4',
    'diamond_5',
    'diamond_6',
    'diamond_7',
    'diamond_8',
    'diamond_9',
    'diamond_10',
    'diamond_jack',
    'diamond_queen',
    'diamond_king',
];

export const CARD_BACK = 'back';
