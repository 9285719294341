import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Avatar from '@material-ui/core/Avatar';
import {
    hasGameControl,
    determineHost
  } from '../../game_functions/functions';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { updateGroupState, updateGroupStateParams, updateResults, editResults, removeResults, editResultsPath, removeResultsPath, updateGroupStateStateVal } from '../../../../../store/actions/gameActions';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styles from '../../../../Styles';
import AddIcon from '@material-ui/icons/Add';
import TextLogger from './textLogger';
import TextEntryList from './textEntryList';


/* updateGroupStateParams to toggle the profiles, etc */

const INITIAL_STATE = {
    open: false,
    isEdit: false,
    entry_text: '',
}

const getTimeEpoch = () => {
    return Date.now();
}

function capitalizeFLetter(s) { 
    if ( s !== undefined ){
        return s[0].toUpperCase() + s.slice(1); 
    } else {
        return s;
    }
}


class TextEntry extends Component {
    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_STATE
        }
    }

    componentDidMount(){
      //console.log('what the feck', this.props.savePath);
    }


    componentDidUpdate(prevProps, prevState) {
        /* When a leader opens the writing window, we want to show it for all users */
        if ( ( this.props.session.active_game.groupStates[this.props.group_id].params.writing !== undefined && this.props.session.active_game.groupStates[this.props.group_id].params.writing !== false ) && (this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === undefined || this.props.session.active_game.groupStates[this.props.group_id].params.interrupting === false) && this.state.open === false ) {
          if ( this.props.finalPath === this.props.session.active_game.groupStates[this.props.group_id].params.writingWhat ){
            this.setState({
              ...this.state,
              open: true
            })
          }
        }
        /* When a leader closes the writing window, we want to close it for all users */
        if ( this.props.session.active_game.groupStates[this.props.group_id].params.writing === false && this.state.open === true ) {
          this.setState({
            ...this.state,
            open: false
          })
        }
    }


    addItem = () => {
        let group_id = this.props.group_id;
        const hasControl = hasGameControl(this.props.session.groups[group_id].leadPlayer, this.props.auth.uid, this.props.profile);
    
        console.log('path', this.props.savePath);

        if ( !hasControl ){
          return;
        }

        

        this.setState({
            ...this.state,
            entry_text: '',
            isEdit: false           
        });
    
        let s = {
          ...this.props.session.active_game.groupStates[group_id].params,
          writing: this.props.auth.uid,
          writingWhat: this.props.finalPath
        };
        this.props.updateGroupStateParams(group_id, s, determineHost(this.props.profile, this.props.auth));
      }
    
      handleClose = (event) => {
        if ( !hasGameControl(this.props.session.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile) ){
          return;
        }
 
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: false,
          writingWhat: false,
          ttd: ""
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
      };
    
      handleSave = (event, t) => {
        
        console.log('path', this.props.savePath, t);

        let p = `${this.props.savePath}.${getTimeEpoch()}`;
        this.props.editResultsPath(p, this.state.entry_text.replaceAll('\n', '<br/>'), determineHost(this.props.profile, this.props.auth));
    
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: false,
          writingWhat: false,
          ttd: ""
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
    
        this.setState({
          ...this.state,
          open: false,
        });
      };
    
      
    
      itemAction = (index, value, col) => {
        this.setState({
          ...this.state,
          isEdit: index,
          entry_text: value.replaceAll('<br/>', '\n')
        });
    
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: this.props.auth.uid,
          writingWhat: this.props.finalPath,
          ttd: value.replaceAll('<br/>', '\n')
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
      };
    
      updateRow = (row) => {
        let p = `${this.props.savePath}.${row}`;
        this.props.editResultsPath(p, this.state.entry_text.replaceAll('\n', '<br/>'), determineHost(this.props.profile, this.props.auth));
    
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: false,
          writingWhat: false,
          ttd: false
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
    
        this.setState({
          ...this.state,
          isEdit: false,
        });
      };

      deleteRow = (row) => {
        
        let p = `${this.props.savePath}.${row}`;
        this.props.removeResultsPath(p, null, determineHost(this.props.profile, this.props.auth));
    
        let s = {
          ...this.props.session.active_game.groupStates[this.props.group_id].params,
          writing: false,
          writingWhat: false,
        };
        this.props.updateGroupStateParams(this.props.group_id, s, determineHost(this.props.profile, this.props.auth));
    
        this.setState({
          ...this.state,
          isEdit: false,
        });
      };
    
      onChange = (event) => {
        this.setState({ ...this.state, [event.target.name]: event.target.value });    
      };

  

  render() {
      
    const { classes, session, profile, auth, group_id, savePath } = this.props;
    const { open, entry_text } = this.state;


    const hasControl = hasGameControl(this.props.session.groups[this.props.group_id].leadPlayer, this.props.auth.uid, this.props.profile);
    const isInvalid = entry_text === '';

    const writingName = this.props.session.active_game.groupStates[this.props.group_id].params.writing !== undefined && this.props.session.active_game.groupStates[this.props.group_id].params.writing !== false ? 
        this.props.session.playerProfiles[this.props.session.active_game.groupStates[this.props.group_id].params.writing] === undefined ? 'Facilitator' : this.props.session.playerProfiles[this.props.session.active_game.groupStates[this.props.group_id].params.writing].displayName 
    : 'Group Leader';

    return (
        <div>
            { /* Dialogue for Adding/Editing Notes */}
            <Dialog id={this.props.finalPath} key={this.props.finalPath} open={open} onClose={this.handleClose} aria-labelledby='form-dialog-title' maxWidth='md' fullWidth>
                { hasControl === true && this.state.open ?
                        this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid ?
                        <TextLogger ttd={this.props.session.active_game.groupStates[this.props.group_id].params.ttd} group_id={group_id} val={entry_text} host={determineHost(this.props.profile, this.props.auth)}/>
                        : null
                    : null
                }
                <DialogContent>
                <DialogContentText className={classes.bodyText}>{ hasControl === true ?  `` : `${writingName} is typing...` }</DialogContentText>
                { hasControl ?

                    this.props.session.active_game.groupStates[this.props.group_id].params.writing !== auth.uid ?
                        <div className={classes.spacingBottom}>{this.props.session.active_game.groupStates[this.props.group_id].params.ttd}</div>
                    : <TextField multiline autoFocus autoComplete="off" margin='dense' id='entry_text' name='entry_text' value={entry_text} onChange={this.onChange} label="Note" type='email' fullWidth /> 
                : 
                    <div className={classes.spacingBottom}>{this.props.session.active_game.groupStates[this.props.group_id].params.ttd}</div>
                }
                </DialogContent>
                <DialogActions>
                { hasControl === true && this.props.session.active_game.groupStates[this.props.group_id].params.writing === auth.uid ? 
                this.state.isEdit !== false ? (
                    <div>
                    <Button onClick={() => this.deleteRow(this.state.isEdit)} color='secondary'>
                        Delete
                    </Button>
                    <Button onClick={this.handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={() => this.updateRow(this.state.isEdit)} color='primary'>
                        Save
                    </Button>
                    </div>
                ) : (
                    <div>
                    <Button onClick={this.handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button disabled={isInvalid} onClick={(e) => this.handleSave(e, this.props.savePath)} color='primary'>
                        Save
                    </Button>
                    </div>
                )
                : null }
                </DialogActions>
            </Dialog>          
            <Grid container spacing={3}>
                
                <Grid item sm={12} xs={12}>
                    <TextEntryList session={session} profile={profile} auth={auth} group_id={group_id} resultsPath={session.active_game.results} finalPath={this.props.finalPath} debrief={false} hasControl={hasControl} itemAction={this.itemAction}/>
                </Grid> 
                <Grid item sm={12} xs={12} align="right">
                    <Button type='submit' disabled={hasControl === false} variant="contained" color="primary" onClick={() => this.addItem()} startIcon={<AddIcon/>}>
                    Add Note
                    </Button>
                </Grid>
                
            </Grid>     
        </div>
    );
  }
}

const mapStateToProps = (state) => {
        return {}
    };
    
    const mapDispatchToProps = (dispatch) => {
        return {        
            updateGroupStateStateVal: (group, groupState, hostID) => dispatch(updateGroupStateStateVal(group, groupState, hostID)),
            updateGroupState: (group, groupState, hostID) => dispatch(updateGroupState(group, groupState, hostID)),
            updateGroupStateParams: (group, groupStateParams, hostID) => dispatch(updateGroupStateParams(group, groupStateParams, hostID)),
            updateResults: (group, top_level, value, hostID) => dispatch(updateResults(group, top_level, value, hostID)),
            editResults: (group, top_level, value, hostID) => dispatch(editResults(group, top_level, value, hostID)),
            editResultsPath: (path, value, hostID) => dispatch(editResultsPath(path, value, hostID)),
            removeResults: (group, top_level, value, hostID) => dispatch(removeResults(group, top_level, value, hostID)),
            removeResultsPath: (group, value, hostID) => dispatch(removeResultsPath(group, value, hostID))
        };
    };

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(TextEntry);



