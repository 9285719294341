import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LiquidLoadingBlock from '../Loading/loadingCup';
import styles from '../Styles';

class PlayersLobby extends Component {

  render() {
    const { classes } = this.props;
    if ( this.props.session === undefined ){
      return null;
    }
    let full_players_list = this.props.session.playerProfiles;
    if (full_players_list) {
      return (
        <Grid container className={classes.avatarRoot} spacing={2}>
          <Grid item xs={12} align='center'>
            <Typography variant='h3' className={`${classes.nameSpacing} ${classes.greyText}`}>
              Player Lobby
            </Typography>
          </Grid>
          {Object.keys(full_players_list).sort(function(a,b){ if ( full_players_list[a].displayName < full_players_list[b].displayName ){ return -1; } if ( full_players_list[a].displayName > full_players_list[b].displayName ){ return 1;} return 0; }).map((player, index) => {
            //console.log("player check:", player, this.props.session.players);
            if ( !this.props.session.players.includes(player) ){
              return null;
            }
            return (
              <Grid item align='center' key={index}>
                <Avatar alt={full_players_list[player].displayName ? full_players_list[player].displayName : 'New Player'} className={` ${classes.large} ${classes.glassPurpleAvatar}`}></Avatar>
                <Typography variant='body1' className={classes.nameSpacing}>
                  {full_players_list[player].displayName ? full_players_list[player].displayName : 'New Player'}
                </Typography>
              </Grid>
            );
          })}
          <Grid item xs={12} align='center' className={classes.spacingTop}>
          <br/><br/><br/>
              <LiquidLoadingBlock />
            </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    full_players_list: state.firestore.data.players,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(PlayersLobby);