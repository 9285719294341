import { Image, Transformer } from "react-konva";
import React, { useEffect } from "react";
import { createPiecesGroup, createSwoop, createWindow, createEll, createPiece } from '../utils/createPiecesGroup'
import { BLOCKS_SIDEBAR } from './../data/data'

import useImage from "use-image";

export const URLImage = ({
  image,
  isDrawing,
  scaleX,
  scaleY,
  handleDragEnd,
  dragStart,
  transformEnd,
  control,
  openContext,
  deselectAll,
  resetDeselectAll,
}) => {
  const [img] = useImage(image.src);
  const trRef = React.useRef();
  const imgRef = React.useRef();
  const [isSelected, setIsSelected] = React.useState([false]);

  useEffect(() => {
    if (deselectAll === true) {
      setIsSelected(false);
      resetDeselectAll();
    }
  }, [deselectAll]);


  let offsetX = img ? (parseInt(image.width)) / 2 : 0;
  let offsetY = img ? (parseInt(image.height)) / 2 : 0;

  const canvasWidth = 1160;
  const canvasHeight = 700;

  const contextHandler = (e) => {
    e.evt.preventDefault();
    setIsSelected(true);
    openContext(e.evt, image.id);
  };

  //console.log('image: ', image);
  const getPiece = () => {

    var piece = BLOCKS_SIDEBAR.filter(obj => {
      //console.log('objs', obj.id, draggedEl.id);
      return obj.id === parseInt(image.dataId)
    });
    //console.log(piece[0]);
    piece = piece[0];


    let el = null;
    //console.log('what', piece.func);
    if (piece.func === 'block') {
      el = createPiece(
        piece.blockWidth,
        piece.blockHeight,
        piece.color,
        piece.baseType,
        piece.id,
        image.x,
        image.y
      )
    } else if (piece.func === 'window') {
      el = createWindow(
        piece.blockWidth,
        piece.blockHeight,
        piece.color,
        piece.baseType,
        piece.id,
        image.x,
        image.y
      )
    } else if (piece.func === 'ell') {
      el = createEll(
        piece.blockWidth,
        piece.blockHeight,
        piece.color,
        piece.baseType,
        piece.id,
        image.x,
        image.y
      )
    } else if (piece.func === 'swoop') {
      el = createSwoop(
        piece.blockWidth,
        piece.blockHeight,
        piece.color,
        piece.baseType,
        piece.id,
        image.x,
        image.y
      )
    }
    return el;
  };

  return (
    <>
      <Image
        ref={imgRef}
        name="object"
        image={img}
        x={image.x}
        y={image.y}
        id={image.id}
        dataId={image.dataId}
        rotation={image.rotation}
        scaleX={scaleX !== undefined ? scaleX : 1}
        scaleY={scaleY !== undefined ? scaleY : 1}
        onClick={(e) => {
          e.evt.preventDefault();
          if (e.evt.button === 0) {
            setIsSelected(isSelected === true ? false : true);
          }
        }}
        dragBoundFunc={(pos) => {
          var newY = pos.y < offsetY ? offsetY : pos.y;
          newY = newY > canvasHeight ? canvasHeight : newY;

          //console.log('COORDS: ', pos.x, offsetX);

          var newX = pos.x < offsetX + 10 ? offsetX + 10 : pos.x;
          newX = newX > canvasWidth - offsetX ? canvasWidth - offsetX : newX;

          //console.log('NEW: ', newX);

          return {
            x: newX,
            y: newY,
          };
        }}

        // I will use offset to set origin to the center of the image
        offsetX={offsetX}
        offsetY={offsetY}
        draggable={control === true ? true : false}
        onDragEnd={handleDragEnd}
        onDragStart={dragStart}
        onTransformEnd={transformEnd}
      />


    </>
  );
};
