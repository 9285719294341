import React, { Component } from 'react'
import Footer from '../Footer';
import LogoHeader from '../Header';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LiquidLoadingBlock from './../Loading/loadingCup';

class ErrorBoundary extends Component {
    constructor(props){
        super(props)
        this.state = { error: null, errorInfo: null, showButton: true }
    }
    
    // For this example we'll just use componentDidCatch, this is only 
    // here to show you what this method would look like.
    // static getDerivedStateFromProps(error){
        // return { error: true }
    // }

    componentDidCatch(error, info){
        this.setState({ error: error, errorInfo: info });
        if ( this.props.profile.role === 'host' ) {
            this.props.logTheInfo(error, info);
        }
    }

    endTheCurrentGame = () => {
        //console.log('we gotta end this');
        this.setState({
            ...this.state,
            showButton: false
        })
        this.props.endGame();
        setTimeout(() => {            
            window.location.reload(true);
        },3500);
    }

    render() {
        if(this.state.errorInfo){
            return (
                <div className='meta-react'>
                    <LogoHeader/>
                    <div className='outer-container'>
                        <div className='container'>
                            <div className="error-boundary">
                                <Typography variant="h3">Oops, VirtualGlass has encountered an error</Typography>
                                <Typography variant="body1">Details are listed below. Please contact an admin to seek resolution.</Typography>
                                <details style={{ whiteSpace: 'pre-wrap' }}>
                                    { this.state.error && this.state.error.toString() }
                                    { this.state.errorInfo.componentStack }
                                </details>
                                { this.props.profile.role === 'host' || this.props.profile.role === 'moderator' ?
                                    <>
                                        <Typography variant="h6" style={{marginTop: "30px"}}>Host/Moderator Tools</Typography>
                                        { this.state.showButton === true ?
                                            <Button variant="contained" onClick={() => this.endTheCurrentGame()} color="primary" style={{marginTop: "20px"}}>End Current Activity</Button>
                                        :
                                            <LiquidLoadingBlock/>
                                        }
                                    </>
                                : null }
                                
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>

                
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary;